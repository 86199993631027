import { Button } from '@acheloisbiosoftware/absui.core';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PropTypes from 'prop-types';
import React from 'react';
import { withOktaAuth } from '@okta/okta-react';

class Logout extends React.Component {
  static propTypes = {
    // The variant of the Button component to use.
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),

    // The color of the Button component to use.
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),

    // Internal authentication prop.
    oktaAuth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
      tokenManager: PropTypes.shape({
        clear: PropTypes.func.isRequired,
      }),
    }).isRequired,
  };

  static defaultProps = {
    variant: 'text',
    color: 'inherit',
  };

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  async logout() {
    await this.props.oktaAuth.signOut();
    this.props.oktaAuth.tokenManager.clear();
  }

  render() {
    const { variant, color } = this.props;
    return (
      <Button
        variant={variant}
        color={color}
        onClick={this.logout}
        startIcon={<ExitToAppIcon />}
      >
        Logout
      </Button>
    );
  }
}

export default withOktaAuth(Logout);
