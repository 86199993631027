import NotesRTE from './NotesRTE';
import PropTypes from 'prop-types';
import React from 'react';
import StepperButtons from 'views/Batch/StepperButtons';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';

const STEP_NAME = 'DESIGN';

class DesignStep extends React.Component {
  static propTypes = {
    onNextStep: PropTypes.func.isRequired,
  };

  render() {
    const { onNextStep } = this.props;
    return (
      <>
        <NotesRTE step={STEP_NAME} />
        <StepperButtons position='right' onNextStep={onNextStep} />
      </>
    );
  }
}

const { onNextStep } = batchStepperActions;

export default connect(
  null, { onNextStep },
)(DesignStep);
