import { batchActions, batchSelectors } from 'store/batch';

import { ACTION_SEQUENCE_CONFIRMATION } from 'constants/batchActions.constants';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import SequencingComponent from 'views/Sequencing';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { timeNow } from 'utils/date.utils';

const ConfirmCheckbox = ({ valid, appearDisabled, ...props }) => (
  <Checkbox
    icon={valid ? <ThumbUpOutlinedIcon /> : <ThumbDownOutlinedIcon />}
    checkedIcon={
      valid ? (
        <ThumbUpIcon
          sx={[
            { color: 'success.main' },
            appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {},
          ]}
        />
      ) : (
        <ThumbDownIcon
          sx={[
            { color: 'error.main' },
            appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {},
          ]}
        />
      )
    }
    sx={[{ m: 'auto' }, appearDisabled ? { color: 'text.disabled' } : {}]}
    color='default'
    {...props}
  />
);
ConfirmCheckbox.propTypes = {
  valid: PropTypes.bool,
  appearDisabled: PropTypes.bool,
};

class SequenceConfirmation extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    constructs: PropTypes.array.isRequired,
    batchId: PropTypes.number.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        construct_code: PropTypes.string.isRequired,
        confirmed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
      })).isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
    handleBatchAction: PropTypes.func.isRequired,
    editMiddleware: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggleConfirm = this.toggleConfirm.bind(this);
  }

  toggleConfirm(constructCode, valid) {
    const { handleBatchAction, step, substep, line, lineData } = this.props;
    const rowData = lineData.data.find((e) => e.construct_code === constructCode);
    handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_SEQUENCE_CONFIRMATION,
      payload: {
        construct_code: constructCode,
        valid,
        confirmed: !(rowData.confirmed && valid === rowData.valid),
        at: timeNow(),
      },
    });
  }

  render() {
    const { constructs, batchId, lineData, readOnly, appearDisabled, editMiddleware } = this.props;
    const { data } = lineData;
    const columns = [
      { key: 'constructCode', title: 'Construct' },
      { key: 'confirmation', title: 'Confirmed?' },
    ];
    const rows = data.map(({ construct_code, valid, confirmed }) => ({
      key: `${construct_code}_confirmed`,
      constructCode: construct_code,
      confirmation: (
        <Box sx={{ display: 'flex' }}>
          <ConfirmCheckbox
            valid
            appearDisabled={appearDisabled}
            checked={Boolean(confirmed && valid)}
            onChange={() => editMiddleware(() => this.toggleConfirm(construct_code, true))}
            disabled={readOnly}
          />
          <ConfirmCheckbox
            valid={false}
            appearDisabled={appearDisabled}
            checked={Boolean(confirmed && !valid)}
            onChange={() => editMiddleware(() => this.toggleConfirm(construct_code, false))}
            disabled={readOnly}
          />
        </Box>
      ),
    }));
    return (
      <>
        <SequencingComponent
          constructs={constructs}
          batchId={batchId}
          readOnly={readOnly}
        />
        <DataTable
          columns={columns}
          data={rows}
          containerProps={{ sx: { width: 240, m: 'auto' }}}
          readOnly={readOnly}
          appearDisabled={appearDisabled}
        />
      </>
    );
  }
}

const { selectBatchId, selectBatchLineData, selectConstructs } = batchSelectors;
const { handleBatchAction } = batchActions;
const { selectSessionUser } = sessionSelectors;

export default connect(
  (state, ownProps) => ({
    batchId: selectBatchId(state),
    constructs: selectConstructs(state),
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
    user: selectSessionUser(state),
  }),
  { handleBatchAction },
)(SequenceConfirmation);
