import client from 'services';

export async function getInsertSuggestions(featureName, species) {
  const params = {
    feature_name: featureName,
    species,
  };
  return await client({ method: 'get', url: '/autoclone/suggestions', params });
}

export async function validateSubmission(constructCodes) {
  const params = {
    construct_codes: constructCodes,
  };
  return await client({ method: 'get', url: '/autoclone/validate_submission', params });
}

export async function autocloneDisplay(data) {
  // data = {
  //   construct_code: string,
  //   insert_name: string,
  //   backbone: string,
  //   feature_details: { note: string, gene: string, product: string },
  //   insert: string of nucleotides or amino acids,
  //   is_amino_acid_seq: boolean,
  // };
  return await client({ method: 'post', url: '/autoclone/display', data });
}

export async function autocloneMhc(data) {
  // data = {
  //   construct_code: string,
  //   peptide_name: string,
  //   mhc_species: string (probably "Homo Sapien" or "Mus Musculus")
  //   mhc_allele: string (one of the possible choices sent from backend)
  //   backbone: string,
  //   feature_details: { note: string, gene: string, product: string },
  //   peptide_sequence: string of nucleotides or amino acids,
  //   is_amino_acid_seq: boolean,
  // };
  return await client({ method: 'post', url: '/autoclone/mhc', data });
}
