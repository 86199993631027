import InfoTable from 'components/InfoTable';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

class TritonTable extends React.Component {
  static propTypes = {
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    appearDisabled: PropTypes.bool,
  };

  render() {
    const { lineData, appearDisabled } = this.props;
    const { data } = lineData;
    return (
      <Paper variant='outlined' sx={{ width: 560, m: '8px 96px', display: 'block' }}>
        <InfoTable
          defaultBodyCellProps={{ sx: appearDisabled ? { color: 'text.disabled' } : null }}
          defaultHeaderCellProps={{ sx: appearDisabled ? { color: 'text.disabled' } : null }}
          autoSpacing
          data={data.map((row, idx) => ({
            key: `row${idx}`,
            title: row.header,
            content: row.value,
          }))}
        />
      </Paper>
    );
  }
}

const { selectBatchLineData } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
)(TritonTable);
