import * as extraActions from './session.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING } from 'constants/statuses.constants';

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const fetchSession = ({ pending, fulfilled, rejected }) => ({
  [pending]: (state) => {
    state.status = STATUS_LOADING;
  },
  [fulfilled]: (state, action) => {
    const { session } = action.payload;
    return {
      ...state,
      ...session,
      status: STATUS_IDLE,
      error: null,
    };
  },
  [rejected]: (state, action) => {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

export const reducers = {};
export const extraReducers = {
  ...fetchSession(extraActions.fetchSession),
  ...fetchSession(extraActions.refreshSession),
};
