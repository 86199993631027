import Color from 'color';

export const disableTextSelect = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
};

export const BLUE_SELECT = Color('#ACCEF7');

export const FONT = 'Arial, Helvetica, sans-serif';
