import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1caf76',
      light: '#8bc9a5',
      dark: '#167c54',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fbb41a',
      light: '#fedea0',
      dark: '#b1762a',
      contrastText: '#1b272d',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
      red: '#b22222',
      blue: '#0000ff',
      green: '#008000',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
      grey: '#f5f5f5',
    },
    text: {
      primary: '#1b272d',
      secondary: '#6b8086',
      disabled: '#8ba8af',
    },
  },
  typography: {
    fontSizeSmall: 12,
    h1: {
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '0.01562em',
    },
    h2: {
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h3: {
      fontWeight: 500,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: 1.5,
      letterSpacing: '0.0075em',
    },
    h6: {
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    dna: {
      fontFamily: '"Courier New", Courier, monospace',
      fontWeight: 700,
    },
  },
  opacity: {
    primary: 0.87,
    secondary: 0.6,
    disabled: 0.38,
    light: 0.12,
  },
  zIndex: {
    backdrop: 1099,
  },
});
