import client from 'services';

// No longer in use, see getImageUrlBuilder() instead
export async function getImage(img, type) {
  const { file_name } = img;
  return await client({
    method: 'get',
    url: '/common/image',
    params: { file_name, image_type: type },
    timeout: 60000,
  });
}

export function getImageUrlBuilder(type, token) {
  return (img) => {
    const { file_name } = img;
    return `${process.env.REACT_APP_FLASK_BASE_URL}/common/image?file_name=${file_name}&image_type=${type}&token=${token}`;
  };
}
