import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TextField } from '@acheloisbiosoftware/absui.core';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function DataTableCell(props) {
  const {
    col,
    colIdx,
    row,
    rowIdx,
    columns,
    data,
    onChange,
    defaultCellProps,
    defaultInputProps,
    noHeader,
    appearDisabled,
    readOnly,
  } = props;

  const isEditable = (row_, col_) => (
    !readOnly &&
    col_.editable &&
    (typeof(col_.editable) !== 'function' || col_.editable(row_))
  );

  const onPaste = async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    if (!clipboardData) return;

    const htmlData = clipboardData.getData('text/html');
    if (!htmlData) return;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, 'text/html');
    const rows = [...doc.querySelectorAll('table tr')];
    const tableData = rows.map((r) => [...r.querySelectorAll('td, th')].map((c) => c.innerText.trim()));
    if (!tableData.length) return;

    event.stopPropagation();
    event.preventDefault();
    const asyncOnChange = (value, r, c) => new Promise((resolve) => {
      if (onChange && isEditable(r, c)) {
        onChange(value, r, c);
      }
      resolve();
    });
    // Can't use forEach because of how redux updates state
    for (let i = 0; i < tableData.length; i++) {
      for (let j = 0; j < tableData[0].length; j++) {
        const r = data[rowIdx + i];
        const c = columns[colIdx + j];
        if (r && c) await asyncOnChange(tableData[i][j], r, c);
      }
    }
  };

  const cellProps = typeof(col.cellProps) === 'function' ? col.cellProps(row) : col?.cellProps;
  const inputProps = typeof(col.inputProps) === 'function' ? col.inputProps(row) : col?.inputProps;
  const editable = isEditable(row, col);
  return (
    <TableCell
      key={col.key}
      {...defaultCellProps}
      {...cellProps}
      sx={mergeSx(
        defaultCellProps?.sx,
        cellProps?.sx,
        appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {},
        { borderBottom: rowIdx + 1 === data.length ? 'none' : null },
      )}
    >
      {
        editable ? (
          <TextField
            size='small'
            name={col.key}
            value={row[col.key]}
            onChange={onChange ? (event) => onChange(event.target.value, row, col) : null}
            onPaste={onChange ? onPaste : null}
            label={noHeader ? col.title : null}
            endAdornment={!col.adornment ? null : (
              typeof(col.adornment) === 'function' ? col.adornment(row) : col.adornment
            )}
            {...defaultInputProps}
            {...inputProps}
            InputProps={{
              ...defaultInputProps?.InputProps,
              ...inputProps?.InputProps,
              sx: mergeSx(
                defaultInputProps?.InputProps?.sx,
                inputProps?.InputProps?.sx,
              ),
            }}
          />
        ) : col.adornment ? (
          typeof(col.adornment) === 'function' ? (
            <>
              {`${row[col.key] || '–'}\xa0`}
              {col.adornment(row)}
            </>

          ) : (
            `${row[col.key] || '–'} ${col.adornment}`
          )
        ) : row[col.key]
      }
    </TableCell>
  );
}

DataTableCell.propTypes = {
  col: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    adornment: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    cellProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    editable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  }).isRequired,
  colIdx: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired, // Requires a key corresponding to the col key value
  rowIdx: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultCellProps: PropTypes.object,
  defaultInputProps: PropTypes.object,
  noHeader: PropTypes.bool,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default DataTableCell;
