import * as extraActions from './batch.extraActions';
import * as selectors from './batch.selectors';

import { extraReducers, reducers } from './batch.reducers';

import { BATCH } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './batch.initialState';

const slice = createSlice({
  name: BATCH,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const batchSelectors = { ...selectors };
export const batchActions = { ...slice.actions, ...extraActions };
export const { reducer: batchReducer } = slice;
