import { ACTION_ALTER_Q_TABLE, ACTION_Q, ADD, REMOVE } from 'constants/batchActions.constants';
import { ROW_TYPE_COMBINE_ELUTIONS, ROW_TYPE_ELUTION_Q_1, ROW_TYPE_ELUTION_Q_2, ROW_TYPE_WASH } from 'constants/batchLineTypes.constants';
import { batchActions, batchSelectors } from 'store/batch';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import Checkbox from '@mui/material/Checkbox';
import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Tooltip from '@mui/material/Tooltip';
import { connect } from 'react-redux';

class QTable extends React.Component {
  static propTypes = {
    handleBatchAction: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    editMiddleware: PropTypes.func.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        row_id: PropTypes.number.isRequired,
        row_type: PropTypes.string.isRequired,
        concentration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        amount: PropTypes.number,
        yield: PropTypes.number,
        include: PropTypes.bool,
      })).isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleAlterTable = this.handleAlterTable.bind(this);
  }

  handleInput(value, row, col) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_Q,
        payload: {
          row_id: row.row_id,
          key: col.key,
          value,
        },
      });
    });
  }

  handleCheck(event, row) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    const { checked } = event.target;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_Q,
        payload: {
          row_id: row.row_id,
          key: 'include',
          value: checked,
        },
      });
    });
  }

  handleAlterTable(alteration) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_ALTER_Q_TABLE,
        payload: {
          alteration,
        },
      });
    });
  }

  render() {
    const { lineData, readOnly, appearDisabled, editMiddleware, step, substep, line } = this.props;
    const { data } = lineData;

    const isEditable = (row) => ![ROW_TYPE_COMBINE_ELUTIONS].includes(row.row_type);

    const requiredColumns = [
      { key: 'step', title: 'Step' },
      { key: 'concentration', title: 'Concentration', editable: isEditable, adornment: 'ng/µL', cellProps: { sx: { minWidth: 140 }}},
      { key: 'ratio', title: 'OD260/280', editable: isEditable, cellProps: { sx: { minWidth: 90 }}},
      { key: 'volume', title: 'Volume', editable: isEditable, adornment: 'mL', cellProps: { sx: { minWidth: 120 }}},
      { key: 'amount', title: 'Nucleic Acid Amount', adornment: 'mg', cellProps: { sx: { minWidth: 90 }}},
      { key: 'yield', title: 'Yield', adornment: '%', cellProps: { sx: { minWidth: 70 }}},
    ];

    const includableRowTypes = [ROW_TYPE_ELUTION_Q_1, ROW_TYPE_ELUTION_Q_2];
    const columns = data.some((row) => includableRowTypes.includes(row.row_type)) ? [
      ...requiredColumns,
      { key: 'include', title: 'Included?', cellProps: { sx: { minWidth: 80 }}},
    ] : requiredColumns;

    const rows = data.map((row) => ({
      key: `${row.row_id}_QRow`,
      row_id: row.row_id,
      row_type: row.row_type,
      step: [ROW_TYPE_ELUTION_Q_1, ROW_TYPE_ELUTION_Q_2, ROW_TYPE_WASH].includes(row.row_type) ? `${row.row_type} ${row.row_id}` : row.row_type,
      concentration: row.concentration,
      ratio: row.ratio,
      volume: row.volume,
      amount: row.amount,
      yield: row.yield,
      include: includableRowTypes.includes(row.row_type) ? (
        <Checkbox
          color='default'
          checked={row.include}
          disabled={readOnly}
          onChange={(e) => this.handleCheck(e, row)}
          sx={appearDisabled ? { color: 'text.disabled' } : null}
        />
      ) : null,
    }));
    const canAlterTable = (
      data.some((row) => [ROW_TYPE_ELUTION_Q_1, ROW_TYPE_ELUTION_Q_2, ROW_TYPE_WASH].includes(row.row_type)) &&
      !(step === 'Day 4' && substep === 'D4_3' && line === 'D4_S11_qtable') /* This is so that we don't render the buttons on the first Q2 elution and the user can only to the second elution table */
    );
    return (
      <Box sx={{ display: 'flex' }}>
        <DataTable
          columns={columns}
          data={rows}
          onChange={this.handleInput}
          defaultInputProps={{
            type: 'number',
            variant: 'outlined',
            onFocus: () => editMiddleware(null),
          }}
          defaultCellProps={{ sx: { height: 40 }}}
          tableProps={{ size: 'small' }}
          containerProps={{ sx: { m: 3, mt: 1.5, maxWidth: 1200, flexGrow: 1 }}}
          readOnly={readOnly}
          appearDisabled={appearDisabled}
        />
        {
          canAlterTable ? (
            <Box sx={{ mr: 0, ml: 'auto', mb: 3, mt: 'auto', display: 'flex', flexDirection: 'column' }}>
              <Tooltip title={`Add ${data[0].row_type} Row`} arrow>
                <Box>
                  <Button
                    onClick={() => this.handleAlterTable(ADD)}
                    disabled={readOnly}
                    sx={appearDisabled ? { color: 'text.disabled' } : {}}
                    icon
                  >
                    <AddCircleRoundedIcon />
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip title={`Remove ${data[0].row_type} Row`} arrow>
                <Box>
                  <Button
                    onClick={() => this.handleAlterTable(REMOVE)}
                    disabled={readOnly}
                    sx={appearDisabled ? { color: 'text.disabled' } : {}}
                    icon
                  >
                    <RemoveCircleRoundedIcon />
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          ) : null
        }
      </Box>
    );
  }
}

const { selectBatchLineData } = batchSelectors;
const { handleBatchAction } = batchActions;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
  { handleBatchAction },
)(QTable);
