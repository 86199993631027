import * as extraActions from './batchStepper.extraActions';
import * as selectors from './batchStepper.selectors';

import { extraReducers, reducers } from './batchStepper.reducers';

import { BATCH_STEPPER } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './batchStepper.initialState';

const slice = createSlice({
  name: BATCH_STEPPER,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const batchStepperSelectors = { ...selectors };
export const batchStepperActions = { ...slice.actions, ...extraActions };
export const { reducer: batchStepperReducer } = slice;
