import * as d3 from 'd3';

import AnnotationBlock from './AnnotationBlock';
import PropTypes from 'prop-types';
import React from 'react';
import { isOverlapping } from 'utils/sequence.utils';

const DEFAULT_PROPS = {
  alignmentData: {},
  bpRange: [0, 0],
  canvasRange: [0, 0],
  y: 0,
  blockHeight: 24,
  blockSpacing: 8,
};

function parseAlignmentData(props) {
  const {
    alignmentData,
    bpRange,
    canvasRange,
  } = { ...DEFAULT_PROPS, ...props };
  const { columns, read_meta_data } = alignmentData;
  const errorColumns = columns.filter((col) => col.error);
  const x = d3.scaleLinear().domain(bpRange).range(canvasRange);

  // Filter for reads in bpRange
  let parsedAlignmentData = read_meta_data.filter((header) => isOverlapping(bpRange, [header.first_read, header.last_read]));

  // Parse reads for relevant data
  parsedAlignmentData = parsedAlignmentData.map((header, idx) => {
    const mismatches = errorColumns.filter((col) => col.reads[header.read_idx] && col.reads[header.read_idx] !== col.ref);
    return {
      read_idx: header.read_idx,
      read_name: header.read_name,
      strand: header.strand,
      mismatchPos: mismatches.map((col) => x(col.ref_idx)),
      canvasStart: x(header.first_read),
      canvasEnd: x(header.last_read),
      blockRow: idx,
    };
  });

  return parsedAlignmentData;
}

function _getReadsMapHeight(parsedAlignmentData, blockHeight, blockSpacing) {
  if (!parsedAlignmentData.length) return 0;
  const maxRow = Math.max(...parsedAlignmentData.map((read) => read.blockRow)) + 1;
  return maxRow * (blockHeight + blockSpacing);
}

function getReadsMapHeight(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedAlignmentData = parseAlignmentData(completeProps);
  const { blockHeight, blockSpacing } = completeProps;
  return _getReadsMapHeight(parsedAlignmentData, blockHeight, blockSpacing);
}

class ReadsMap extends React.Component {
  static propTypes = {
    alignmentData: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape({
        reads: PropTypes.arrayOf(PropTypes.string).isRequired,
        ref: PropTypes.string,
        ref_idx: PropTypes.number,
        error: PropTypes.string,
      })).isRequired,
      read_meta_data: PropTypes.arrayOf(PropTypes.shape({
        first_read: PropTypes.number.isRequired,
        last_read: PropTypes.number.isRequired,
        read_idx: PropTypes.number.isRequired,
        read_name: PropTypes.string.isRequired,
        strand: PropTypes.oneOf([1, -1]).isRequired,
      })),
    }).isRequired,
    // Because of the strange default props unpacking needed, eslint cannot determine these are being used
    /* eslint-disable-next-line react/no-unused-prop-types */
    bpRange: PropTypes.arrayOf(PropTypes.number),
    /* eslint-disable-next-line react/no-unused-prop-types */
    canvasRange: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.number,
    blockHeight: PropTypes.number,
    blockSpacing: PropTypes.number,
  };

  static defaultProps = DEFAULT_PROPS;

  render() {
    const {
      y,
      blockHeight,
      blockSpacing,
    } = this.props;
    const parsedAlignmentData = parseAlignmentData(this.props);

    return (
      <g transform={`translate(0,${y})`}>
        {
          parsedAlignmentData.map((header, idx) => (
            <AnnotationBlock
              /* eslint-disable-next-line react/no-array-index-key */
              key={`read${header.read_idx}_${header.read_name}_${idx}`}
              readHeader={header}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
            />
          ))
        }
      </g>
    );
  }
}

export { getReadsMapHeight };
export default ReadsMap;
