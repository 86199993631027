import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { materialActions, materialSelectors } from 'store/material';

import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import InfoTable from 'components/InfoTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deepObjectModify } from 'utils/helpers';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

const initState = (initData) => ({
  data: {
    lot_no: '',
    date_received: moment(),
    storage_location: '',
    custom_props: [],
    ...initData,
  },
  error: '',
});

class AddMaterialDialog extends React.Component {
  static propTypes = {
    material: PropTypes.shape({
      material_id: PropTypes.number,
      name: PropTypes.string,
      vendor: PropTypes.string,
    }),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    createMaterialLot: PropTypes.func,
    initData: PropTypes.shape({
      lot_no: PropTypes.string,
      date_received: PropTypes.object, // moment object
      storage_location: PropTypes.string,
      custom_props: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })),
    }),
  };

  constructor(props) {
    super(props);
    this.state = initState(props.initData);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCustomPropChange = this.handleCustomPropChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addProperty = this.addProperty.bind(this);
    this.removeProperty = this.removeProperty.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open, initData } = this.props;
    if (!prevProps.open && open) {
      this.setState({ ...initState(initData) });
    }
  }

  handleClose() {
    this.setState({ ...initState() });
    this.props.onClose();
  }

  handleInputChange(event) {
    const { data, error } = this.state;
    const { id, value } = event.target;
    const newData = { ...data, [id]: value };
    const newError = id === 'lot_no' ? '' : error;
    this.setState({ data: newData, error: newError });
  }

  handleCustomPropChange(event, idx, type) {
    const { data } = this.state;
    const { value } = event.target;
    const newData = deepObjectModify(data, ['custom_props', idx, type], value);
    this.setState({ data: newData });
  }

  addProperty() {
    const { data } = this.state;
    const { custom_props } = data;
    const newData = {
      ...data,
      custom_props: [...custom_props, { title: '', value: '' }],
    };
    this.setState({ data: newData });
  }

  removeProperty(idx) {
    const { data } = this.state;
    const { custom_props } = data;
    const newCustomProps = [...custom_props];
    newCustomProps.splice(idx, 1);
    const newData = {
      ...data,
      custom_props: newCustomProps,
    };
    this.setState({ data: newData });
  }

  async handleSubmit() {
    const { onClose, createMaterialLot, material } = this.props;
    const { data } = this.state;
    const { lot_no, date_received, storage_location, custom_props } = data;
    if (!lot_no) {
      this.setState({ error: 'Lot # required' });
      return;
    }
    if (!date_received?.toISOString?.()) {
      return;
    }
    for (const prop of custom_props) {
      if (!prop.title && prop.value) {
        return;
      }
    }

    const res = await createMaterialLot({
      material_id: material.material_id,
      lot_no,
      date_received: date_received.toISOString(),
      storage_location,
      custom_props: custom_props.filter((prop) => prop.title),
    });

    try {
      const resUnwrapped = unwrapResult(res);
      this.setState({ ...initState() });
      onClose(resUnwrapped.material_lot);
    } catch (e) {}
  }

  render() {
    const { loading, open, material } = this.props;
    const { data, error } = this.state;
    const { lot_no, date_received, storage_location, custom_props } = data;

    const isInvalidDate = Boolean(date_received && !date_received?.toISOString?.());
    const inputSx = { minWidth: 250 };

    const rows = [
      {
        key: 'name',
        title: 'Name',
        content: material.name,
      },
      {
        key: 'vendor',
        title: 'Vendor',
        content: material.vendor,
      },
      {
        key: 'lot_no',
        title: 'Lot #',
        content: (
          <TextField
            variant='outlined'
            id='lot_no'
            label='Lot #'
            value={lot_no}
            onChange={this.handleInputChange}
            sx={inputSx}
            error={Boolean(error)}
            helperText={error}
          />
        ),
      },
      {
        key: 'date_received',
        title: 'Date Received',
        content: (
          <DatePicker
            value={date_received}
            onChange={(value) => this.setState({ data: { ...data, date_received: value }})}
            disableFuture
            renderInput={(params) => (
              <TextField
                {...params}
                sx={mergeSx(params.sx, inputSx)}
                error={params.error || isInvalidDate}
                helperText={isInvalidDate ? 'Invalid date' : params.helperText}
              />
            )}
          />
        ),
      },
      {
        key: 'storage_location',
        title: 'Storage Location',
        content: (
          <TextField
            variant='outlined'
            id='storage_location'
            label='Storage Location'
            value={storage_location}
            onChange={this.handleInputChange}
            sx={inputSx}
          />
        ),
      },
      ...custom_props.map(({ title, value }, idx) => ({
        key: `custom_prop${idx}`,
        title: (
          <TextField
            variant='outlined'
            label='Property Name'
            value={title}
            onChange={(e) => this.handleCustomPropChange(e, idx, 'title')}
            sx={{ width: 150 }}
            error={Boolean(!title && value)}
            helperText={!title && value ? 'Required' : ''}
          />
        ),
        content: (
          <Box sx={{ display: 'flex' }}>
            <TextField
              variant='outlined'
              label={title}
              value={value}
              onChange={(e) => this.handleCustomPropChange(e, idx, 'value')}
              sx={inputSx}
            />
            <IconButton onClick={() => this.removeProperty(idx)} sx={{ m: 'auto', ml: 1 }}>
              <ClearIcon />
            </IconButton>
          </Box>
        ),
      })),
    ];

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        onConfirm={this.handleSubmit}
        disableCloseOnConfirm
        loading={loading}
        confirmButtonText='Submit'
        maxWidth={false}
        title='New Material Lot'
        extraDialogActions={(
          <Button onClick={this.addProperty} variant='text'>
            Add Property
          </Button>
        )}
      >
        <InfoTable data={rows} tableProps={{ sx: { minWidth: 470 }}} />
      </Dialog>
    );
  }
}

const { selectPageLoading } = materialSelectors;
const { createMaterialLot } = materialActions;

export default connect(
  (state) => ({
    loading: selectPageLoading(state),
  }),
  { createMaterialLot },
)(AddMaterialDialog);
