import * as ConstructService from 'services/ConstructService';

import { STATUS_IDLE, STATUS_INIT } from 'constants/statuses.constants';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectConstruct, selectConstructStatus } from './construct.selectors';

import { CONSTRUCT } from 'constants/store.constants';
import { debounce } from 'lodash';
import { responseToPayload } from 'utils/store.utils';

export const deleteConstructs = createAsyncThunk(
  `${CONSTRUCT}/delete`,
  async (constructCodes, { rejectWithValue }) => {
    const res = await ConstructService.deleteConstructs(constructCodes);
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ construct_codes: response.constructs }),
    );
  },
);

export const fetchConstruct = createAsyncThunk(
  `${CONSTRUCT}/fetch`,
  async (construct_code, { rejectWithValue }) => {
    const res = await ConstructService.getConstruct(construct_code);
    return responseToPayload(res, rejectWithValue);
  },
);

export const updateConstruct = createAsyncThunk(
  `${CONSTRUCT}/update`,
  async (construct, { rejectWithValue }) => {
    const res = await ConstructService.updateConstructs([construct]);
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ construct: response.constructs[0] }),
    );
  },
);

const save = (dispatch, getState) => {
  const state = getState();
  if (![STATUS_INIT, STATUS_IDLE].includes(selectConstructStatus(state))) return;
  dispatch(updateConstruct(selectConstruct(state)));
};

const autosave = debounce(save, 10000);

export const handleSave = () => save;

export const _handleConstructInputAction = createAction(`${CONSTRUCT}/handleConstructInput`);
export const handleConstructInput = (keyList, value) => (dispatch, getState) => {
  dispatch(_handleConstructInputAction({ keyList, value }));
  autosave(dispatch, getState);
};

export const _resetConstructAction = createAction(`${CONSTRUCT}/resetConstruct`);
export const resetConstruct = () => (dispatch) => {
  autosave.cancel();
  dispatch(_resetConstructAction());
};
