import {
  LINE_BUFFER_TABLE,
  LINE_CHECKBOX,
  LINE_CHEMICAL_TABLE,
  LINE_ENDOTOXIN_TABLE,
  LINE_EQUIPMENT_TABLE,
  LINE_FLASK_TABLE,
  LINE_GEL_IMAGES,
  LINE_MATERIAL_TABLE,
  LINE_PLAIN_TEXT,
  LINE_PS_TABLE,
  LINE_Q_TABLE,
  LINE_SEQUENCE_CONFIRMATION,
  LINE_SHAKE_TIMER,
  LINE_TRITON_TABLE,
  LINE_YIELD_TABLE,
} from 'constants/batchLineTypes.constants';

import BufferTable from './BufferTable';
import ChemicalTable from './ChemicalTable';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import EndotoxinTable from './EndotoxinTable';
import EquipmentTable from './EquipmentTable';
import FlaskTable from './FlaskTable';
import GelImages from './GelImages';
import MaterialTable from './MaterialTable';
import PSTable from './PSTable';
import PlainText from './PlainText';
import PropTypes from 'prop-types';
import QTable from './QTable';
import React from 'react';
import SOPLine from './SOPLine';
import SequenceConfirmation from './SequenceConfirmation';
import ShakeTimer from './ShakeTimer';
import TritonTable from './TritonTable';
import YieldTable from './YieldTable';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

class Line extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    lineType: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    isCurrentStep: PropTypes.bool.isRequired,
    isFutureStep: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      warningOpen: false,
      overrideDisabled: false,
      editCallback: null,
    };
    this.getReadOnly = this.getReadOnly.bind(this);
    this.getAppearDisabled = this.getAppearDisabled.bind(this);
    this.openWarning = this.openWarning.bind(this);
    this.closeWarning = this.closeWarning.bind(this);
    this.onConfirmWarning = this.onConfirmWarning.bind(this);
    this.editMiddleware = this.editMiddleware.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCompleted && !prevProps.isCompleted) {
      this.setState({ overrideDisabled: false });
    }
  }

  getReadOnly() {
    const { isFutureStep, isLocked } = this.props;
    return isFutureStep || isLocked;
  }

  getAppearDisabled() {
    const { isCompleted, isCurrentStep } = this.props;
    const { overrideDisabled } = this.state;
    if (overrideDisabled) return false;
    return (isCompleted && !isCurrentStep) || this.getReadOnly();
  }

  openWarning() {
    this.setState({ warningOpen: true });
  }

  closeWarning() {
    this.setState({ warningOpen: false, editCallback: null });
  }

  onConfirmWarning() {
    this.setState({ overrideDisabled: true });
    const { editCallback } = this.state;
    this.closeWarning();
    editCallback?.();
  }

  editMiddleware(callback) {
    if (!this.getAppearDisabled() || this.state.overrideDisabled) {
      return callback?.();
    }
    this.setState({ editCallback: callback });
    this.openWarning();
    return null;
  }

  render() {
    const { lineType, step, substep, line } = this.props;
    const { warningOpen } = this.state;
    const lineProps = {
      step,
      substep,
      line,
      readOnly: this.getReadOnly(),
      appearDisabled: this.getAppearDisabled(),
      editMiddleware: this.editMiddleware,
    };

    const lineMapping = {
      [LINE_CHECKBOX]: (<SOPLine {...lineProps} />),
      [LINE_ENDOTOXIN_TABLE]: (<EndotoxinTable {...lineProps} />),
      [LINE_FLASK_TABLE]: (<FlaskTable {...lineProps} />),
      [LINE_GEL_IMAGES]: (<GelImages {...lineProps} />),
      [LINE_SEQUENCE_CONFIRMATION]: (<SequenceConfirmation {...lineProps} />),
      [LINE_SHAKE_TIMER]: (<ShakeTimer {...lineProps} />),
      [LINE_TRITON_TABLE]: (<TritonTable {...lineProps} />),
      [LINE_YIELD_TABLE]: (<YieldTable {...lineProps} />),
      [LINE_Q_TABLE]: (<QTable {...lineProps} />),
      [LINE_PS_TABLE]: (<PSTable {...lineProps} />),
      [LINE_PLAIN_TEXT]: (<PlainText {...lineProps} />),
      [LINE_CHEMICAL_TABLE]: (<ChemicalTable {...lineProps} />),
      [LINE_MATERIAL_TABLE]: (<MaterialTable {...lineProps} />),
      [LINE_BUFFER_TABLE]: (<BufferTable {...lineProps} />),
      [LINE_EQUIPMENT_TABLE]: (<EquipmentTable {...lineProps} />),
    };
    return (
      <>
        {lineMapping[lineType]}
        <Dialog
          open={warningOpen}
          onClose={this.closeWarning}
          title='Return to previous step?'
          onConfirm={this.onConfirmWarning}
          disableCloseOnConfirm
        >
          You have already completed this step. Are you sure you would like to go back to edit it?
        </Dialog>
      </>
    );
  }
}

const { selectBatchLineData, selectBatchLockedStatus } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    lineType: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line).type,
    isLocked: selectBatchLockedStatus(state),
  }),
)(Line);
