import * as d3 from 'd3';

import Chromatogram from './Chromatogram';
import PropTypes from 'prop-types';
import React from 'react';
import Sequence from 'components/SeqView/Linear/Sequence';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';

const DEFAULT_PROPS = {
  alignmentData: {},
  containerId: '',
  canvasRange: [0, 0],
  y: 0,
  fontSize: 16,
  chromatogramHeight: 100,
  chromatogramPadding: 8,
  showChromatograms: false,
};

function parseAlignmentData(props) {
  const { alignmentData, canvasRange } = { ...DEFAULT_PROPS, ...props };

  const { columns, read_meta_data } = alignmentData;
  const x = d3.scaleLinear().domain([0, columns.length]).range(canvasRange);

  // Parse reads for relevant data
  const parsedAlignmentData = read_meta_data.map((header, idx) => {
    const readSeq = columns.map((col) => col.reads[header.read_idx] || '\u00A0')
      .join('')
      .trim()
      .replace(' ', '\u00A0');
    const firstRead = columns.findIndex((col) => col.ref_idx >= header.first_read);
    const lastRead = columns.length - columns.slice().reverse().findIndex((col) => col.ref_idx < header.last_read);
    return {
      read_name: header.read_name,
      read_idx: header.read_idx,
      readSeq,
      canvasStart: x(firstRead),
      canvasEnd: x(lastRead + 1),
      row: idx,
    };
  });
  return parsedAlignmentData;
}

function _getReadsSequenceHeight(parsedAlignmentData, fontSize, chromatogramHeight, showChromatograms) {
  return parsedAlignmentData.length * (fontSize + (showChromatograms ? chromatogramHeight : 0));
}

function getReadsSequenceHeight(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedAlignmentData = parseAlignmentData(completeProps);
  const { fontSize, chromatogramHeight, showChromatograms } = completeProps;
  return _getReadsSequenceHeight(parsedAlignmentData, fontSize, chromatogramHeight, showChromatograms);
}

class ReadsSequence extends React.Component {
  static propTypes = {
    alignmentData: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape({
        reads: PropTypes.arrayOf(PropTypes.string).isRequired,
        ref_idx: PropTypes.number,
        qualities: PropTypes.arrayOf(PropTypes.number).isRequired,
        ref: PropTypes.string,
      })).isRequired,
      read_meta_data: PropTypes.arrayOf(PropTypes.shape({
        read_idx: PropTypes.number.isRequired,
        read_name: PropTypes.string.isRequired,
        first_read: PropTypes.number.isRequired,
        last_read: PropTypes.number.isRequired,
      })).isRequired,
    }).isRequired,
    containerId: PropTypes.string.isRequired,
    canvasRange: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.number,
    fontSize: PropTypes.number,
    chromatogramHeight: PropTypes.number,
    chromatogramPadding: PropTypes.number,
    showChromatograms: PropTypes.bool,
  };

  static defaultProps = DEFAULT_PROPS;

  constructor(props) {
    super(props);
    this.drawReadConfidences = this.drawReadConfidences.bind(this);
  }

  drawReadConfidences(parsedAlignmentData) {
    if (!this.canvas) {
      return;
    }

    const {
      alignmentData,
      canvasRange,
      fontSize,
      chromatogramHeight,
      showChromatograms,
    } = this.props;
    const { columns } = alignmentData;
    const totalHeight = _getReadsSequenceHeight(parsedAlignmentData, fontSize, chromatogramHeight, showChromatograms);
    this.canvas = this.canvas.attr('width', canvasRange[1] - canvasRange[0])
      .attr('height', totalHeight);
    const ctx = this.canvas.node().getContext('2d');
    ctx.clearRect(0, 0, this.canvas.node().width, this.canvas.node().height);
    const x = d3.scaleLinear().domain([0, columns.length]).range([0, canvasRange[1] - canvasRange[0]]);
    const baseWidth = x(1) - x(0);
    const rowHeight = showChromatograms ? chromatogramHeight + fontSize : fontSize;
    const yOffset = showChromatograms ? chromatogramHeight : 0;

    columns.forEach((col, colIdx) => {
      parsedAlignmentData.forEach((read) => {
        const baseRead = col.reads[read.read_idx];
        if (baseRead && baseRead.trim()) {
          // Qualities range from 0 to 63
          const readQual = col.qualities[read.read_idx] || 63;
          if (baseRead !== col.ref) {
            const errorShade = (
              readQual > 45 ? 400 : (
                readQual > 30 ? 200 : 100
              ));
            ctx.beginPath();
            ctx.fillStyle = red[errorShade];
            ctx.fillRect(x(colIdx), (read.row * rowHeight) + yOffset, baseWidth, fontSize);
            ctx.stroke();
          } else if (readQual < 45) {
            const confidenceShade = readQual > 30 ? 100 : 400;
            ctx.beginPath();
            ctx.fillStyle = blue[confidenceShade];
            ctx.fillRect(x(colIdx), (read.row * rowHeight) + yOffset, baseWidth, fontSize);
            ctx.stroke();
          }
        }
      });
    });
  }

  componentDidMount() {
    const { containerId, canvasRange, y } = this.props;
    this.canvas = d3.select(`#${containerId}`)
      .insert('canvas', ':first-child')
      .attr('width', canvasRange[1] - canvasRange[0])
      .attr('height', getReadsSequenceHeight(this.props))
      .style('position', 'absolute')
      .style('top', `${y}px`)
      .style('left', `${canvasRange[0]}px`);
  }

  render() {
    const {
      alignmentData,
      y,
      fontSize,
      canvasRange,
      chromatogramHeight,
      chromatogramPadding,
      showChromatograms,
    } = this.props;
    const { columns } = alignmentData;
    const parsedAlignmentData = parseAlignmentData(this.props);
    const rowHeight = fontSize + (showChromatograms ? chromatogramHeight : 0);
    this.drawReadConfidences(parsedAlignmentData);
    return (
      <g transform={`translate(0,${y})`}>
        {
          parsedAlignmentData.map((read) => (
            <React.Fragment key={`${read.read_name}_${read.read_idx}_seq`}>
              {
                showChromatograms ? (
                  <Chromatogram
                    read={read}
                    columns={columns}
                    canvasRange={canvasRange}
                    height={chromatogramHeight - chromatogramPadding}
                    y={(read.row * rowHeight) + (chromatogramPadding * 3 / 4)}
                  />
                ) : null
              }
              <Sequence
                canvasRange={[read.canvasStart, read.canvasEnd]}
                y={(read.row * rowHeight) + (showChromatograms ? chromatogramHeight : 0)}
                seq={read.readSeq}
                fontSize={fontSize}
                upper
              />
            </React.Fragment>
          ))
        }
      </g>
    );
  }
}

export { getReadsSequenceHeight };
export default ReadsSequence;
