import React, { useEffect, useState } from 'react';
import { backboneActions, backboneSelectors } from 'store/backbone';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import DesignSubmission from '../DesignSubmission';
import Grid from '@mui/material/Grid';
import InsertSuggestionsList from './InsertSuggestionsList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TextField } from '@acheloisbiosoftware/absui.core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { designActions } from 'store/design';
import { featureDetailTypes } from 'constants/seqFeatures.constants';

const DISPLAY_CONSTRUCT = 'Display Construct';
const MHC_CONSTRUCT = 'MHC Construct';

const SPECIES = ['Homo Sapien', 'Mus Musculus'];

const NUC_SEQ_REGEX = /^[acgturykmswbdhvnACGTURYKMSWBDHVN]+$/g;
const AA_SEQ_REGEX = /^[acdefghiklmnpqrstvwyACDEFGHIKLMNPQRSTVWY]+$/g;

function AutocloneForm() {
  const dispatch = useDispatch();
  const backboneData = useSelector(backboneSelectors.selectBackboneData);
  const mhcAlleles = useSelector(backboneSelectors.selectMHCAlleles);
  const { backbones, backbone_types, tags, backbone_info, type_tag_mapping } = backboneData;

  const [constructType, setConstructType] = useState(DISPLAY_CONSTRUCT);
  const [formData, setFormData] = useState({
    constructCode: '',
    insertName: '', /* Only for display constructs */
    peptideName: '', /* Only for MHC constructs */
    mhcAllele: '', /* Only for MHC constructs */
    species: SPECIES[0],
    backbone: '',
    backboneType: '',
    tag: '',
    sequence: '',
    isAminoAcidSeq: false,
    ...Object.fromEntries(Object.keys(featureDetailTypes).map((key) => [key, ''])),
  });

  const [detailSelected, setDetailSelected] = useState(Object.keys(featureDetailTypes)[0]);
  const [errors, setErrors] = useState({
    constructCode: '',
    insertName: '',
    peptideName: '',
    mhcAllele: '',
    species: '',
    backbone: '',
    backboneType: '',
    tag: '',
    sequence: '',
  });

  useEffect(() => {
    dispatch(backboneActions.fetchBackboneData());
    dispatch(backboneActions.fetchMHCAlleles());
  }, [dispatch]);

  const handleFormChange = (name, value) => {
    const newErrors = { ...errors, [name]: '' };
    const newFormData = { ...formData, [name]: value };

    if (name === 'backbone') {
      newErrors.backboneType = '';
      newErrors.tag = '';
      newFormData.backboneType = backbone_info[value].type;
      newFormData.tag = backbone_info[value].tag;
    } else if (name === 'backboneType' || name === 'tag') {
      const { tag, backboneType } = newFormData;
      if (backboneType && tag) {
        newFormData.backbone = type_tag_mapping?.[backboneType]?.[tag] ?? '';
        if (newFormData.backbone) {
          newErrors.backbone = '';
        } else {
          newErrors.backbone = 'No backbone with given type and tag combination';
        }
      }
    } else if (name === 'sequence' || name === 'isAminoAcidSeq') {
      const { sequence, isAminoAcidSeq } = newFormData;
      if (sequence && !sequence.match(isAminoAcidSeq ? AA_SEQ_REGEX : NUC_SEQ_REGEX)) {
        newErrors.sequence = isAminoAcidSeq ? 'Remove invalid amino acid characters' : 'Remove non-DNA characters';
      } else {
        newErrors.sequence = '';
      }
    } else if (name === 'species') {
      newFormData.mhcAllele = '';
    }

    setFormData(newFormData);
    setErrors(newErrors);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleFormChange(name, value);
  };

  const handleSuggestionSelection = (suggestion) => {
    const newErrors = { ...errors, sequence: '' };
    setErrors(newErrors);

    if (suggestion.origin === 'Autocloner database') {
      setFormData({
        ...formData,
        sequence: suggestion.sequence,
        isAminoAcidSeq: false,
        insertName: suggestion.feature_name,
      });
    } else {
      setFormData({
        ...formData,
        sequence: suggestion.sequence,
        isAminoAcidSeq: false,
      });
    }
  };

  const validateInputs = () => {
    const { constructCode } = formData;
    const newErrors = { ...errors };

    if (!constructCode.match(/([a-zA-Z]+)([0-9]+)$/)) {
      newErrors.constructCode = 'Must be project abbreviation followed by a number';
    }

    const requiredFields = {
      [DISPLAY_CONSTRUCT]: ['constructCode', 'insertName', 'backbone', 'backboneType', 'tag', 'sequence'],
      [MHC_CONSTRUCT]: ['constructCode', 'peptideName', 'species', 'mhcAllele', 'backbone', 'backboneType', 'tag', 'sequence'],
    };

    for (const requiredField of requiredFields[constructType]) {
      if (!formData[requiredField]) {
        newErrors[requiredField] = 'Required';
      }
    }

    const valid = Object.values(newErrors).filter((e) => e).length === 0;
    if (!valid) {
      setErrors(newErrors);
    }
    return valid;
  };

  const handleSubmit = async () => {
    const { constructCode, insertName, peptideName, species, mhcAllele, backbone, sequence, isAminoAcidSeq } = formData;
    if (constructType === DISPLAY_CONSTRUCT) {
      return await dispatch(designActions.autocloneDisplay({
        construct_code: constructCode,
        insert_name: insertName,
        backbone,
        feature_details: Object.fromEntries(Object.keys(featureDetailTypes).map((key) => [key, formData[key]])),
        insert: sequence,
        is_amino_acid_seq: isAminoAcidSeq,
      }));
    }

    if (constructType === MHC_CONSTRUCT) {
      return await dispatch(designActions.autocloneMhc({
        construct_code: constructCode,
        peptide_name: peptideName,
        mhc_species: species,
        mhc_allele: mhcAllele,
        backbone,
        feature_details: Object.fromEntries(Object.keys(featureDetailTypes).map((key) => [key, formData[key]])),
        peptide_sequence: sequence,
        is_amino_acid_seq: isAminoAcidSeq,
      }));
    }

    return null;
  };

  const sxInput = { width: 1 };
  const sxInputMulti = { width: 1, height: 200 };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper sx={{ p: 3, color: 'text.secondary' }} variant='outlined'>
        <Typography variant='h4' sx={{ textAlign: 'center' }}>Design</Typography>
        <Box sx={{ m: 'auto', width: 700 }}>
          <Tabs
            value={constructType}
            onChange={(_, newConstructType) => setConstructType(newConstructType)}
            sx={{ mt: 1, mb: 2, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label={DISPLAY_CONSTRUCT} value={DISPLAY_CONSTRUCT} />
            <Tab label={MHC_CONSTRUCT} value={MHC_CONSTRUCT} />
          </Tabs>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                label='Construct Code'
                variant='outlined'
                name='constructCode'
                value={formData.constructCode}
                onChange={handleInputChange}
                sx={sxInput}
                error={Boolean(errors.constructCode)}
                helperText={errors.constructCode || null}
              />
            </Grid>
            {constructType === DISPLAY_CONSTRUCT ? (
              <Grid item xs={4}>
                <TextField
                  label='Insert Name'
                  variant='outlined'
                  name='insertName'
                  value={formData.insertName}
                  onChange={handleInputChange}
                  sx={sxInput}
                  error={Boolean(errors.insertName)}
                  helperText={errors.insertName || null}
                />
              </Grid>
            ) : null}
            {constructType === MHC_CONSTRUCT ? (
              <>
                <Grid item xs={4}>
                  <TextField
                    label='Peptide Name'
                    variant='outlined'
                    name='peptideName'
                    value={formData.peptideName}
                    onChange={handleInputChange}
                    sx={sxInput}
                    error={Boolean(errors.peptideName)}
                    helperText={errors.peptideName || null}
                  />
                </Grid>
                <Grid item xs={4} />
              </>
            ) : null}
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='Species'
                name='species'
                value={formData.species}
                onChange={handleInputChange}
                sx={sxInput}
                error={Boolean(errors.species)}
                helperText={errors.species || null}
              >
                {SPECIES.map((species) => (
                  <MenuItem key={species} value={species}>{species}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {constructType === DISPLAY_CONSTRUCT ? (
              <Grid item xs={12}>
                <InsertSuggestionsList
                  sx={sxInputMulti}
                  insertName={formData.insertName}
                  species={formData.species}
                  handleSuggestionSelection={handleSuggestionSelection}
                />
              </Grid>
            ) : null}
            {constructType === MHC_CONSTRUCT ? (
              <>
                <Grid item xs={4}>
                  <TextField
                    select
                    variant='outlined'
                    label='MHC Allele'
                    name='mhcAllele'
                    value={formData.mhcAllele}
                    onChange={handleInputChange}
                    sx={sxInput}
                    error={Boolean(errors.mhcAllele)}
                    helperText={errors.mhcAllele || null}
                  >
                    {(mhcAlleles[formData.species] ?? []).map((allele) => (
                      <MenuItem key={allele} value={allele}>{allele}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4} />
              </>
            ) : null}
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='Backbone'
                name='backbone'
                value={formData.backbone}
                onChange={handleInputChange}
                sx={sxInput}
                error={Boolean(errors.backbone)}
                helperText={errors.backbone || null}
              >
                {backbones.map((bkb) => <MenuItem key={bkb} value={bkb}>{bkb}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='Backbone Type'
                name='backboneType'
                value={formData.backboneType}
                onChange={handleInputChange}
                sx={sxInput}
                error={Boolean(errors.backboneType)}
                helperText={errors.backboneType || null}
              >
                {backbone_types.map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='Tag'
                name='tag'
                value={formData.tag}
                onChange={handleInputChange}
                sx={sxInput}
                error={Boolean(errors.tag)}
                helperText={errors.tag || null}
              >
                {tags.map((tag) => <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='Feature Details'
                name='detailSelected'
                value={detailSelected}
                onChange={(event) => setDetailSelected(event.target.value)}
                sx={sxInput}
              >
                {
                  Object.entries(featureDetailTypes).map(([key, displayName]) => (
                    <MenuItem value={key} key={key}>{displayName}</MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                label={featureDetailTypes[detailSelected]}
                variant='outlined'
                name={detailSelected}
                value={formData[detailSelected]}
                onChange={handleInputChange}
                sx={sxInput}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  label='Insert Sequence'
                  multiline
                  rows={7}
                  variant='outlined'
                  value={formData.sequence}
                  onChange={handleInputChange}
                  name='sequence'
                  sx={mergeSx(sxInputMulti, { mr: 2 })}
                  InputSx={{ typography: 'dna' }}
                  error={Boolean(errors.sequence)}
                  helperText={errors.sequence || null}
                />
                <ToggleButtonGroup
                  color='info'
                  size='small'
                  value={formData.isAminoAcidSeq}
                  exclusive
                  orientation='vertical'
                  onChange={(_, isAminoAcidSeq) => (isDefined(isAminoAcidSeq) ? handleFormChange('isAminoAcidSeq', isAminoAcidSeq) : null)}
                >
                  <ToggleButton value={false}>Nucleotides</ToggleButton>
                  <ToggleButton value>Amino Acids</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <DesignSubmission
                validateInputs={validateInputs}
                handleSubmit={handleSubmit}
                constructCodes={[formData.constructCode]}
                updateConstructCode={(newConstructCode) => setFormData({ ...formData, constructCode: newConstructCode })}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default AutocloneForm;
