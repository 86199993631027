import * as CommonService from 'services/CommonService';

import Box from '@mui/material/Box';
import ImageGallery from 'components/ImageGallery';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';

class DetailedContent extends React.Component {
  static propTypes = {
    lines: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string.isRequired,
      subcontent: PropTypes.arrayOf(PropTypes.string).isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
    })).isRequired,
    token: PropTypes.string.isRequired,
  };

  render() {
    const { lines, token } = this.props;
    const images = lines.map((line) => line.images || []);
    const singleStep = lines.length <= 1;
    return (
      <Box>
        {
          !singleStep ? (
            <Box component='ol'>
              {
                lines.map((line, i) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <Box component='li' key={`direction${i}`} sx={{ p: 0.5 }}>
                    <Typography>{line.content}</Typography>
                    <Box component='ul' sx={{ my: 0 }}>
                      {
                        line.subcontent.map((sc, j) => (
                          /* eslint-disable-next-line react/no-array-index-key */
                          <Box component='li' key={`subcontentLine${j}`}>
                            <Typography variant='body2'>{sc}</Typography>
                          </Box>
                        ))
                      }
                    </Box>
                  </Box>
                ))
              }
            </Box>
          ) : lines.map((line, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Typography key={`direction${i}`} sx={{ m: 2 }}>
              {line.content}
            </Typography>
          ))

        }
        {
          images.flat().length ? (
            <ImageGallery
              images={images.map((stepImages, idx) => stepImages.map((img) => ({
                file_name: img,
                content_type: 'image/jpeg',
                caption: lines[idx].content,
                refNum: !singleStep ? idx + 1 : null,
              }))).flat()}
              getImageUrl={CommonService.getImageUrlBuilder('maxi', token)}
              height={240}
            />
          ) : null
        }
      </Box>
    );
  }
}

const { selectSessionToken } = sessionSelectors;

export default connect(
  (state) => ({
    token: selectSessionToken(state),
  }),
)(DetailedContent);
