import { STATUS_INIT } from 'constants/statuses.constants';

export const getInitialState = () => ({
  alignments: [],
  readMapping: [],
  showMappingDialog: false,
  uploadWarnings: {
    noAlignments: [],
    noConstructs: [],
  },
  status: STATUS_INIT,
  error: null,
});
