import { GIGA_BATCH, MAXI_BATCH, batchStatuses } from 'constants/batch.constants';
import { STATUS_ERROR, STATUS_IDLE, STATUS_SUCCESS } from 'constants/statuses.constants';
import { batchActions, batchSelectors } from 'store/batch';
import { selectActiveStep, selectSaveStatus } from './batchStepper.selectors';

import { BATCH_STEPPER } from 'constants/store.constants';
import { createAction } from '@reduxjs/toolkit';

const {
  selectBatchData,
  selectBatchId,
  selectBatchStepIdx,
  selectBatchSubstepIdx,
  selectBatchType,
  selectBatchUpdates,
  selectConstructs,
} = batchSelectors;
const { updateBatch } = batchActions;

export const handleSave = () => (dispatch, getState) => {
  const state = getState();
  if (![STATUS_IDLE, STATUS_ERROR].includes(selectSaveStatus(state))) return;
  // TODO: temporary fix
  if (selectBatchType(state) === MAXI_BATCH || selectBatchType(state) === GIGA_BATCH) {
    dispatch(updateBatch({
      batch_id: selectBatchId(state),
      updates: selectBatchUpdates(state),
    }));
  } else {
    dispatch(updateBatch({
      batch_id: selectBatchId(state),
      batch_data: selectBatchData(state),
      construct_data: selectConstructs(state),
    }));
  }
};

export const _updateStepAction = createAction(`${BATCH_STEPPER}/updateStep`);
export const updateStep = (stepIdx, substepIdx) => (dispatch, getState) => {
  const state = getState();
  const batchType = selectBatchType(state);
  // TODO: temporary fix
  if ([MAXI_BATCH, GIGA_BATCH].includes(batchType) && selectBatchUpdates(state).length) {
    dispatch(handleSave());
  }
  dispatch(_updateStepAction({
    stepIdx,
    substepIdx: substepIdx || selectBatchSubstepIdx(state, batchStatuses[batchType][stepIdx]),
  }));
};

// NOTE: this function is ONLY used for cloning batches, TODO: has a lot of pointless code
export const onNextStep = () => (dispatch, getState) => {
  const state = getState();
  const batch_id = selectBatchId(state);
  const type = selectBatchType(state);
  const statuses = batchStatuses[type];
  const viewStepIdx = selectActiveStep(state);
  const latestStepIdx = selectBatchStepIdx(state);

  // Stepper without substeps
  if (viewStepIdx + 1 > latestStepIdx) {
    dispatch(updateBatch({
      batch_id,
      status: statuses[viewStepIdx + 1],
      batch_data: selectBatchData(state),
      construct_data: selectConstructs(state),
    }));
  } else if (selectSaveStatus(state) !== STATUS_SUCCESS) {
    dispatch(updateBatch({
      batch_id,
      batch_data: selectBatchData(state),
      construct_data: selectConstructs(state),
    }));
  }
  dispatch(updateStep(viewStepIdx + 1));
};
