import React, { useEffect } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_READ } from 'constants/batchActions.constants';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { timeNow } from 'utils/date.utils';

function PlainText(props) {
  const { step, substep, line, readOnly } = props;
  const lineData = useSelector((state) => batchSelectors.selectBatchLineData(state, step, substep, line));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!readOnly && !lineData.is_read) {
      dispatch(batchActions.handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_READ,
        payload: {
          is_read: true,
          at: timeNow(),
        },
      }));
    }
  }, [readOnly, lineData.is_read, dispatch, step, substep, line]);


  const { content } = lineData;
  return (
    <Typography>{content}</Typography>
  );
}

PlainText.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default PlainText;
