import * as BatchService from 'services/BatchService';
import * as CommonService from 'services/CommonService';

import { deepGet, mergeSx } from '@acheloisbiosoftware/absui.utils';

import { BATCH_REPORT } from 'constants/batch.constants';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommentButton from './CommentButton';
import DataTable from 'components/DataTable';
import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageGallery from 'components/ImageGallery';
import InfoTable from 'components/InfoTable';
import LockButton from './LockButton';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import SendSignaturesButton from './SendSignaturesButton';
import SignButton from './SignButton';
import SignatureSection from './SignatureSection';
import StatusPill from 'components/StatusPill';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { calcYield } from 'utils/biology.utils';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';
import { idFindFn } from 'utils/helpers';
import moment from 'moment';
import { notificationActions } from 'store/notification';
import { sessionSelectors } from 'store/session';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const CenterText = (props) => (
  <Typography {...props} sx={mergeSx({ textAlign: 'center', m: 'auto' }, props?.sx)} />
);
CenterText.propTypes = {
  sx: sxPropType,
};

class MaxiReport extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    constructCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    batchData: PropTypes.arrayOf(PropTypes.shape({
      substeps: PropTypes.arrayOf(PropTypes.shape({
        lines: PropTypes.arrayOf(PropTypes.object),
      })),
    })).isRequired,
    batchReportData: PropTypes.shape({
      signed_status: PropTypes.string.isRequired,
    }).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // TODO load batch report
    // const { fetchBatchReport, batchId } = this.props;
    // fetchBatchReport(batchId);
  }

  render() {
    const { batchId, batchData, batchReportData, constructCodes, token, enqueueSnackbar } = this.props;

    const gelImages = deepGet(batchData, [idFindFn('QC'), 'substeps', idFindFn('GEL'), 'lines', idFindFn('gel_images'), 'data']);
    const columns = [
      { key: 'constructCode', title: 'Construct', cellProps: { sx: { textAlign: 'left' }}},
      { key: 'numFlasks', title: 'Number of 200 mL Cultures', adornment: 'Flasks' },
      { key: 'yield', title: 'Yield', adornment: 'µg' },
      { key: 'endotoxin', title: 'Endotoxin' },
      { key: 'sequenceConfirmed', title: 'Sequence Confirmed' },
    ];
    const rows = constructCodes.map((constructCode) => ({
      key: `${constructCode}_QCrow`,
      constructCode,
      numFlasks: deepGet(batchData, [idFindFn('SEED'), 'substeps', idFindFn('SEED_FLASK'), 'lines', idFindFn('num_flasks'), 'data', idFindFn(constructCode, 'construct_code'), 'count']) || '–',
      yield: calcYield(deepGet(batchData, [idFindFn('QC'), 'substeps', idFindFn('YIELD'), 'lines', idFindFn('yields'), 'data', idFindFn(constructCode, 'construct_code')])),
      endotoxin: (
        deepGet(
          batchData,
          [idFindFn('QC'), 'substeps', idFindFn('ENDOTOXIN'), 'lines', idFindFn('endotoxin_levels'), 'data', idFindFn(constructCode, 'construct_code'), 'concentration'],
        ) ? (
            `${deepGet(batchData, [idFindFn('QC'), 'substeps', idFindFn('ENDOTOXIN'), 'lines', idFindFn('endotoxin_levels'), 'data', idFindFn(constructCode, 'construct_code'), 'concentration'])} EU/mL`
          ) : '–'
      ),
      sequenceConfirmed: (
        deepGet(batchData, [idFindFn('QC'), 'substeps', idFindFn('SEQUENCING'), 'lines', idFindFn('confirmation'), 'data', idFindFn(constructCode, 'construct_code'), 'confirmed']) ? (
          deepGet(batchData, [idFindFn('QC'), 'substeps', idFindFn('SEQUENCING'), 'lines', idFindFn('confirmation'), 'data', idFindFn(constructCode, 'construct_code'), 'valid']) ? (
            <CheckCircleOutlineIcon sx={{ color: 'success.main' }} />
          ) : (
            <ErrorOutlineIcon sx={{ color: 'error.main' }} />
          )
        ) : (
          <HelpOutlineIcon sx={{ color: 'text.disabled' }} />
        )
      ),
    }));
    const startTime = deepGet(batchData, [idFindFn('SEED'), 'substeps', idFindFn('SHAKING'), 'lines', idFindFn('shake'), 'start', 'at']);
    const endTime = deepGet(batchData, [idFindFn('SEED'), 'substeps', idFindFn('SHAKING'), 'lines', idFindFn('shake'), 'end', 'at']);
    const timeData = [
      { key: 'start', title: 'Shake Start', content: formatDate(startTime, FMT_DATE_TIME) },
      { key: 'end', title: 'Shake End', content: formatDate(endTime, FMT_DATE_TIME) },
      { key: 'duration', title: 'Shake Duration', content: startTime && endTime ? moment(startTime).from(endTime, true) : '–' },
    ];
    const columnsVariations = [
      { key: 'step', title: 'Step' },
      { key: 'note', title: 'Variation / Observation' },
      { key: 'reported', title: 'Reporter' },
    ];
    const rowsVariations = batchData.map((step) => step.substeps.map((substep) => substep.lines.map((line) => ({
      stepTitle: step.title,
      substepTitle: substep.title,
      lineTitle: line.title,
      notes: line.notes,
    })))).flat(3)
      .filter((line) => line.notes?.content)
      .map((row) => ({
        key: `${row.stepTitle}/${row.substepTitle}/${row.lineTitle}`,
        step: (
          <>
            {row.stepTitle}
            <br />
            {row.substepTitle}
            <br />
            {row.lineTitle}
          </>
        ),
        note: row.notes.content,
        reported: (
          <>
            {row.notes.by?.name}
            <br />
            {row.notes?.at ? formatDate(row.notes?.at, FMT_DATE_TIME) : ''}
          </>
        ),
      }));
    return (
      <Box>
        <CenterText variant='h3'>Report</CenterText>
        <DataTable
          columns={columns}
          data={rows}
          defaultCellProps={{ sx: { textAlign: 'center' }}}
          containerProps={{ sx: { width: 0.8, m: 'auto', mt: 2 }}}
        />
        <Box sx={{ display: 'flex', width: 0.8, my: 3, mx: 'auto' }}>
          <Box sx={{ width: 0.5, m: 'auto', ml: 0 }}>
            <Paper variant='outlined' sx={{ width: 0.9 }}>
              <InfoTable
                data={timeData}
                borderTop={false}
                borderBottom={false}
                autoSpacing
              />
            </Paper>
          </Box>
          <Box sx={{ width: 0.5, m: 'auto', mr: 0 }}>
            <CenterText variant='h5'>Gel</CenterText>
            {
              gelImages.length ? (
                <ImageGallery
                  images={gelImages}
                  getImageUrl={CommonService.getImageUrlBuilder('gel', token)}
                />
              ) : (
                <CenterText>None</CenterText>
              )
            }
          </Box>
        </Box>
        <CenterText variant='h5'>Variations / Observations</CenterText>
        <DataTable
          columns={columnsVariations}
          data={rowsVariations}
          containerProps={{ sx: { width: 0.8, m: 'auto' }}}
        />
        <Box sx={{ display: 'flex', my: 1.5 }}>
          <Typography
            variant='h6'
            sx={{ m: 'auto', mr: 1 }}
          >
            Report Status:
          </Typography>
          <StatusPill
            status={batchReportData.signed_status}
            type={BATCH_REPORT}
            sx={{ mr: 2 }}
          />
        </Box>
        <Box sx={{ mr: 2, textAlign: 'right' }}>
          <LockButton sx={{ ml: 2 }} />
          <SendSignaturesButton sx={{ ml: 2 }} />
          <CommentButton sx={{ ml: 2 }} />
          <SignButton sx={{ ml: 2 }} />
          <DownloadButton
            variant='outlined'
            size='small'
            sx={{ ml: 2 }}
            download={() => BatchService.getBatchReportPdf(batchId)}
            onError={(err) => enqueueSnackbar({
              message: err?.error?.response?.data?.message ?? 'Error downloading report.',
              key: `reportError_${(new Date()).getTime()}`,
              options: { variant: 'error' },
            })}
          >
            Export
          </DownloadButton>
        </Box>
        <SignatureSection />
      </Box>
    );
  }
}

const { selectBatchData, selectBatchId, selectBatchReportData, selectConstructCodes } = batchSelectors;
const { selectSessionToken } = sessionSelectors;
const { enqueueSnackbar } = notificationActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchData: selectBatchData(state),
    batchReportData: selectBatchReportData(state),
    constructCodes: selectConstructCodes(state),
    token: selectSessionToken(state),
  }),
  { enqueueSnackbar },
)(MaxiReport);
