import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import LoadingPage from 'components/LoadingPage';
import PropTypes from 'prop-types';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

function SecureRoute({ redirectTo }) {
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return (<LoadingPage />);
  }

  return authState.isAuthenticated ? <Outlet /> : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}

SecureRoute.propTypes = {
  redirectTo: PropTypes.string,
};

SecureRoute.defaultProps = {
  redirectTo: '/login',
};

export default SecureRoute;
