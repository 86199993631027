import { sequencingActions, sequencingSelectors } from 'store/sequencing';

import { Dialog } from '@acheloisbiosoftware/absui.core';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class NoAlignmentError extends React.Component {
  static propTypes = {
    noConstructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      file_name: PropTypes.string.isRequired,
    })).isRequired,
    clearWarnings: PropTypes.func.isRequired,
  };

  render() {
    const { noConstructs, clearWarnings } = this.props;
    const onClose = () => clearWarnings('noConstructs');
    return (
      <Dialog
        open={noConstructs.length > 0}
        onClose={onClose}
        onConfirm={onClose}
        confirmOnly
        title='Construct not found'
        disableCloseOnConfirm
      >
        <DialogContentText>
          The following
          {noConstructs.length > 1 ? ' constructs were ' : ' construct was '}
          was not found in the database, and therefore could not be aligned:
        </DialogContentText>
        <Paper variant='outlined'>
          <List>
            {
              noConstructs.map((noConstruct) => (
                <ListItem key={`${noConstruct.construct_code}-${noConstruct.clone}_${noConstruct.file_name}`}>
                  <ListItemIcon><ErrorOutlineIcon sx={{ color: 'error.main' }} /></ListItemIcon>
                  <ListItemText
                    primary={`${noConstruct.construct_code}${noConstruct.clone ? `-${noConstruct.clone}` : null}`}
                    secondary={noConstruct.file_name}
                  />
                </ListItem>
              ))
            }
          </List>
        </Paper>
      </Dialog>
    );
  }
}

const { selectUploadWarnings } = sequencingSelectors;
const { clearWarnings } = sequencingActions;

export default connect(
  (state) => ({
    noConstructs: selectUploadWarnings(state).noConstructs,
  }),
  { clearWarnings },
)(NoAlignmentError);
