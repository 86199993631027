import React, { useEffect, useState } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { materialActions, materialSelectors } from 'store/material';
import { mergeSx, usePromise } from '@acheloisbiosoftware/absui.utils';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_SELECT_MATERIAL } from 'constants/batchActions.constants';
import { AddChemicalDialog } from 'views/Chemical';
import { Autocomplete } from '@acheloisbiosoftware/absui.core';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function LotField(props) {
  const {
    chemical,
    value,
    onChange,
    editMiddleware,
    appearDisabled,
    readOnly,
  } = props;
  const { chemical_id, chemical_lots } = chemical;
  const [open, setOpen] = useState(false);
  const [lotNo, setLotNo] = useState('');
  const { promise, defer } = usePromise();

  const onCreate = (inputValue) => {
    setLotNo(inputValue);
    setOpen(true);
    return defer();
  };

  const onClose = (newChemicalLot) => {
    onChange(chemical_id, newChemicalLot);
    promise.resolve(newChemicalLot || null);
    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        onChange={(newValue) => onChange(chemical_id, newValue)}
        onCreate={onCreate}
        getOptionLabel={(option) => option.lot_no}
        createOptionText='New Chemical Lot'
        options={chemical_lots}
        label='Lot #'
        inputProps={(params) => ({
          ...params,
          variant: 'standard',
          onFocus: () => editMiddleware(null),
          InputProps: {
            ...params?.InputProps,
            sx: mergeSx(params?.InputProps?.sx, appearDisabled ? { color: 'text.disabled' } : {}),
          },
        })}
        value={value || null}
        disableClearable={appearDisabled}
        disabled={readOnly}
      />
      <AddChemicalDialog
        chemical={chemical}
        open={open}
        onClose={onClose}
        initData={{ lot_no: lotNo }}
      />
    </>
  );
}

LotField.propTypes = {
  chemical: PropTypes.shape({
    chemical_id: PropTypes.number.isRequired,
    chemical_lots: PropTypes.arrayOf(PropTypes.shape({
      lot_no: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  value: PropTypes.shape({
    lot_no: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  editMiddleware: PropTypes.func.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

function ChemicalTable(props) {
  const { step, substep, line, readOnly, appearDisabled, editMiddleware } = props;
  const dispatch = useDispatch();
  const lineData = useSelector((state) => batchSelectors.selectBatchLineData(state, step, substep, line));
  const chemicals = useSelector(materialSelectors.selectChemicals);

  useEffect(() => {
    dispatch(materialActions.fetchChemicals(true));
  }, [dispatch]);

  const setLotNo = (chemicalId, value) => {
    dispatch(batchActions.handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_SELECT_MATERIAL,
      payload: {
        row_id_key: 'chemical_id',
        chemical_id: chemicalId,
        chemical_lot_id: value?.chemical_lot_id || null,
      },
    }));
  };

  const { title, data } = lineData;
  const columns = [
    { key: 'name', title: 'Name', cellProps: { sx: { width: 120 }}},
    { key: 'vendor', title: 'Vendor', cellProps: { sx: { width: 100 }}},
    { key: 'catalog_no', title: 'Catalog #', cellProps: { sx: { width: 130 }}},
    { key: 'lot_no', title: 'Lot #', cellProps: { sx: { width: 200 }}},
    { key: 'storage_cond', title: 'Storage Condition', cellProps: { sx: { width: 150 }}},
  ];

  const rows = data.map(({ chemical_id, chemical_lot_id }) => {
    const chemical = chemicals.find((c) => c.chemical_id === chemical_id);
    if (!chemical || !chemical.chemical_lots) {
      const skeletonProps = { height: 45 };
      return {
        key: `chemical${chemical_id}_row`,
        name: <Skeleton {...skeletonProps} />,
        vendor: <Skeleton {...skeletonProps} />,
        catalog_no: <Skeleton {...skeletonProps} />,
        lot_no: <Skeleton {...skeletonProps} />,
        storage_cond: <Skeleton {...skeletonProps} />,
      };
    }
    const chemicalLot = chemical.chemical_lots.find((cl) => cl.chemical_lot_id === chemical_lot_id);
    return {
      key: `chemical${chemical_id}_row`,
      name: (
        <Link
          href={`/chemical/${chemical_id}`}
          target='_blank'
          sx={appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {}}
        >
          {chemical.name}
        </Link>
      ),
      vendor: chemical.vendor,
      catalog_no: chemical.catalog_no,
      lot_no: (
        <LotField
          chemical={chemical}
          value={chemicalLot}
          onChange={setLotNo}
          editMiddleware={editMiddleware}
          appearDisabled={appearDisabled}
          readOnly={readOnly}
        />
      ),
      storage_cond: chemical.storage_cond,
    };
  });
  return (
    <Box sx={{ textAlign: 'center', m: 3 }}>
      <Typography variant='h6'>{title}</Typography>
      <DataTable
        columns={columns}
        data={rows}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
        containerProps={{ sx: { m: 1 }}}
      />
    </Box>
  );
}

ChemicalTable.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  editMiddleware: PropTypes.func.isRequired,
};

export default ChemicalTable;
