import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { styled } from '@mui/system';

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[50],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.fontSize,
  },
}));

export default Cell;
