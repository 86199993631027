import FormGroup from '@mui/material/FormGroup';
import Item from './ChecklistItem';
import PropTypes from 'prop-types';
import React from 'react';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function Checklist(props) {
  const {
    sx,
    items,
    onClick,
    dense,
    disabled,
    checkboxProps,
  } = props;
  return (
    <FormGroup sx={sx}>
      {
        items.map((child, idx) => (
          <Item
            /* eslint-disable-next-line react/no-array-index-key */
            key={`child_${idx}`}
            onClick={onClick}
            dense={dense}
            checkboxProps={{ ...checkboxProps, ...child?.checkboxProps }}
            disabled={disabled}
            {...child}
          />
        ))
      }
    </FormGroup>
  );
}

Checklist.propTypes = {
  /**
   * An array of objects representing checklist items. Each object should
   * contain content to display (item.content) and whether or not the item has
   * been checked (found at item.checked). Additionally, each item can include
   * subcontent to be displayed under the main content (found at
   * item.subcontent, a node or a list of nodes to be rendered) and props for
   * the specific Checkbox component (found at item.checkboxProps).
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** If true, editable interactions are disabled. */
  disabled: PropTypes.bool,

  /** Callback function when a checkbox item is clicked. */
  onClick: PropTypes.func,

  /** If true, the checklist will be condensed with less padding. */
  dense: PropTypes.bool,

  /** Props to be passed to each Checkbox component in the list. */
  checkboxProps: PropTypes.object,

  /** sx to be applied to the FormGroup wrapper component. */
  sx: sxPropType,
};

export default Checklist;

