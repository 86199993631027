import { PROJECT } from 'constants/store.constants';
import { createSelector } from '@reduxjs/toolkit';

const selectProjectsSlice = (state) => state[PROJECT];
export const selectProjects = (state) => selectProjectsSlice(state).projects;

export const selectProjectNames = createSelector(
  selectProjects,
  (projects) => projects.map((p) => p.project_name),
);
