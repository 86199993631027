import { STATUS_INIT } from 'constants/statuses.constants';

export const getInitialState = () => ({
  // Inventory listing
  equipments: [],
  materials: [],
  chemicals: [],
  buffers: [],
  statusInventory: STATUS_INIT,
  errorInventory: null,

  // Individual page data
  equipment: {},
  material: {},
  chemical: {},
  buffer: {},
  statusPage: STATUS_INIT,
  errorPage: null,
});
