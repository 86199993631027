import DescriptionIcon from '@mui/icons-material/Description';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@mui/material/Tab';
import TableSelection from './TableSelection';
import Tabs from '@mui/material/Tabs';

class TableSelectionDialog extends React.Component {
  static propTypes = {
    handleDataSelection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    csvData: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.array).isRequired,
      file: PropTypes.string.isRequired,
    })).isRequired,
    selection: PropTypes.shape({
      csvFile: PropTypes.number.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      csvSelected: props.selection.csvFile,
    };
    this.dataSelection = null;
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleConfirm() {
    const { handleDataSelection } = this.props;
    if (this.dataSelection) {
      handleDataSelection(this.dataSelection);
      this.handleClose(this.dataSelection.csvFile);
    }
  }

  handleSelection(selection, csvSelected) {
    const { csvData } = this.props;
    const { colRange, rowRange } = selection;
    this.dataSelection = {
      csvFile: csvSelected,
      colRange,
      rowRange,
      data: csvData[csvSelected].data
        .slice(rowRange[0], rowRange[1] + 1)
        .map((row) => row.slice(colRange[0], colRange[1] + 1)),
    };
  }

  handleClose(csvSelected) {
    const { onClose, selection } = this.props;
    this.setState({ csvSelected: typeof(csvSelected) !== 'undefined' ? csvSelected : selection.csvFile });
    onClose();
  }

  render() {
    const { csvData, open, selection } = this.props;
    const { csvSelected } = this.state;
    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose()}
        maxWidth={false}
        onConfirm={this.handleConfirm}
      >
        <Tabs
          value={csvSelected}
          onChange={(e, newCsvSelected) => this.setState({ csvSelected: newCsvSelected })}
          indicatorColor='primary'
          textColor='primary'
        >
          {
            csvData.map((d) => (
              <Tab label={d.file} key={d.file} icon={<DescriptionIcon />} />
            ))
          }
        </Tabs>
        <Paper
          id='csv_selction_container'
          variant='outlined'
          sx={{ overflow: 'auto', mt: 1 }}
        >
          <TableSelection
            data={csvData[csvSelected].data}
            setSelection={(newSelection) => this.handleSelection(newSelection, csvSelected)}
            selection={selection}
          />
        </Paper>
      </Dialog>
    );
  }
}

export default TableSelectionDialog;
