import {
  aminoAcidLetter,
  aminoAcidShort,
  dnaComplements,
  dnaStandardMapping,
  translationTable,
} from 'constants/biology.constants';

import { deepGet } from '@acheloisbiosoftware/absui.utils';

export function isOverlapping(range1, range2, circularLen) {
  const r1 = range1.map((v) => (v > circularLen ? v - (Math.floor(v / circularLen) * circularLen) : v));
  const r2 = range2.map((v) => (v > circularLen ? v - (Math.floor(v / circularLen) * circularLen) : v));

  if (circularLen && r1[0] > r1[1]) {
    return isOverlapping([0, r1[1]], r2, circularLen) || isOverlapping([r1[0], circularLen], r2, circularLen);
  }
  if (circularLen && r2[0] > r2[1]) {
    return isOverlapping(r1, [0, r2[1]], circularLen) || isOverlapping(r1, [r2[0], circularLen], circularLen);
  }
  return (
    (r1[0] >= r2[0] && r1[0] < r2[1]) ||
    (r2[0] >= r1[0] && r2[0] < r1[1])
  );
}

export function getLocationRange(locationData) {
  if (locationData.type === 'singular') {
    const { start, end } = locationData.location;
    return [start, end];
  }
  if (locationData.type === 'compound') {
    // Should be wrapping around origin
    // E.g. [(start: 0, end: 194), (start: 5708, end: 6103)] where len=6103
    const start = Math.max(...locationData.location.map((loc) => loc.start));
    const end = Math.min(...locationData.location.map((loc) => loc.end));
    return [start, end];
  }
  return [0, 0];
}

export function dnaComplement(seq) {
  return seq.split('').map((base) => dnaComplements[base]).join('');
}

export function reverseComplement(seq) {
  return dnaComplement(seq)
    .split('')
    .reverse()
    .join('');
}

function standardizeSeq(seq) {
  return seq.split('').map((base) => dnaStandardMapping[base][0]).join('');
}

export function seqSlice(seq, bpRange) {
  if (bpRange[0] > bpRange[1]) {
    // Wraps
    return seq.slice(bpRange[0], seq.length) + seq.slice(0, bpRange[1]);
  }
  return seq.slice(...bpRange);
}

export function codonTranslate(codon, format = 'short') {
  if (codon.length !== 3) return null;
  const standardCodon = standardizeSeq(codon);
  const aminoAcid = deepGet(translationTable, standardCodon.toLowerCase().split(''));
  if (format === 'short') return aminoAcidShort[aminoAcid];
  if (format === 'letter') return aminoAcidLetter[aminoAcid];
  return aminoAcid;
}

export function seqTranslate(seq, strand = 1, format = 'short') {
  const toTranslate = strand === -1 ? reverseComplement(seq) : seq;
  return toTranslate.match(/.{1,3}/g).map((codon) => codonTranslate(codon, format));
}
