import { batchActions, batchSelectors } from 'store/batch';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { range as _range } from 'lodash';
import { connect } from 'react-redux';
import { deepObjectModify } from 'utils/helpers';

function parseClones(cloneString) {
  const numbersOnly = cloneString.replace(/[^1-9\-,\s]/, ' ');
  const components = numbersOnly.split(/[\s,]+/);
  const cloneNumbers = components.map((numOrRange) => {
    const range = numOrRange.split('-', 2);
    if (range.length === 1) {
      return parseInt(numOrRange);
    }
    return _range(parseInt(range[0]), parseInt(range[1]) + 1);
  }).flat();
  return cloneNumbers.filter((clone) => clone);
}

function parsePrimers(primerString) {
  const primers = primerString.split(/[\s,]+/);
  return primers.filter((primer) => primer).map((primer) => (/[0-9]$/.test(primer[0]) ? `_${primer}` : primer));
}

function generateReactionNames(sequencingReactions) {
  return sequencingReactions.map(({ construct_code, clones, primers }) => {
    const parsedClones = parseClones(clones);
    const parsedPrimers = parsePrimers(primers);
    return parsedClones.map((clone) => parsedPrimers.map((primer) => `${construct_code}-${clone}${primer}`));
  }).flat(3);
}

const columns = [
  { key: 'constructCode', title: 'Construct' },
  { key: 'clones', title: 'Clone Numbers', editable: true },
  { key: 'primers', title: 'Primers', editable: true },
];

class ReactionTable extends React.Component {
  static propTypes = {
    sequencingReactions: PropTypes.arrayOf(PropTypes.shape({
      clones: PropTypes.string.isRequired,
      construct_code: PropTypes.string.isRequired,
      primers: PropTypes.string.isRequired,
    })).isRequired,
    handleBatchInput: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onGenerateNames = this.onGenerateNames.bind(this);
  }

  onChange(value, row, col) {
    const { sequencingReactions, handleBatchInput } = this.props;
    const newSequencingReactions = deepObjectModify(sequencingReactions, [(e) => e.construct_code === row.constructCode, col.key], value);
    handleBatchInput(['SEQUENCING', 'reactions'], newSequencingReactions);
  }

  onGenerateNames() {
    const { sequencingReactions, handleBatchInput } = this.props;
    const newReactionNames = generateReactionNames(sequencingReactions);
    handleBatchInput(['SEQUENCING', 'reaction_names'], newReactionNames);
  }

  render() {
    const { sequencingReactions } = this.props;
    const rows = sequencingReactions.map(({ construct_code, clones, primers }) => ({
      key: `${construct_code}_reactionRow`,
      constructCode: construct_code,
      clones,
      primers,
    }));
    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ m: 'auto' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant='h5' sx={{ color: 'common.red', textDecoration: 'underline', ml: 0, mb: 0.5 }}>Reactions</Typography>
            <Button
              onClick={this.onGenerateNames}
              variant='outlined'
              sx={{ m: 'auto', mr: 0 }}
            >
              Generate List
            </Button>
          </Box>
          <DataTable
            columns={columns}
            data={rows}
            onChange={this.onChange}
            tableProps={{ size: 'small' }}
            defaultInputProps={{ variant: 'outlined' }}
            containerProps={{ sx: { mt: 1.5, maxHeight: 500, overflow: 'auto' }}}
          />
        </Box>
      </Box>
    );
  }
}

const { selectBatchStepData } = batchSelectors;
const { handleBatchInput } = batchActions;

export default connect(
  (state) => ({
    sequencingReactions: selectBatchStepData(state, 'SEQUENCING').reactions,
  }),
  { handleBatchInput },
)(ReactionTable);
