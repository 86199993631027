import PropTypes from 'prop-types';
import React from 'react';

class Tick extends React.Component {
  static propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    bp: PropTypes.number.isRequired,
    plasmidLength: PropTypes.number.isRequired,
    thickness: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
  };

  render() {
    const { cx, cy, r, bp, plasmidLength, thickness, length } = this.props;
    const theta = (bp / plasmidLength) * 360;
    return (
      <rect
        x={cx}
        y={cy - r}
        width={thickness}
        height={length}
        /* eslint-disable-next-line react/no-unknown-property */
        transform-origin={`${cx}px ${cy}px`}
        transform={`rotate(${theta})`}
      />
    );
  }
}

export default Tick;
