import { round } from 'utils/formatting.utils';
import { stringToDownloadable } from '@acheloisbiosoftware/absui.utils';

export function calcYield(yieldObj) {
  const { volume, concentration } = yieldObj;
  const v = parseFloat(volume);
  const c = parseFloat(concentration);
  if (!v || !c) return 0;
  return round(v * c / 1000, 0.01);
}

export function createPrimerTsv(primers) {
  const header = 'Name\tSequence\tScale\tPurification\n';
  const lines = primers.map((p) => `${p.name}\t${p.sequence}\t25nm\tSTD\n`);
  const strFile = header.concat(lines.join(''));
  return stringToDownloadable(strFile, 'text/plain;charset=utf-8');
}

export function getAbbrev(constructCode) {
  return constructCode?.match(/([a-zA-Z]+)([0-9]+)/)?.[1] || '';
}
