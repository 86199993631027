import { notificationActions, notificationSelectors } from 'store/notification';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

class Notifier extends React.Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      options: PropTypes.object,
    })).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.displayed = [];
  }

  componentDidUpdate() {
    const { notifications, enqueueSnackbar, closeSnackbar, removeSnackbar } = this.props;
    notifications.forEach(({ key, message, options = {}}) => {
      if (this.displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        action: (myKey) => (
          <IconButton
            size='small'
            color='inherit'
            onClick={() => closeSnackbar(myKey)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        ),
        onExited: (event, myKey) => {
          removeSnackbar(myKey);
          this.displayed = this.displayed.filter((id) => id !== myKey);
        },
      });

      this.displayed = [...this.displayed, key];
    });
  }

  render() {
    return null;
  }
}

const { selectNotifications } = notificationSelectors;
const { removeSnackbar } = notificationActions;
export default connect(
  (state) => ({
    notifications: selectNotifications(state),
  }),
  { removeSnackbar },
)(withSnackbar(Notifier));
