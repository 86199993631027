const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const ISSUER = process.env.REACT_APP_ISSUER;

export const ORIGIN = window.location.origin;
export const LOGIN_CALLBACK_PATH = '/login/callback';
export const LOGOUT_CALLBACK_PATH = '/logout';
const LOGIN_REDIRECT_URI = `${ORIGIN}${LOGIN_CALLBACK_PATH}`;
const LOGOUT_REDIRECT_URI = `${ORIGIN}${LOGOUT_CALLBACK_PATH}`;
const SCOPES = ['openid', 'profile', 'email', 'offline_access'];

export default {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: LOGIN_REDIRECT_URI,
  postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
  scopes: SCOPES,
  pkce: true,
};
