import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';

class AnnotationText extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      textRow: PropTypes.number.isRequired,
      blockRow: PropTypes.number.isRequired,
      canvasStart: PropTypes.number.isRequired,
      canvasEnd: PropTypes.number.isRequired,
      feature_name: PropTypes.string.isRequired,
      color: PropTypes.object.isRequired,
      location_id: PropTypes.number.isRequired,
      annotationId: PropTypes.string.isRequired,
    }),
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    textPadding: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const {
      feature,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      stackUpHeight,
      onClick,
      onDoubleClick,
      onHover,
    } = this.props;
    const {
      textRow,
      blockRow,
      canvasStart,
      canvasEnd,
      feature_name,
      color,
      location_id,
      annotationId,
    } = feature;

    const midPoint = (canvasEnd + canvasStart) / 2;
    const textWidth = TextMeasurer.getWidth(feature_name, fontSize, fontFamily);
    const textStart = midPoint - (textWidth / 2);
    const yRow = textRow * (blockHeight + blockSpacing);
    const yRowTop = !stackUpHeight ? yRow : stackUpHeight - yRow - blockHeight;
    const yText = yRowTop + (blockHeight / 2);

    const clickable = onClick || onDoubleClick;
    return (
      <g
        onClick={onClick ? () => onClick(location_id) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(location_id) : null}
        style={clickable ? { cursor: 'pointer' } : null}
        onMouseEnter={clickable ? () => onHover(feature, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(feature, 'leave') : null}
      >
        <rect
          x={textStart - textPadding}
          y={yRowTop}
          rx={textPadding}
          ry={textPadding}
          width={textWidth + (2 * textPadding)}
          height={blockHeight}
          fill={textRow !== blockRow ? 'white' : 'none'}
          stroke={textRow !== blockRow ? color === '#FFFFFF' ? '#A3A3A3' : color : 'none'}
          className={`${annotationId}_textContainer`}
        />
        <text
          x={textStart}
          y={yText}
          fontSize={fontSize}
          fontFamily={fontFamily}
          alignmentBaseline='central'
          fill={color.isDark() && textRow === blockRow ? 'white' : 'black'}
          style={disableTextSelect}
          className={`${annotationId}_text`}
        >
          {feature_name}
        </text>
      </g>
    );
  }
}

export default AnnotationText;
