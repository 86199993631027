export const dnaComplements = {
  A: 'T',
  C: 'G',
  G: 'C',
  T: 'A',
  U: 'A',
  R: 'Y',
  Y: 'R',
  K: 'M',
  M: 'K',
  S: 'S',
  W: 'W',
  B: 'V',
  D: 'H',
  H: 'D',
  V: 'B',
  N: 'N',
  a: 't',
  c: 'g',
  g: 'c',
  t: 'a',
  u: 'a',
  r: 'y',
  y: 'r',
  k: 'm',
  m: 'k',
  s: 's',
  w: 'w',
  b: 'v',
  d: 'h',
  h: 'd',
  v: 'b',
  n: 'n',
};

export const dnaStandardMapping = {
  A: ['A'],
  C: ['C'],
  G: ['G'],
  T: ['T'],
  U: ['T'],
  R: ['G', 'A'],
  Y: ['C', 'T'],
  K: ['G', 'T'],
  M: ['A', 'C'],
  S: ['G', 'C'],
  W: ['A', 'T'],
  B: ['G', 'T', 'C'],
  D: ['G', 'A', 'T'],
  H: ['A', 'C', 'T'],
  V: ['G', 'C', 'A'],
  N: ['A', 'G', 'C', 'T'],
  a: ['a'],
  c: ['c'],
  g: ['g'],
  t: ['t'],
  u: ['t'],
  r: ['g', 'a'],
  y: ['c', 't'],
  k: ['g', 't'],
  m: ['a', 'c'],
  s: ['g', 'c'],
  w: ['a', 't'],
  b: ['g', 't', 'c'],
  d: ['g', 'a', 't'],
  h: ['a', 'c', 't'],
  v: ['g', 'c', 'a'],
  n: ['a', 'g', 'c', 't'],
};

export const aminoAcidShort = {
  'Alanine': 'Ala',
  'Glycine': 'Gly',
  'Isoleucine': 'Ile',
  'Leucine': 'Leu',
  'Proline': 'Pro',
  'Valine': 'Val',
  'Phenylalanine': 'Phe',
  'Tryptophan': 'Trp',
  'Tyrosine': 'Tyr',
  'Aspartic Acid': 'Asp',
  'Glutamic Acid': 'Glu',
  'Arginine': 'Arg',
  'Histidine': 'His',
  'Lysine': 'Lys',
  'Serine': 'Ser',
  'Threonine': 'Thr',
  'Cysteine': 'Cys',
  'Methionine': 'Met',
  'Asparagine': 'Asn',
  'Glutamine': 'Gln',
  'Stop': '\uFF0A',
};

export const aminoAcidLetter = {
  'Alanine': 'A',
  'Glycine': 'G',
  'Isoleucine': 'I',
  'Leucine': 'L',
  'Proline': 'P',
  'Valine': 'V',
  'Phenylalanine': 'F',
  'Tryptophan': 'W',
  'Tyrosine': 'Y',
  'Aspartic Acid': 'D',
  'Glutamic Acid': 'E',
  'Arginine': 'R',
  'Histidine': 'H',
  'Lysine': 'K',
  'Serine': 'S',
  'Threonine': 'T',
  'Cysteine': 'C',
  'Methionine': 'M',
  'Asparagine': 'N',
  'Glutamine': 'Q',
  'Stop': '\uFF0A',
};

export const translationTable = {
  // ///////////////////////////// First letter T ///////////////////////////////
  t: {
    t: {
      t: 'Phenylalanine',
      c: 'Phenylalanine',
      a: 'Leucine',
      g: 'Leucine',
    },
    c: {
      t: 'Serine',
      c: 'Serine',
      a: 'Serine',
      g: 'Serine',
    },
    a: {
      t: 'Tyrosine',
      c: 'Tyrosine',
      a: 'Stop',
      g: 'Stop',
    },
    g: {
      t: 'Cysteine',
      c: 'Cysteine',
      a: 'Stop',
      g: 'Tryptophan',
    },
  },
  // ///////////////////////////// First letter C ///////////////////////////////
  c: {
    t: {
      t: 'Leucine',
      c: 'Leucine',
      a: 'Leucine',
      g: 'Leucine',
    },
    c: {
      t: 'Proline',
      c: 'Proline',
      a: 'Proline',
      g: 'Proline',
    },
    a: {
      t: 'Histidine',
      c: 'Histidine',
      a: 'Glutamine',
      g: 'Glutamine',
    },
    g: {
      t: 'Arginine',
      c: 'Arginine',
      a: 'Arginine',
      g: 'Arginine',
    },
  },
  // ///////////////////////////// First letter A ///////////////////////////////
  a: {
    t: {
      t: 'Isoleucine',
      c: 'Isoleucine',
      a: 'Isoleucine',
      g: 'Methionine',
    },
    c: {
      t: 'Threonine',
      c: 'Threonine',
      a: 'Threonine',
      g: 'Threonine',
    },
    a: {
      t: 'Asparagine',
      c: 'Asparagine',
      a: 'Lysine',
      g: 'Lysine',
    },
    g: {
      t: 'Serine',
      c: 'Serine',
      a: 'Arginine',
      g: 'Arginine',
    },
  },
  // ///////////////////////////// First letter G ///////////////////////////////
  g: {
    t: {
      t: 'Valine',
      c: 'Valine',
      a: 'Valine',
      g: 'Valine',
    },
    c: {
      t: 'Alanine',
      c: 'Alanine',
      a: 'Alanine',
      g: 'Alanine',
    },
    a: {
      t: 'Aspartic Acid',
      c: 'Aspartic Acid',
      a: 'Glutamic Acid',
      g: 'Glutamic Acid',
    },
    g: {
      t: 'Glycine',
      c: 'Glycine',
      a: 'Glycine',
      g: 'Glycine',
    },
  },
};
