import { LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH } from 'constants/oktaConfig';
import { LoginCallback, withOktaAuth } from '@okta/okta-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { STATUS_ERROR, STATUS_IDLE, STATUS_INIT, STATUS_LOADING } from 'constants/statuses.constants';
import { sessionActions, sessionSelectors } from 'store/session';

import Batch from 'views/Batch';
import Buffer from 'views/Buffer';
import Chemical from 'views/Chemical';
import Construct from 'views/Construct';
import Design from 'views/Design';
import Equipment from 'views/Equipment';
import FigureAutomation from 'views/FigureAutomation';
import Inventory from 'views/Inventory';
import Landing from 'views/Landing';
import LoadingPage from 'components/LoadingPage';
import Material from 'views/Material';
import NavBar from 'views/NavBar';
import NavigationLock from './NavigationLock';
import Notifier from './Notifier';
import PrivacyPolicy from 'views/PrivacyPolicy';
import PropTypes from 'prop-types';
import React from 'react';
import SecureRoute from './SecureRoute';
import Sequencing from 'views/Sequencing';
import { connect } from 'react-redux';

const SESSION_REFRESH_TIME = 10; // in minutes

class Main extends React.Component {
  static propTypes = {
    fetchSession: PropTypes.func.isRequired,
    refreshSession: PropTypes.func.isRequired,
    session: PropTypes.shape({
      token: PropTypes.object,
      status: PropTypes.oneOf([STATUS_ERROR, STATUS_IDLE, STATUS_INIT, STATUS_LOADING]),
    }).isRequired,
    authState: PropTypes.object,
    oktaAuth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.refreshSession = this.refreshSession.bind(this);
  }

  refreshSession() {
    const { authState, oktaAuth, session, refreshSession } = this.props;
    if (session.token) {
      refreshSession({ authState, oktaAuth });
    }
  }

  componentDidMount() {
    this.refreshInterval = setInterval(this.refreshSession, SESSION_REFRESH_TIME * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  componentDidUpdate() {
    const { authState, oktaAuth, session, fetchSession } = this.props;
    if (
      session.status === STATUS_INIT &&
      authState?.isAuthenticated &&
      session.token !== authState.accessToken
    ) {
      fetchSession({ authState, oktaAuth });
    }
  }

  render() {
    const isAuthenticated = Boolean(this.props.authState?.isAuthenticated);
    return (
      <>
        <Notifier />
        <NavigationLock />
        <NavBar />
        <Routes>
          <Route
            exact path='/'
            element={<Landing auth={isAuthenticated} />}
          />
          <Route
            exact path={LOGOUT_CALLBACK_PATH}
            element={<Landing auth={isAuthenticated} />}
          />
          <Route exact path={LOGIN_CALLBACK_PATH} element={<LoginCallback loadingElement={<LoadingPage />} />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route element={<SecureRoute redirectTo='/' />}>
            <Route
              exact path='/design'
              element={<Design />}
            />
            <Route
              exact path='/inventory/:type'
              element={<Inventory />}
            />
            <Route
              exact path='/sequencing'
              element={<Sequencing sx={{ mt: '64px' }} />}
            />
            <Route
              exact path='/construct/:constructCode'
              element={<Construct />}
            />
            <Route
              exact path='/batch/:batchId'
              element={<Batch />}
            />
            <Route
              exact path='/equipment/:equipmentId'
              element={<Equipment />}
            />
            <Route
              exact path='/material/:materialId'
              element={<Material />}
            />
            <Route
              exact path='/chemical/:chemicalId'
              element={<Chemical />}
            />
            <Route
              exact path='/buffer/:bufferId'
              element={<Buffer />}
            />
            <Route
              exact path='/figures'
              element={<FigureAutomation />}
            />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </>
    );
  }
}

const { selectSession } = sessionSelectors;
const { fetchSession, refreshSession } = sessionActions;
export default connect(
  (state) => ({
    session: selectSession(state),
  }),
  { fetchSession, refreshSession },
)(withOktaAuth(Main));
