import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { batchActions, batchSelectors } from 'store/batch';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { unwrapResult } from '@reduxjs/toolkit';

class CommentButton extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    commentReport: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    batchReportData: PropTypes.shape({
      locked_status: PropTypes.bool.isRequired,
      signed_status: PropTypes.string.isRequired,
    }).isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      comment: '',
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onComment = this.onComment.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onOpen() {
    this.setState({ dialogOpen: true });
  }

  onClose() {
    this.setState({ dialogOpen: false });
  }

  async onComment() {
    const { batchId, commentReport } = this.props;
    const { comment } = this.state;
    const res = await commentReport({ batchId, comment });
    try {
      unwrapResult(res);
      this.setState({ comment: '' });
      this.onClose();
    } catch (e) {}
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ comment: value });
  }

  render() {
    const { sx, batchReportData, loading } = this.props;
    const { dialogOpen, comment } = this.state;
    const isCommentAllowed = (
      batchReportData.locked_status &&
      batchReportData.signed_status !== 'Signatures Completed'
    );
    return !isCommentAllowed ? null : (
      <>
        <Button
          size='small'
          variant='contained'
          sx={sx}
          onClick={this.onOpen}
        >
          Comment
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={this.onClose}
          maxWidth='xs'
          fullWidth
          title='Add Comment'
          onConfirm={this.onComment}
          confirmDisabled={!comment}
          confirmButtonText='Comment'
          loading={loading}
          disableCloseOnConfirm
        >
          <TextField
            multiline
            minRows={5}
            variant='outlined'
            placeholder='Type your comment here...'
            sx={{ width: 1, mt: 2 }}
            value={comment}
            onChange={this.onChange}
          />
        </Dialog>
      </>
    );
  }
}

const { selectBatchId, selectBatchLoading, selectBatchReportData } = batchSelectors;
const { commentReport } = batchActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchReportData: selectBatchReportData(state),
    loading: selectBatchLoading(state),
  }),
  { commentReport },
)(CommentButton);
