import { FMTS_ISO, FMT_DATE_READABLE } from 'constants/dateFormats.constants';

import moment from 'moment';

export function formatDate(date, format = FMT_DATE_READABLE) {
  if (!date || !moment(date, FMTS_ISO).isValid()) return '–';
  return moment(date, FMTS_ISO).format(format);
}

export function timeNow() {
  return (moment()).toISOString();
}
