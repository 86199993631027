import { Button, Dialog } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';
import { primerActions, primerSelectors } from 'store/primer';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import PublishIcon from '@mui/icons-material/Publish';
import { deepObjectModify } from 'utils/helpers';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { unwrapResult } from '@reduxjs/toolkit';

const rowInit = (constructs) => ({
  constructCode: constructs[0].construct_code,
  primerName: '',
  primerSeq: '',
  errors: {
    constructCode: '',
    primerName: '',
    primerSeq: '',
  },
});

function PrimerUpload(props) {
  const { constructs, sx } = props;
  const dispatch = useDispatch();
  const loading = useSelector(primerSelectors.selectPrimersLoading);
  const warnings = useSelector(primerSelectors.selectPrimersWarnings);

  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([rowInit(constructs)]);

  const handleClose = () => {
    setOpen(false);
    setRowData([rowInit(constructs)]);
  };

  const addRow = () => {
    setRowData([...rowData, rowInit(constructs)]);
  };

  const removeRow = (idx) => {
    const newRowData = [...rowData];
    newRowData.splice(idx, 1);
    if (!newRowData.length) {
      newRowData.push(rowInit(constructs));
    }
    setRowData(newRowData);
  };

  const handleInputChange = (value, row, col) => {
    const newRow = deepObjectModify(rowData[row.idx], ['errors', col.key], '');
    const newRowData = [...rowData];
    if (col.key === 'primerSeq' && value && !value.match(/^[acgturykmswbdhvnACGTURYKMSWBDHVN]+$/g)) {
      newRow.errors[col.key] = 'Remove non-DNA characters';
    }
    newRow[col.key] = value;
    newRowData[row.idx] = newRow;
    setRowData(newRowData);
  };

  const handleSubmit = async () => {
    let invalid = false;
    const newRowData = rowData.map((row) => {
      let newRow = row;
      ['constructCode', 'primerName', 'primerSeq'].forEach((field) => {
        if (!row[field]) {
          newRow = deepObjectModify(newRow, ['errors', field], 'Required');
          invalid = true;
        }
      });
      return newRow;
    });

    if (invalid) {
      setRowData(newRowData);
      return;
    }

    const res = await dispatch(primerActions.uploadPrimers(rowData.map((row) => ({
      construct_code: row.constructCode,
      primer_name: row.primerName,
      primer_seq: row.primerSeq,
    }))));
    try {
      unwrapResult(res);
      handleClose();
    } catch (e) {}
  };

  const columns = [
    {
      key: 'constructCode',
      title: 'Construct',
      editable: true,
      inputProps: {
        select: true,
        children: constructs.map((c) => (
          <MenuItem key={c.construct_code} value={c.construct_code}>
            {c.construct_code}
          </MenuItem>
        )),
      },
    },
    {
      key: 'primerName',
      title: 'Primer Name',
      editable: true,
      inputProps: (row) => ({
        error: Boolean(row.errors.primerName),
        helperText: row.errors.primerName || null,
      }),
    },
    {
      key: 'primerSeq',
      title: 'Primer Sequence',
      editable: true,
      inputProps: (row) => ({
        error: Boolean(row.errors.primerSeq),
        helperText: row.errors.primerSeq || null,
        sx: { width: 200 },
      }),
    },
    { key: 'remove', title: 'Remove' },
  ];
  const rows = rowData.map((row, idx) => ({
    key: `primerUpload${idx}`,
    idx,
    ...row,
    remove: (
      <IconButton
        size='small'
        sx={{ width: 32, height: 32, m: 'auto' }}
        onClick={() => removeRow(idx)}
      >
        <DeleteIcon fontSize='small' />
      </IconButton>
    ),
  }));

  return (
    <Box sx={sx}>
      <Button
        variant='outlined'
        color='inherit'
        onClick={() => setOpen(true)}
        startIcon={<PublishIcon />}
      >
        Upload Primers
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        title='Upload Primers'
        extraDialogActions={(
          <Button
            variant='outlined'
            sx={{ ml: 2, mr: 'auto' }}
            onClick={addRow}
          >
            Add Primer
          </Button>
        )}
        loading={loading}
        onConfirm={handleSubmit}
        confirmButtonText='Submit'
      >
        <DataTable
          columns={columns}
          data={rows}
          onChange={handleInputChange}
          noHeader
          defaultInputProps={{
            variant: 'outlined',
            size: 'medium',
            sx: { mr: 1.5, my: 0.75, width: 160 },
          }}
          containerProps={{ sx: { border: 'none' }}}
        />
      </Dialog>

      <Dialog
        open={warnings.length > 0}
        onClose={() => dispatch(primerActions.clearWarnings())}
        onConfirm={() => dispatch(primerActions.clearWarnings())}
        title='Errors while adding primers'
        disableCloseOnConfirm
      >
        {
          warnings.map((e, idx) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <DialogContentText key={idx}>({idx + 1}) {e}</DialogContentText>
          ))
        }
      </Dialog>
    </Box>
  );
}

PrimerUpload.propTypes = {
  /**
   * The construct options to upload the primers in association with.
   */
  constructs: PropTypes.arrayOf(PropTypes.shape({
    construct_code: PropTypes.string.isRequired,
  })).isRequired,

  /** sx to be applied to the container Box. */
  sx: sxPropType,
};

export default PrimerUpload;
