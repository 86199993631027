import * as BatchService from 'services/BatchService';
import * as ConstructService from 'services/ConstructService';

import { INVENTORY } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const fetchBatches = createAsyncThunk(
  `${INVENTORY}/fetch_batches`,
  async (_, { rejectWithValue }) => {
    const res = await BatchService.getBatches();
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchConstructs = createAsyncThunk(
  `${INVENTORY}/fetch_constructs`,
  async (_, { rejectWithValue }) => {
    const res = await ConstructService.getConstructs();
    return responseToPayload(res, rejectWithValue);
  },
);
