import * as selectors from './notification.selectors';

import { extraReducers, reducers } from './notification.reducers';

import { NOTIFICATION } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './notification.initialState';

const slice = createSlice({
  name: NOTIFICATION,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const notificationSelectors = { ...selectors };
export const notificationActions = { ...slice.actions };
export const { reducer: notificationReducer } = slice;
