import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ConditionalWrapper } from '@acheloisbiosoftware/absui.core';
import DataTableRow from './DataTableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function DataTable(props) {
  const {
    columns,
    data,
    onReorder,
    tableProps,
    containerProps,
    noHeader,
    appearDisabled,
    headerCellProps,
  } = props;

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    onReorder(data[source.index], source.index, destination.index);
  };

  return (
    <Paper
      variant='outlined'
      {...containerProps}
      sx={mergeSx(containerProps?.sx, { overflow: 'auto' })}
    >
      <ConditionalWrapper
        condition={onReorder}
        wrapperComponent={DragDropContext}
        wrapperProps={{ onDragEnd }}
      >
        <Table stickyHeader {...tableProps}>
          {
            noHeader ? null : (
              <TableHead>
                <TableRow>
                  {
                    columns.map((col) => (
                      <TableCell
                        key={col.key}
                        {...headerCellProps}
                        sx={mergeSx(
                          headerCellProps?.sx,
                          { fontWeight: 'fontWeightBold', bgcolor: 'background.grey' },
                          appearDisabled ? { color: 'text.disabled' } : {},
                        )}
                      >
                        {col.title}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
            )
          }
          {
            onReorder ? (
              <Droppable droppableId='droppable'>
                {(droppableProvided) => (
                  <TableBody
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    {
                      data.map((row, rowIdx) => (
                        <Draggable
                          key={row.key}
                          draggableId={row.key}
                          index={rowIdx}
                        >
                          {(draggableProvided) => (
                            <TableRow
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              hover
                            >
                              <DataTableRow row={row} rowIdx={rowIdx} {...props} />
                            </TableRow>
                          )}
                        </Draggable>
                      ))
                    }
                    { droppableProvided.placeholder }
                  </TableBody>
                )}
              </Droppable>
            ) : (
              <TableBody>
                {
                  data.map((row, rowIdx) => (
                    <TableRow key={row.key} hover>
                      <DataTableRow row={row} rowIdx={rowIdx} {...props} />
                    </TableRow>
                  ))
                }
              </TableBody>
            )
          }
        </Table>
      </ConditionalWrapper>
    </Paper>
  );
}

DataTable.propTypes = {
  /** Defines the list of columns to be used. */
  columns: PropTypes.arrayOf(PropTypes.shape({

    /**
     * The key to identify the column. NOTE: this value will be used as the
     * key to find data in each row.
     */
    key: PropTypes.string.isRequired,

    /** The column title to be displayed. */
    title: PropTypes.string.isRequired,

    /**
     * The adornment to use for the column. This can either be a node (e.g.
     * a string) that will be displayed on all rows OR a function. If it is a
     * function, it will recieve a row as a parameter (from the props.data)
     * and should return the adornment node to be displayed.
     */
    adornment: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),

    /**
     * Props that are given to the TableCell component. These props override
     * any overlapping props from props.defaultCellProps. This can be given as
     * either an object or a function. If it is a function, it will recieve
     * a row as a parameter (from the props.data) and should return an object
     * of props for the TableCell component at the given row.
     */
    cellProps: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),

    /**
     * Props that are passed to the TextField component of an input field.
     * These props override any overlapping props from props.defaultInputProps.
     * This can be given as either an object or a function. if it is a
     * function, it will recieve a row as a parameter (from props.data) and
     * should return an object of props for the TextField component at the
     * given row.
     */
    inputProps: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),

    /**
     * Whether or not the column should be an editable one. This can be given
     * as either a boolean or a function. If it is a function, it will
     * recieve a row as a parameter (from props.data) and should return a
     * boolean of whether or not the cell at the given row should be editable.
     * NOTE: this parameter is overriden by the props.readOnly parameter.
     */
    editable: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
  })).isRequired,

  /** Defines the list of rows to be used. */
  data: PropTypes.arrayOf(PropTypes.shape({

    /** The key for the row (for React). */
    key: PropTypes.string.isRequired,

    /**
     * NOTE: Each object in props.data should include a key-value pair for
     * corresponding to every key in props.columns. That is, if there is an
     * object in props.columns with key "k1", then for every object in
     * props.data, there should be a key-value pairing with "k1" => <value>.
     */
  })),

  /**
   * Callback function for any edits. On change, the function will receive
   * a value, a row, and a column as parameters. The value will be the value
   * from event.target, the row will be the row from props.data that was
   * changed, and the column will be the column from props.columns that was
   * changed.
   */
  onChange: PropTypes.func,

  /**
   * Callback function for reordering rows (i.e. drag-and-drop). When a row
   * is changed position, the function will receive the row that was moved
   * (from props.data), the original index of that row, and the destination
   * index of that row.
   */
  onReorder: PropTypes.func,

  /**
   * If true, all cells are read only (i.e. any "editable" fields will not be
   * editable). This overrides any columns that are set to be editable from
   * props.columns[i].editable.
   */
  readOnly: PropTypes.bool,

  /**
   * If true, the table will have a disabled appearance. NOTE: this does NOT
   * actually disable inputs! Purely used for visual purposes. To disable
   * inputs, use readOnly prop.
   */
  appearDisabled: PropTypes.bool,

  /**
   * If true, there is no table header with column titles. Any input fields
   * will be individually labeled with the column title for clarity.
   */
  noHeader: PropTypes.bool,

  /** Props that are applied to all header TableCell components. */
  headerCellProps: PropTypes.object,

  /**
   * Default props that are applied to all TableCell components. NOTE: these
   * props are overriden by any overlapping props found in
   * props.columns[i].cellProps.
   */
  defaultCellProps: PropTypes.object,

  /**
   * Default props that are applied to all TextField components. NOTE: these
   * props are overriden by any overlapping props found in
   * props.columns[i].inputProps.
   */
  defaultInputProps: PropTypes.object,

  /** Props that are applied to the Table component. */
  tableProps: PropTypes.object,

  /** Props that are applied to the container Paper component. */
  containerProps: PropTypes.object,
};

DataTable.defaultProps = {
  data: [],
};

export default DataTable;
