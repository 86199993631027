import Bar from './Bar';
import Bases from './Bases';
import PropTypes from 'prop-types';
import React from 'react';
import { dnaComplement } from 'utils/sequence.utils';

const DEFAULT_PROPS = {
  canvasRange: [0, 0],
  y: 0,
  seq: '',
  fontSize: 16,
  barHeight: 18,
  barStroke: 2,
  showComplement: false,
  upper: false,
};

function getSequenceHeight(props) {
  const {
    fontSize,
    barHeight,
    showComplement,
  } = { ...DEFAULT_PROPS, ...props };
  return showComplement ? (fontSize * 2) + barHeight : fontSize;
}

class Sequence extends React.Component {
  static propTypes = {
    canvasRange: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.number,
    seq: PropTypes.string,
    fontSize: PropTypes.number,
    barHeight: PropTypes.number,
    barStroke: PropTypes.number,
    showComplement: PropTypes.bool,
    upper: PropTypes.bool,
  };

  static defaultProps = DEFAULT_PROPS;

  render() {
    const {
      canvasRange,
      y,
      seq,
      fontSize,
      upper,
      showComplement,
      barHeight,
      barStroke,
    } = this.props;

    const transform = `translate(0,${y})`;
    if (!showComplement) {
      return (
        <g transform={transform} style={{ cursor: 'text' }}>
          <Bases
            canvasRange={canvasRange}
            seq={upper ? seq.toUpperCase() : seq.toLowerCase()}
            fontSize={fontSize}
          />
        </g>
      );
    }

    return (
      <g transform={transform} style={{ cursor: 'text' }}>
        <Bases
          canvasRange={canvasRange}
          y={0}
          seq={upper ? seq.toUpperCase() : seq.toLowerCase()}
          fontSize={fontSize}
        />
        <Bar
          canvasRange={canvasRange}
          y={fontSize + (barHeight / 2)}
          seq={seq}
          barHeight={barHeight}
          barStroke={barStroke}
        />
        <Bases
          canvasRange={canvasRange}
          y={fontSize + barHeight}
          seq={dnaComplement(upper ? seq.toUpperCase() : seq.toLowerCase())}
          fontSize={fontSize}
        />
      </g>
    );
  }
}

export { getSequenceHeight };
export default Sequence;
