import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const columns = [
  { key: 'constructCode', title: 'Construct' },
  { key: 'primer', title: 'Primer Name' },
  { key: 'length', title: 'Length' },
  { key: 'sequence', title: 'Sequence', cellProps: { sx: { typography: 'dna' }}},
  { key: 'tm', title: 'TM' },
];

function PrimerTable(props) {
  const { constructs, dropCols, sx } = props;

  const primers = Array.prototype.concat.apply([], constructs.filter((c) => c.construct_data.primers)
    .map((c) => c.construct_data.primers.map((p) => ({ ...p, construct_code: c.construct_code }))));

  const cols = columns.filter((col) => !dropCols.includes(col.title));
  const rows = primers.map((primer, idx) => ({
    key: `${primer.construct_code}_${primer.name}${idx}`,
    constructCode: primer.construct_code,
    primer: primer.name,
    length: `${primer.length} bp`,
    sequence: primer.sequence,
    tm: `${primer.TM.toFixed(1)} ºC`,
  }));

  return (
    <DataTable
      columns={cols}
      data={rows}
      defaultBodyCellProps={{ sx: { maxWidth: 300, minWidth: 80, overflow: 'auto' }}}
      containerProps={{ sx: mergeSx({ maxWidth: 900, m: 'auto' }, sx) }}
    />
  );
}

PrimerTable.propTypes = {
  /**
   * The list of constructs. The table will show all of the primers from all of
   * the constructs.
   */
  constructs: PropTypes.arrayOf(PropTypes.shape({
    construct_code: PropTypes.string.isRequired,
    construct_data: PropTypes.shape({
      primers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        length: PropTypes.number,
        sequence: PropTypes.string,
        TM: PropTypes.number,
      })),
    }),
  })).isRequired,

  /**
   * Columns to hide from the table. Can be zero or more of: ['Construct',
   * 'Primer Name', 'Length', 'Sequence', 'TM'].
   */
  dropCols: PropTypes.arrayOf(PropTypes.oneOf(columns.map((col) => col.title))),

  /** sx to be applied to the DataTable */
  sx: sxPropType,
};

PrimerTable.defaultProps = {
  dropCols: [],
};

export default PrimerTable;
