import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { batchActions, batchSelectors } from 'store/batch';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { unwrapResult } from '@reduxjs/toolkit';

class SignButton extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    signReport: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    me: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    batchReportData: PropTypes.shape({
      next_signee_email: PropTypes.string,
    }).isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      comment: '',
      certified: false,
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSign = this.onSign.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCertify = this.onCertify.bind(this);
  }

  onOpen() {
    this.setState({ dialogOpen: true });
  }

  onClose() {
    this.setState({ dialogOpen: false });
  }

  async onSign() {
    const { batchId, signReport } = this.props;
    const { comment } = this.state;
    const res = await signReport({ batchId, comment });
    try {
      unwrapResult(res);
      this.setState({ comment: '', certified: false });
      this.onClose();
    } catch (e) { }
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ comment: value });
  }

  onCertify(event) {
    const { checked } = event.target;
    this.setState({ certified: checked });
  }

  render() {
    const { sx, batchReportData, me, loading } = this.props;
    const { dialogOpen, comment, certified } = this.state;
    const isSignAllowed = batchReportData.next_signee_email === me.email;
    return !isSignAllowed ? null : (
      <>
        <Button
          size='small'
          variant='contained'
          sx={sx}
          onClick={this.onOpen}
        >
          Sign
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={this.onClose}
          maxWidth='xs'
          title='Sign and Comment'
          onConfirm={this.onSign}
          confirmDisabled={!certified}
          confirmButtonText='Sign'
          disableCloseOnConfirm
          loading={loading}
        >
          <TextField
            multiline
            rows={6}
            variant='outlined'
            placeholder='Type your comment here...'
            sx={{ width: 1, my: 2 }}
            value={comment}
            onChange={this.onChange}
          />
          <Box sx={{ display: 'flex' }}>
            <Checkbox
              checked={certified}
              onChange={this.onCertify}
              color='primary'
              sx={{ mr: 1 }}
            />
            <Typography variant='body2'>I certify that the report has been generated with the right steps and procedures.</Typography>
          </Box>
        </Dialog>
      </>
    );
  }
}

const { selectBatchId, selectBatchLoading, selectBatchReportData } = batchSelectors;
const { signReport } = batchActions;
const { selectSessionUser } = sessionSelectors;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchReportData: selectBatchReportData(state),
    me: selectSessionUser(state),
    loading: selectBatchLoading(state),
  }),
  { signReport },
)(SignButton);
