import * as extraActions from './batch.extraActions';

import {
  ACTION_ALTER_PS_TABLE,
  ACTION_ALTER_Q_TABLE,
  ACTION_CHECKED,
  ACTION_INPUT,
  ACTION_NOTES,
  ACTION_PS,
  ACTION_Q,
  ACTION_READ,
  ACTION_SELECT_MATERIAL,
  ACTION_SEQUENCE_CONFIRMATION,
  ACTION_SHAKE_UPDATE,
  ACTION_TABLE_UPDATE,
  ADD,
  REMOVE,
} from 'constants/batchActions.constants';
import { GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import { NA, NO, YES } from 'constants/enums.constants';
import { ROW_TYPE_COMBINE_ELUTIONS, ROW_TYPE_ELUTION_PS, ROW_TYPE_ELUTION_Q_1, ROW_TYPE_ELUTION_Q_2, ROW_TYPE_FLOWTHROUGH, ROW_TYPE_LOAD, ROW_TYPE_STRIP, ROW_TYPE_WASH } from 'constants/batchLineTypes.constants';
import { STATUS_ERROR, STATUS_IDLE, STATUS_INIT, STATUS_LOADING } from 'constants/statuses.constants';
import { deepObjectModify, idFindFn, mergeLists } from 'utils/helpers';

import { deepGet } from '@acheloisbiosoftware/absui.utils';
import { isLineComplete } from 'utils/batch.utils';
import { primerActions } from 'store/primer';
import { round } from 'utils/formatting.utils';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const handleBatchActionUpdate = (state, update) => {
  const { step_id, substep_id, line_id, action, payload } = update;
  const { batch } = state;
  const { type, batch_data } = batch;

  const duplicateData = (src, dest) => {
    const oldData = deepGet(batch_data, dest);
    const newData = deepGet(batch_data, src);
    for (const [k, v] of Object.entries(newData)) {
      oldData[k] = v;
    }
  };

  const maxiUpdateTriton = () => {
    const numFlasksData = deepGet(batch_data, [idFindFn('SEED'), 'substeps', idFindFn('SEED_FLASK'), 'lines', idFindFn('num_flasks'), 'data']);
    const numFlasks = numFlasksData.reduce((total, curr) => (parseFloat(curr.count) || 0.0) + total, 0);
    const newTable = [
      { header: `Prepare for ${numFlasks} Samples`, value: `Need ${numFlasks * 5.0} mL 10% Triton X-100` },
      { header: `DI H2O`, value: `${numFlasks * 2.0} mL` },
      { header: `50mM Tris pH7.2, 10mM EDTA, 1.5M NaCl`, value: `${numFlasks * 2.5} mL` },
      { header: `Triton X-100`, value: `${numFlasks * 0.5} mL` },
    ];
    deepObjectModify(
      batch_data,
      [idFindFn('EXTRACTION'), 'substeps', idFindFn('REMOVE_ENDOTOXIN'), 'lines', idFindFn('triton_table'), 'data'],
      newTable,
      true,
    );
  };

  const calcEndotoxinTreatment = () => {
    const superVol = round(parseFloat(deepGet(batch_data, [idFindFn('Day 1'), 'substeps', idFindFn('D1_3'), 'lines', idFindFn('D1_S7'), 'subcontent', idFindFn('D1_S7_starting'), 'value'])), 0.01);
    // Calculate "B" value (Triton X volume)
    const bValue = round(superVol / 9.0, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_3'), 'lines', idFindFn('D1_S8'), 'subcontent', idFindFn('D1_S8_1'), 'value'],
      bValue,
      true,
    );
    const newTable = [
      { header: 'Ingredients', value: `Need ${round(bValue, 0.01)} mL 10% Triton X-100` },
      { header: 'Endofree water', value: `${round(bValue * 0.725, 0.01)} mL` },
      { header: '1M Tris (pH 7.2)_commerical', value: `${round(bValue * 0.025, 0.01)} mL` },
      { header: '5M NaCl (stock)', value: `${round(bValue * 0.15, 0.01)} mL` },
      { header: 'Triton X-100 (commercial)', value: `${round(bValue * 0.1, 0.01)} mL` },
      { header: 'Total ', value: `${round(bValue, 0.01)} mL` },
    ];
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_3'), 'lines', idFindFn('triton_table'), 'data'],
      newTable,
      true,
    );

    // Calculate resulting volume after Endotoxin Removal
    const volPostEndo = round(superVol + bValue, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_3'), 'lines', idFindFn('D1_S8'), 'subcontent', idFindFn('D1_S8_concluding'), 'value'],
      volPostEndo,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_4'), 'lines', idFindFn('D1_S9'), 'subcontent', idFindFn('D1_S9_starting'), 'value'],
      volPostEndo,
      true,
    );

    // Calculate "C" value (IPA volume)
    const ipa = round(0.7 * volPostEndo, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_4'), 'lines', idFindFn('D1_S9'), 'subcontent', idFindFn('D1_S9_1'), 'value'],
      ipa,
      true,
    );

    // Calculate resulting volume after IPA (not in excel)
    const concludingSample = round(volPostEndo + ipa, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_4'), 'lines', idFindFn('D1_S9'), 'subcontent', idFindFn('D1_S9_concluding'), 'value'],
      concludingSample,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_2'), 'lines', idFindFn('D2_S1'), 'subcontent', idFindFn('D2_S1_starting'), 'value'],
      concludingSample,
      true,
    );
  };

  const gigaCalcFromInitBacteria = () => {
    const od = deepGet(batch_data, [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S1'), 'subcontent', idFindFn('D1_S1_3'), 'value']);
    const adjustedOd = (parseFloat(od) / 40.0) || 0.0;
    const weight = parseFloat(deepGet(batch_data, [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S1'), 'subcontent', idFindFn('D1_S1_2'), 'value'])) || 0.0;

    // Calculate "A" value (P1, P2, P3)
    const aValue = round(10 * weight * adjustedOd, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S3'), 'subcontent', idFindFn('D1_S3_1'), 'value'],
      aValue,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S4'), 'subcontent', idFindFn('D1_S4_1'), 'value'],
      aValue,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S5'), 'subcontent', idFindFn('D1_S5_1'), 'value'],
      aValue,
      true,
    );

    // Calculate supernatant volume
    const superVol = round(aValue * 3, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S6'), 'subcontent', idFindFn('D1_S6_concluding'), 'value'],
      superVol,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 1'), 'substeps', idFindFn('D1_3'), 'lines', idFindFn('D1_S7'), 'subcontent', idFindFn('D1_S7_starting'), 'value'],
      String(superVol),
      true,
    );

    calcEndotoxinTreatment();
  };

  const gigaCalcFromInitVol = () => {
    // Calcualte "D" value (strip buffer volume)
    const fermVol = parseFloat(deepGet(batch_data, [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S1'), 'subcontent', idFindFn('D1_S1_1'), 'value']));
    const dValue = round(fermVol / 10, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S5'), 'subcontent', idFindFn('D2_S5_3'), 'value'],
      dValue,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S8'), 'subcontent', idFindFn('D2_S8_concluding'), 'value'],
      dValue,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_4'), 'lines', idFindFn('D2_S10'), 'subcontent', idFindFn('D2_S10_starting'), 'value'],
      dValue,
      true,
    );

    // Calculate the resulting volume at the end of D2 (not in excel)
    const concludingSample = round(dValue * 1.25, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_4'), 'lines', idFindFn('D2_S11'), 'subcontent', idFindFn('D2_S11_concluding'), 'value'],
      concludingSample,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S1'), 'subcontent', idFindFn('D3_S1_starting'), 'value'],
      concludingSample,
      true,
    );

    // Calculate strip buffer pellet wash volume (not in excel)
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S8'), 'subcontent', idFindFn('D2_S8_1'), 'value'],
      round(fermVol / 1000, 0.01),
      true,
    );
  };

  const gigaCalcD2YieldBeforePrec = () => {
    // Calculate nucleic acid amount (mg) -- lysis before (NH4)2SO4 precipitation
    const concentration = parseFloat(deepGet(batch_data, [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S9'), 'subcontent', idFindFn('D2_S9_4'), 'value'])) || 0.0;
    const volume = parseFloat(deepGet(batch_data, [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S9'), 'subcontent', idFindFn('D2_S9_6'), 'value'])) || 0.0;
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S9'), 'subcontent', idFindFn('D2_S9_7'), 'value'],
      round(5.0 * concentration * volume / 1000, 0.01),
      true,
    );
  };

  const gigaCalcAmmoniumSulfateVol = () => {
    // Calculate "E" value (ammonium sulfate volume)
    const volume = parseFloat(deepGet(batch_data, [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S9'), 'subcontent', idFindFn('D2_S9_6'), 'value']));
    const eValue = round(volume * 0.396, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 2'), 'substeps', idFindFn('D2_4'), 'lines', idFindFn('D2_S10'), 'subcontent', idFindFn('D2_S10_1'), 'value'],
      eValue,
      true,
    );
  };

  const gigaCalcD5FinalYield = () => {
    const concentration = parseFloat(deepGet(batch_data, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_2'), 'value'])) || 0.0;
    const volume = parseFloat(deepGet(batch_data, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_4'), 'value'])) || 0.0;
    deepObjectModify(
      batch_data,
      [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_5'), 'value'],
      round(concentration * volume / 1000, 0.01),
      true,
    );
  };

  const calcD3CombElut = () => {
    const loadAmount = deepGet(batch_data, [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S2_pstable'), 'data', idFindFn(ROW_TYPE_LOAD, 'row_type'), 'amount']);
    let totalAmount = 0.0;
    let totalVolume = 0.0;
    let totalSuggestedAmount = 0.0;
    let previousSuggestedAmount = 0.0;
    let moreSuggestions = true;
    const rowTypesToSum = [ROW_TYPE_ELUTION_PS];
    const data = deepGet(batch_data, [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S8_pstable'), 'data']);

    for (const row of data) {
      if (rowTypesToSum.includes(row.row_type) && row.concentration && row.volume) {
        if (row.include) {
          totalVolume += parseFloat(row.volume);
          totalAmount += parseFloat(row.amount);
        }

        totalSuggestedAmount += parseFloat(row.amount);
        if (moreSuggestions && (totalSuggestedAmount > (previousSuggestedAmount * 1.05))) {
          row.suggestion = YES;
        } else {
          row.suggestion = NO;
          moreSuggestions = false;
        }

        previousSuggestedAmount = totalSuggestedAmount;
      }
    }
    const combinedRow = deepGet(batch_data, [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S8_pstable'), 'data', idFindFn(ROW_TYPE_COMBINE_ELUTIONS, 'row_type')]);
    const combinedVolume = round(totalVolume, 0.01);
    combinedRow.volume = combinedVolume;
    combinedRow.amount = round(totalAmount, 0.01);
    combinedRow.concentration = totalVolume ? round(totalAmount / totalVolume * 1000, 0.01) : 0;
    combinedRow.yield = loadAmount ? round(totalAmount / loadAmount * 100, 0.01) : 0;
    deepObjectModify(
      batch_data,
      [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S8'), 'subcontent', idFindFn('D3_S8_concluding'), 'value'],
      combinedVolume,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S1'), 'subcontent', idFindFn('D4_S1_starting'), 'value'],
      combinedVolume,
      true,
    );

    // Calculate dilution
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S1'), 'subcontent', idFindFn('D4_S1_5'), 'value'],
      round(totalVolume * 3, 0.01),
      true,
    );

    // Calculate D4 bead, number Q-sepharose columns necessary, and Q-sepharose CV amount
    const beads = Math.ceil(totalAmount * 2);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S2'), 'subcontent', idFindFn('D4_S2_N1'), 'value'],
      beads,
      true,
    );
    const columnsNecessaryD4 = Math.ceil(beads / 150);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S2'), 'subcontent', idFindFn('D4_S2_1'), 'value'],
      columnsNecessaryD4,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S2'), 'subcontent', idFindFn('D4_S2_2'), 'value'],
      columnsNecessaryD4 ? round(beads / columnsNecessaryD4, 0.01) : 0,
      true,
    );

    // Calculate D4 pump speeds
    const slowD4 = round(beads * 15.0 / 60.0 / 0.6);
    const mediumD4 = round(beads * 20.0 / 60.0 / 0.6);
    const fastD4 = round(beads * 25.0 / 60.0 / 0.6);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S3'), 'subcontent', idFindFn('D4_S3_1'), 'value'],
      slowD4,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S4'), 'subcontent', idFindFn('D4_S4_1'), 'value'],
      slowD4,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S7'), 'subcontent', idFindFn('D4_S7_1'), 'value'],
      slowD4,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S8'), 'subcontent', idFindFn('D4_S8_1'), 'value'],
      mediumD4,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S10'), 'subcontent', idFindFn('D4_S10_1'), 'value'],
      fastD4,
      true,
    );
  };

  const calcD4CombElut = () => {
    const loadRow = deepGet(batch_data, [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S1_qtable'), 'data', idFindFn(ROW_TYPE_LOAD, 'row_type')]);
    const { amount: loadAmount } = loadRow;
    let totalAmount = 0.0;
    let totalVolume = 0.0;
    const qTables = [
      'D4_S10_qtable',
      'D4_S11_qtable',
      'D4_S12_qtable',
      'D4_S14_qtable',
    ];
    const rowTypesToSum = [
      ROW_TYPE_ELUTION_Q_1,
      ROW_TYPE_ELUTION_Q_2,
    ];
    for (const qTable of qTables) {
      const tableData = deepGet(batch_data, [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn(qTable), 'data']);
      for (const r of tableData) {
        if (rowTypesToSum.includes(r.row_type) && r.include) {
          totalAmount += parseFloat(r.amount) || 0.0;
          totalVolume += parseFloat(r.volume) || 0.0;
        }
      }
    }

    const combinedRow = deepGet(batch_data, [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S14_qtable'), 'data', idFindFn(ROW_TYPE_COMBINE_ELUTIONS, 'row_type')]);
    const combinedVolume = round(totalVolume, 0.01);
    combinedRow.volume = combinedVolume;
    combinedRow.amount = round(totalAmount, 0.01);
    combinedRow.concentration = totalVolume ? round(totalAmount / totalVolume * 1000, 0.01) : 0;
    combinedRow.yield = loadAmount ? round(totalAmount / loadAmount * 100, 0.01) : 0;
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S14'), 'subcontent', idFindFn('D4_S14_concluding'), 'value'],
      combinedVolume,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_4'), 'lines', idFindFn('D4_S15'), 'subcontent', idFindFn('D4_S15_starting'), 'value'],
      combinedVolume,
      true,
    );
    // Calculate optional shaking concentration condition
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S6'), 'subcontent', idFindFn('D4_S6_4'), 'value'],
      round(0.25 * parseFloat(loadRow.concentration), 0.01) || 0.0,
      true,
    );

    // Calculate "I" value (IPA volume)
    const iValue = round(0.7 * totalVolume, 0.01);
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_4'), 'lines', idFindFn('D4_S15'), 'subcontent', idFindFn('D4_S15_1'), 'value'],
      iValue,
      true,
    );

    // Calculate resulting volume after IPA (not in excel)
    const concludingSample = totalVolume + iValue;
    deepObjectModify(
      batch_data,
      [idFindFn('Day 4'), 'substeps', idFindFn('D4_4'), 'lines', idFindFn('D4_S15'), 'subcontent', idFindFn('D4_S15_concluding'), 'value'],
      concludingSample,
      true,
    );
    deepObjectModify(
      batch_data,
      [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S1'), 'subcontent', idFindFn('D5_S1_starting'), 'value'],
      concludingSample,
      true,
    );
  };

  const sameLine = (batchType, stepId, substepId, lineId, subcontentId) => (
    batchType === type && stepId === step_id && substepId === substep_id && lineId === line_id && (!payload.subcontent_id || subcontentId === payload.subcontent_id)
  );

  const dependencies = [
    [[MAXI_BATCH, 'SEED', 'SHAKING', 'shake'], () => duplicateData([idFindFn('SEED'), 'substeps', idFindFn('SHAKING'), 'lines', idFindFn('shake')], [idFindFn('HARVEST'), 'substeps', idFindFn('SPIN'), 'lines', idFindFn('shake')])],
    [[MAXI_BATCH, 'HARVEST', 'SPIN', 'shake'], () => duplicateData([idFindFn('HARVEST'), 'substeps', idFindFn('SPIN'), 'lines', idFindFn('shake')], [idFindFn('SEED'), 'substeps', idFindFn('SHAKING'), 'lines', idFindFn('shake')])],
    [[MAXI_BATCH, 'PURIFICATION', 'DILUTION', 'yields'], () => duplicateData([idFindFn('PURIFICATION'), 'substeps', idFindFn('DILUTION'), 'lines', idFindFn('yields')], [idFindFn('QC'), 'substeps', idFindFn('YIELD'), 'lines', idFindFn('yields')])],
    [[MAXI_BATCH, 'QC', 'YIELD', 'yields'], () => duplicateData([idFindFn('QC'), 'substeps', idFindFn('YIELD'), 'lines', idFindFn('yields')], [idFindFn('PURIFICATION'), 'substeps', idFindFn('DILUTION'), 'lines', idFindFn('yields')])],
    [[MAXI_BATCH, 'SEED', 'SEED_FLASK', 'num_flasks'], maxiUpdateTriton],
    [[GIGA_BATCH, 'Day 1', 'D1_2', 'D1_S1', 'D1_S1_2'], gigaCalcFromInitBacteria],
    [[GIGA_BATCH, 'Day 1', 'D1_2', 'D1_S1', 'D1_S1_3'], gigaCalcFromInitBacteria],
    [[GIGA_BATCH, 'Day 1', 'D1_2', 'D1_S1', 'D1_S1_1'], gigaCalcFromInitVol],
    [[GIGA_BATCH, 'Day 1', 'D1_3', 'D1_S7', 'D1_S7_starting'], calcEndotoxinTreatment],
    [[GIGA_BATCH, 'Day 2', 'D2_3', 'D2_S9', 'D2_S9_4'], gigaCalcD2YieldBeforePrec],
    [[GIGA_BATCH, 'Day 2', 'D2_3', 'D2_S9', 'D2_S9_6'], () => [gigaCalcD2YieldBeforePrec(), gigaCalcAmmoniumSulfateVol()]],
    [[GIGA_BATCH, 'Day 5', 'D5_2', 'D5_S6', 'D5_S6_2'], gigaCalcD5FinalYield],
    [[GIGA_BATCH, 'Day 5', 'D5_2', 'D5_S6', 'D5_S6_4'], gigaCalcD5FinalYield],
  ];

  if (action === ACTION_CHECKED) {
    deepObjectModify(
      batch_data,
      [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'checked'],
      payload.checked,
      true,
    );
  } else if (action === ACTION_NOTES) {
    deepObjectModify(
      batch_data,
      [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'notes', 'content'],
      payload.content,
      true,
    );
  } else if (action === ACTION_SEQUENCE_CONFIRMATION) {
    const keyList = [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data', idFindFn(payload.construct_code, 'construct_code')];
    deepObjectModify(batch_data, [...keyList, 'valid'], payload.valid, true);
    deepObjectModify(batch_data, [...keyList, 'confirmed'], payload.confirmed, true);
  } else if (action === ACTION_TABLE_UPDATE) {
    for (const [key, value] of Object.entries(payload)) {
      deepObjectModify(
        batch_data,
        [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data', idFindFn(payload.construct_code, 'construct_code'), key],
        value,
        true,
      );
    }
  } else if (action === ACTION_SHAKE_UPDATE) {
    const keyList = [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id)];
    const keyMap = { end: 'ended', start: 'started' };
    deepObjectModify(batch_data, [...keyList, payload.type, 'at'], payload.at, true);
    deepObjectModify(batch_data, [...keyList, keyMap[payload.type]], Boolean(payload.at), true);
  } else if (action === ACTION_INPUT) {
    const { subcontent_id, value } = payload;
    deepObjectModify(
      batch_data,
      [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'subcontent', idFindFn(subcontent_id), 'value'],
      value,
      true,
    );
  } else if (action === ACTION_PS) {
    // Update row value with input
    const { row_id, key, value } = payload;
    const updatedRow = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data', idFindFn(row_id, 'row_id')]);
    updatedRow[key] = value;

    // Calculate yields for updatedRow
    updatedRow.amount = round(parseFloat(updatedRow.concentration) * parseFloat(updatedRow.volume) / 1000, 0.01) || 0.0;
    const loadAmount = deepGet(batch_data, [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S2_pstable'), 'data', idFindFn(ROW_TYPE_LOAD, 'row_type'), 'amount']);
    updatedRow.yield = loadAmount ? round(updatedRow.amount / loadAmount * 100, 0.01) : 0;

    if (updatedRow.row_type === ROW_TYPE_LOAD) {
      const psLines = ['D3_S6_pstable', 'D3_S7_pstable', 'D3_S8_pstable', 'D3_S9_pstable'];
      for (const lineId of psLines) {
        const rows = deepGet(batch_data, [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn(lineId), 'data']);
        for (const row of rows) {
          row.yield = loadAmount ? round(row.amount / loadAmount * 100, 0.01) : 0;
        }
      }

      // Calculate D3 bead volume
      const bead = Math.ceil(parseFloat(loadAmount) / 3) || 0;
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S3'), 'subcontent', idFindFn('D3_S3_1'), 'value'],
        bead,
        true,
      );

      // Calculate number of D3 plasmid select columns necessary
      const columnsNecessary = Math.ceil(bead / 150);
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S3'), 'subcontent', idFindFn('D3_S3_3'), 'value'],
        columnsNecessary,
        true,
      );

      // Calculate D3 CV amount
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S3'), 'subcontent', idFindFn('D3_S3_5'), 'value'],
        columnsNecessary ? round(bead / columnsNecessary, 0.01) : 0,
        true,
      );

      // Calculate D3 pump speeds
      const fast = round(15.0 * bead / 60.0 / 0.6, 0.01);
      const slow = round(8.0 * bead / 60.0 / 0.6, 0.01);
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S3'), 'subcontent', idFindFn('D3_S3_4'), 'value'],
        fast,
        true,
      );
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S5'), 'subcontent', idFindFn('D3_S5_1'), 'value'],
        fast,
        true,
      );
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S6'), 'subcontent', idFindFn('D3_S6_1'), 'value'],
        slow,
        true,
      );
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S7'), 'subcontent', idFindFn('D3_S7_1'), 'value'],
        slow,
        true,
      );
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S8'), 'subcontent', idFindFn('D3_S8_1'), 'value'],
        fast,
        true,
      );
      deepObjectModify(
        batch_data,
        [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S9'), 'subcontent', idFindFn('D3_S9_1'), 'value'],
        fast,
        true,
      );
    }

    // Calculate D3 combined elutions
    calcD3CombElut();


  } else if (action === ACTION_Q) {
    // Update row value with input
    const { row_id, key, value } = payload;
    const updatedRow = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data', idFindFn(row_id, 'row_id')]);
    updatedRow[key] = value;

    // Calculate yields for row
    updatedRow.amount = round(parseFloat(updatedRow.concentration) * parseFloat(updatedRow.volume) / 1000, 0.01) || 0.0;
    const loadRow = deepGet(batch_data, [idFindFn('Day 4'), 'substeps', idFindFn('D4_2'), 'lines', idFindFn('D4_S1_qtable'), 'data', idFindFn(ROW_TYPE_LOAD, 'row_type')]);
    const { amount: loadAmount } = loadRow;
    updatedRow.yield = loadAmount ? round(updatedRow.amount / loadAmount * 100, 0.01) : 0;

    if (updatedRow.row_type === ROW_TYPE_LOAD) {
      const qLines = ['D4_S7_qtable', 'D4_S8_qtable', 'D4_S10_qtable', 'D4_S11_qtable', 'D4_S12_qtable', 'D4_S14_qtable'];
      for (const lineId of qLines) {
        const rows = deepGet(batch_data, [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn(lineId), 'data']);
        for (const row of rows) {
          row.yield = loadAmount ? round(row.amount / loadAmount * 100, 0.01) : 0;
        }
      }
    }

    // Calculate D4 combined elutions
    calcD4CombElut();


  } else if (action === ACTION_READ) {
    deepObjectModify(
      batch_data,
      [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'is_read'],
      payload.is_read,
      true,
    );
  } else if (action === ACTION_SELECT_MATERIAL) {
    const { row_id_key } = payload;
    for (const [key, value] of Object.entries(payload)) {
      if (!['row_id_key', row_id_key].includes(key)) {
        deepObjectModify(
          batch_data,
          [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data', idFindFn(payload[row_id_key], row_id_key), key],
          value,
          true,
        );
      }
    }
  } else if (action === ACTION_ALTER_PS_TABLE) {
    const { alteration } = payload;
    if (alteration === ADD) {
      const psTableData = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data']);
      const lastRow = psTableData.findLast((row) => row.row_type !== ROW_TYPE_COMBINE_ELUTIONS);
      const newRow = {
        row_id: lastRow.row_id + 1,
        row_type: lastRow.row_type,
        volume: '',
        concentration: '',
        ratio: '',
        amount: 0,
        yield: 0,
        suggestion: [
          ROW_TYPE_LOAD,
          ROW_TYPE_FLOWTHROUGH,
          ROW_TYPE_WASH,
          ROW_TYPE_COMBINE_ELUTIONS,
          ROW_TYPE_STRIP,
        ].includes(lastRow.row_type) ? NA : '',
        include: false,
      };
      psTableData.splice(psTableData.findIndex((row) => row === lastRow) + 1, 0, newRow);
    } else if (alteration === REMOVE) {
      const psTableData = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data']);
      const lastRowIdx = psTableData.findLastIndex((row) => row.row_type !== ROW_TYPE_COMBINE_ELUTIONS);
      if (lastRowIdx !== 0) {
        psTableData.splice(lastRowIdx, 1);
        calcD3CombElut();
      }
    }
  } else if (action === ACTION_ALTER_Q_TABLE) {
    const { alteration } = payload;
    if (alteration === ADD) {
      const qTableData = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data']);
      const lastRow = qTableData.findLast((row) => row.row_type !== ROW_TYPE_COMBINE_ELUTIONS);
      const newRow = {
        row_id: lastRow.row_id + 1,
        row_type: lastRow.row_type,
        volume: '',
        concentration: '',
        ratio: '',
        amount: 0,
        yield: 0,
        include: false,
      };
      qTableData.splice(qTableData.findIndex((row) => row === lastRow) + 1, 0, newRow);
    } else if (alteration === REMOVE) {
      const qTableData = deepGet(batch_data, [idFindFn(step_id), 'substeps', idFindFn(substep_id), 'lines', idFindFn(line_id), 'data']);
      const lastRowIdx = qTableData.findLastIndex((row) => row.row_type !== ROW_TYPE_COMBINE_ELUTIONS);
      if (lastRowIdx !== 0) {
        qTableData.splice(lastRowIdx, 1);
        calcD4CombElut();
      }
    }
  }

  // Update any dependencies
  for (const [line, func] of dependencies) {
    if (sameLine(...line)) func();
  }

  // Update status and substatuses if necessary
  const step = batch_data.find(idFindFn(step_id));
  const substep = step.substeps.find(idFindFn(substep_id));
  let stepDone = true;
  let substepDone = true;
  for (const line of substep.lines) {
    if (!isLineComplete(step.id, substep.id, line, batch_data)) {
      stepDone = false;
      substepDone = false;
      break;
    }
  }
  if (substepDone !== substep.is_completed) {
    substep.is_completed = substepDone;
  }
  for (let i = 0; i < step.substeps.length; i++) {
    if (step.substeps[i].is_completed) {
      if (i + 1 === step.substeps.length) {
        step.substatus = 'DONE';
      } else {
        step.substatus = step.substeps[i + 1].id;
      }
    }
  }

  for (const substep_ of step.substeps) {
    if (!substep_.is_completed) {
      stepDone = false;
      break;
    }
  }
  if (stepDone !== step.is_completed) {
    step.is_completed = stepDone;
  }
  for (let i = 0; i < batch_data.length; i++) {
    if (batch_data[i].is_completed) {
      if (i + 1 === batch_data.length) {
        batch.status = 'DONE';
        batch.signed_status = 'Ready to Lock';
      } else {
        batch.status = batch_data[i + 1].id;
      }
    }
  }
};

const reduceConstructsBatch = (state, action) => {
  const { batch, constructs } = action.payload;
  const newConstructs = mergeLists(state.constructs, constructs, 'construct_code');
  newConstructs.sort((c1, c2) => c1.construct_code.localeCompare(c2.construct_code));
  state.constructs = newConstructs;
  state.error = null;
  state.batch = batch;

  // In case user has made changes between the time of the update and when the
  // request responds, "remake" those changes visually for the user to see.
  const { updates } = state;
  for (const update of updates) {
    handleBatchActionUpdate(state, update);
  }
};

const reduceClearUpdates = (state) => {
  state.updates = [];
};

const reduceRestoreUpdates = (state, action) => {
  const { updates } = action.payload;
  state.updates = (updates || []).concat(state.updates);
};

const reduceBatchAction = (state, action) => {
  state.updates.push(action.payload);
  handleBatchActionUpdate(state, action.payload);
};

const reduceLoading = (state) => {
  state.status = STATUS_LOADING;
};

const reduceIdle = (state) => {
  state.status = STATUS_IDLE;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const clearUpdates = (state) => {
  reduceClearUpdates(state);
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const addSignees = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { batch_report } = action.payload;
    state.batch.report_data = batch_report;
    state.status = STATUS_IDLE;
  },
  [rejected]: reduceIdle,
});

const commentReport = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { comment } = action.payload;
    const reportSigns = [...state.reportSigns, comment];
    reportSigns.sort((s1, s2) => s1.sign.at.localeCompare(s2.sign.at));
    state.reportSigns = reportSigns;
    state.status = STATUS_IDLE;
  },
  [rejected]: reduceIdle,
});

const fetchBatch = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { batch, constructs } = action.payload;
    if (batch) {
      constructs.sort((c1, c2) => c1.construct_code.localeCompare(c2.construct_code));
      state.batch = batch;
      state.constructs = constructs;
      state.error = null;
    }
    state.status = STATUS_IDLE;
  },
  [rejected]: (state, action) => {
    const { error } = action;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

const fetchBatchReportSigns = ({ pending, fulfilled }) => ({
  [pending]: (state) => {
    state.reportSigns = [];
  },
  [fulfilled]: (state, action) => {
    const { comments, signs } = action.payload;
    const reportSigns = [...comments, ...signs];
    reportSigns.sort((s1, s2) => s1.sign.at.localeCompare(s2.sign.at));
    state.reportSigns = reportSigns;
  },
});

const fetchSignees = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { signee_list } = action.payload;
    state.reportSignees = signee_list;
  },
});

const handleBatchInput = (actionName) => ({
  [actionName]: (state, action) => {
    const { keyList, value } = action.payload;
    deepObjectModify(state.batch.batch_data, keyList, value, true);
  },
});

const handleBatchAction = (actionName) => ({
  [actionName]: reduceBatchAction,
});

const handleConstructInput = (actionName) => ({
  [actionName]: (state, action) => {
    const { constructCode, keyList, value } = action.payload;
    const construct = state.constructs.find((c) => c.construct_code === constructCode);
    deepObjectModify(construct, keyList, value, true);
  },
});

const lockReport = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { batch_report } = action.payload;
    state.batch.report_data = batch_report;
    state.status = STATUS_IDLE;
  },
  [rejected]: reduceIdle,
});

const resetBatch = (actionName) => ({
  [actionName]: (state) => {
    state.status = STATUS_INIT;
    state.error = null;
  },
});

const signReport = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { batch } = state;
    const { sign, report } = action.payload;
    deepObjectModify(batch, ['report_data'], report, true);
    const reportSigns = [...state.reportSigns, sign];
    reportSigns.sort((s1, s2) => s1.sign.at.localeCompare(s2.sign.at));
    state.reportSigns = reportSigns;

    // To prevent signing updates directly after a signature, we need to
    // update reportSignees with which report just got signed
    deepObjectModify(state, [
      'reportSignees',
      (s) => s.signee.user_id === sign.signee_id,
      'signed',
    ], sign.signed, true);

    state.status = STATUS_IDLE;
  },
  [rejected]: reduceIdle,
});

const updatedConstructsBatch = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceClearUpdates,
  [fulfilled]: reduceConstructsBatch,
  [rejected]: reduceRestoreUpdates,
});

const updateSignees = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { signs } = action.payload;
    state.reportSignees = signs.map((sign) => ({
      sign_order: sign.order,
      signed: sign.signed,
      signee: sign.sign.by,
    }));

    // NOTE: updating the next_signee_email is necessary for the sign button
    // to properly appear after updating signee list.
    const nextSignee = signs.find(({ signed }) => !signed);
    state.batch.report_data.next_signee_email = nextSignee.sign.by.email;
    state.batch.report_data.next_signee = nextSignee.sign.by.user_id;

    state.status = STATUS_IDLE;
  },
  [rejected]: reduceIdle,
});

const uploadPrimers = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    const newConstructs = mergeLists(state.constructs, constructs, 'construct_code');
    newConstructs.sort((c1, c2) => c1.construct_code.localeCompare(c2.construct_code));
    state.constructs = newConstructs;
  },
});

export const reducers = {
  clearUpdates,
};
export const extraReducers = {
  ...addSignees(extraActions.addSignees),
  ...commentReport(extraActions.commentReport),
  ...updatedConstructsBatch(extraActions.editGelImage),
  ...fetchBatch(extraActions.fetchBatch),
  ...fetchBatchReportSigns(extraActions.fetchBatchReportSigns),
  ...fetchSignees(extraActions.fetchSignees),
  ...handleBatchInput(extraActions._handleBatchInputAction),
  ...handleBatchAction(extraActions._handleBatchActionAction),
  ...handleConstructInput(extraActions._handleConstructInputAction),
  ...lockReport(extraActions.lockReport),
  ...updatedConstructsBatch(extraActions.removeGelImage),
  ...resetBatch(extraActions._resetBatchAction),
  ...signReport(extraActions.signReport),
  ...updatedConstructsBatch(extraActions.updateBatch),
  ...updateSignees(extraActions.updateSignees),
  ...updatedConstructsBatch(extraActions.uploadGelImages),
  ...uploadPrimers(primerActions.uploadPrimers),
};
