import { STATUS_INIT } from 'constants/statuses.constants';

export const getInitialState = () => ({
  batch: {},
  reportSignees: [],
  reportSigns: [],
  constructs: [],
  updates: [],
  status: STATUS_INIT,
  error: null,
});
