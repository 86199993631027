import { Button, Dialog } from '@acheloisbiosoftware/absui.core';
import { batchActions, batchSelectors } from 'store/batch';

import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class LockButton extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    lockReport: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    batchReportData: PropTypes.shape({
      signed_status: PropTypes.string.isRequired,
      locked_status: PropTypes.bool.isRequired,
    }).isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onOpen() {
    this.setState({ dialogOpen: true });
  }

  onClose() {
    this.setState({ dialogOpen: false });
  }

  async onConfirm() {
    const { batchId, lockReport } = this.props;
    await lockReport(batchId);
  }

  render() {
    const { sx, batchReportData, loading } = this.props;
    const { dialogOpen } = this.state;
    const isLockAllowed = (
      batchReportData.signed_status === 'Ready to Lock' &&
      !batchReportData.locked_status
    );
    return !isLockAllowed ? null : (
      <>
        <Button
          size='small'
          variant='contained'
          sx={sx}
          onClick={this.onOpen}
        >
          Lock Report
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={this.onClose}
          title='Lock this report?'
          onConfirm={this.onConfirm}
          loading={loading}
        >
          <Typography>Are you sure you would like to lock this report? This action cannot be undone. Once locked, no changes to the batch will be possible.</Typography>
        </Dialog>
      </>
    );
  }
}

const { selectBatchId, selectBatchLoading, selectBatchReportData } = batchSelectors;
const { lockReport } = batchActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchReportData: selectBatchReportData(state),
    loading: selectBatchLoading(state),
  }),
  { lockReport },
)(LockButton);
