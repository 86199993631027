import {
  BATCH,
  BUFFER,
  CHEMICAL,
  COL_BATCH_TYPE,
  COL_DATE_RANGE,
  COL_NUMBER,
  COL_NUMBER_MEMBER,
  COL_NUMBER_RANGE,
  COL_SELECT,
  COL_STATUS,
  COL_TEXT,
  COL_TEXT_MEMBER,
  CONSTRUCT,
  EQUIPMENT,
  MATERIAL,
  REPORT,
} from 'constants/inventory.constants';
import { BATCH_REPORT, CLONING_BATCH } from 'constants/batch.constants';
import { FMT_DATE_READABLE, FMT_DATE_TIME } from 'constants/dateFormats.constants';

import BatchPill from 'components/BatchPill';
import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import React from 'react';
import StatusPill from 'components/StatusPill';
import Typography from '@mui/material/Typography';
import { deepGet } from '@acheloisbiosoftware/absui.utils';
import { formatDate } from 'utils/date.utils';

const Centered = (props) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }} {...props} />
);

function rangifyConstructs(constructCodes) {
  const sortedCodes = [...constructCodes].sort();
  const parsedCodes = sortedCodes.map((cc) => {
    const match = cc.match(/([a-zA-Z]+)(\d+)/);
    return match ? { project: match[1], code: match[2], construct_code: cc } : null;
  }).filter((pc) => pc);
  if (!parsedCodes.length) return '';
  let rangifiedCodes = '';
  let startOfRange = null;
  let prevInRange = null;
  parsedCodes.forEach((parsedCode) => {
    if (!startOfRange) {
      rangifiedCodes += parsedCode.construct_code;
      startOfRange = parsedCode;
    } else if (!prevInRange) {
      if (startOfRange.project !== parsedCode.project) {
        rangifiedCodes += `,${parsedCode.construct_code}`;
        startOfRange = parsedCode;
      } else if (Number(startOfRange.code) + 1 !== Number(parsedCode.code)) {
        rangifiedCodes += `,${parsedCode.code}`;
        startOfRange = parsedCode;
      } else {
        rangifiedCodes += '-';
        prevInRange = parsedCode;
      }
    } else if (prevInRange.project !== parsedCode.project) {
      rangifiedCodes += `${prevInRange.code},${parsedCode.construct_code}`;
      startOfRange = parsedCode;
      prevInRange = null;
    } else if (Number(prevInRange.code) + 1 !== Number(parsedCode.code)) {
      rangifiedCodes += `${prevInRange.code},${parsedCode.code}`;
      startOfRange = parsedCode;
      prevInRange = null;
    } else {
      prevInRange = parsedCode;
    }
  });
  if (prevInRange) rangifiedCodes += prevInRange.code;
  return rangifiedCodes;
}

const CONSTRUCT_COLUMNS = [
  {
    id: 'construct_code',
    label: 'Construct Code',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 140,
    getValue: (c) => c.construct_code,
    getDisplay: (c) => c.construct_code,
  },
  {
    id: 'backbone',
    label: 'Backbone',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 110,
    getValue: (c) => deepGet(c, ['construct_data', 'backbone', 'vector']),
    getDisplay: (c) => deepGet(c, ['construct_data', 'backbone', 'vector'], '–')
    ,
  },
  {
    id: 'type',
    label: 'Display',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 110,
    getValue: (c) => deepGet(c, ['construct_data', 'backbone', 'type']),
    getDisplay: (c) => deepGet(c, ['construct_data', 'backbone', 'type'], '–')
    ,
  },
  {
    id: 'tag',
    label: 'Tag',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 120,
    getValue: (c) => deepGet(c, ['construct_data', 'backbone', 'tag']),
    getDisplay: (c) => deepGet(c, ['construct_data', 'backbone', 'tag'], '–')
    ,
  },
  {
    id: 'insert',
    label: 'Insert',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 100,
    getValue: (c) => c.insert,
    getDisplay: (c) => c.insert || '–',
  },
  {
    id: 'length',
    label: 'Length',
    sortable: true,
    filter: { type: COL_NUMBER_RANGE, default: ['', ''], units: 'bp' },
    width: 100,
    getValue: (c) => c.length,
    getDisplay: (c) => `${c.length} bp`,
  },
  {
    id: 'batch',
    label: 'Batch',
    sortable: false,
    filter: { type: COL_NUMBER_MEMBER, default: '' },
    width: 120,
    getValue: (c) => c.batches.map((b) => b.batch_id),
    getDisplay: (c) => (<BatchPill group max={3} sx={{ width: 210 }} batches={c.batches} />),
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    filter: { type: COL_STATUS, default: '' },
    width: 150,
    getValue: (c) => c.status,
    getDisplay: (c) => (
      <Centered><StatusPill status={c.status} type={CLONING_BATCH} /></Centered>
    ),
  },
  {
    id: 'date',
    label: 'Date Created',
    sortable: true,
    filter: { type: COL_DATE_RANGE, default: { startDate: null, endDate: null }},
    width: 125,
    getValue: (c) => new Date(c.created_at).getTime(),
    getDisplay: (c) => formatDate(c.created_at, FMT_DATE_READABLE),
  },
  {
    id: 'project',
    label: 'Project',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 105,
    getValue: (c) => deepGet(c, ['project', 'project_name']),
    getDisplay: (c) => deepGet(c, ['project', 'project_name'], '–'),
  },
  {
    id: 'author',
    label: 'Designed By',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 120,
    getValue: (c) => deepGet(c, ['author', 'name']),
    getDisplay: (c) => deepGet(c, ['author', 'name'], '–'),
  },
];

const BATCH_COLUMNS = [
  {
    id: 'batch_id',
    label: 'Batch ID',
    sortable: true,
    filter: { type: COL_NUMBER, default: '' },
    width: 125,
    getValue: (b) => b.batch_id,
    getDisplay: (b) => (
      <Centered><BatchPill noIcon batches={b} /></Centered>
    ),
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (b) => b.type,
    getDisplay: (b) => (<BatchType type={b.type} />),
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    filter: { type: COL_STATUS, default: '' },
    width: 180,
    getValue: (b) => b.status,
    getDisplay: (b) => (
      <Centered><StatusPill status={b.status} type={b.type} /></Centered>
    ),
  },
  {
    id: 'constructs',
    label: 'Constructs',
    sortable: false,
    filter: { type: COL_TEXT_MEMBER, default: '' },
    width: 100,
    getValue: (b) => b.construct_codes,
    getDisplay: (b) => rangifyConstructs(b.construct_codes),
  },
  {
    id: 'created',
    label: 'Date Created',
    sortable: true,
    filter: { type: COL_DATE_RANGE, default: { startDate: null, endDate: null }},
    width: 125,
    getValue: (b) => new Date(b.created_at).getTime(),
    getDisplay: (b) => formatDate(b.created_at, FMT_DATE_READABLE),
  },
  {
    id: 'modified',
    label: 'Last Modified',
    sortable: true,
    filter: { type: COL_DATE_RANGE, default: { startDate: null, endDate: null }},
    width: 125,
    getValue: (b) => new Date(b.modified_at).getTime(),
    getDisplay: (b) => formatDate(b.modified_at, FMT_DATE_READABLE),
  },
  {
    id: 'modifier',
    label: 'Last Modified By',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 145,
    getValue: (b) => deepGet(b, ['modifier', 'name']),
    getDisplay: (b) => deepGet(b, ['modifier', 'name']) || '–',
  },
];

const REPORT_COLUMNS = [
  {
    id: 'batch_id',
    label: 'Batch ID',
    sortable: true,
    filter: { type: COL_NUMBER, default: '' },
    width: 125,
    getValue: (r) => r.batch_id,
    getDisplay: (r) => (
      <Centered><BatchPill noIcon batches={r} /></Centered>
    ),
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (r) => r.type,
    getDisplay: (r) => (<BatchType type={r.type} />),
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    filter: { type: COL_STATUS, default: '' },
    width: 196,
    getValue: (r) => r.signed_status,
    getDisplay: (r) => (
      <Centered>
        <StatusPill status={r.signed_status} type={BATCH_REPORT} />
      </Centered>
    ),
  },
  {
    id: 'last_signed_by',
    label: 'Last Signed By',
    sortable: true,
    filter: { type: COL_SELECT, default: '' },
    width: 135,
    getValue: (r) => deepGet(r, ['last_signed', 'by', 'name']),
    getDisplay: (r) => deepGet(r, ['last_signed', 'by', 'name'], '–'),
  },
  {
    id: 'last_signed_at',
    label: 'Signed Date',
    sortable: true,
    filter: { type: COL_DATE_RANGE, default: { startDate: null, endDate: null }},
    width: 125,
    getValue: (r) => new Date(deepGet(r, ['last_signed', 'at'])).getTime(),
    getDisplay: (r) => formatDate(deepGet(r, ['last_signed', 'at']), FMT_DATE_TIME),
  },
  {
    id: 'total_sign',
    label: 'Total Sign',
    sortable: true,
    filter: { type: COL_NUMBER, default: '' },
    width: 125,
    getValue: (r) => r.signed_count || 0,
    getDisplay: (r) => `${r.signed_count || 0} of ${r.signee_count || 0}`,
  },
];

const EQUIPMENT_COLUMNS = [
  {
    id: 'serial_no',
    label: 'Serial No.',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (e) => e.serial_no,
    getDisplay: (e) => e.serial_no,
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (e) => e.name,
    getDisplay: (e) => e.name,
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (e) => e.batch_type,
    getDisplay: (e) => (<BatchType type={e.batch_type} />),
  },
  {
    id: 'vendor',
    label: 'Vendor',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (e) => e.vendor,
    getDisplay: (e) => e.vendor,
  },
  {
    id: 'model',
    label: 'Model',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (e) => e.model,
    getDisplay: (e) => e.model,
  },
  {
    id: 'location',
    label: 'Location',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (e) => e.location,
    getDisplay: (e) => e.location,
  },
  {
    id: 'custom_props',
    label: 'Additional Properties',
    sortable: false,
    filter: { type: COL_TEXT, default: '' },
    width: 250,
    getValue: (e) => e.custom_props.map((prop) => `${prop.title}: ${prop.value}`).join(' , '),
    getDisplay: (e) => (
      <>
        {
          e.custom_props.map((prop) => (
            <Typography key={prop.title} variant='body2'>
              {prop.title}: {prop.value}
            </Typography>
          ))
        }
      </>
    ),
  },
];

const MATERIAL_COLUMNS = [
  {
    id: 'catalog_no',
    label: 'Catalog No.',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.catalog_no,
    getDisplay: (m) => m.catalog_no,
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.name,
    getDisplay: (m) => m.name,
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (m) => m.batch_type,
    getDisplay: (m) => (<BatchType type={m.batch_type} />),
  },
  {
    id: 'vendor',
    label: 'Vendor',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.vendor,
    getDisplay: (m) => m.vendor,
  },
  {
    id: 'custom_props',
    label: 'Additional Properties',
    sortable: false,
    filter: { type: COL_TEXT, default: '' },
    width: 250,
    getValue: (m) => m.custom_props.map((prop) => `${prop.title}: ${prop.value}`).join(' , '),
    getDisplay: (m) => (
      <>
        {
          m.custom_props.map((prop) => (
            <Typography key={prop.title} variant='body2'>
              {prop.title}: {prop.value}
            </Typography>
          ))
        }
      </>
    ),
  },
];

const CHEMICAL_COLUMNS = [
  {
    id: 'catalog_no',
    label: 'Catalog No.',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (c) => c.catalog_no,
    getDisplay: (c) => c.catalog_no,
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (c) => c.name,
    getDisplay: (c) => c.name,
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (c) => c.batch_type,
    getDisplay: (c) => (<BatchType type={c.batch_type} />),
  },
  {
    id: 'vendor',
    label: 'Vendor',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.vendor,
    getDisplay: (m) => m.vendor,
  },
  {
    id: 'formula',
    label: 'Formula',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.formula,
    getDisplay: (m) => m.formula,
  },
  {
    id: 'molecular_weight',
    label: 'Molecular Weight',
    sortable: true,
    filter: { type: COL_NUMBER_RANGE, default: ['', ''], units: 'Da' },
    width: 150,
    getValue: (m) => m.molecular_weight,
    getDisplay: (m) => `${m.molecular_weight} Da`,
  },
  {
    id: 'storage_cond',
    label: 'Storage Condition',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 155,
    getValue: (m) => m.storage_cond,
    getDisplay: (m) => m.storage_cond,
  },
  {
    id: 'appearance',
    label: 'Appearance',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (m) => m.appearance,
    getDisplay: (m) => m.appearance,
  },
  {
    id: 'custom_props',
    label: 'Additional Properties',
    sortable: false,
    filter: { type: COL_TEXT, default: '' },
    width: 250,
    getValue: (m) => m.custom_props.map((prop) => `${prop.title}: ${prop.value}`).join(' , '),
    getDisplay: (m) => (
      <>
        {
          m.custom_props.map((prop) => (
            <Typography key={prop.title} variant='body2'>
              {prop.title}: {prop.value}
            </Typography>
          ))
        }
      </>
    ),
  },
];

const BUFFER_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (b) => b.name,
    getDisplay: (b) => b.name,
  },
  {
    id: 'type',
    label: 'Batch Type',
    sortable: true,
    filter: { type: COL_BATCH_TYPE, default: '' },
    width: 140,
    getValue: (b) => b.batch_type,
    getDisplay: (b) => (<BatchType type={b.batch_type} />),
  },
  {
    id: 'formula',
    label: 'Formula',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (b) => b.formula,
    getDisplay: (b) => b.formula,
  },
  {
    id: 'molecular_weight',
    label: 'Molecular Weight',
    sortable: true,
    filter: { type: COL_NUMBER_RANGE, default: ['', ''], units: 'Da' },
    width: 150,
    getValue: (b) => b.molecular_weight,
    getDisplay: (b) => `${b.molecular_weight} Da`,
  },
  {
    id: 'storage_cond',
    label: 'Storage Condition',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 155,
    getValue: (b) => b.storage_cond,
    getDisplay: (b) => b.storage_cond,
  },
  {
    id: 'appearance',
    label: 'Appearance',
    sortable: true,
    filter: { type: COL_TEXT, default: '' },
    width: 125,
    getValue: (b) => b.appearance,
    getDisplay: (b) => b.appearance,
  },
  {
    id: 'preparation',
    label: 'Preparation',
    sortable: false,
    filter: { type: COL_TEXT, default: '' },
    width: 150,
    getValue: (b) => b.preparation,
    getDisplay: (b) => (
      <>
        {b.preparation.split('\n').map((step, idx) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Box key={`prep${idx}`}>{step}</Box>
        ))}
      </>
    ),
  },
  {
    id: 'custom_props',
    label: 'Additional Properties',
    sortable: false,
    filter: { type: COL_TEXT, default: '' },
    width: 250,
    getValue: (b) => b.custom_props.map((prop) => `${prop.title}: ${prop.value}`).join(' , '),
    getDisplay: (b) => (
      <>
        {
          b.custom_props.map((prop) => (
            <Typography key={prop.title} variant='body2'>
              {prop.title}: {prop.value}
            </Typography>
          ))
        }
      </>
    ),
  },
];

export const columns = {
  [CONSTRUCT]: CONSTRUCT_COLUMNS,
  [BATCH]: BATCH_COLUMNS,
  [REPORT]: REPORT_COLUMNS,
  [EQUIPMENT]: EQUIPMENT_COLUMNS,
  [MATERIAL]: MATERIAL_COLUMNS,
  [CHEMICAL]: CHEMICAL_COLUMNS,
  [BUFFER]: BUFFER_COLUMNS,
};
