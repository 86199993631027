import { BATCH_REPORT, CLONING_BATCH, GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import { blue, green, grey, pink, purple } from '@mui/material/colors';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import StatusMenu from './StatusMenu';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const Pill = (props) => (
  <Box
    {...props}
    sx={mergeSx({
      height: 24,
      width: 120,
      py: 0.5,
      px: 1,
      display: 'flex',
      borderRadius: 8,
    }, props?.sx)}
  />
);
Pill.propTypes = { sx: sxPropType };

const PillText = (props) => (
  <Typography
    {...props}
    sx={mergeSx({
      m: 'auto',
      fontWeight: 'fontWeightBold',
      fontSize: 10,
    }, props?.sx)}
    variant='button'
  />
);
PillText.propTypes = { sx: sxPropType };

const colorSchemes = {
  blackOnWhite: {
    bgcolor: grey[50],
    color: grey[800],
    border: 1,
  },
  lightPink: {
    bgcolor: pink[50],
    color: pink.A200,
    border: 'none',
  },
  darkPink: {
    bgcolor: pink.A200,
    color: pink[50],
    border: 'none',
  },
  lightBlue: {
    bgcolor: blue[50],
    color: blue[800],
    border: 'none',
  },
  darkBlue: {
    bgcolor: blue[800],
    color: blue[50],
    border: 'none',
  },
  darkPurple: {
    bgcolor: purple[800],
    color: purple[50],
    border: 'none',
  },
  lightPurple: {
    bgcolor: purple[50],
    color: purple[800],
    border: 'none',
  },
  lightGreen: {
    bgcolor: green[50],
    color: green[800],
    border: 'none',
  },
  darkGreen: {
    bgcolor: green[800],
    color: green[50],
    border: 'none',
  },
};

const cloningStyles = {
  DESIGN: colorSchemes.blackOnWhite,
  PRIMER_ORDER: colorSchemes.lightPink,
  CLONING: colorSchemes.darkPink,
  SEQUENCING: colorSchemes.lightBlue,
  SEQUENCE_ANALYSIS: colorSchemes.darkBlue,
  MAXI_PREP: colorSchemes.lightGreen,
  DONE: colorSchemes.darkGreen,
};

const maxiStyles = {
  SEED: colorSchemes.lightPurple,
  HARVEST: colorSchemes.darkPurple,
  EXTRACTION: colorSchemes.lightBlue,
  PURIFICATION: colorSchemes.darkBlue,
  STORAGE: colorSchemes.darkPink,
  QC: colorSchemes.lightGreen,
  DONE: colorSchemes.darkGreen,
};

const gigaStyles = {
  'OVERVIEW': colorSchemes.blackOnWhite,
  'Day 0': colorSchemes.lightPink,
  'Day 1': colorSchemes.darkPurple,
  'Day 2': colorSchemes.lightPurple,
  'Day 3': colorSchemes.darkBlue,
  'Day 4': colorSchemes.lightBlue,
  'Day 5': colorSchemes.darkPink,
  'DONE': colorSchemes.darkGreen,
};

const reportStyles = {
  'Open': colorSchemes.blackOnWhite,
  'Ready to Lock': colorSchemes.lightPink,
  'Locked': colorSchemes.darkPink,
  'Sent for signatures': colorSchemes.lightBlue,
  'Signing in Progress': colorSchemes.darkBlue,
  'Signatures Completed': colorSchemes.darkGreen,
};

const statusColors = {
  [CLONING_BATCH]: cloningStyles,
  [MAXI_BATCH]: maxiStyles,
  [GIGA_BATCH]: gigaStyles,
  [BATCH_REPORT]: reportStyles,
};

function getStatusColor(status, type) {
  const styles = statusColors[type];
  if (styles) {
    return styles[status] || colorSchemes.blackOnWhite;
  }
  return colorSchemes.blackOnWhite;
}

function StatusPill(props) {
  const { status, onSelect, sx, type } = props;
  const statusColor = getStatusColor(status, type);
  const statusLabel = status.replace('_', ' ');
  const sxPill = mergeSx(
    statusColor,
    type === BATCH_REPORT ? { width: 150 } : {},
    sx,
  );

  if (!onSelect) {
    return (
      <Pill sx={sxPill}>
        <PillText>{statusLabel}</PillText>
      </Pill>
    );
  }

  return (
    <PopupState variant='popover'>
      {(popupState) => (
        <>
          <Pill
            sx={mergeSx({ cursor: 'pointer' }, sxPill)}
            {...bindTrigger(popupState)}
          >
            <PillText>{statusLabel}</PillText>
          </Pill>
          <StatusMenu
            popupState={popupState}
            onSelect={onSelect}
            type={type}
          />
        </>
      )}
    </PopupState>
  );
}

StatusPill.propTypes = {
  /**
   * The status to be displayed. The value must be one of the statuses from
   * the the corresponding types (from props.type).
   * For 'CLONING': [DESIGN, PRIMER_ORDER, CLONING, SEQUENCING, SEQUENCE_ANALYSIS, MAXI_PREP, DONE]
   * For 'MAXI': [SEED, HARVEST, EXTRACTION, PURIFICATION, STORAGE, QC, DONE]
   * For 'GIGA': [OVERVIEW, Day 0, Day 1, Day 2, Day 3, Day 4, Day 5]
   * For 'REPORT': [Open, Ready to Lock, Locked, Sent for signatures, Signing in Progress, Signatures Completed]
   */
  status: (props, propName, componentName) => {
    if (typeof(props[propName]) !== 'string') {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected type string, given type ${typeof(props[propName])}.`);
    }
    const options = Object.keys(statusColors[props.type] || {});
    if (!options.includes(props[propName])) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected one of [${options.join(', ')}], given '${props[propName]}'.`);
    }
    return null;
  },

  /**
   * The type of status to be displayed. Currently support 'CLONING', 'MAXI',
   * 'GIGA', and 'REPORT' statuses.
   */
  type: PropTypes.oneOf([CLONING_BATCH, MAXI_BATCH, GIGA_BATCH, BATCH_REPORT]).isRequired,

  /**
   * If provided, the status pill will be clickable, and all options for the
   * provided type (from props.type) will be displayed as menu options. The
   * callback consumes the string status that was selected.
   */
  onSelect: PropTypes.func,

  // sx to be applied to the Pill container.
  sx: sxPropType,
};

export default StatusPill;
