import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';

class CircleText extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    thetaRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    fontSize: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontWeight: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    color: PropTypes.string,
    alignmentBaseline: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    color: 'black',
    alignmentBaseline: 'central',
  };

  render() {
    const {
      id,
      cx,
      cy,
      r,
      children,
      thetaRange,
      fontSize,
      fontFamily,
      fontWeight,
      color,
      alignmentBaseline,
      className,
    } = this.props;

    const textWidth = TextMeasurer.getWidth(`${children}`, fontSize, fontFamily) / r;
    const thetaStart = thetaRange[0] - (Math.PI / 2) || -Math.PI / 2;
    const thetaEnd = thetaRange[1] - (Math.PI / 2) || thetaRange[0] + textWidth - (Math.PI / 2);

    const thetaStartPad = ((thetaEnd + thetaStart) / 2) - (textWidth / 2);
    const thetaEndPad = ((thetaEnd + thetaStart) / 2) + (textWidth / 2);

    const path = (Math.sin(thetaStart) + Math.sin(thetaEnd)) / 2 < 0 ? `
      M ${cx + (r * Math.cos(thetaStartPad))}, ${cy + (r * Math.sin(thetaStartPad))}
      A ${r},${r} 0 0,1 ${cx + (r * Math.cos(thetaEnd))},${cy + (r * Math.sin(thetaEnd))}
      ` : `
      M ${cx + (r * Math.cos(thetaEndPad))}, ${cy + (r * Math.sin(thetaEndPad))}
      A ${r},${r} 0 0,0 ${cx + (r * Math.cos(thetaStart))},${cy + (r * Math.sin(thetaStart))}
      `;
    return (
      <g>
        <path
          id={id}
          d={path}
          fill='none'
          stroke='none'
        />
        <text
          fontSize={fontSize}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          style={disableTextSelect}
          fill={color}
          className={className}
        >
          <textPath
            href={`#${id}`}
            alignmentBaseline={alignmentBaseline}
          >
            {children}
          </textPath>
        </text>
      </g>
    );
  }
}

export default CircleText;
