import DataTableCell from './DataTableCell';
import React from 'react';

function DataTableRow(props) {
  const { row, rowIdx, columns } = props;
  return columns.map((col, colIdx) => (
    <DataTableCell
      key={`cell_${row.key},${col.key}`}
      row={row}
      rowIdx={rowIdx}
      col={col}
      colIdx={colIdx}
      {...props}
    />
  ));
}

DataTableRow.propTypes = {};

export default DataTableRow;
