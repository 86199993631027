import { INVENTORY } from 'constants/store.constants';
import { createSelector } from '@reduxjs/toolkit';

const selectInventorySlice = (state) => state[INVENTORY];
export const selectBatches = (state) => selectInventorySlice(state).batches;
export const selectConstructs = (state) => selectInventorySlice(state).constructs;

export const selectReports = createSelector(
  selectBatches,
  (batches) => batches.filter((b) => b.report_data).map((b) => ({ ...b.report_data, type: b.type })),
);
