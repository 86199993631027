import 'components/SeqView/SeqView.styles.css';

import * as d3 from 'd3';

import AnnotationStem from './AnnotationStem';
import AnnotationText from './AnnotationText';
import { FONT } from 'constants/styles.constants';
import PropTypes from 'prop-types';
import React from 'react';
import { assignSpaceCircular } from 'components/SeqView/SeqView.utils';

const DEFAULT_PROPS = {
  enzymes: [],
  plasmidLength: 1,
  cx: 0,
  cy: 0,
  r_out: 1,
  blockHeight: 12,
  blockSpacing: 6,
  fontFamily: FONT,
  fontSize: 10,
  textPadding: 4,
  onClick: null, // Takes in enzyme name and location of enzyme clicked
  onDoubleClick: null, // Takes in enzyme name and location of enzyme clicked
};

function parseEnzymes(props) {
  const {
    enzymes,
    plasmidLength,
    r_out,
    blockHeight,
    blockSpacing,
    fontFamily,
    fontSize,
    textPadding,
  } = { ...DEFAULT_PROPS, ...props };

  let parsedEnzymes = enzymes.map(({ enzyme_name, location }) => ({
    enzymeName: enzyme_name,
    annotationId: `enzymeAnnotation_${enzyme_name.replace(' ', '_')}_${location}`,
    bpLocation: location,
    thetaLoc: location / plasmidLength * 2 * Math.PI,
    r_text: r_out - (blockHeight / 2),
    textRow: null,
  }));

  parsedEnzymes = assignSpaceCircular(parsedEnzymes, blockHeight + blockSpacing, {
    textKey: 'enzymeName',
    textMiddleKey: 'thetaLoc',
    r_textKey: 'r_text',
    textRowKey: 'textRow',
    fontSize,
    fontFamily,
    textPadding,
  });

  return parsedEnzymes;
}

function _getEnzymeWidth(parsedEnzymes, blockHeight, blockSpacing) {
  if (!parsedEnzymes.length) return 0;
  const maxRow = Math.max(...parsedEnzymes.map((enzyme) => enzyme.textRow)) + 1;
  return maxRow * (blockHeight + blockSpacing);
}

function getEnzymesWidth(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedEnzymes = parseEnzymes(completeProps);
  const { blockHeight, blockSpacing } = completeProps;
  return _getEnzymeWidth(parsedEnzymes, blockHeight, blockSpacing);
}

class Enzymes extends React.Component {
  static propTypes = {
    // Because of how props are passed around and parsed, eslint cannot detect some are in use
    /* eslint-disable-next-line react/no-unused-prop-types */
    enzymes: PropTypes.arrayOf(PropTypes.shape({
      enzyme_name: PropTypes.string.isRequired,
      location: PropTypes.number.isRequired,
    })),
    /* eslint-disable-next-line react/no-unused-prop-types */
    plasmidLength: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r_out: PropTypes.number.isRequired,
    blockHeight: PropTypes.number,
    blockSpacing: PropTypes.number,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    textPadding: PropTypes.number,
    onClick: PropTypes.func, // Takes in enzyme name and location of enzyme clicked
    onDoubleClick: PropTypes.func, // Takes in enzyme name and location of enzyme clicked
  };

  static defaultProps = DEFAULT_PROPS;

  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(enzyme, trigger) {
    const { annotationId } = enzyme;
    const isHovering = trigger === 'enter';
    d3.selectAll(`.${annotationId}_stem`).classed('hoverStem', isHovering);
    d3.selectAll(`.${annotationId}_text`).classed('hoverText', isHovering);
    d3.selectAll(`.${annotationId}_textContainer`).classed('hoverTextContainer', isHovering);
  }

  render() {
    const {
      cx,
      cy,
      r_out,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      onClick,
      onDoubleClick,
    } = this.props;
    const parsedEnzymes = parseEnzymes(this.props);
    const width = _getEnzymeWidth(parsedEnzymes, blockHeight, blockSpacing);
    const r_in = r_out - width;
    return (
      <g>
        {
          parsedEnzymes.map((enzyme) => (
            <AnnotationStem
              key={`${enzyme.annotationId}_stemKey`}
              enzyme={enzyme}
              cx={cx}
              cy={cy}
              r_in={r_in}
            />
          ))
        }
        {
          parsedEnzymes.map((enzyme) => (
            <AnnotationText
              key={`${enzyme.annotationId}_textKey`}
              enzyme={enzyme}
              cx={cx}
              cy={cy}
              blockHeight={blockHeight}
              fontSize={fontSize}
              fontFamily={fontFamily}
              textPadding={textPadding}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
      </g>
    );
  }
}

export { getEnzymesWidth };
export default Enzymes;
