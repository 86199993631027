import { ASC, CONSTRUCT } from 'constants/inventory.constants';
import { batchStepperActions, batchStepperSelectors } from 'store/batchStepper';

import BatchInfo from '../BatchInfo';
import BatchStep from './BatchStep';
import Box from '@mui/material/Box';
import InventoryTable from 'components/InventoryTable';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@mui/material/Slide';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const StepperPaper = (props) => (
  <Paper variant='outlined' {...props} sx={mergeSx(props?.sx, { p: 3, mb: 3, minWidth: 800 })} />
);
StepperPaper.propTypes = { sx: sxPropType };

class MaxiGigaBatch extends React.Component {
  static propTypes = {
    updateStep: PropTypes.func.isRequired,
    batchStepMeta: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      is_completed: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
    constructs: PropTypes.array.isRequired,
    activeStep: PropTypes.number.isRequired,
    slideDirection: PropTypes.oneOf(['left', 'right']),
  };

  render() {
    const { batchStepMeta, constructs, activeStep, slideDirection, updateStep } = this.props;
    return (
      <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, maxWidth: 1200 }}>
        <StepperPaper>
          <BatchInfo />
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {
              batchStepMeta.map((meta, idx) => (
                <Step key={meta.id} completed={meta.is_completed}>
                  <StepButton onClick={() => updateStep(idx)}>
                    <Typography sx={idx === activeStep ? { fontWeight: 'fontWeightBold' } : null}>
                      {meta.title}
                    </Typography>
                  </StepButton>
                </Step>
              ))
            }
          </Stepper>
        </StepperPaper>
        <StepperPaper sx={{ overflow: 'hidden' }}>
          {
            batchStepMeta.map((meta, idx) => (
              activeStep === idx ? (
                <Slide key={`step_${meta.id}`} direction={slideDirection} in>
                  <Box><BatchStep step={meta.id} /></Box>
                </Slide>
              ) : null
            ))
          }
        </StepperPaper>
        <Paper
          sx={{
            p: 3,
            maxHeight: (theme) => `calc(100vh - 64px - ${theme.spacing(3) * 2}px)`,
            display: 'flex',
            flexFlow: 'column',
          }}
          variant='outlined'
        >
          <InventoryTable
            tableType={CONSTRUCT}
            orderBy='construct_code'
            order={ASC}
            dropCols={['Batch', 'Status']}
            entities={constructs}
          />
        </Paper>
      </Box>
    );
  }
}

const { selectActiveStep, selectSlideDirection } = batchStepperSelectors;
const { updateStep } = batchStepperActions;
const { selectBatchStepMeta, selectConstructs } = batchSelectors;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
    batchStepMeta: selectBatchStepMeta(state),
    activeStep: selectActiveStep(state),
    slideDirection: selectSlideDirection(state),
  }),
  { updateStep },
)(MaxiGigaBatch);
