import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import React from 'react';
import Typography from '@mui/material/Typography';

const STEPS = [
  'Add 1:1 volume of Binding Buffer to gel slices',
  'Incubate at 50-60ºC for 10 min, vortexing intermittently until gel is melted',
  'Transfer up to 800μL to GeneJET column',
  'Centrifuge 1 min @13K rpm',
  'Discard flow through',
  'Add 700μL wash buffer to column',
  'Centrifuge 1 min @13K rpm',
  'Discard flow through',
  'Centrifuge empty column 1 min @13K rpm to dry column',
  'Transfer column to clean eppendorf tube',
  'Add 30μL elution buffer to column',
  'Incubate 1 min then centrifuge 1 min @13K rpm',
  'Nanodrop and record concentration',
];

class GelExtractionProtocol extends React.Component {
  render() {
    return (
      <Box>
        <Typography
          sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}
        >
          Gel Extraction Protocol
        </Typography>
        <Checklist
          dense
          items={STEPS.map((step) => ({ content: step }))}
        />
      </Box>
    );
  }
}

export default GelExtractionProtocol;
