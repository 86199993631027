import 'components/SeqView/SeqView.styles.css';

import * as d3 from 'd3';

import { assignSpaceCircular, getFeatureColor } from 'components/SeqView/SeqView.utils';

import AnnotationBlock from './AnnotationBlock';
import AnnotationStem from './AnnotationStem';
import AnnotationText from './AnnotationText';
import { FONT } from 'constants/styles.constants';
import PropTypes from 'prop-types';
import React from 'react';
import { featureTypes } from 'components/SeqView/SeqView.constants';
import { getLocationRange } from 'utils/sequence.utils';

const DEFAULT_PROPS = {
  features: [],
  plasmidLength: 1,
  cx: 0,
  cy: 0,
  r_out: 1,
  blockHeight: 16,
  blockSpacing: 6,
  fontFamily: FONT,
  fontSize: 12,
  textPadding: 4,
  onClick: null, // Takes in location_id of feature clicked
  onDoubleClick: null, // Takes in location_id of feature clicked
};

function parseFeatures(props) {
  const {
    features,
    plasmidLength,
    r_out,
    blockHeight,
    blockSpacing,
    fontFamily,
    fontSize,
    textPadding,
  } = { ...DEFAULT_PROPS, ...props };

  let parsedFeatures = features.map((featLoc) => {
    const { feature, location_data, location_id } = featLoc;
    const [bpStart, bpEnd] = getLocationRange(location_data);
    const thetaStart = (bpStart / plasmidLength) * 2 * Math.PI;
    const thetaEnd = ((bpEnd / plasmidLength) + (bpStart > bpEnd ? 1 : 0)) * 2 * Math.PI;
    const r_start = r_out - blockSpacing - (blockHeight / 2);
    return {
      location_id,
      annotationId: `featureAnnotation_${location_id}`,
      feature_name: feature.feature_name,
      type: feature.type,
      strand: location_data.strand,
      color: getFeatureColor(featLoc.feature_id, feature.type),
      thetaStart,
      thetaEnd,
      blockRow: null,
      textRow: null,
      r_block: r_start,
      r_text: r_start,
    };
  });

  parsedFeatures = assignSpaceCircular(parsedFeatures, blockHeight + blockSpacing, {
    blockStartKey: 'thetaStart',
    blockEndKey: 'thetaEnd',
    textKey: 'feature_name',
    r_blockKey: 'r_block',
    r_textKey: 'r_text',
    blockRowKey: 'blockRow',
    textRowKey: 'textRow',
    fontSize,
    fontFamily,
    textPadding,
  });

  return parsedFeatures;
}

function _getFeaturesWidth(parsedFeatures, blockHeight, blockSpacing) {
  if (!parsedFeatures.length) return 0;
  const maxRow = Math.max(...parsedFeatures.map((feat) => Math.max(feat.blockRow, feat.textRow))) + 1;
  return maxRow * (blockHeight + blockSpacing);
}

function getFeaturesWidth(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedFeatures = parseFeatures(completeProps);
  const { blockHeight, blockSpacing } = completeProps;
  return _getFeaturesWidth(parsedFeatures, blockHeight, blockSpacing);
}

class Features extends React.Component {
  static propTypes = {
    /* eslint-disable-next-line react/no-unused-prop-types */
    features: PropTypes.arrayOf(PropTypes.shape({
      feature_id: PropTypes.number.isRequired,
      feature: PropTypes.shape({
        feature_name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(featureTypes).isRequired,
      }).isRequired,
      location_data: PropTypes.shape({
        type: PropTypes.oneOf(['singular', 'compound']),
        strand: PropTypes.oneOf([1, -1]).isRequired,
        location: PropTypes.oneOfType([
          PropTypes.shape({
            start: PropTypes.number.isRequired,
            end: PropTypes.number.isRequired,
          }),
          PropTypes.arrayOf(PropTypes.shape({
            start: PropTypes.number.isRequired,
            end: PropTypes.number.isRequired,
          })),
        ]).isRequired,
      }).isRequired,
      location_id: PropTypes.number.isRequired,
    })).isRequired,
    /* eslint-disable-next-line react/no-unused-prop-types */
    plasmidLength: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r_out: PropTypes.number.isRequired,
    blockHeight: PropTypes.number,
    blockSpacing: PropTypes.number,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    textPadding: PropTypes.number,
    onClick: PropTypes.func, // Takes in location_id of feature clicked
    onDoubleClick: PropTypes.func, // Takes in location_id of feature clicked
  };

  static defaultProps = DEFAULT_PROPS;

  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(feature, trigger) {
    const { annotationId } = feature;
    const isHovering = trigger === 'enter';
    d3.selectAll(`.${annotationId}_stem`).classed('hoverStem', isHovering);
    d3.selectAll(`.${annotationId}_block`).classed('hoverBlock', isHovering);
    d3.selectAll(`.${annotationId}_text`).classed('hoverText', isHovering);
    d3.selectAll(`.${annotationId}_textContainer`).classed('hoverTextContainer', isHovering);
  }

  render() {
    const {
      cx,
      cy,
      r_out,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      onClick,
      onDoubleClick,
    } = this.props;
    if (r_out < blockHeight + (2 * blockSpacing)) return null;
    const parsedFeatures = parseFeatures(this.props);
    return (
      <g>
        {
          parsedFeatures.map((feature) => (
            <AnnotationStem
              key={`${feature.annotationId}_stemKey`}
              feature={feature}
              cx={cx} cy={cy}
            />
          ))
        }
        {
          parsedFeatures.map((feature) => (
            <AnnotationBlock
              key={`${feature.annotationId}_blockKey`}
              feature={feature}
              cx={cx} cy={cy}
              blockHeight={blockHeight}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
        {
          parsedFeatures.map((feature) => (
            <AnnotationText
              key={`${feature.annotationId}_textKey`}
              feature={feature}
              cx={cx} cy={cy}
              fontFamily={fontFamily}
              fontSize={fontSize}
              textPadding={textPadding}
              blockHeight={blockHeight}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
      </g>
    );
  }
}

export { getFeaturesWidth };
export default Features;
