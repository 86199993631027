import { batchActions, batchSelectors } from 'store/batch';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import TextEditor from 'components/TextEditor';
import Typography from '@mui/material/Typography';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class NotesRTE extends React.Component {
  static propTypes = {
    notesContent: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleBatchInput: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content) {
    const { step, handleBatchInput } = this.props;
    handleBatchInput([step, 'notes'], content);
  }

  render() {
    const { sx, height, width, handleSave, notesContent } = this.props;
    return (
      <Box sx={sx}>
        <Typography
          variant='h5'
          sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5, textAlign: 'center' }}
        >
          Notes
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ m: 'auto' }}>
            <TextEditor
              height={height}
              width={width}
              handleSave={handleSave}
              handleChange={this.handleChange}
              content={notesContent}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

const { handleBatchInput } = batchActions;
const { selectBatchStepData } = batchSelectors;
const { handleSave } = batchStepperActions;

export default connect(
  (state, ownProps) => ({
    notesContent: selectBatchStepData(state, ownProps.step).notes,
  }),
  { handleBatchInput, handleSave },
)(NotesRTE);
