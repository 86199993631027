import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import { TextField } from '@acheloisbiosoftware/absui.core';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function InputPopper(props) {
  const { label, sx, value, onClear, children, disabled } = props;
  const [popperAnchor, setPopperAnchor] = useState(null);
  const inputRef = useRef(null);

  const handleOpen = () => {
    if (!disabled) {
      setPopperAnchor(inputRef.current);
    }
  };

  const handleClose = () => {
    setPopperAnchor(null);
  };

  const handleClear = (event) => {
    onClear?.();
    event.stopPropagation();
  };


  const dropArrow = popperAnchor ? (
    <IconButton disabled={disabled} sx={{ p: 0.25 }} onClick={handleClose}>
      <ArrowDropUpIcon />
    </IconButton>
  ) : (
    <IconButton disabled={disabled} sx={{ p: 0.25 }} onClick={handleOpen}>
      <ArrowDropDownIcon />
    </IconButton>
  );
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <TextField
          label={label}
          value={value}
          disabled={disabled}
          onMouseDown={handleOpen}
          InputLabelProps={{ sx: { color: 'text.primary' }}}
          sx={sx}
          variant='standard'
          endAdornment={
            value && onClear ? (
              <>
                <IconButton disabled={disabled} onMouseDown={disabled ? null : handleClear} sx={{ p: 0.25 }}>
                  <ClearIcon />
                </IconButton>
                {dropArrow}
              </>
            ) : dropArrow
          }
          InputProps={{ ref: inputRef }}
        />
        <Popper
          open={Boolean(popperAnchor)}
          anchorEl={popperAnchor}
          onClose={handleClose}
          placement='bottom-start'
          sx={{ zIndex: 'modal' }}
        >
          { children(handleClose) }
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

InputPopper.propTypes = {
  /**
   * Callback fired when the clear button is clicked. If no callback is
   * provided, there will be no clear button available.
   */
  onClear: PropTypes.func,

  /** Label applied to the input TextField. */
  label: PropTypes.string,

  /** Value of the TextField to be displayed if any. */
  value: PropTypes.any,

  /**
   * The children to be displayed by the popper when the TextField is focused.
   * Must be a function that consumes the onClose callback function (which
   * controls when the popper closes) and returns a node to be displayed.
   */
  children: PropTypes.func.isRequired,

  /** If true, the TextField is disabled and the popper cannot be opened. */
  disabled: PropTypes.bool,

  /** sx to be applied to the TextField. */
  sx: sxPropType,
};

export default InputPopper;
