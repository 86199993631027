import { Dialog, TextField } from '@acheloisbiosoftware/absui.core';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

class CommentDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    notes: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    editMiddleware: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      content: props.notes,
    };

    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    this.props.handleSave(this.state.content.trim());
    this.props.onClose();
  }

  render() {
    const { open, onClose, children, readOnly, appearDisabled, editMiddleware } = this.props;
    const { content } = this.state;
    const sxNote = { mb: 1, fontStyle: 'italic' };
    return (
      <Dialog
        open={open}
        onClose={onClose}
        onConfirm={this.onConfirm}
        maxWidth='md'
        fullWidth
        title='Report Variation / Observations'
        confirmDisabled={readOnly}
        confirmButtonProps={{
          sx: appearDisabled ? {
            'color': 'action.disabled',
            'bgcolor': 'action.disabledBackground',
            '&:hover': {
              color: 'primary.contrastText',
            },
          } : null,
        }}
      >
        <Paper variant='outlined' sx={{ p: 2, m: 2 }}>
          {
            typeof(children) === 'string' ? (
              <Typography variant='body2'>{children}</Typography>
            ) : (
              <Box>{children}</Box>
            )
          }
        </Paper>
        {
          appearDisabled ? (
            <Typography sx={[sxNote, { color: 'text.disabled' }]}>
              Noted variations taken from this step or any observations:
            </Typography>
          ) : (
            <Typography sx={sxNote}>
              Please note any variations taken from this step or any observations:
            </Typography>
          )
        }
        <TextField
          value={content}
          onChange={(e) => this.setState({ content: e.target.value })}
          onFocus={() => editMiddleware(null)}
          minRows={8}
          multiline
          disabled={readOnly}
          fullWidth
          variant='outlined'
          InputSx={appearDisabled ? { color: 'text.disabled' } : null}
        />
      </Dialog>
    );
  }
}

export default CommentDialog;
