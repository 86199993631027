import AssemblyTableRow from './AssemblyTableRow';
import Cell from '../Cell';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

class AssemblyTable extends React.Component {
  static propTypes = {
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      construct_data: PropTypes.shape({
        pcr_fragments: PropTypes.array.isRequired,
      }).isRequired,
    })).isRequired,
  };

  render() {
    const { constructs } = this.props;
    const fragmentNumStarts = [0];
    constructs.forEach((construct) => {
      const numFragments = construct.construct_data.pcr_fragments.length;
      fragmentNumStarts.push(numFragments + fragmentNumStarts[fragmentNumStarts.length - 1]);
    });
    return (
      <TableContainer
        sx={{
          maxHeight: 600,
          overflow: 'auto',
          m: 'auto',
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <Cell />
              <Cell>Construct</Cell>
              <Cell>Vector</Cell>
              <Cell>Insert</Cell>
              <Cell>NEBuilder HF DNA Assembly MM</Cell>
              <Cell>H20</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              constructs.map((construct, idx) => (
                <AssemblyTableRow
                  key={`${construct.construct_code}_assemblyRow`}
                  construct={construct}
                  idx={idx}
                  fragmentNumStart={fragmentNumStarts[idx]}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const { selectConstructs } = batchSelectors;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
  }),
)(AssemblyTable);
