import * as d3 from 'd3';

import Features, { getFeaturesHeight } from 'components/SeqView/Linear/Features';
import ReadsMap, { getReadsMapHeight } from 'components/SeqView/Linear/ReadsMap';

import { BLUE_SELECT } from 'constants/styles.constants';
import LinearBar from './LinearBar';
import PropTypes from 'prop-types';
import React from 'react';
import ResizableSvg from 'components/SeqView/ResizableSvg';

const BLOCK_HEIGHT = 18;
const BLOCK_SPACING = 9;
const FONT_SIZE = 12;
const MARGIN = 24;

class AlignmentMap extends React.Component {
  static propTypes = {
    alignment: PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      features: PropTypes.array.isRequired,
      alignment_data: PropTypes.object.isRequired,
      ref_sequence: PropTypes.string.isRequired,
    }).isRequired,
    viewBoxRange: PropTypes.arrayOf(PropTypes.number),
  };

  constructor(props) {
    super(props);
    const { alignment } = props;
    const { construct_code, clone, created_at } = alignment;
    const timeStamp = (new Date(created_at)).getTime();
    this.svgId = `alignment_map-svg_${construct_code}-${clone}_${timeStamp}`;

    this.renderContent = this.renderContent.bind(this);
  }

  componentDidUpdate() {
    const { viewBoxRange } = this.props;
    this.svg.select('#viewBox').remove();
    this.svg.append('rect')
      .attr('id', 'viewBox')
      .attr('x', this.x(viewBoxRange[0]))
      .attr('y', 0)
      .attr('width', this.x(viewBoxRange[1]) - this.x(viewBoxRange[0]))
      .attr('height', this.height)
      .attr('fill', BLUE_SELECT)
      .attr('fill-opacity', 0.4);
  }

  componentDidMount() {
    this.svg = d3.select(`#${this.svgId}`);
  }

  renderContent(width) {
    const { alignment } = this.props;
    const { features, alignment_data, ref_sequence } = alignment;

    const bpRange = [0, ref_sequence.length];
    const canvasRange = [MARGIN, width - MARGIN];
    this.x = d3.scaleLinear().domain(bpRange).range(canvasRange);

    const readsMapProps = {
      alignmentData: alignment_data,
      bpRange,
      canvasRange,
      blockHeight: BLOCK_HEIGHT,
      blockSpacing: BLOCK_SPACING,
    };
    const totalReadHeight = getReadsMapHeight(readsMapProps) + BLOCK_SPACING;

    const barThickness = 8;

    const featuresProps = {
      features,
      bpRange,
      canvasRange,
      y: totalReadHeight + barThickness + BLOCK_SPACING,
      blockHeight: BLOCK_HEIGHT,
      blockSpacing: BLOCK_SPACING,
      fontSize: FONT_SIZE,
    };
    const totalFeatureHeight = getFeaturesHeight(featuresProps) + BLOCK_SPACING;
    this.height = totalReadHeight + barThickness + totalFeatureHeight;

    return {
      height: this.height,
      render: (
        <>
          <ReadsMap {...readsMapProps} />
          <LinearBar
            x={0}
            y={totalReadHeight}
            width={width}
            height={barThickness}
            margin={MARGIN}
          />
          <Features {...featuresProps} />
        </>
      ),
    };
  }

  render() {
    return (
      <ResizableSvg id={this.svgId}>
        { this.renderContent }
      </ResizableSvg>
    );
  }
}

export default AlignmentMap;
