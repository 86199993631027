import {
  BATCH,
  BUFFER,
  CHEMICAL,
  CONSTRUCT,
  DESC,
  EQUIPMENT,
  MATERIAL,
  REPORT,
} from 'constants/inventory.constants';
import React, { useEffect, useState } from 'react';
import { inventoryActions, inventorySelectors } from 'store/inventory';
import { materialActions, materialSelectors } from 'store/material';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { ConstructUpload } from 'views/Design';
import Error from 'components/Error';
import InventoryTable from 'components/InventoryTable';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';

const url_mapping = {
  batch: BATCH,
  construct: CONSTRUCT,
  report: REPORT,
  equipment: EQUIPMENT,
  material: MATERIAL,
  chemical: CHEMICAL,
  buffer: BUFFER,
};

function Inventory() {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const constructs = useSelector(inventorySelectors.selectConstructs);
  const batches = useSelector(inventorySelectors.selectBatches);
  const reports = useSelector(inventorySelectors.selectReports);
  const buffers = useSelector(materialSelectors.selectBuffers);
  const chemicals = useSelector(materialSelectors.selectChemicals);
  const equipments = useSelector(materialSelectors.selectEquipments);
  const materials = useSelector(materialSelectors.selectMaterials);

  const type = url_mapping[params.type];

  useEffect(() => {
    const fetchMapper = {
      [CONSTRUCT]: inventoryActions.fetchConstructs,
      [BATCH]: inventoryActions.fetchBatches,
      [REPORT]: inventoryActions.fetchBatches,
      [BUFFER]: materialActions.fetchBuffers,
      [CHEMICAL]: materialActions.fetchChemicals,
      [EQUIPMENT]: materialActions.fetchEquipments,
      [MATERIAL]: materialActions.fetchMaterials,
    };
    dispatch(fetchMapper[type]()).then(unwrapResult).catch(() => setError(true));
  }, [type, dispatch]);

  if (error) {
    return (
      <Error>
        Something went wrong fetching the inventory data <span role='img' aria-label='confused face'>😕</span>
      </Error>
    );
  }

  const typePropMapping = {
    [CONSTRUCT]: {
      orderBy: 'date',
      dropCols: ['Length'],
      entities: constructs,
    },
    [BATCH]: {
      orderBy: 'modified',
      entities: batches,
    },
    [REPORT]: {
      orderBy: 'last_signed_at',
      entities: reports,
    },
    [BUFFER]: {
      orderBy: 'name',
      entities: buffers,
    },
    [CHEMICAL]: {
      orderBy: 'name',
      entities: chemicals,
    },
    [EQUIPMENT]: {
      orderBy: 'name',
      entities: equipments,
    },
    [MATERIAL]: {
      orderBy: 'name',
      entities: materials,
    },
  };

  return (
    <>
      {
        type === CONSTRUCT ? (
          <ConstructUpload
            sx={{
              position: 'fixed',
              top: 12,
              left: 265,
              zIndex: 1101,
            }}
          />
        ) : null
      }
      <Box
        sx={{
          flexGrow: 1,
          width: 1,
          mt: '64px',
          height: 'calc(100vh - 64px)',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <InventoryTable
          tableType={type}
          order={DESC}
          {...typePropMapping[type]}
        />
      </Box>
    </>
  );
}

export default Inventory;
