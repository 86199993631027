import React, { useEffect } from 'react';
import { materialActions, materialSelectors } from 'store/material';
import { useDispatch, useSelector } from 'react-redux';

import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import Error from 'components/Error';
import InfoTable from 'components/InfoTable';
import LoadingPage from 'components/LoadingPage';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

function Equipment() {
  const dispatch = useDispatch();
  const params = useParams();
  const equipment = useSelector(materialSelectors.selectEquipment);
  const fetchedEquipment = useSelector(materialSelectors.selectPageFetched);

  useEffect(() => {
    dispatch(materialActions.fetchEquipment(params.equipmentId));
    return () => dispatch(materialActions.resetPage());
  }, [params, dispatch]);

  if (!fetchedEquipment) {
    return <LoadingPage />;
  }
  if (!equipment || !equipment.equipment_id) {
    return (
      <Error>Equipment not found!</Error>
    );
  }
  const {
    batch_type,
    name,
    vendor,
    model,
    serial_no,
    location,
    service_details,
    custom_props,
  } = equipment;
  const infoData = [
    {
      key: 'name',
      title: 'Name',
      content: name,
    },
    {
      key: 'vendor_model',
      title: 'Vendor & Model',
      content: `${vendor} ${model}`,
    },
    {
      key: 'serial_no',
      title: 'Serial #',
      content: serial_no,
    },
    {
      key: 'location',
      title: 'Location',
      content: location,
    },
    {
      key: 'service_details',
      title: 'Service Details',
      content: service_details,
    },
    ...custom_props.map(({ title, value }, idx) => ({
      key: `custom_prop${idx}`,
      title,
      content: value,
    })),
    {
      key: 'experiment',
      title: 'Experiment',
      content: (<BatchType type={batch_type} />),
    },
  ];

  const sxCentered = { textAlign: 'center', m: 'auto', justifyContent: 'center' };

  return (
    <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, maxWidth: 1000 }}>
      <Paper sx={{ p: 3, color: 'text.secondary', mb: 2 }} variant='outlined'>
        <Typography variant='h4' sx={sxCentered}>{vendor} {model}</Typography>
        <InfoTable
          data={infoData}
          borderTop
          tableProps={{ sx: { ...sxCentered, maxWidth: 500, mt: 1.5 }}}
        />
      </Paper>
    </Box>
  );
}

export default Equipment;
