// Inventory Table Types
export const BATCH = 'Batch';
export const CONSTRUCT = 'Construct';
export const REPORT = 'Report';
export const EQUIPMENT = 'Equipment';
export const MATERIAL = 'Material';
export const CHEMICAL = 'Chemical';
export const BUFFER = 'Buffer';

// Inventory Table Sort Types
export const DESC = 'desc';
export const ASC = 'asc';

// Inventory Table Column types
export const COL_TEXT = 'text';
export const COL_TEXT_MEMBER = 'textMember';
export const COL_NUMBER = 'number';
export const COL_NUMBER_MEMBER = 'numberMember';
export const COL_NUMBER_RANGE = 'numberRange';
export const COL_STATUS = 'status';
export const COL_BATCH_TYPE = 'batchType';
export const COL_SELECT = 'select';
export const COL_DATE_RANGE = 'dateRange';
