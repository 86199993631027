import { Button, Image } from '@acheloisbiosoftware/absui.core';

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { withOktaAuth } from '@okta/okta-react';

const ROW_HEIGHT = 180;

const Img = (props) => (
  <Image sx={{ height: ROW_HEIGHT }} {...props} />
);

class Landing extends React.Component {
  static propTypes = {
    auth: PropTypes.bool.isRequired,
    oktaAuth: PropTypes.shape({
      signInWithRedirect: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    auth: false,
  };

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  login() {
    this.props.oktaAuth.signInWithRedirect({ originalUri: '/inventory' });
  }

  render() {
    if (this.props.auth) {
      return <Navigate to='/inventory/construct' />;
    }

    return (
      <Box sx={{ mt: '64px', pt: 6, display: 'flex' }}>
        <Box sx={{ m: 'auto' }}>
          <ImageList rowHeight={ROW_HEIGHT} cols={12} gap={8} sx={{ width: 1000 }}>
            <ImageListItem cols={4}>
              <Img src='/images/eintung.jpg' alt='Einstein tung' />
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/phusion.jpg' alt='Phusion Flash' />
            </ImageListItem>
            <ImageListItem cols={2}>
              <Img src='/images/science_cat.jpg' alt='Cat scientist' />
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/covid.png' alt='SARS-CoV-2' />
            </ImageListItem>
            <ImageListItem cols={12}>
              <Button onClick={this.login} variant='text'>
                <Img src='/images/autocloner_logo.png' height={ROW_HEIGHT} alt='Achelois Autocloner' />
              </Button>
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/confused_science.jpg' alt='Confused scientist' />
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/science.png' alt='Science' />
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/dna_copy.jpg' alt='DNA vs. RNA' />
            </ImageListItem>
            <ImageListItem cols={3}>
              <Img src='/images/biologist.jpg' alt='Biologist' />
            </ImageListItem>
          </ImageList>
        </Box>
      </Box>
    );
  }
}

export default withOktaAuth(Landing);
