import 'components/SeqView/SeqView.styles.css';

import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';
import { featureTypes } from 'components/SeqView/SeqView.constants';

class Translation extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      canvasStart: PropTypes.number.isRequired,
      canvasEnd: PropTypes.number.isRequired,
      blockRow: PropTypes.number.isRequired,
      type: PropTypes.oneOf(featureTypes).isRequired,
      codons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string, // Not required since sometimes cannot be translated, e.g. 2 bp "codons"
        codonNum: PropTypes.number.isRequired,
        canvasMiddle: PropTypes.number.isRequired,
        bpRange: PropTypes.arrayOf(PropTypes.number).isRequired,
      })),
      location_id: PropTypes.number.isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
    onClick: PropTypes.func,
  };

  render() {
    const {
      feature,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      stackUpHeight,
      onClick,
    } = this.props;

    const { canvasStart, canvasEnd, blockRow, type, codons, location_id } = feature;
    if (type !== 'CDS' && !codons) return null;
    const translationRow = blockRow - 1;
    const yRow = translationRow * (blockHeight + blockSpacing);
    const yTop = !stackUpHeight ? yRow : stackUpHeight - yRow - blockHeight;

    const tickHeight = fontSize / 2;
    const tickStroke = 1;
    const yBaseline = yTop + (fontSize * 5 / 4) + tickStroke;
    const yCodonLabelBase = yBaseline + fontSize;
    const actualHeight = yCodonLabelBase - yTop;

    // Condition to show codon number label (first, then every fifth)
    const codonNumCondition = (codon) => (codon.codonNum + 1) % 5 === 0 || codon.codonNum === 0;
    const tickPoints = codons.filter((codon) => !codonNumCondition(codon)).map((codon) => {
      const x = codon.canvasMiddle - (tickStroke / 2);
      return [[x, yBaseline], [x, yBaseline - tickHeight]];
    });
    const baseLine = `M ${canvasStart} ${yBaseline} L ${canvasEnd} ${yBaseline}`;
    const path = baseLine + tickPoints.map(([[x1, y1], [x2, y2]]) => `M ${x1} ${y1} L ${x2} ${y2}`).join(' ');
    return (
      <g transform={`translate(0,${(blockHeight - actualHeight) / 2})`}>
        <path
          d={path}
          stroke='black'
          strokeWidth={tickStroke}
        />
        {
          codons.map((codon) => {
            if (!codonNumCondition(codon)) return null;
            const { canvasMiddle, codonNum } = codon;
            const textLength = TextMeasurer.getWidth(`${codonNum + 1}`, fontSize, fontFamily);
            return (
              <text
                key={`codon_tick_label${codonNum}`}
                x={canvasMiddle - (textLength / 2)}
                y={yTop}
                style={disableTextSelect}
                fontSize={fontSize}
                alignmentBaseline='text-before-edge'
              >
                {codonNum + 1}
              </text>
            );
          })
        }
        {
          codons.map((codon) => {
            const { label, canvasMiddle, bpRange } = codon;
            const isStopCodon = label === '\uFF0A';
            const textLength = TextMeasurer.getWidth(label, fontSize, fontFamily);
            const x = canvasMiddle - (textLength / 2);
            return (
              <g
                key={`codon_label_${JSON.stringify(codon)}`}
                className={onClick ? 'codonLabelLinear' : null}
                onClick={onClick ? () => onClick(label, bpRange, location_id) : null}
              >
                <rect
                  x={x}
                  y={yCodonLabelBase - fontSize + (isStopCodon ? 2 : 0)}
                  width={textLength}
                  height={fontSize}
                  fill={isStopCodon ? 'red' : 'none'}
                  stroke='none'
                  pointerEvents='visibleFill'
                />
                <text
                  x={x}
                  y={yCodonLabelBase}
                  style={disableTextSelect}
                  fontSize={fontSize}
                  fill={isStopCodon ? 'white' : 'black'}
                  fontWeight={isStopCodon ? 'bold' : null}
                  alignmentBaseline='baseline'
                >
                  {label}
                </text>
              </g>
            );
          })
        }
      </g>
    );
  }
}

export default Translation;
