import React, { useEffect, useState } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { materialActions, materialSelectors } from 'store/material';
import { mergeSx, usePromise } from '@acheloisbiosoftware/absui.utils';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_SELECT_MATERIAL } from 'constants/batchActions.constants';
import { Autocomplete } from '@acheloisbiosoftware/absui.core';
import Box from '@mui/material/Box';
import { BufferBatchDialog } from 'views/Buffer';
import DataTable from 'components/DataTable';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function BatchField(props) {
  const {
    buffer,
    value,
    onChange,
    editMiddleware,
    appearDisabled,
    readOnly,
  } = props;
  const { buffer_id, buffer_batches } = buffer;
  const [open, setOpen] = useState(false);
  const [batchNo, setBatchNo] = useState('');
  const { promise, defer } = usePromise();

  const onCreate = (inputValue) => {
    setBatchNo(inputValue);
    setOpen(true);
    return defer();
  };

  const onClose = (newBufferBatch) => {
    onChange(buffer_id, newBufferBatch);
    promise.resolve(newBufferBatch || null);
    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        onChange={(newValue) => onChange(buffer_id, newValue)}
        onCreate={onCreate}
        getOptionLabel={(option) => option.batch_no}
        createOptionText='New Buffer Batch'
        options={buffer_batches}
        label='Batch #'
        inputProps={(params) => ({
          ...params,
          variant: 'standard',
          onFocus: () => editMiddleware(null),
          InputProps: {
            ...params?.InputProps,
            sx: mergeSx(params?.InputProps?.sx, appearDisabled ? { color: 'text.disabled' } : {}),
          },
        })}
        value={value || null}
        disableClearable={appearDisabled}
        disabled={readOnly}
      />
      <BufferBatchDialog
        buffer={buffer}
        open={open}
        onClose={onClose}
        initData={{ batch_no: batchNo }}
      />
    </>
  );
}

BatchField.propTypes = {
  buffer: PropTypes.shape({
    buffer_id: PropTypes.number.isRequired,
    buffer_batches: PropTypes.arrayOf(PropTypes.shape({
      batch_no: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  value: PropTypes.shape({
    batch_no: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  editMiddleware: PropTypes.func.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

function BufferTable(props) {
  const { step, substep, line, readOnly, appearDisabled, editMiddleware } = props;
  const dispatch = useDispatch();
  const lineData = useSelector((state) => batchSelectors.selectBatchLineData(state, step, substep, line));
  const buffers = useSelector(materialSelectors.selectBuffers);

  useEffect(() => {
    dispatch(materialActions.fetchBuffers(true));
  }, [dispatch]);

  const setBatchNo = (bufferId, value) => {
    dispatch(batchActions.handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_SELECT_MATERIAL,
      payload: {
        row_id_key: 'buffer_id',
        buffer_id: bufferId,
        buffer_batch_id: value?.buffer_batch_id || null,
      },
    }));
  };

  const { title, data } = lineData;
  const columns = [
    { key: 'name', title: 'Name', cellProps: { sx: { width: 120 }}},
    { key: 'alias', title: 'Alias', cellProps: { sx: { width: 80 }}},
    { key: 'formula', title: 'Formula', cellProps: { sx: { width: 100 }}},
    { key: 'batch_no', title: 'Batch #', cellProps: { sx: { width: 200 }}},
    { key: 'storage_cond', title: 'Storage Condition', cellProps: { sx: { width: 150 }}},
  ];

  const rows = data.map(({ buffer_id, buffer_batch_id }) => {
    const buffer = buffers.find((b) => b.buffer_id === buffer_id);
    if (!buffer || !buffer.buffer_batches) {
      const skeletonProps = { height: 45 };
      return {
        key: `buffer${buffer_id}_row`,
        name: <Skeleton {...skeletonProps} />,
        alias: <Skeleton {...skeletonProps} />,
        formula: <Skeleton {...skeletonProps} />,
        batch_no: <Skeleton {...skeletonProps} />,
        storage_cond: <Skeleton {...skeletonProps} />,
      };
    }
    const bufferBatch = buffer.buffer_batches.find((bb) => bb.buffer_batch_id === buffer_batch_id);
    return {
      key: `buffer${buffer_id}_row`,
      name: (
        <Link
          href={`/buffer/${buffer_id}`}
          target='_blank'
          sx={appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {}}
        >
          {buffer.name}
        </Link>
      ),
      alias: buffer.alias,
      formula: buffer.formula,
      batch_no: (
        <BatchField
          buffer={buffer}
          value={bufferBatch}
          onChange={setBatchNo}
          editMiddleware={editMiddleware}
          appearDisabled={appearDisabled}
          readOnly={readOnly}
        />
      ),
      storage_cond: buffer.storage_cond,
    };
  });

  return (
    <Box sx={{ textAlign: 'center', m: 3 }}>
      <Typography variant='h6'>{title}</Typography>
      <DataTable
        columns={columns}
        data={rows}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
        containerProps={{ sx: { m: 1 }}}
      />
    </Box>
  );
}

BufferTable.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  editMiddleware: PropTypes.func.isRequired,
};

export default BufferTable;
