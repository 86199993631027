import * as extraActions from './primer.extraActions';
import * as selectors from './primer.selectors';

import { extraReducers, reducers } from './primer.reducers';

import { PRIMER } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './primer.initialState';

const slice = createSlice({
  name: PRIMER,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const primerSelectors = { ...selectors };
export const primerActions = { ...slice.actions, ...extraActions };
export const { reducer: primerReducer } = slice;
