import * as ConstructService from 'services/ConstructService';

import Box from '@mui/material/Box';
import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import PCRTable from 'components/PCRTable';
import Paper from '@mui/material/Paper';
import PrimerTable from 'components/PrimerTable';
import PropTypes from 'prop-types';
import React from 'react';
import SeqView from 'components/SeqView';
import Typography from '@mui/material/Typography';
import { createPrimerTsv } from 'utils/biology.utils';

const ResultsPaper = (props) => (
  <Paper sx={{ p: 3, color: 'text.secondary', mt: 2 }} variant='outlined' {...props} />
);

const Title = (props) => (
  <Typography variant='h4' sx={{ textAlign: 'center' }} {...props} />
);

class AutocloneResults extends React.Component {
  static propTypes = {
    handleSave: PropTypes.func.isRequired,
    handleConstructInput: PropTypes.func.isRequired,
    construct: PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      visualization: PropTypes.object.isRequired,
      construct_data: PropTypes.shape({
        primers: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          sequence: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    const { construct, handleConstructInput, handleSave } = this.props;
    const { construct_code, construct_data, visualization } = construct;
    const constructDownload = async () => await ConstructService.getConstructsDownload([construct_code]);
    const { primers } = construct_data;
    const primerTsv = {
      name: `${construct_code}_primers.tsv`,
      file: createPrimerTsv(primers),
    };
    return (
      <Box sx={{ flexGrow: 1 }}>
        {
          primers.length ? (
            <ResultsPaper>
              <Title>Primers</Title>
              <PrimerTable constructs={[construct]} dropCols={['Construct']} />
              <Box sx={{ mt: 3, textAlign: 'right' }}>
                <DownloadButton
                  download={primerTsv}
                  size='small'
                >
                  Download Primers
                </DownloadButton>
              </Box>
            </ResultsPaper>
          ) : null
        }
        <ResultsPaper>
          <Title>PCR Fragments</Title>
          <PCRTable
            constructs={[construct]}
            handleConstructInput={handleConstructInput}
            handleSave={handleSave}
            sx={{ m: 'auto', mt: 1, maxWidth: 0.9 }}
          />
        </ResultsPaper>
        <ResultsPaper>
          <Title>Sequence</Title>
          <SeqView seqData={visualization} />
          <Box sx={{ mt: 3, textAlign: 'right' }}>
            <DownloadButton
              download={constructDownload}
              size='small'
            >
              Download Sequence
            </DownloadButton>
          </Box>
        </ResultsPaper>
      </Box>
    );
  }
}

export default AutocloneResults;
