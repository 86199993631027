import InsertSuggestionModal from './InsertSuggestionModal';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';

class InsertSuggestionItem extends React.Component {
  static propTypes = {
    handleSuggestionSelection: PropTypes.func.isRequired,
    suggestion: PropTypes.shape({
      feature_name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      origin: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.props.handleSuggestionSelection();
    this.setState({ modalOpen: false });
  }

  render() {
    const { suggestion } = this.props;
    return (
      <>
        <ListItem button onClick={() => this.setState({ modalOpen: true })}>
          <ListItemText
            primary={suggestion.feature_name}
            secondary={`${suggestion.type} from ${suggestion.origin}`}
          />
        </ListItem>
        <InsertSuggestionModal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          suggestion={suggestion}
          handleConfirm={this.handleConfirm}
        />
      </>
    );
  }
}

export default InsertSuggestionItem;
