import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import TableSelectionDialog from './TableSelectionDialog';
import Typography from '@mui/material/Typography';

class CellsOnlySelection extends React.Component {
  static propTypes = {
    handleCellsOnlySelection: PropTypes.func.isRequired,
    cellsOnlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.array).isRequired,
    }).isRequired,
    csvData: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectionModal: false,
    };
  }

  render() {
    const { csvData, cellsOnlyData, handleCellsOnlySelection } = this.props;
    const { selectionModal } = this.state;
    return (
      <>
        <Box sx={{ display: 'flex', width: 1 }}>
          <Box sx={{ display: 'flex', m: 'auto' }}>
            <Typography variant='h6' sx={{ m: 'auto', mr: 1 }}>Cells Only</Typography>
            {
              cellsOnlyData.data.length ? (
                <Button
                  color='inherit'
                  variant='contained'
                  onClick={() => this.setState({ selectionModal: true })}
                  endIcon={<EditIcon />}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant='contained'
                  onClick={() => this.setState({ selectionModal: true })}
                  endIcon={<CropFreeIcon />}
                >
                  Select
                </Button>
              )
            }
            <TableSelectionDialog
              open={selectionModal}
              onClose={() => this.setState({ selectionModal: false })}
              csvData={csvData}
              handleDataSelection={handleCellsOnlySelection}
              selection={cellsOnlyData}
            />
          </Box>
        </Box>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </>
    );
  }
}

export default CellsOnlySelection;
