import { Autocomplete, Button, Dialog } from '@acheloisbiosoftware/absui.core';
import { batchActions, batchSelectors } from 'store/batch';
import { userActions, userSelectors } from 'store/user';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const checkValidSelection = (signatories, reportSignees) => {
  const firstEmptyIdx = signatories.findIndex((s) => !s);
  // No first signatory
  if (!signatories[0]) return false;

  // There is an empty slot before a filled slot
  if (
    firstEmptyIdx > 0 &&
    signatories.slice(firstEmptyIdx + 1).reduce((prev, curr) => prev || curr, false)
  ) return false;

  // Signing is in progress, but the update would remove all signatories who
  // have yet to sign.
  const newSignatories = signatories.reduce(
    (signatoryList, signatory, idx) => ((
      !reportSignees[idx]?.signed && signatory?.user_id
    ) ? (
        [...signatoryList, { order: idx + 1, user_id: signatory.user_id }]
      ) : signatoryList),
    [],
  );
  if (!newSignatories.length) return false;

  return true;
};

class SendSignaturesButton extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    fetchSignees: PropTypes.func.isRequired,
    addSignees: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    updateSignees: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    me: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    reportSignees: PropTypes.arrayOf(PropTypes.shape({
      signee: PropTypes.object.isRequired,
      signed: PropTypes.bool.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    batchReportData: PropTypes.shape({
      locked_status: PropTypes.bool.isRequired,
      signed_status: PropTypes.string.isRequired,
      signee_count: PropTypes.number.isRequired,
      signed_count: PropTypes.number.isRequired,
      locked: PropTypes.shape({
        by: PropTypes.shape({
          email: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }).isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      signatories: [null, null, null, null],
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSend = this.onSend.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
    const { batchId, fetchSignees } = this.props;
    await fetchSignees(batchId);
  }

  onOpen() {
    const { reportSignees } = this.props;
    const signatories = this.state.signatories.map((signatory, idx) => signatory || reportSignees[idx]?.signee || null);
    this.setState({ signatories, dialogOpen: true });
  }

  onClose() {
    this.setState({ dialogOpen: false, signatories: [null, null, null, null]});
  }

  async onSend() {
    const { addSignees, batchId, updateSignees, fetchSignees, batchReportData, reportSignees } = this.props;
    const { signatories } = this.state;
    const signees = signatories.reduce(
      (signatoryList, signatory, idx) => ((
        !reportSignees[idx]?.signed && signatory?.user_id
      ) ? (
          [...signatoryList, { order: idx + 1, user_id: signatory.user_id }]
        ) : signatoryList),
      [],
    );
    if (batchReportData.signed_status === 'Locked') {
      await addSignees({ batchId, signees });
    } else {
      await updateSignees({ batchId, signees });
    }

    // This call is a workaround to ensure that the reportSignees is kept up to
    // date. The addSignees and updateSignees call do not gaurantee that the
    // reportSignees is kept up to date.
    fetchSignees(batchId);
  }

  onSelect(selectedUser, signatoryIdx) {
    const { signatories } = this.state;
    const newSignatories = [...signatories];
    newSignatories[signatoryIdx] = selectedUser;
    this.setState({ signatories: newSignatories });
  }

  render() {
    const { sx, batchReportData, me, loading, reportSignees, users } = this.props;
    const { signatories, dialogOpen } = this.state;
    const isValidSelection = checkValidSelection(signatories, reportSignees);
    const isSendForSignatureAllowed = (
      batchReportData.locked_status &&
      batchReportData.locked.by.email === me.email &&
      batchReportData.signed_status !== 'Signatures Completed'
    );
    const disabledSelections = signatories.map((signatory, idx) => reportSignees[idx]?.signed);
    return !isSendForSignatureAllowed ? null : (
      <>
        <Button
          size='small'
          variant='contained'
          sx={sx}
          onClick={this.onOpen}
        >
          {
            (
              batchReportData.signee_count > 0 &&
              batchReportData.signed_count < batchReportData.signee_count
            ) ? 'Update Signatories' : 'Send for Signatures'
          }
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={this.onClose}
          maxWidth='xs'
          title='Select signatories in the desired order'
          onConfirm={this.onSend}
          confirmDisabled={!isValidSelection}
          confirmButtonText='Send'
          loading={loading}
        >
          {
            signatories.map((signatory, idx) => (
              <Box
                /* eslint-disable-next-line react/no-array-index-key */
                key={`signatory${idx}`}
                sx={{ display: 'flex', m: 2 }}
              >
                <Typography sx={{ m: 'auto', width: 150 }}>
                  Signatory {idx + 1}
                </Typography>
                <Autocomplete
                  options={users}
                  sx={{ m: 'auto', width: 250 }}
                  disabled={disabledSelections[idx]}
                  disableCreateOption
                  inputProps={(params) => ({ ...params, variant: 'standard' })}
                  value={signatory || null}
                  getOptionLabel={(option) => option.name}
                  onChange={(newValue) => this.onSelect(newValue, idx)}
                  isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                />
              </Box>
            ))
          }
        </Dialog>
      </>
    );
  }
}

const { selectBatchId, selectBatchLoading, selectBatchReportData, selectBatchReportSignees } = batchSelectors;
const { addSignees, fetchSignees, updateSignees } = batchActions;
const { selectUsers } = userSelectors;
const { fetchUsers } = userActions;
const { selectSessionUser } = sessionSelectors;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    users: selectUsers(state),
    batchReportData: selectBatchReportData(state),
    reportSignees: selectBatchReportSignees(state),
    me: selectSessionUser(state),
    loading: selectBatchLoading(state),
  }),
  { addSignees, fetchSignees, fetchUsers, updateSignees },
)(SendSignaturesButton);
