import { STATUS_INIT } from 'constants/statuses.constants';

export const getInitialState = () => ({
  backboneData: {
    backbones: [],
    backbone_types: [],
    backbone_info: {},
    tags: [],
    type_tag_mapping: {},
  },
  mhcAlleles: {},
  status: STATUS_INIT,
  error: null,
});
