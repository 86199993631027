import CircleText from 'components/SeqView/Circular/CircleText';
import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { getAnnotationPathCircular } from 'components/SeqView/SeqView.utils';

class AnnotationText extends React.Component {
  static propTypes = {
    enzyme: PropTypes.shape({
      enzymeName: PropTypes.string.isRequired,
      annotationId: PropTypes.string.isRequired,
      thetaLoc: PropTypes.number.isRequired,
      r_text: PropTypes.number.isRequired,
      textRow: PropTypes.number,
      bpLocation: PropTypes.number.isRequired,
    }).isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    blockHeight: PropTypes.number.isRequired,
    fontSize: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    textPadding: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const {
      enzyme,
      cx,
      cy,
      blockHeight,
      fontSize,
      fontFamily,
      textPadding,
      onClick,
      onDoubleClick,
      onHover,
    } = this.props;
    const { enzymeName, annotationId, thetaLoc, r_text, textRow, bpLocation } = enzyme;
    if (textRow === null) return null;

    const textWidth = TextMeasurer.getWidth(enzymeName, fontSize, fontFamily) / r_text;
    const pad = textPadding / r_text;
    const thetaRange = [thetaLoc - (textWidth / 2) - pad, thetaLoc + (textWidth / 2) + pad];
    const path = getAnnotationPathCircular(cx, cy, r_text, thetaRange, blockHeight);

    const clickable = onClick || onDoubleClick;
    return (
      <g
        onClick={onClick ? () => onClick(enzymeName, bpLocation) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(enzymeName, bpLocation) : null}
        style={clickable ? { cursor: 'pointer' } : {}}
        onMouseEnter={clickable ? () => onHover(enzyme, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(enzyme, 'leave') : null}
      >
        <path
          d={path}
          fill='white'
          stroke='none'
          className={`${annotationId}_textContainer`}
        />
        <CircleText
          cx={cx}
          cy={cy}
          r={r_text}
          thetaRange={thetaRange}
          id={`${annotationId}_circleTextId`}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight='bold'
          className={`${annotationId}_text`}
        >
          { enzymeName }
        </CircleText>
      </g>
    );
  }
}

export default AnnotationText;
