import NotesRTE from './NotesRTE';
import PropTypes from 'prop-types';
import React from 'react';
import Sequencing from 'views/Sequencing';
import StepperButtons from 'views/Batch/StepperButtons';
import { batchSelectors } from 'store/batch';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';

const STEP_NAME = 'SEQUENCE_ANALYSIS';

class SequenceAnalysisStep extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    constructs: PropTypes.array.isRequired,
    onNextStep: PropTypes.func.isRequired,
  };

  render() {
    const { constructs, batchId, onNextStep } = this.props;
    return (
      <>
        <Sequencing constructs={constructs} batchId={batchId} />
        <NotesRTE step={STEP_NAME} />
        <StepperButtons position='right' onNextStep={onNextStep} />
      </>
    );
  }
}

const { selectBatchId, selectConstructs } = batchSelectors;
const { onNextStep } = batchStepperActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    constructs: selectConstructs(state),
  }),
  { onNextStep },
)(SequenceAnalysisStep);
