import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { Image } from '@acheloisbiosoftware/absui.core';
import MuiDialog from '@mui/material/Dialog';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useTheme } from '@mui/material';

function ImageCarousel(props) {
  const { open, views, hideOnIdle, onClose } = props;
  const [galleryIdx, setGalleryIdx] = useState(0);
  const [hovering, setHovering] = useState(false);
  const [idle, setIdle] = useState(false);
  const captionDiv = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    let idleTimer;
    if (!idle) {
      idleTimer = setTimeout(() => setIdle(true), typeof(hideOnIdle) === 'number' ? hideOnIdle : 10000);
    }
    return () => clearTimeout(idleTimer);
  }, [idle, hideOnIdle]);

  useEffect(() => {
    if (open) {
      setGalleryIdx(props.galleryIdx);
    }
  }, [open, props.galleryIdx]);

  useEffect(() => {
    const idleListener = () => {
      if (hideOnIdle) {
        setIdle(false);
      }
    };
    document.addEventListener('mousemove', idleListener);
    return () => {
      document.removeEventListener('mousemove', idleListener);
    };
  }, [hideOnIdle]);

  useEffect(() => {
    const navButtonHandler = (event) => {
      if (open) {
        if (event.keyCode === 37) { // Left arrow
          setGalleryIdx(Math.max(0, galleryIdx - 1));
        } else if (event.keyCode === 39) { // Right arrow
          setGalleryIdx(Math.min(views.length - 1, galleryIdx + 1));
        }
      }
    };
    document.addEventListener('keydown', navButtonHandler);
    return () => {
      document.removeEventListener('keydown', navButtonHandler);
    };
  }, [open, galleryIdx, views.length]);

  const captionHeight = captionDiv ? (captionDiv.clientHeight || 24) : 0;
  const sxNavButtons = { position: 'absolute', top: 'calc(50% - 20px)' };
  const { grey } = theme.palette;
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperComponent={Box}
    >
      <Carousel
        autoPlay={false}
        index={galleryIdx}
        onChange={(idx) => setGalleryIdx(idx)}
        animation='fade'
        timeout={200}
        cycleNavigation={false}
        navButtonsAlwaysInvisible
        indicatorIconButtonProps={{ style: { color: grey[100] }}}
        activeIndicatorIconButtonProps={{ style: { color: grey[600], backgroundColor: grey[100] }}}
      >
        {
          views.map((image) => (
            <Box
              key={image.name}
              sx={{ height: '80vh', position: 'relative', display: 'flex' }}
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
            >
              <Image
                src={image.source}
                sx={{ maxWidth: 1, maxHeight: 1, borderRadius: 4, m: 'auto' }}
                alt={image.name}
              />
              <Fade in={Boolean(hovering) && (!hideOnIdle || !idle)} timeout={400}>
                <Box>
                  <Box
                    sx={mergeSx(
                      { position: 'absolute', bottom: 0, right: 0, width: 1 },
                      captionDiv ? {
                        height: (captionHeight * 2) + 16,
                        background: `linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)`,
                      } : {},
                    )}
                  />
                  {
                    galleryIdx + 1 < views.length ? (
                      <Fab
                        size='small'
                        sx={{ ...sxNavButtons, right: 12 }}
                        onClick={() => setGalleryIdx(galleryIdx + 1)}
                      >
                        <NavigateNextIcon />
                      </Fab>
                    ) : null
                  }
                  {
                    galleryIdx - 1 >= 0 ? (
                      <Fab
                        size='small'
                        sx={{ ...sxNavButtons, left: 12 }}
                        onClick={() => setGalleryIdx(galleryIdx - 1)}
                      >
                        <NavigateBeforeIcon />
                      </Fab>
                    ) : null
                  }
                  <IconButton
                    sx={{
                      position: 'absolute',
                      color: 'grey.100',
                      top: theme.spacing(1),
                      right: theme.spacing(1),
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: theme.spacing(1),
                      left: theme.spacing(2),
                    }}
                    ref={captionDiv}
                  >
                    { image.caption }
                  </Box>
                </Box>
              </Fade>
            </Box>
          ))
        }
      </Carousel>
    </MuiDialog>
  );
}

ImageCarousel.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hideOnIdle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  galleryIdx: PropTypes.number.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    caption: PropTypes.node,
  })).isRequired,
};

ImageCarousel.defaultProps = {
  hideOnIdle: false,
};

export default ImageCarousel;
