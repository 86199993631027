import AssemblyTable from './AssemblyTable';
import { ChecklistItem } from 'components/Checklist';
import Grid from '@mui/material/Grid';
import InfoPopper from 'components/InfoPopper';
import React from 'react';
import Typography from '@mui/material/Typography';

class Assembly extends React.Component {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <AssemblyTable />
        </Grid>
        <Grid item xs={1}>
          <InfoPopper
            tooltipProps={{ title: 'Show Assembly Protocol', placement: 'top', arrow: true }}
            paperProps={{ sx: { p: 2 }}}
            placement='bottom-end'
          >
            <Typography
              sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}
            >
              Protocol
            </Typography>
            <ChecklistItem
              dense
              content='Incubate at 50ºC for 15 min'
            />
          </InfoPopper>
        </Grid>
      </Grid>
    );
  }
}

export default Assembly;
