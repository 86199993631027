import * as CommonService from 'services/CommonService';

import { batchActions, batchSelectors } from 'store/batch';

import FragmentConcentrationTable from './FragmentConcentrationTable';
import GelExtractionProtocol from './GelExtractionProtocol';
import Grid from '@mui/material/Grid';
import ImageGallery from 'components/ImageGallery';
import InfoPopper from 'components/InfoPopper';
import PropTypes from 'prop-types';
import React from 'react';
import VectorConcentrationTable from './VectorConcentrationTable';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { unwrapResult } from '@reduxjs/toolkit';

class Gel extends React.Component {
  static propTypes = {
    uploadGelImages: PropTypes.func.isRequired,
    removeGelImage: PropTypes.func.isRequired,
    editGelImage: PropTypes.func.isRequired,
    batchId: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    gelImages: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.editImage = this.editImage.bind(this);
  }

  uploadImage(files) {
    const { batchId, uploadGelImages } = this.props;
    uploadGelImages({
      files,
      body: {
        batch_id: batchId,
      },
    });
  }

  removeImage(imageName) {
    const { batchId, removeGelImage } = this.props;
    removeGelImage({
      batch_id: batchId,
      image_name: imageName,
    });
  }

  async editImage(imageName, imageData) {
    const { batchId, editGelImage } = this.props;
    const res = await editGelImage({
      batch_id: batchId,
      image_name: imageName,
      image_data: imageData,
    });
    try {
      return unwrapResult(res);
    } catch (e) {
      return e;
    }
  }

  render() {
    const { gelImages, token } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <ImageGallery
            images={gelImages}
            getImageUrl={CommonService.getImageUrlBuilder('gel', token)}
            onDelete={this.removeImage}
            onUpload={this.uploadImage}
            onEdit={this.editImage}
          />
        </Grid>
        <Grid item xs={1}>
          <InfoPopper
            tooltipProps={{ title: 'Show Gel Extraction Protocol', placement: 'top', arrow: true }}
            paperProps={{ sx: { p: 2 }}}
            placement='bottom-end'
          >
            <GelExtractionProtocol />
          </InfoPopper>
        </Grid>
        <Grid item xs={5}>
          <VectorConcentrationTable />
        </Grid>
        <Grid item xs={6}>
          <FragmentConcentrationTable />
        </Grid>
      </Grid>
    );
  }
}

const { selectBatchId, selectBatchStepData } = batchSelectors;
const { editGelImage, removeGelImage, uploadGelImages } = batchActions;
const { selectSessionToken } = sessionSelectors;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    gelImages: selectBatchStepData(state, 'CLONING').gel_images,
    token: selectSessionToken(state),
  }),
  { uploadGelImages, removeGelImage, editGelImage },
)(Gel);
