import { ASC, CONSTRUCT } from 'constants/inventory.constants';
import { batchStepperActions, batchStepperSelectors } from 'store/batchStepper';
import { cloningStatuses, cloningSteps } from 'constants/batch.constants';

import BatchInfo from '../BatchInfo';
import Box from '@mui/material/Box';
import CloningStep from './CloningStep';
import DesignStep from './DesignStep';
import InventoryTable from 'components/InventoryTable';
import MaxiPrepStep from './MaxiPrepStep';
import Paper from '@mui/material/Paper';
import PrimerOrderStep from './PrimerOrderStep';
import PropTypes from 'prop-types';
import React from 'react';
import ReportStep from './ReportStep';
import SequenceAnalysisStep from './SequenceAnalysisStep';
import SequencingStep from './SequencingStep';
import Slide from '@mui/material/Slide';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

const CLONING_STEPS = [
  (<DesignStep key='DesignStep' />),
  (<PrimerOrderStep key='PrimerOrderStep' />),
  (<CloningStep key='CloningStep' />),
  (<SequencingStep key='SequencingStep' />),
  (<SequenceAnalysisStep key='SequenceAnalysisStep' />),
  (<MaxiPrepStep key='MaxiPrepStep' />),
  (<ReportStep key='ReportStep' />),
];


class Cloning extends React.Component {
  static propTypes = {
    batchStatus: PropTypes.string.isRequired,
    activeStep: PropTypes.number.isRequired,
    updateStep: PropTypes.func.isRequired,
    constructs: PropTypes.arrayOf(PropTypes.object).isRequired,
    slideDirection: PropTypes.oneOf(['left', 'right']),
  };

  static defaultProps = {
    slideDirection: 'left',
  };

  render() {
    const { constructs, batchStatus, activeStep, updateStep, slideDirection } = this.props;
    const currCompleted = cloningStatuses.indexOf(batchStatus);

    const sxPaper = { overflow: 'hidden', p: 3, mb: 3 };
    return (
      <Box sx={{ mt: '64px', py: 3, px: 4, minWidth: 1100 }}>
        <Paper variant='outlined' sx={sxPaper}>
          <BatchInfo />
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {
              cloningSteps.map((label, idx) => (
                <Step
                  key={label}
                  completed={idx < currCompleted || batchStatus === 'DONE'}
                >
                  <StepButton onClick={() => updateStep(idx)}>
                    <Typography sx={idx === activeStep ? { fontWeight: 'fontWeightBold' } : null}>
                      {label}
                    </Typography>
                  </StepButton>
                </Step>
              ))
            }
          </Stepper>
        </Paper>
        <Paper variant='outlined' sx={sxPaper}>
          {
            CLONING_STEPS.map((step, idx) => (
              activeStep === idx ? (
                <Slide key={`step_${step.id}`} direction={slideDirection} in>
                  <Box>
                    <Typography
                      sx={{ color: 'common.red', mb: 1, textAlign: 'center' }}
                      variant='h4'
                    >
                      {cloningSteps[idx]}
                    </Typography>
                    {step}
                  </Box>
                </Slide>
              ) : null
            ))
          }
        </Paper>
        <Paper
          sx={{
            p: 3,
            maxHeight: (theme) => `calc(100vh - 64px - ${theme.spacing(3) * 2}px)`,
            display: 'flex',
            flexFlow: 'column',
          }}
          variant='outlined'
        >
          <InventoryTable
            tableType={CONSTRUCT}
            orderBy='construct_code'
            order={ASC}
            dropCols={['Batch', 'Status']}
            entities={constructs}
          />
        </Paper>
      </Box>
    );
  }
}

const { selectActiveStep, selectSlideDirection } = batchStepperSelectors;
const { updateStep } = batchStepperActions;
const { selectBatchStatus, selectConstructs } = batchSelectors;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
    batchStatus: selectBatchStatus(state),
    activeStep: selectActiveStep(state),
    slideDirection: selectSlideDirection(state),
  }),
  { updateStep },
)(Cloning);
