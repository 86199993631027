import React, { useState } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Image } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import green from '@mui/material/colors/green';
import { measureImg } from 'utils/visualization.utils';

function ImageTile(props) {
  const { image, onClick, onEdit, onDelete, height } = props;
  const [hovering, setHovering] = useState(false);
  const [loading, setLoading] = useState(true);

  const { width: originalWidth, height: originalHeight } = measureImg(image);
  const width = loading ? height * 4 / 3 : originalWidth / originalHeight * height;
  const sxIcon = { color: 'grey.100' };
  return (
    <Box
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        borderRadius: 2,
        ml: image.refNum ? 2 : 1,
        height,
        width,
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Badge
        badgeContent={image.refNum || 0}
        color='secondary'
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        overlap='rectangular'
      >
        <Box
          sx={{
            bgcolor: green[100],
            borderRadius: 2,
            height,
            minWidth: width,
            display: loading ? 'flex' : 'none',
          }}
        >
          <CircularProgress size={32} sx={{ m: 'auto' }} />
        </Box>
        <Image
          src={image.source}
          sx={{ cursor: 'pointer', borderRadius: 1, opacity: hovering ? 0.8 : null, display: loading ? 'none' : null }}
          height={height}
          width={width}
          alt={image.name}
          onClick={onClick}
          onLoad={() => setLoading(false)}
        />
      </Badge>
      {
        hovering ? (
          <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
            {
              onEdit ? (
                <IconButton size='small' onClick={onEdit}>
                  <EditIcon sx={sxIcon} />
                </IconButton>
              ) : null
            }
            {
              onDelete ? (
                <IconButton size='small' onClick={onDelete}>
                  <DeleteIcon sx={sxIcon} />
                </IconButton>
              ) : null
            }
          </Box>
        ) : null
      }
    </Box>
  );
}

ImageTile.propTypes = {
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  height: PropTypes.number.isRequired,
  image: PropTypes.shape({
    refNum: PropTypes.number,
    source: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default ImageTile;
