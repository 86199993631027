import * as extraActions from './design.extraActions';
import * as selectors from './design.selectors';

import { extraReducers, reducers } from './design.reducers';

import { DESIGN } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './design.initialState';

const slice = createSlice({
  name: DESIGN,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const designSelectors = { ...selectors };
export const designActions = { ...slice.actions, ...extraActions };
export const { reducer: designReducer } = slice;
