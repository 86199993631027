import React from 'react';
import { ReactComponent as SignatureValid } from './SignatureValid.svg';
import SvgIcon from '@mui/material/SvgIcon';

class SignatureValidIcon extends React.Component {
  render() {
    return (
      <SvgIcon {...this.props} inheritViewBox component={SignatureValid} />
    );
  }
}

export default SignatureValidIcon;
