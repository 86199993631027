export const TextMeasurer = (() => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  function getWidth(text, fontSize, fontFace) {
    context.font = `${fontSize}px ${fontFace}`;
    return context.measureText(text).width;
  }

  return {
    getWidth,
  };
})();

export const measureImg = (image) => {
  const img = new Image();
  img.src = image.source;
  const { width, height } = img;
  return { width, height };
};
