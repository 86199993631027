import * as ConstructService from 'services/ConstructService';

import React, { useEffect } from 'react';
import { constructActions, constructSelectors } from 'store/construct';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import ConstructInfo from './ConstructInfo';
import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import Error from 'components/Error';
import LoadingPage from 'components/LoadingPage';
import PCRTable from 'components/PCRTable';
import Paper from '@mui/material/Paper';
import PrimerTable from 'components/PrimerTable';
import PrimerUpload from 'components/PrimerUpload';
import SeqView from 'components/SeqView';
import Sequencing from 'views/Sequencing';
import Typography from '@mui/material/Typography';
import { createPrimerTsv } from 'utils/biology.utils';
import { useParams } from 'react-router-dom';

const ContentPaper = (props) => (
  <Paper sx={{ p: 3, color: 'text.secondary', mt: 2 }} variant='outlined' {...props} />
);

function Construct() {
  const dispatch = useDispatch();
  const params = useParams();
  const construct = useSelector(constructSelectors.selectConstruct);
  const fetchedConstruct = useSelector(constructSelectors.selectFetched);

  useEffect(() => {
    dispatch(constructActions.fetchConstruct(params.constructCode));
    return () => dispatch(constructActions.resetConstruct());
  }, [params, dispatch]);

  if (!fetchedConstruct) {
    return <LoadingPage />;
  }
  if (!construct || !construct.construct_code) {
    return (
      <Error>Construct not found!</Error>
    );
  }
  const { construct_code, construct_data, visualization } = construct;
  const constructDownload = async () => await ConstructService.getConstructsDownload([construct_code]);
  const { primers } = construct_data;
  const primerTsv = {
    name: `${construct_code}_primers.tsv`,
    file: createPrimerTsv(primers || []),
  };
  const sxCentered = { textAlign: 'center', m: 'auto', justifyContent: 'center' };
  return (
    <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, maxWidth: 1050 }}>
      <ConstructInfo construct={construct} />
      {
        visualization ? (
          <ContentPaper>
            <Typography variant='h4' sx={sxCentered}>Sequence</Typography>
            <SeqView seqData={visualization} />
            <Box sx={sxCentered}>
              <DownloadButton
                download={constructDownload}
                size='small'
              >
                Download Sequence
              </DownloadButton>
            </Box>
          </ContentPaper>
        ) : null
      }
      <ContentPaper>
        <Typography variant='h4' sx={sxCentered}>Primers</Typography>
        {
          construct_data.primers && construct_data.primers.length ? (
            <>
              <PrimerTable
                constructs={[construct]}
                dropCols={['Construct']}
                sx={{ mt: 1 }}
              />
              <Box sx={{ mt: 3, display: 'flex' }}>
                <PrimerUpload
                  constructs={[construct]}
                  sx={{ ml: 'auto' }}
                />
                <DownloadButton
                  download={primerTsv}
                  sx={{ mr: 0, ml: 1.5 }}
                  size='small'
                >
                  Download Primers
                </DownloadButton>
              </Box>
            </>
          ) : (
            <Box sx={{ mt: 1.5 }}>
              <Typography sx={sxCentered}>None found.</Typography>
              <PrimerUpload
                sx={sxCentered}
                constructs={[construct]}
              />
            </Box>
          )
        }
      </ContentPaper>
      <ContentPaper>
        <Typography variant='h4' sx={sxCentered}>PCR Fragments</Typography>
        <PCRTable
          constructs={[construct]}
          handleConstructInput={(_, keyList, value) => dispatch(constructActions.handleConstructInput(keyList, value))}
          handleSave={(...args) => dispatch(constructActions.handleSave(...args))}
          sx={{ m: 'auto', maxWidth: 0.9 }}
        />
      </ContentPaper>
      <ContentPaper>
        <Typography variant='h4' sx={sxCentered}>Sequencing</Typography>
        <Sequencing constructs={[construct]} />
      </ContentPaper>
    </Box>
  );
}

export default Construct;
