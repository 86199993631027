import * as extraActions from './sequencing.extraActions';
import * as selectors from './sequencing.selectors';

import { extraReducers, reducers } from './sequencing.reducers';

import { SEQUENCING } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './sequencing.initialState';

const slice = createSlice({
  name: SEQUENCING,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const sequencingSelectors = { ...selectors };
export const sequencingActions = { ...slice.actions, ...extraActions };
export const { reducer: sequencingReducer } = slice;
