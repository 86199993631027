import * as UserService from 'services/UserService';

import { USER } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const fetchUsers = createAsyncThunk(
  `${USER}/fetch`,
  async (_, { rejectWithValue }) => {
    const res = await UserService.getUsers();
    return responseToPayload(res, rejectWithValue);
  },
);
