import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function InfoTable(props) {
  const {
    data,
    tableProps,
    defaultHeaderCellProps,
    defaultBodyCellProps,
    autoSpacing,
    borderTop,
    borderBottom,
  } = props;
  return (
    <Table {...tableProps}>
      <TableBody>
        {
          data.map((row, idx) => (
            <TableRow key={row.key}>
              <TableCell
                align='right'
                {...defaultHeaderCellProps}
                {...row?.headerCellProps}
                sx={mergeSx(
                  { fontWeight: 'fontWeightBold', py: 1, px: 2 },
                  autoSpacing ? {} : { width: 0.5 },
                  defaultHeaderCellProps?.sx,
                  row?.headerCellProps?.sx,
                  {
                    borderTop: idx === 0 && borderTop ? 'solid lightGrey 1px' : 'none',
                    borderBottom: idx + 1 === data.length && !borderBottom ? 'none' : null,
                  },
                )}
              >
                {row.title}
              </TableCell>
              <TableCell
                align='left'
                {...defaultBodyCellProps}
                {...row?.bodyCellProps}
                sx={mergeSx(
                  { py: 1, px: 2 },
                  autoSpacing ? {} : { width: 0.5 },
                  defaultBodyCellProps?.sx,
                  row?.bodyCellProps?.sx,
                  {
                    borderTop: idx === 0 && borderTop ? 'solid lightGrey 1px' : 'none',
                    borderBottom: idx + 1 === data.length && !borderBottom ? 'none' : null,
                  },
                )}
              >
                {row.content}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}

InfoTable.propTypes = {
  /** The data to be displayed in the table. */
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.node,
    content: PropTypes.node,
    headerCellProps: PropTypes.object,
    bodyCellProps: PropTypes.object,
  })).isRequired,

  /** Props for the table. */
  tableProps: PropTypes.object,

  /**
   * Default props for the header cells of the table. NOTE: these are
   * overriden by props.data.headerCellProps in addition to a few styling
   * props that must be fixed (sx.width, sx.borderTop, and sx.borderBottom).
   */
  defaultHeaderCellProps: PropTypes.object,

  /**
   * Default props for the body cells of the table. NOTE: these are
   * overriden by props.data.bodyCellProps in addition to a few styling
   * props that must be fixed (sx.width, sx.borderTop, and sx.borderBottom).
   */
  defaultBodyCellProps: PropTypes.object,

  /**
   * If set to false (default), both the header cells and body cells will take
   * up equal column spacing. If set to true, the columns will be autospaced.
   */
  autoSpacing: PropTypes.bool,

  /** If set to true, include a border on the top of the table. */
  borderTop: PropTypes.bool,

  /** If set to true, include a boarder on the bottom of the table. */
  borderBottom: PropTypes.bool,
};

export default InfoTable;
