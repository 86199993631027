import PropTypes from 'prop-types';
import React from 'react';

class LinearBar extends React.Component {
  static propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    margin: PropTypes.number.isRequired,
  };

  render() {
    const { x, y, width, height, margin } = this.props;
    if (!width) {
      return null;
    }

    const dotSpacing = margin / 4;
    const lineThickness = height / 3;
    const dotRadius = Math.min(dotSpacing / 3, lineThickness);
    return (
      <g transform={`translate(${x},${y})`} width={width}>
        <rect width={width - (2 * margin)} height={lineThickness} x={margin} />
        <rect width={width - (2 * margin)} height={lineThickness} x={margin} y={lineThickness * 2} />
        {
          [1, 2, 3].map((i) => (
            <React.Fragment key={`dots${i}`}>
              <circle r={dotRadius} cx={dotSpacing * i} cy={height / 2} />
              <circle r={dotRadius} cx={width - (dotSpacing * i)} cy={height / 2} />
            </React.Fragment>
          ))
        }
      </g>
    );
  }
}

export default LinearBar;
