import client from 'services';

export async function createProjects(projectNames, abbreviations) {
  const data = {
    project_names: projectNames,
    abbreviations,
  };
  return await client({ method: 'post', url: '/projects', data });
}

export async function getProjects() {
  return await client({ method: 'get', url: '/projects' });
}
