import React, { useEffect, useState } from 'react';
import { materialActions, materialSelectors } from 'store/material';
import { useDispatch, useSelector } from 'react-redux';

import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import BufferBatchDialog from './BufferBatchDialog';
import { Button } from '@acheloisbiosoftware/absui.core';
import DataTable from 'components/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import Error from 'components/Error';
import InfoTable from 'components/InfoTable';
import LoadingPage from 'components/LoadingPage';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function Buffer() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bufferBatch, setBufferBatch] = useState(null);
  const [createNew, setCreateNew] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const buffer = useSelector(materialSelectors.selectBuffer);
  const fetchedBuffer = useSelector(materialSelectors.selectPageFetched);

  useEffect(() => {
    dispatch(materialActions.fetchBuffer(params.bufferId));
    return () => dispatch(materialActions.resetPage());
  }, [params, dispatch]);

  if (!fetchedBuffer) {
    return <LoadingPage />;
  }
  if (!buffer || !buffer.buffer_id) {
    return (
      <Error>Buffer not found!</Error>
    );
  }
  const {
    batch_type,
    name,
    formula,
    molecular_weight,
    storage_cond,
    appearance,
    custom_props,
    buffer_batches,
    preparation,
  } = buffer;
  const infoData = [
    {
      key: 'formula',
      title: 'Formula',
      content: formula,
    },
    {
      key: 'molecular_weight',
      title: 'Molecular Weight',
      content: `${molecular_weight} Da`,
    },
    {
      key: 'storage_cond',
      title: 'Storage Conditions',
      content: storage_cond,
    },
    {
      key: 'appearance',
      title: 'Appearance',
      content: appearance,
    },
    {
      key: 'preparation',
      title: 'Preparation',
      content: (
        <>
          {preparation.split('\n').map((step, idx) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Box key={`prep${idx}`}>{step}</Box>
          ))}
        </>
      ),
    },
    ...custom_props.map(({ title, value }, idx) => ({
      key: `custom_prop${idx}`,
      title,
      content: value,
    })),
    {
      key: 'experiment',
      title: 'Experiment',
      content: (<BatchType type={batch_type} />),
    },
  ];

  const columns = [
    { key: 'batch_no', title: 'Batch #' },
    { key: 'date_prepared', title: 'Date Prepared' },
    { key: 'prepared_by', title: 'Prepared By' },
    { key: 'storage_location', title: 'Storage Location' },
    { key: 'expiration_date', title: 'Expiration Date' },
    { key: 'custom_props', title: 'Additional Properties' },
    { key: 'edit', title: '' },
  ];
  const bufferBatchData = buffer_batches.map((_bufferBatch) => ({
    key: `buffer_batch_${_bufferBatch.buffer_batch_id}`,
    batch_no: _bufferBatch.batch_no,
    date_prepared: formatDate(_bufferBatch.date_prepared),
    prepared_by: _bufferBatch.prepared_by?.name,
    storage_location: _bufferBatch.storage_location,
    expiration_date: formatDate(_bufferBatch.expiration_date),
    custom_props: _bufferBatch.custom_props.map((prop) => (
      <Typography key={prop.title} variant='body2'>
        {prop.title}: {prop.value}
      </Typography>
    )),
    edit: (
      <Button
        icon
        onClick={() => {
          setBufferBatch(_bufferBatch);
          setDialogOpen(true);
          setCreateNew(false);
        }}
      >
        <EditIcon />
      </Button>
    ),
  }));

  const sxPaper = { p: 3, color: 'text.secondary', mb: 2 };
  const sxCentered = { textAlign: 'center', m: 'auto', justifyContent: 'center' };

  return (
    <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, maxWidth: 1000 }}>
      <Paper sx={sxPaper} variant='outlined'>
        <Typography variant='h4' sx={sxCentered}>{name}</Typography>
        <InfoTable
          data={infoData}
          borderTop
          tableProps={{ sx: { ...sxCentered, maxWidth: 500, mt: 1.5 }}}
        />
      </Paper>
      <Paper sx={sxPaper} variant='outlined'>
        <Typography variant='h5' sx={sxCentered}>Buffer Batches</Typography>
        <DataTable
          columns={columns}
          data={bufferBatchData}
          containerProps={{ sx: { m: 1.5 }}}
        />
        <Box sx={{ width: 1, display: 'flex' }}>
          <Button
            variant='contained'
            sx={{ mr: 1.5, ml: 'auto' }}
            onClick={() => setDialogOpen(true)}
          >
            Create new
          </Button>
          <BufferBatchDialog
            buffer={buffer}
            createNew={createNew}
            initData={bufferBatch ? {
              ...bufferBatch,
              date_prepared: bufferBatch?.date_prepared ? moment(bufferBatch.date_prepared) : null,
              expiration_date: bufferBatch?.expiration_date ? moment(bufferBatch.expiration_date) : null,
            } : null}
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setBufferBatch(null);
              setCreateNew(true);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default Buffer;
