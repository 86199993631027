import 'components/SeqView/SeqView.styles.css';

import * as d3 from 'd3';

import AnnotationStem from './AnnotationStem';
import AnnotationText from './AnnotationText';
import { FONT } from 'constants/styles.constants';
import PropTypes from 'prop-types';
import React from 'react';
import { assignSpaceLinear } from 'components/SeqView/SeqView.utils';

const DEFAULT_PROPS = {
  enzymes: [],
  bpRange: [0, 0],
  canvasRange: [0, 0],
  y: 0,
  blockHeight: 16,
  blockSpacing: 8,
  fontFamily: FONT,
  fontSize: 12,
  textPadding: 4,
  stackUp: false,
  onClick: null, // Takes in enzyme name and location of enzyme clicked
  onDoubleClick: null, // Takes in enzyme name and location of enzyme clicked
};

function parseEnzymes(props) {
  const {
    enzymes,
    bpRange,
    canvasRange,
    fontSize,
    fontFamily,
    textPadding,
  } = { ...DEFAULT_PROPS, ...props };
  const x = d3.scaleLinear().domain(bpRange).range(canvasRange);

  // Filter for enzymes in bpRange
  let parsedEnzymes = enzymes.filter(({ location }) => location >= bpRange[0] && location < bpRange[1]);

  // Parse enzymes/locations for relevant data
  parsedEnzymes = parsedEnzymes.map(({ enzyme_name, location }) => ({
    enzymeName: enzyme_name,
    annotationId: `enzymeAnnotation_${enzyme_name.replace(' ', '_')}_${location}`,
    bpLocation: location,
    location: x(location),
    textRow: null,
  }));

  // Assign text rows
  parsedEnzymes = assignSpaceLinear(parsedEnzymes, {
    textKey: 'enzymeName',
    textMiddleKey: 'location',
    textRowKey: 'textRow',
    fontSize,
    fontFamily,
    textPadding,
  });

  return parsedEnzymes;
}

function _getEnyzmesHeight(parsedEnzymes, blockHeight, blockSpacing) {
  if (!parsedEnzymes.length) return 0;
  const maxRow = Math.max(...parsedEnzymes.map((enzyme) => enzyme.textRow)) + 1;
  return maxRow * (blockHeight + blockSpacing);
}

function getEnzymesHeight(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedEnzymes = parseEnzymes(completeProps);
  const { blockHeight, blockSpacing } = completeProps;
  return _getEnyzmesHeight(parsedEnzymes, blockHeight, blockSpacing);
}

class Enzymes extends React.Component {
  static propTypes = {
    // Because of how props are passed around and parsed, eslint cannot detect some are in use
    /* eslint-disable-next-line react/no-unused-prop-types */
    enzymes: PropTypes.arrayOf(PropTypes.shape({
      enzyme_name: PropTypes.string.isRequired,
      location: PropTypes.number.isRequired,
    })).isRequired,
    /* eslint-disable-next-line react/no-unused-prop-types */
    bpRange: PropTypes.arrayOf(PropTypes.number),
    /* eslint-disable-next-line react/no-unused-prop-types */
    canvasRange: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.number,
    blockHeight: PropTypes.number,
    blockSpacing: PropTypes.number,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    textPadding: PropTypes.number,
    stackUp: PropTypes.bool,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
  };

  static defaultProps = DEFAULT_PROPS;

  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(enzyme, trigger) {
    const { annotationId } = enzyme;
    const isHovering = trigger === 'enter';
    d3.selectAll(`.${annotationId}_stem`).classed('hoverStem', isHovering);
    d3.selectAll(`.${annotationId}_text`).classed('hoverText', isHovering);
    d3.selectAll(`.${annotationId}_textContainer`).classed('hoverTextContainer', isHovering);
  }

  render() {
    const {
      y,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      stackUp,
      onClick,
      onDoubleClick,
    } = this.props;
    const parsedEnzymes = parseEnzymes(this.props);
    const height = _getEnyzmesHeight(parsedEnzymes, blockHeight, blockSpacing);

    return (
      <g transform={`translate(0,${y})`}>
        {
          parsedEnzymes.map((enzyme) => (
            <AnnotationStem
              key={`${enzyme.annotationId}_stemKey`}
              enzyme={enzyme}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              stackUpHeight={stackUp ? height : null}
            />
          ))
        }
        {
          parsedEnzymes.map((enzyme) => (
            <AnnotationText
              key={`${enzyme.annotationId}_textKey`}
              enzyme={enzyme}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              fontFamily={fontFamily}
              fontSize={fontSize}
              textPadding={textPadding}
              stackUpHeight={stackUp ? height : null}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
      </g>
    );
  }
}

export { getEnzymesHeight };
export default Enzymes;
