export const CLONING_BATCH = 'CLONING';
export const MAXI_BATCH = 'MAXI';
export const GIGA_BATCH = 'GIGA';
export const BATCH_REPORT = 'REPORT';
export const BATCH_TYPES = [CLONING_BATCH, MAXI_BATCH, GIGA_BATCH];

export const batchTypeDisplay = {
  [CLONING_BATCH]: 'Cloning',
  [MAXI_BATCH]: 'Maxi Prep',
  [GIGA_BATCH]: 'Giga Prep',
};

export const reportStatuses = [
  'Open',
  'Ready to Lock',
  'Locked',
  'Sent for signatures',
  'Signing in Progress',
  'Signatures Completed',
];

export const cloningStatuses = [
  'DESIGN',
  'PRIMER_ORDER',
  'CLONING',
  'SEQUENCING',
  'SEQUENCE_ANALYSIS',
  'MAXI_PREP',
  'DONE',
];

export const cloningSteps = [
  'Design',
  'Primer Order',
  'Cloning',
  'Sequencing',
  'Sequence Analysis',
  'Maxi Prep',
  'Report',
];

export const maxiStatuses = [
  'SEED',
  'HARVEST',
  'EXTRACTION',
  'PURIFICATION',
  'STORAGE',
  'QC',
  'DONE',
];

export const gigaStatuses = [
  'OVERVIEW',
  'Day 0',
  'Day 1',
  'Day 2',
  'Day 3',
  'Day 4',
  'Day 5',
  'DONE',
];

export const batchStatuses = {
  [CLONING_BATCH]: cloningStatuses,
  [MAXI_BATCH]: maxiStatuses,
  [GIGA_BATCH]: gigaStatuses,
};
