import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';

class AnnotationText extends React.Component {
  static propTypes = {
    enzyme: PropTypes.shape({
      enzymeName: PropTypes.string.isRequired,
      annotationId: PropTypes.string.isRequired,
      bpLocation: PropTypes.number.isRequired,
      location: PropTypes.number.isRequired,
      textRow: PropTypes.number.isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    textPadding: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const {
      enzyme,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      stackUpHeight,
      onClick,
      onDoubleClick,
      onHover,
    } = this.props;
    const { enzymeName, annotationId, bpLocation, location, textRow } = enzyme;

    const textWidth = TextMeasurer.getWidth(enzymeName, fontSize, fontFamily);
    const textStart = location - (textWidth / 2);
    const yRow = textRow * (blockHeight + blockSpacing);
    const yRowTop = stackUpHeight ? (
      stackUpHeight - yRow - blockHeight - blockSpacing
    ) : (
      yRow + blockSpacing
    );
    const yText = yRowTop + (blockHeight / 2);

    const clickable = onClick || onDoubleClick;
    return (
      <g
        onClick={onClick ? () => onClick(enzymeName, bpLocation) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(enzymeName, bpLocation) : null}
        style={clickable ? { cursor: 'pointer' } : null}
        onMouseEnter={clickable ? () => onHover(enzyme, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(enzyme, 'leave') : null}
      >
        <rect
          x={textStart - textPadding}
          y={yRowTop}
          rx={textPadding}
          ry={textPadding}
          width={textWidth + (2 * textPadding)}
          height={blockHeight}
          fill='white'
          pointerEvents='visibleFill'
          className={`${annotationId}_textContainer`}
        />
        <text
          x={textStart}
          y={yText}
          fontSize={fontSize}
          fill='black'
          fontFamily={fontFamily}
          alignmentBaseline='central'
          style={disableTextSelect}
          className={`${annotationId}_text`}
        >
          {enzymeName}
        </text>
      </g>
    );
  }
}

export default AnnotationText;
