import { batchActions, batchSelectors } from 'store/batch';

import { ACTION_TABLE_UPDATE } from 'constants/batchActions.constants';
import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class EndotoxinTable extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        construct_code: PropTypes.string.isRequired,
        concentration: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
    handleBatchAction: PropTypes.func.isRequired,
    editMiddleware: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, row) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_TABLE_UPDATE,
        payload: {
          construct_code: row.constructCode,
          concentration: value,
        },
      });
    });
  }

  render() {
    const { lineData, readOnly, appearDisabled, editMiddleware } = this.props;
    const { data } = lineData;
    const columns = [
      { key: 'constructCode', title: 'Construct' },
      {
        key: 'concentration',
        title: 'Endotoxin Concentration',
        editable: true,
        adornment: 'EU/mL',
        inputProps: { type: 'number' },
      },
    ];
    const rows = data.map(({ construct_code, concentration }) => ({
      key: `${construct_code}_endotoxinRow`,
      constructCode: construct_code,
      concentration,
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.handleChange}
        defaultInputProps={{ variant: 'outlined', size: 'medium', onFocus: () => editMiddleware(null) }}
        containerProps={{ sx: { maxWidth: 400, m: 3 }}}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
      />
    );
  }
}

const { handleBatchAction } = batchActions;
const { selectBatchLineData } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
  { handleBatchAction },
)(EndotoxinTable);
