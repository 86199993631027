import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';

import React, { useCallback, useEffect, useRef } from 'react';
import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageEditor from 'tui-image-editor';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';

function ImageAnnotator(props) {
  const { onSave, img, saveStatus } = props;
  const editor = useRef(null);

  useEffect(() => {
    const { clientWidth, clientHeight } = document.documentElement;
    editor.current = new ImageEditor(document.querySelector('#tui-image-editor'), {
      includeUI: {
        loadImage: {
          path: img.source,
          name: img.name,
        },
        theme: {
          'header.display': 'none',
          'loadButton.display': 'none',
          'downloadButton.display': 'none',
        },
        menu: ['crop', 'text', 'shape', 'draw', 'icon'],
        uiSize: {
          width: `${clientWidth - 256}px`,
          height: `${clientHeight - 64}px`,
        },
        menuBarPosition: 'top',
      },
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
      usageStatistics: false,
    });
  }, [img]);

  const handleSave = useCallback(
    async () => {
      const imgData = editor.current.toDataURL();
      return await onSave(img.name, imgData);
    }, [img.name, onSave],
  );

  useEffect(() => {
    const ctrlSHandler = (event) => {
      if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
        event.preventDefault();
        handleSave();
      }
    };

    document.addEventListener('keydown', ctrlSHandler);
    return () => document.removeEventListener('keydown', ctrlSHandler);
  }, [handleSave]);

  const displaySuccess = saveStatus === STATUS_SUCCESS;
  const displayFailure = saveStatus === STATUS_ERROR;
  const displayLoading = saveStatus === STATUS_LOADING;
  return (
    <>
      <Box id='tui-image-editor' />
      <Button
        startIcon={
          displaySuccess ? (
            <CheckBoxIcon sx={{ color: 'success.main' }} />
          ) : (displayFailure ? (
            <ErrorOutlineIcon sx={{ color: 'error.main' }} />
          ) : (
            <SaveIcon />
          ))
        }
        sx={{
          'position': 'absolute',
          'top': 14,
          'right': 14,
          'zIndex': 3,
          '&:disabled': {
            bgcolor: 'background.default',
            color: 'primary.main',
          },
        }}
        onClick={handleSave}
        disabled={displayLoading || displaySuccess || displayFailure}
        loading={displayLoading}
      >
        Save
      </Button>
    </>
  );
}

ImageAnnotator.propTypes = {
  onSave: PropTypes.func.isRequired,
  saveStatus: PropTypes.oneOf([STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS]),
  img: PropTypes.shape({
    name: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImageAnnotator;
