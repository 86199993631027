import CircleText from 'components/SeqView/Circular/CircleText';
import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { getAnnotationPathCircular } from 'components/SeqView/SeqView.utils';

class AnnotationText extends React.Component {
  static propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    feature: PropTypes.shape({
      textRow: PropTypes.number,
      blockRow: PropTypes.number.isRequired,
      thetaStart: PropTypes.number.isRequired,
      thetaEnd: PropTypes.number.isRequired,
      r_text: PropTypes.number.isRequired,
      feature_name: PropTypes.string.isRequired,
      color: PropTypes.shape({
        hex: PropTypes.func.isRequired,
        isDark: PropTypes.func.isRequired,
      }).isRequired,
      location_id: PropTypes.number.isRequired,
      annotationId: PropTypes.string.isRequired,
    }).isRequired,
    fontSize: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    textPadding: PropTypes.number.isRequired,
    blockHeight: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const {
      cx,
      cy,
      feature,
      fontSize,
      fontFamily,
      textPadding,
      blockHeight,
      onClick,
      onDoubleClick,
      onHover,
    } = this.props;
    const {
      textRow,
      blockRow,
      thetaStart,
      thetaEnd,
      r_text,
      feature_name,
      color,
      location_id,
      annotationId,
    } = feature;
    if (textRow === null) return null;

    const thetaMid = (thetaEnd + thetaStart) / 2;
    const textWidth = TextMeasurer.getWidth(feature_name, fontSize, fontFamily) / r_text;
    const pad = textPadding / r_text;
    const textRange = [thetaMid - (textWidth / 2) - pad, thetaMid + (textWidth / 2) + pad];
    const path = getAnnotationPathCircular(cx, cy, r_text, textRange, blockHeight);

    const clickable = onClick || onDoubleClick;
    return (
      <g
        onClick={onClick ? () => onClick(location_id) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(location_id) : null}
        style={clickable ? { cursor: 'pointer' } : {}}
        onMouseEnter={clickable ? () => onHover(feature, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(feature, 'leave') : null}
      >
        <path
          d={path}
          fill={textRow !== blockRow ? 'white' : 'none'}
          stroke={textRow !== blockRow ? (color.hex() === '#FFFFFF' ? '#A3A3A3' : color) : 'none'}
          className={`${annotationId}_textContainer`}
        />
        <CircleText
          id={`${annotationId}_circleTextId`}
          cx={cx} cy={cy}
          r={r_text}
          thetaRange={textRange}
          fontSize={fontSize}
          fontFamily={fontFamily}
          color={color.isDark() && textRow === blockRow ? 'white' : 'black'}
          className={`${annotationId}_text`}
        >
          { feature_name }
        </CircleText>
      </g>
    );
  }
}

export default AnnotationText;
