import * as extraActions from './construct.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_INIT, STATUS_SUCCESS } from 'constants/statuses.constants';

import { deepObjectModify } from 'utils/helpers';
import { primerActions } from 'store/primer';

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const fetchConstruct = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { construct } = action.payload;
    state.construct = construct;
    state.status = STATUS_SUCCESS;
    state.error = null;
  },
  [rejected]: (state, action) => {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

const handleConstructInput = (actionName) => ({
  [actionName]: (state, action) => {
    const { keyList, value } = action.payload;
    deepObjectModify(state.construct, keyList, value, true);
    state.status = STATUS_IDLE;
  },
});

const resetConstruct = (actionName) => ({
  [actionName]: (state) => {
    state.status = STATUS_INIT;
    state.error = null;
  },
});

const updateConstruct = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { construct } = action.payload;
    state.construct = construct;
    state.status = STATUS_SUCCESS;
  },
});

const uploadPrimers = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    if (constructs.length) {
      state.construct = constructs[0];
    }
    state.status = STATUS_SUCCESS;
  },
});

export const reducers = {};
export const extraReducers = {
  ...fetchConstruct(extraActions.fetchConstruct),
  ...handleConstructInput(extraActions._handleConstructInputAction),
  ...resetConstruct(extraActions._resetConstructAction),
  ...updateConstruct(extraActions.updateConstruct),
  ...uploadPrimers(primerActions.uploadPrimers),
};
