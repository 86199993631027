import * as d3 from 'd3';

import { DNA_BASE_COLORS } from 'components/SeqView/SeqView.constants';
import PropTypes from 'prop-types';
import React from 'react';

class Chromatogram extends React.Component {
  static propTypes = {
    read: PropTypes.shape({
      read_idx: PropTypes.number.isRequired,
      read_name: PropTypes.string.isRequired,
    }).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
      chromatograms: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        A: PropTypes.number.isRequired,
        C: PropTypes.number.isRequired,
        G: PropTypes.number.isRequired,
        T: PropTypes.number.isRequired,
      }))),
    })).isRequired,
    canvasRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    height: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  };

  render() {
    const { read, columns, canvasRange, height, y } = this.props;

    const x = d3.scaleLinear().domain([0, columns.length]).range(canvasRange);
    const readIdx = read.read_idx;
    const peaks = columns.map((col) => (
      d3.max(col.chromatograms[readIdx], (segment) => (
        Math.max(segment.A, segment.T, segment.G, segment.C)
      ))
    )).sort();
    const q3Peak = peaks[Math.floor(peaks.length * 3 / 4)];
    const maxSignal = d3.max(peaks, (peak) => (peak > 3 * q3Peak ? 0 : peak));

    const yTrace = d3.scaleLinear()
      .domain([0, maxSignal])
      .range([y + height, y]);

    // Prepare trace data in format for d3
    const traceData = columns.map((col, colIdx) => {
      const segment = col.chromatograms[readIdx];
      if (segment.length) {
        return segment.map((traceCol, segIdx) => ({
          pos: colIdx + ((segIdx + 0.5) / segment.length),
          heights: traceCol,
        }));
      }
      return null;
    }).flat();

    const traces = ['A', 'T', 'G', 'C'].map((base) => {
      const trace = d3.line()
        .curve(d3.curveCardinal)
        .defined((traceCol) => traceCol !== null)
        .x((traceCol) => x(traceCol.pos))
        .y((traceCol) => Math.max(yTrace(traceCol.heights[base]), y));
      return {
        base,
        data: trace(traceData),
      };
    });

    return (
      <>
        {
          traces.map((trace) => (
            <path
              key={`${read.read_name}_${trace.base}_trace`}
              d={trace.data}
              fill='none'
              stroke={DNA_BASE_COLORS[trace.base]}
              strokeWidth={1.5}
            />
          ))
        }
      </>
    );
  }
}

export default Chromatogram;
