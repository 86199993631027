import { BATCH_TYPES } from 'constants/batch.constants';
import BatchPillGroup from './BatchPillGroup';
import BatchPillIndividual from './BatchPillIndividual';
import PropTypes from 'prop-types';
import React from 'react';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class BatchPill extends React.Component {
  static propTypes = {
    /**
     * Either a batch or an array of batches to be displayed. The batch object
     * must contain the batch ID (batch.batch_id) and type (batch.type).
     */
    batches: PropTypes.oneOfType([
      PropTypes.shape({
        batch_id: PropTypes.number.isRequired,
        type: PropTypes.oneOf(BATCH_TYPES).isRequired,
      }),
      PropTypes.arrayOf(PropTypes.shape({
        batch_id: PropTypes.number.isRequired,
        type: PropTypes.oneOf(BATCH_TYPES).isRequired,
      })),
    ]),

    /**
     * If true, renders a group of BatchPills from an array of batches.
     * If false (default), renders a single BatchPill.
     */
    group: PropTypes.bool,

    /**
     * When props.group === true, this is the maximum number of BatchPills to be
     * rendered in their entirety. If there are batches provided that exceed
     * this limit, it will render a stack of condensed batches.
     */
    max: PropTypes.number,

    /** If true, no icon will be rendered within the BatchPill. */
    noIcon: PropTypes.bool,

    /** Sx to be applied to the Pill component. */
    sx: sxPropType,
  };

  static defaultProps = {
    group: false,
    max: 3,
    noIcon: false,
  };

  render() {
    const { batches, group, max, noIcon, sx } = this.props;
    return group ? (
      <BatchPillGroup batches={batches} max={max} noIcon={noIcon} sx={sx} />
    ) : (
      <BatchPillIndividual batch={batches} noIcon={noIcon} sx={sx} />
    );
  }
}

export default BatchPill;
