import * as extraActions from './project.extraActions';

import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';

import { mergeLists } from 'utils/helpers';

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const createProjects = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { projects } = action.payload;
    state.projects = mergeLists(state.projects, projects, 'project_name');
  },
});

const fetchProjects = ({ pending, fulfilled, rejected }) => ({
  [pending]: (state) => {
    state.status = STATUS_LOADING;
    state.error = null;
  },
  [fulfilled]: (state, action) => {
    const { projects } = action.payload;
    state.projects = projects;
    state.status = STATUS_SUCCESS;
    state.error = null;
  },
  [rejected]: (state, action) => {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

export const reducers = {};
export const extraReducers = {
  ...createProjects(extraActions.createProjects),
  ...fetchProjects(extraActions.fetchProjects),
};
