import { FONT } from 'constants/styles.constants';
import PropTypes from 'prop-types';
import React from 'react';
import Tick from './Tick';
import TickLabel from './TickLabel';
import { getAnnotationPathCircular } from 'components/SeqView/SeqView.utils';
import { range } from 'lodash';

const RING_THICKNESS = 2;
const TICKS_EVERY = 500;
const TICK_THICKNESS = 2.5;
const FONT_SIZE = 10;

class Ring extends React.Component {
  static propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    plasmidLength: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  };

  render() {
    const { cx, cy, r, plasmidLength, width } = this.props;
    if (r <= 0) return null;
    const numMarks = Math.ceil(plasmidLength / TICKS_EVERY);
    const r_in = r - (RING_THICKNESS * 2);
    const showTicks = r > width * 2;
    const groupArea = getAnnotationPathCircular(cx, cy, r - (width / 2), [0, 1.99999 * Math.PI], width);
    return (
      <g style={{ cursor: 'text' }}>
        <path d={groupArea} fill='none' pointerEvents='visibleFill' />
        <circle
          cx={cx} cy={cy} r={r}
          stroke='black'
          strokeWidth={RING_THICKNESS}
          fill='none'
        />
        {
          r_in > 0 ? (
            <circle
              cx={cx} cy={cy} r={r_in}
              stroke='black'
              strokeWidth={RING_THICKNESS}
              fill='none'
            />
          ) : null
        }
        {
          showTicks ? range(numMarks).map((idx) => (
            <React.Fragment key={`tick${idx}`}>
              <Tick
                cx={cx} cy={cy}
                r={r}
                bp={idx * TICKS_EVERY}
                plasmidLength={plasmidLength}
                length={width}
                thickness={TICK_THICKNESS}
              />
              <TickLabel
                cx={cx} cy={cy}
                r={r - width + (FONT_SIZE / 2)}
                bp={idx * TICKS_EVERY}
                nextBp={Math.min((idx + 1) * TICKS_EVERY, plasmidLength)}
                plasmidLength={plasmidLength}
                thickness={TICK_THICKNESS}
                fontSize={FONT_SIZE}
                fontFamily={FONT}
              />
            </React.Fragment>
          )) : null
        }
      </g>
    );
  }
}

export default Ring;
