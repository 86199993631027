import { batchActions, batchSelectors } from 'store/batch';

import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class DigestionTable extends React.Component {
  static propTypes = {
    handleConstructInput: PropTypes.func.isRequired,
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      construct_data: PropTypes.shape({
        backbone: PropTypes.shape({
          vector: PropTypes.string.isRequired,
          length: PropTypes.string.isRequired,
          enzyme1: PropTypes.string.isRequired,
          enzyme2: PropTypes.string.isRequired,
          buffer1: PropTypes.string.isRequired,
          buffer2: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })).isRequired,
    newDigestConstructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(value, row, col) {
    const { handleConstructInput } = this.props;
    handleConstructInput(row.constructCode, ['construct_data', 'backbone', col.key], value);
  }

  render() {
    const { constructs, newDigestConstructs } = this.props;
    const includeEnzymeColumns = newDigestConstructs.length > 0;
    const isNewDigest = (row) => newDigestConstructs.map((c) => c.construct_code).includes(row.constructCode);
    let columns = [
      { key: 'vectorNumber', title: '' },
      { key: 'constructCode', title: 'Construct' },
      { key: 'vector', title: 'Vector', editable: isNewDigest },
      { key: 'length', title: 'Vector Length', editable: isNewDigest, adornment: 'bp', inputProps: { type: 'number' }},
    ];
    if (includeEnzymeColumns) {
      columns = [
        ...columns,
        { key: 'enzyme1', title: 'Enzyme I', editable: isNewDigest },
        { key: 'buffer1', title: 'Buffer I', editable: isNewDigest },
        { key: 'enzyme2', title: 'Enzyme II', editable: isNewDigest },
        { key: 'buffer2', title: 'Buffer II', editable: isNewDigest },
      ];
    }
    const rows = constructs.map(({ construct_code, construct_data }, idx) => ({
      key: `${construct_code}_digestionRow`,
      vectorNumber: `V${idx + 1}`,
      constructCode: construct_code,
      vector: construct_data.backbone.vector || '',
      length: construct_data.backbone.length || '',
      enzyme1: construct_data.backbone.enzyme1 || '',
      enzyme2: construct_data.backbone.enzyme2 || '',
      buffer1: construct_data.backbone.buffer1 || '',
      buffer2: construct_data.backbone.buffer2 || '',
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.onInputChange}
        tableProps={{ size: 'small' }}
        containerProps={{
          sx: [
            { maxHeight: 600, overflow: 'auto', m: 'auto' },
            includeEnzymeColumns ? {} : { maxWidth: 500 },
          ],
        }}
      />
    );
  }
}

const { selectConstructs, selectNewDigestConstructs } = batchSelectors;
const { handleConstructInput } = batchActions;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
    newDigestConstructs: selectNewDigestConstructs(state),
  }),
  { handleConstructInput },
)(DigestionTable);
