import Box from '@mui/material/Box';
import Pill from './Pill';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { batchTypeDisplay } from 'constants/batch.constants';
import { pad } from 'utils/formatting.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { useNavigate } from 'react-router-dom';

function BatchPill(props) {
  const { batch, noIcon, sx } = props;
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.stopPropagation();
    navigate(`/batch/${batch.batch_id}`);
  };

  if (!batch || !batch.batch_id) {
    return (<Typography sx={sx}>–</Typography>);
  }
  const paddedBatchNum = pad(batch.batch_id);
  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip
        title={`${batchTypeDisplay[batch.type] || ''} Batch ${paddedBatchNum}`}
        placement='top'
        arrow
      >
        <Box> {/* <Box> necessary for tooltip to function properly */}
          <Pill
            type={noIcon ? null : batch.type}
            onClick={handleClick}
            sx={sx}
          >
            {paddedBatchNum}
          </Pill>
        </Box>
      </Tooltip>
    </Box>
  );
}

BatchPill.propTypes = {
  batch: PropTypes.shape({
    batch_id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.keys(batchTypeDisplay)),
  }),
  noIcon: PropTypes.bool,
  sx: sxPropType,
};

export default BatchPill;
