import {
  LINE_BUFFER_TABLE,
  LINE_CHECKBOX,
  LINE_CHEMICAL_TABLE,
  LINE_ENDOTOXIN_TABLE,
  LINE_EQUIPMENT_TABLE,
  LINE_FLASK_TABLE,
  LINE_GEL_IMAGES,
  LINE_MATERIAL_TABLE,
  LINE_PLAIN_TEXT,
  LINE_PS_TABLE,
  LINE_Q_TABLE,
  LINE_SEQUENCE_CONFIRMATION,
  LINE_SHAKE_TIMER,
  LINE_TRITON_TABLE,
  LINE_YIELD_TABLE,
  ROW_TYPE_COMBINE_ELUTIONS,
} from 'constants/batchLineTypes.constants';

import { deepGet } from '@acheloisbiosoftware/absui.utils';
import { idFindFn } from 'utils/helpers';

const getNeighborLines = (stepId, substepId, lineId, batchData) => {
  const stepIdx = batchData.findIndex(idFindFn(stepId));
  const substepIdx = deepGet(batchData, [idFindFn(stepId), 'substeps']).findIndex(idFindFn(substepId));
  const lineIdx = deepGet(batchData, [idFindFn(stepId), 'substeps', idFindFn(substepId), 'lines']).findIndex(idFindFn(lineId));

  const step = batchData[stepIdx];
  const substep = step.substeps[substepIdx];

  const getIdPosSubstep = (idxPos) => {
    const step_ = batchData[idxPos[0]];
    const substep_ = step_.substeps[idxPos[1]];
    return { step: step_, substep: substep_ };
  };
  const getIdPosLine = (idxPos) => {
    const { step: step_, substep: substep_ } = getIdPosSubstep(idxPos);
    const line = substep_.lines[idxPos[2]];
    return { step: step_, substep: substep_, line };
  };

  let before, after, beforeSubstep, afterSubstep;

  // Find the line before
  if (lineIdx !== 0) {
    before = getIdPosLine([stepIdx, substepIdx, lineIdx - 1]);
  } else if (substepIdx !== 0) {
    const newSubstepIdx = substepIdx - 1;
    before = getIdPosLine([
      stepIdx,
      newSubstepIdx,
      step.substeps[newSubstepIdx].lines.length - 1,
    ]);
  } else if (stepIdx !== 0) {
    const newStepIdx = stepIdx - 1;
    const newSubstepIdx = batchData[newStepIdx].substeps.length - 1;
    before = getIdPosLine([
      newStepIdx,
      newSubstepIdx,
      batchData[newStepIdx].substeps[newSubstepIdx].lines.length - 1,
    ]);
  }

  // Find the substep before
  if (substepIdx !== 0) {
    beforeSubstep = getIdPosSubstep([stepIdx, substepIdx - 1]);
  } else if (stepIdx !== 0) {
    const newStepIdx = stepIdx - 1;
    beforeSubstep = getIdPosSubstep([newStepIdx, batchData[newStepIdx].substeps.length - 1]);
  }

  // Find the line after
  if (lineIdx + 1 < substep.lines.length) {
    after = getIdPosLine([stepIdx, substepIdx, lineIdx + 1]);
  } else if (substepIdx + 1 < step.substeps.length) {
    after = getIdPosLine([stepIdx, substepIdx + 1, 0]);
  } else if (stepIdx + 1 < batchData.length) {
    after = getIdPosLine([stepIdx + 1, 0, 0]);
  }

  // Find the substep after
  if (substepIdx + 1 < step.substeps.length) {
    afterSubstep = getIdPosSubstep([stepIdx, substepIdx + 1]);
  } else if (stepIdx + 1 < batchData.length) {
    afterSubstep = getIdPosSubstep([stepIdx + 1, 0]);
  }

  return { before, after, beforeSubstep, afterSubstep };
};

export const isLineComplete = (stepId, substepId, line, batchData) => {
  const { before } = getNeighborLines(stepId, substepId, line.id, batchData);

  if (line.type === LINE_CHECKBOX) {
    return line.checked;
  } else if (line.type === LINE_FLASK_TABLE) {
    for (const { count } of line.data) {
      if (count === '') return false;
    }
    return true;
  } else if (line.type === LINE_SHAKE_TIMER) {
    return line.started && line.ended;
  } else if (line.type === LINE_TRITON_TABLE) {
    return !before || isLineComplete(before.step.id, before.substep.id, before.line, batchData);
  } else if (line.type === LINE_YIELD_TABLE) {
    for (const { volume, concentration } of line.data) {
      if (volume === '' || concentration === '') return false;
    }
    return true;
  } else if (line.type === LINE_SEQUENCE_CONFIRMATION) {
    for (const { confirmed } of line.data) {
      if (!confirmed) return false;
    }
    return true;
  } else if (line.type === LINE_GEL_IMAGES) {
    return line.data.length > 0;
  } else if (line.type === LINE_ENDOTOXIN_TABLE) {
    for (const { concentration } of line.data) {
      if (concentration === '') return false;
    }
    return true;
  } else if (line.type === LINE_PS_TABLE) {
    for (const { row_type, volume, concentration, ratio } of line.data) {
      if (![ROW_TYPE_COMBINE_ELUTIONS].includes(row_type) && (volume === '' || concentration === '' || ratio === '')) return false;
    }
    return true;
  } else if (line.type === LINE_Q_TABLE) {
    for (const { row_type, volume, concentration, ratio } of line.data) {
      if (row_type === ROW_TYPE_COMBINE_ELUTIONS) {
        if (!(volume && concentration)) return false;
      } else if (volume === '' || concentration === '' || ratio === '') {
        return false;
      }
    }
    return true;
  } else if (line.type === LINE_PLAIN_TEXT) {
    return line.is_read;
  } else if (line.type === LINE_CHEMICAL_TABLE) {
    for (const { chemical_lot_id } of line.data) {
      if (!chemical_lot_id) return false;
    }
    return true;
  } else if (line.type === LINE_MATERIAL_TABLE) {
    for (const { material_lot_id } of line.data) {
      if (!material_lot_id) return false;
    }
    return true;
  } else if (line.type === LINE_BUFFER_TABLE) {
    for (const { buffer_batch_id } of line.data) {
      if (!buffer_batch_id) return false;
    }
    return true;
  } else if (line.type === LINE_EQUIPMENT_TABLE) {
    for (const { acknowledged } of line.data) {
      if (!acknowledged) return false;
    }
    return true;
  }
  return false;
};

const getFirstIncompletedLine = (batchData) => {
  for (let stepIdx = 0; stepIdx < batchData.length; stepIdx++) {
    const step = batchData[stepIdx];
    for (let substepIdx = 0; substepIdx < step.substeps.length; substepIdx++) {
      const substep = step.substeps[substepIdx];
      for (let lineIdx = 0; lineIdx < substep.lines.length; lineIdx++) {
        const line = substep.lines[lineIdx];
        if (!isLineComplete(step.id, substep.id, line, batchData)) {
          return { step, substep, line, stepIdx, substepIdx, lineIdx };
        }
      }
    }
  }
  return null;
};

export const isLineCurrent = (stepId, substepId, line, batchData) => {
  const firstIncomplete = getFirstIncompletedLine(batchData);
  if (!firstIncomplete) {
    return false;
  }

  const { after, before } = getNeighborLines(stepId, substepId, line.id, batchData);

  const isFirstIncomplete = (
    stepId === firstIncomplete.step.id &&
    substepId === firstIncomplete.substep.id &&
    line.id === firstIncomplete.line.id
  );

  // Lines that should only be editable when first incomplete
  const linesFirstIncomplete = [
    LINE_CHECKBOX,
    LINE_PLAIN_TEXT,
    LINE_CHEMICAL_TABLE,
    LINE_MATERIAL_TABLE,
    LINE_BUFFER_TABLE,
    LINE_EQUIPMENT_TABLE,
  ];
  if (linesFirstIncomplete.includes(line.type)) {
    return isFirstIncomplete;
  }

  // Lines strictly linked to the previous step
  if ([LINE_TRITON_TABLE].includes(line.type)) {
    return !before || isLineCurrent(before.step.id, before.substep.id, before.line, batchData);
  }

  // Lines loosely linked to the previous step
  const loosePrevStepLinked = [
    LINE_FLASK_TABLE,
    LINE_SHAKE_TIMER,
    LINE_SEQUENCE_CONFIRMATION,
    LINE_ENDOTOXIN_TABLE,
    LINE_YIELD_TABLE,
    LINE_PS_TABLE,
    LINE_GEL_IMAGES,
    LINE_Q_TABLE,
  ];
  if (loosePrevStepLinked.includes(line.type)) {
    return (
      isFirstIncomplete ||
      (!before || isLineCurrent(before.step.id, before.substep.id, before.line, batchData)) ||
      (
        (!before || isLineComplete(before.step.id, before.substep.id, before.line, batchData)) &&
        (!after || !isLineComplete(after.step.id, after.substep.id, after.line, batchData))
      )
    );
  }

  return null;
};

export const isLineFuture = (stepId, substepId, line, batchData) => (
  !isLineComplete(stepId, substepId, line, batchData) &&
  !isLineCurrent(stepId, substepId, line, batchData)
);

