export const LINE_CHECKBOX = 'CheckboxLine';
export const LINE_FLASK_TABLE = 'FlaskTable';
export const LINE_SHAKE_TIMER = 'ShakeTimer';
export const LINE_TRITON_TABLE = 'TritonTable';
export const LINE_YIELD_TABLE = 'YieldTable';
export const LINE_Q_TABLE = 'QTable';
export const LINE_PS_TABLE = 'PSTable';
export const LINE_PLAIN_TEXT = 'PlainText';
export const LINE_SEQUENCE_CONFIRMATION = 'SequenceConfirmation';
export const LINE_GEL_IMAGES = 'GelImages';
export const LINE_ENDOTOXIN_TABLE = 'EndotoxinTable';
export const LINE_EQUIPMENT_TABLE = 'EquipmentTable';
export const LINE_MATERIAL_TABLE = 'MaterialTable';
export const LINE_CHEMICAL_TABLE = 'ChemicalTable';
export const LINE_BUFFER_TABLE = 'BufferTable';

/* Types of Giga Prep PS & Q Table Rows */
export const ROW_TYPE_ELUTION_PS = 'Elution';
export const ROW_TYPE_WASH = 'Wash';
export const ROW_TYPE_ELUTION_Q_1 = 'Elution Q1';
export const ROW_TYPE_ELUTION_Q_2 = 'Elution Q2';
export const ROW_TYPE_LOAD = 'Loading';
export const ROW_TYPE_FLOWTHROUGH = 'Flowthrough';
export const ROW_TYPE_COMBINE_ELUTIONS = 'Combined Elutions';
export const ROW_TYPE_STRIP = 'Strip';
