import * as d3 from 'd3';

import { BLUE_SELECT, FONT, disableTextSelect } from 'constants/styles.constants';
import Enzymes, { getEnzymesHeight } from 'components/SeqView/Linear/Enzymes';
import Features, { getFeaturesHeight } from 'components/SeqView/Linear/Features';
import Sequence, { getSequenceHeight } from 'components/SeqView/Linear/Sequence';
import { getLocationRange, seqSlice } from 'utils/sequence.utils';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FeatureDialog from 'components/SeqView/FeatureDialog';
import PropTypes from 'prop-types';
import React from 'react';
import ResizableSvg from 'components/SeqView/ResizableSvg';
import { TextMeasurer } from 'utils/visualization.utils';
import { featureTypes } from '../SeqView.constants';
import { range } from 'lodash';

const BODY_WIDTH = 900;
const BASES_PER_LINE = 90;
const BASE_WIDTH = BODY_WIDTH / BASES_PER_LINE;
const SEQ_FONT_SIZE = 14;
const BASE_LABEL_FONT_SIZE = 12;
const ANNOT_FONT_SIZE = 16;
const ANNOT_HEIGHT = 24;
const ANNOT_SPACING = 8;
const SEQ_BAR_HEIGHT = 18;
const SEQ_BAR_STROKE = 2;
const CODON_FONT_SIZE = 10;
const LINE_SPACING = 24;

class SequenceView extends React.Component {
  static propTypes = {
    seqData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      sequence: PropTypes.string.isRequired,
      enzymes: PropTypes.arrayOf(PropTypes.shape({
        enzyme_name: PropTypes.string.isRequired,
        location: PropTypes.number.isRequired,
      })).isRequired,
      feature_locations: PropTypes.arrayOf(PropTypes.shape({
        location_id: PropTypes.number.isRequired,
        feature_id: PropTypes.number.isRequired,
        feature: PropTypes.shape({
          feature_name: PropTypes.string.isRequired,
          feature_data: PropTypes.object.isRequired,
          type: PropTypes.oneOf(featureTypes),
        }).isRequired,
        location_data: PropTypes.shape({
          type: PropTypes.oneOf(['singular', 'compound']),
          strand: PropTypes.oneOf([1, -1]).isRequired,
          location: PropTypes.oneOfType([
            PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            }),
            PropTypes.arrayOf(PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            })),
          ]).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    setSelection: PropTypes.func.isRequired,
    selection: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const name = props.seqData.name.replace(/[^a-zA-Z0-9\-_]/g, '');
    this.containerId = `container_${name}`;
    this.svgId = `svg_${name}`;
    this.dragStartPos = null;

    this.state = {
      featureOpened: null,
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.bpToXY = this.bpToXY.bind(this);
    this.drawSelectionLine = this.drawSelectionLine.bind(this);
    this.drawSelectionBox = this.drawSelectionBox.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.shiftListener = this.shiftListener.bind(this);
    this.handleFeatureClick = this.handleFeatureClick.bind(this);
    this.handleFeatureDoubleClick = this.handleFeatureDoubleClick.bind(this);
    this.handleCodonClick = this.handleCodonClick.bind(this);
    this.handleEnzymeClick = this.handleEnzymeClick.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  handleDrag(svg) {
    const { seqData, setSelection } = this.props;
    const { sequence } = seqData;
    const that = this;

    function getMouseRow(event, strict = false) {
      const yClick = event.y;
      if (strict) {
        const row = that.yLines.findIndex((yRange) => yClick >= yRange.seqStart && yClick < yRange.seqEnd);
        return row === -1 ? null : row;
      }
      const row = that.yLines.findIndex((yRange) => yClick < yRange.end);
      return row === -1 ? that.yLines.length - 1 : row;
    }

    function getMouseColumn(event, strict = false) {
      const canvasLeft = that.margin;
      const canvasRight = that.margin + BODY_WIDTH;
      const xClick = event.x;
      if (strict && (xClick < canvasLeft || xClick > canvasRight)) return null;
      const xToLineBp = d3.scaleLinear()
        .domain([canvasLeft, canvasRight])
        .range([0, BASES_PER_LINE]);
      return Math.min(BASES_PER_LINE, Math.max(0, Math.round(xToLineBp(xClick))));
    }

    function getBpPos(event, strict = false) {
      const yLine = getMouseRow(event, strict);
      const lineBp = getMouseColumn(event, strict);
      if (lineBp === null || yLine === null) return null;
      return Math.min(sequence.length, (yLine * BASES_PER_LINE) + lineBp);
    }

    function getNewSelectionRange(newPos) {
      const { selection } = that.props;
      const newSelection = [that.dragStartPos, newPos];
      // Check if currently wrapping, if it is then continue to wrap, otherwise don't
      return selection[0] > selection[1] ? newSelection : newSelection.sort((a, b) => a - b);
    }

    function dragged(event) {
      const { selection } = that.props;
      if (!selection) return; // No starting point yet
      const bpEnd = getBpPos(event);
      const selectionRange = getNewSelectionRange(bpEnd);
      that.drawSelectionBox(selectionRange);
      that.drawSelectionLine(bpEnd, getMouseColumn(event) === 0);
      setSelection(selectionRange);
    }

    function dragStart(event) {
      if (that.shift) {
        dragged(event);
      } else {
        that.clearSelection();
        const bpStart = getBpPos(event, true);
        if (bpStart !== null) { // BpStart === null if click wasn't on sequence
          that.dragStartPos = bpStart;
          that.drawSelectionLine(bpStart, getMouseColumn(event) === 0);
          setSelection([bpStart, bpStart]);
        }
      }
    }

    function dragEnd(event) {
      const { selection } = that.props;
      that.selectionInput.node().select();
      const bpEnd = getBpPos(event);
      if (!selection) return; // No starting point yet
      const selectionRange = getNewSelectionRange(bpEnd);
      if (selectionRange[0] !== selectionRange[1]) {
        svg.select('#selectionLine').remove();
      }
      that.drawSelectionBox(selectionRange);
      setSelection(selectionRange);
    }

    d3.drag()
      .on('start', dragStart)
      .on('drag', dragged)
      .on('end', dragEnd)(svg);
  }

  bpToXY(bp, endNextLine = false) {
    const lineBpToX = d3.scaleLinear()
      .domain([0, BASES_PER_LINE])
      .range([this.margin, this.margin + BODY_WIDTH]);
    if (!endNextLine && bp % BASES_PER_LINE === 0) {
      return {
        x: lineBpToX(BASES_PER_LINE),
        yRange: this.yLines[(bp / BASES_PER_LINE) - 1],
      };
    }
    const line = Math.floor(bp / BASES_PER_LINE);
    const lineBp = bp - (line * BASES_PER_LINE);
    return {
      x: lineBpToX(lineBp),
      yRange: this.yLines[line],
    };
  }

  drawSelectionLine(bpPos, endNextLine = false) {
    const { x, yRange } = this.bpToXY(bpPos, endNextLine);
    this.svg.select('#selectionLine').remove();
    this.svg.append('line')
      .attr('x1', x)
      .attr('y1', yRange.start)
      .attr('x2', x)
      .attr('y2', yRange.end)
      .attr('stroke', 'black')
      .attr('id', 'selectionLine');
  }

  drawSelectionBox(selectionRange) {
    const { sequence } = this.props.seqData;
    const [bpStart, bpEnd] = selectionRange;
    if (bpStart === bpEnd) return;
    this.selectionInput.text(seqSlice(sequence, selectionRange));
    this.selectionInput.node().select();

    let boxSegments = [];
    if (bpStart > bpEnd) {
      const lineEndsFront = range(0, sequence.length, BASES_PER_LINE).filter((bp) => bp < bpEnd);
      const lineEndsBack = range(0, sequence.length, BASES_PER_LINE).filter((bp) => bp > bpStart);
      boxSegments = [[bpStart, ...lineEndsBack, sequence.length], [...lineEndsFront, bpEnd]];
    } else {
      const lineEnds = range(0, sequence.length, BASES_PER_LINE).filter((bp) => bp > bpStart && bp < bpEnd);
      boxSegments = [[bpStart, ...lineEnds, bpEnd]];
    }
    this.svg.select('#selectionBox').remove();
    this.svg.insert('g', ':first-child').attr('id', 'selectionBox');
    boxSegments.forEach((segment) => {
      for (let i = 0; i < segment.length - 1; i++) {
        const boxStartPos = this.bpToXY(segment[i], true);
        const boxEndPos = this.bpToXY(segment[i + 1]);
        this.svg.select('#selectionBox')
          .append('rect')
          .attr('x', boxStartPos.x)
          .attr('y', boxStartPos.yRange.seqStart)
          .attr('width', boxEndPos.x - boxStartPos.x)
          .attr('height', boxStartPos.yRange.seqEnd - boxStartPos.yRange.seqStart)
          .attr('fill', BLUE_SELECT)
          .attr('fill-opacity', 0.8);
      }
    });
  }

  clearSelection() {
    this.props.setSelection(null);
    this.selectionInput.text('');
    this.svg.select('#selectionLine').remove();
    this.svg.select('#selectionBox').remove();
  }

  handleFeatureClick(location_id) {
    const { seqData, selection, setSelection } = this.props;
    const featureClicked = seqData.feature_locations.find((f) => f.location_id === location_id);
    if (!featureClicked) return;
    const [start, end] = getLocationRange(featureClicked.location_data);
    let selectionRange;
    if (this.shift && selection) {
      selectionRange = [this.dragStartPos, end];
    } else {
      this.clearSelection();
      selectionRange = [start, end];
      this.dragStartPos = start;
    }
    this.drawSelectionBox(selectionRange);
    this.selectionInput.node().select();
    setSelection(selectionRange);
  }

  handleFeatureDoubleClick(location_id) {
    const { seqData } = this.props;
    const featureClicked = seqData.feature_locations.find((f) => f.location_id === location_id);
    if (!featureClicked) return;
    this.setState({ featureOpened: featureClicked });
  }

  handleCodonClick(codonLabel, codonRange) {
    const { selection, setSelection } = this.props;
    const [start, end] = codonRange;
    let selectionRange;
    if (this.shift && selection) {
      selectionRange = [this.dragStartPos, end];
    } else {
      this.clearSelection();
      selectionRange = [start, end];
      this.dragStartPos = start;
    }
    this.drawSelectionBox(selectionRange);
    this.selectionInput.node().select();
    setSelection(selectionRange);
  }

  handleEnzymeClick(enzymeName, location) {
    const { selection, setSelection } = this.props;
    let selectionRange;
    if (this.shift && selection) {
      selectionRange = [this.dragStartPos, location];
      this.drawSelectionBox(selectionRange);
      if (selectionRange[0] === selectionRange[1]) this.drawSelectionLine(location);
    } else {
      this.clearSelection();
      selectionRange = [location, location];
      this.drawSelectionLine(location);
      this.dragStartPos = location;
    }
    setSelection(selectionRange);
  }

  shiftListener(event) {
    if (event.keyCode === 16) { // Shift keyCode = 16
      this.shift = event.type === 'keydown';
    }
  }

  componentDidMount() {
    // FYI: componentDidMount will run BEFORE renderContent, but after render
    this.container = d3.select(`#${this.containerId}`);
    this.svg = d3.select(`#${this.svgId}`);
    this.handleDrag(this.svg);
    document.addEventListener('keydown', this.shiftListener);
    document.addEventListener('keyup', this.shiftListener);

    // Textarea placed behind the canvas, used to "overwrite" copy functionality
    this.selectionInput = this.container.append('textarea')
      .style('position', 'absolute')
      .style('top', 0)
      .style('left', 0)
      .style('z-index', -1);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.shiftListener);
    document.removeEventListener('keyup', this.shiftListener);
  }

  renderContent(width) {
    const { seqData, height, selection } = this.props;

    this.margin = Math.max(32, (width - BODY_WIDTH) / 2);
    let runningHeight = LINE_SPACING;
    const seqLines = seqData.sequence.match(new RegExp(`.{1,${BASES_PER_LINE}}`, 'g'));

    const body = [];
    this.yLines = [];
    seqLines.forEach((bases, idx) => {
      const yLineData = {
        start: runningHeight,
        seqStart: null,
        seqEnd: null,
        end: null,
      };
      const posStart = idx * BASES_PER_LINE;
      const posEnd = Math.min(((idx + 1) * BASES_PER_LINE) - 1, seqData.sequence.length - 1);
      const bpRange = [posStart, posEnd + 1];
      const canvasRange = [this.margin, this.margin + (bases.length * BASE_WIDTH)];
      const enzymeProps = {
        key: `enzyme_annotations(${posStart})`,
        enzymes: seqData.enzymes,
        bpRange,
        canvasRange,
        y: runningHeight,
        blockHeight: ANNOT_HEIGHT,
        blockSpacing: ANNOT_SPACING,
        fontSize: ANNOT_FONT_SIZE,
        stackUp: true,
        onClick: this.handleEnzymeClick,
      };
      body.push(
        <Enzymes {...enzymeProps} />,
      );
      runningHeight += getEnzymesHeight(enzymeProps);
      yLineData.seqStart = runningHeight;

      const sequenceProps = {
        key: `sequence(${posStart})`,
        canvasRange,
        y: runningHeight,
        seq: bases,
        fontSize: SEQ_FONT_SIZE,
        barHeight: SEQ_BAR_HEIGHT,
        barStroke: SEQ_BAR_STROKE,
        showComplement: true,
      };
      body.push(
        <Sequence {...sequenceProps} />,
      );
      const sequenceHeight = getSequenceHeight(sequenceProps);
      const seqBaseLabelX = BODY_WIDTH + (3 / 2 * this.margin) - (TextMeasurer.getWidth(`${posEnd + 1}`, BASE_LABEL_FONT_SIZE, FONT) / 2);
      body.push(
        <text
          key={`seqBaseLabel(${posStart})`}
          x={seqBaseLabelX}
          y={runningHeight + (sequenceHeight / 2)}
          fontFamily={FONT}
          fontSize={BASE_LABEL_FONT_SIZE}
          alignmentBaseline='central'
          style={disableTextSelect}
        >
          {posEnd + 1}
        </text>,
      );
      runningHeight += sequenceHeight;
      yLineData.seqEnd = runningHeight;
      runningHeight += ANNOT_SPACING;

      const featureProps = {
        key: `feature_annotations(${posStart})`,
        features: seqData.feature_locations,
        bpRange,
        canvasRange,
        y: runningHeight,
        blockHeight: ANNOT_HEIGHT,
        blockSpacing: ANNOT_SPACING,
        fontSize: ANNOT_FONT_SIZE,
        showCodons: true,
        sequence: seqData.sequence,
        codonFontSize: CODON_FONT_SIZE,
        onClick: this.handleFeatureClick,
        onDoubleClick: this.handleFeatureDoubleClick,
        onClickCodon: this.handleCodonClick,
      };
      body.push(
        <Features {...featureProps} />,
      );
      runningHeight += getFeaturesHeight(featureProps);
      yLineData.end = runningHeight;
      this.yLines.push(yLineData);
      runningHeight += LINE_SPACING;
    });

    if (selection) {
      this.drawSelectionBox(selection);
    }

    return {
      height: Math.max(runningHeight, height),
      style: width < BODY_WIDTH + (2 * this.margin) ? { minWidth: BODY_WIDTH + (2 * this.margin) } : null,
      render: body,
    };
  }

  render() {
    const { height } = this.props;
    const { featureOpened } = this.state;
    return (
      <Box
        id={this.containerId}
        sx={{ overflow: 'auto', position: 'relative', height }}
      >
        <ClickAwayListener onClickAway={this.clearSelection}>
          <Box>
            <ResizableSvg id={this.svgId}>
              { this.renderContent }
            </ResizableSvg>
            <FeatureDialog feature={featureOpened} onClose={() => this.setState({ featureOpened: null })} />
          </Box>
        </ClickAwayListener>
      </Box>
    );
  }
}

export default SequenceView;
