import axios from 'axios';

let axiosClient, queue, queueReleased;

export const initServices = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_FLASK_BASE_URL,
    timeout: 30000,
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  axiosClient = axios.create(defaultOptions);
  axiosClient.interceptors.response.use(
    (res) => res.data,
    (error) => ({ error }),
  );

  queue = [];
  queueReleased = false;
};

const client = (config) => {
  if (queueReleased) return axiosClient.request(config);
  return new Promise((resolve, reject) => {
    queue.push({ resolve, reject, config });
  });
};

export const releaseQueue = (authToken) => {
  axiosClient.defaults.headers.Authorization = `Bearer ${authToken.accessToken}`;
  queueReleased = true;
  queue.forEach(({ resolve, reject, config }) => client(config).then(resolve).catch(reject));
  queue = [];
};

export default client;
