import * as extraActions from './primer.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING } from 'constants/statuses.constants';

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const clearWarnings = (state) => {
  state.warnings = [];
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const uploadPrimers = ({ pending, fulfilled, rejected }) => ({
  [pending]: (state) => {
    state.warnings = [];
    state.status = STATUS_LOADING;
    state.error = null;
  },
  [fulfilled]: (state, action) => {
    const { upload_warnings } = action.payload;
    state.warnings = upload_warnings;
    state.status = STATUS_IDLE;
    state.error = null;
  },
  [rejected]: (state, action) => {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

export const reducers = { clearWarnings };
export const extraReducers = {
  ...uploadPrimers(extraActions.uploadPrimers),
};
