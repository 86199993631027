import Box from '@mui/material/Box';
import Cell from '../Cell';
import { ChecklistItem } from 'components/Checklist';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { round } from 'utils/formatting.utils';

const EmText = ({ red, green, bold, ...props }) => (
  <Box
    component='span'
    sx={[
      bold ? { fontWeight: 'fontWeightBold' } : {},
      red ? { color: 'common.red' } : {},
      green ? { color: 'common.green' } : {},
    ]}
    {...props}
  />
);
EmText.propTypes = {
  red: PropTypes.bool,
  green: PropTypes.bool,
  bold: PropTypes.bool,
};

const PLACEHOLDER = '–';
const calcMass = (length, pmol) => round(length * pmol * 650 / 1000, 0.1);
const calcVol = (mass, concentration) => round(mass / concentration, 0.5, 'ceil');

class AssemblyTableRow extends React.Component {
  static propTypes = {
    construct: PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      construct_data: PropTypes.shape({
        backbone: PropTypes.shape({
          vector: PropTypes.string.isRequired,
          length: PropTypes.string.isRequired,
          concentration: PropTypes.string.isRequired,
        }).isRequired,
        pcr_fragments: PropTypes.arrayOf(PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          length: PropTypes.string.isRequired,
          concentration: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    }).isRequired,
    idx: PropTypes.number.isRequired,
    fragmentNumStart: PropTypes.number.isRequired,
  };

  render() {
    const { construct, idx, fragmentNumStart } = this.props;
    const { construct_code, construct_data } = construct;
    const { backbone, pcr_fragments } = construct_data;

    const backboneMass = backbone.length ? calcMass(backbone.length, 0.01) : PLACEHOLDER;
    const backboneVolume = backbone.length && backbone.concentration ? calcVol(backboneMass, backbone.concentration) : PLACEHOLDER;

    const fragmentMasses = pcr_fragments.map((f) => (f.length ? calcMass(f.length, 0.02) : PLACEHOLDER));
    const fragmentVolumes = pcr_fragments.map((f, i) => (f.length && f.concentration ? calcVol(fragmentMasses[i], f.concentration) : PLACEHOLDER));

    const h20Volume = (
      backboneVolume !== PLACEHOLDER &&
      !fragmentVolumes.filter((v) => v === PLACEHOLDER).length
    ) ? (
        6.25 - backboneVolume - fragmentVolumes.reduce((v1, v2) => v1 + v2, 0)
      ) : PLACEHOLDER;

    return (
      <TableRow key={`${construct_code}_assemblyRow`} hover>
        <Cell padding='checkbox'>
          <ChecklistItem
            dense
            content={(<Typography variant='body2'>{idx + 1}</Typography>)}
          />
        </Cell>
        <Cell>{construct_code}</Cell>
        <Cell>
          <Typography variant='body2' noWrap>
            <EmText bold>[V{idx + 1}] {backbone.vector || '–'} Digest</EmText> ({backbone.length || '–'} bp)
          </Typography>
          <Typography variant='body2' noWrap>
            0.01 pmol = <EmText green>{backboneMass} ng</EmText>
          </Typography>
          <Typography variant='body2' noWrap>
            <EmText red>{backboneVolume} μL</EmText>
          </Typography>
        </Cell>
        <Cell>
          {
            pcr_fragments.map((fragment, fragmentIdx) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <React.Fragment key={`${fragment.fragment}${fragmentIdx}`}>
                <Typography variant='body2' noWrap>
                  <EmText bold>[{fragmentNumStart + fragmentIdx + 1}] {fragment.fragment || '–'}</EmText> ({fragment.length || '–'} bp)
                </Typography>
                <Typography variant='body2' noWrap>
                  0.02 pmol = <EmText green>{fragmentMasses[fragmentIdx]} ng</EmText>
                </Typography>
                <Typography variant='body2' noWrap>
                  <EmText red>{fragmentVolumes[fragmentIdx]} μL</EmText>
                </Typography>
                {
                  fragmentIdx + 1 !== pcr_fragments.length ? (
                    <Divider sx={{ my: 1 }} />
                  ) : null
                }
              </React.Fragment>
            ))
          }
        </Cell>
        <Cell><EmText red>6.25 μL</EmText></Cell>
        <Cell><EmText red>{h20Volume} μL</EmText></Cell>
      </TableRow>
    );
  }
}

export default AssemblyTableRow;
