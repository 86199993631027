import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { withOktaAuth } from '@okta/okta-react';

class Login extends React.Component {
  static propTypes = {
    // The variant of the Button component to use.
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),

    // The color of the Button component to use.
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),

    // sx to be applied to the Button component.
    sx: sxPropType,

    // Internal authentication prop.
    oktaAuth: PropTypes.shape({
      signInWithRedirect: PropTypes.func.isRequired,
      fetchSession: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    variant: 'text',
    color: 'inherit',
  };

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  async login() {
    const { oktaAuth } = this.props;
    await oktaAuth.signInWithRedirect({ originalUri: '/inventory' });
    await oktaAuth.fetchSession();
  }

  render() {
    const { variant, color, sx } = this.props;
    return (
      <Button
        sx={sx}
        variant={variant}
        color={color}
        onClick={this.login}
      >
        Login
      </Button>
    );
  }
}

export default withOktaAuth(Login);
