import { CLONING_BATCH, cloningStatuses } from 'constants/batch.constants';

import BatchPill from 'components/BatchPill';
import { FMT_DATE_READABLE } from 'constants/dateFormats.constants';
import InfoTable from 'components/InfoTable';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import StatusPill from 'components/StatusPill';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';

class ConstructInfo extends React.Component {
  static propTypes = {
    construct: PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      insert: PropTypes.string,
      project: PropTypes.shape({
        project_name: PropTypes.string,
      }),
      construct_data: PropTypes.shape({
        backbone: PropTypes.shape({
          vector: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          tag: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      length: PropTypes.number.isRequired,
      status: PropTypes.oneOf(cloningStatuses),
      batches: PropTypes.arrayOf(PropTypes.object),
      created_at: PropTypes.string.isRequired,
      author: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { construct } = this.props;
    const { construct_code, insert, project, construct_data, length, status, batches, created_at, author } = construct;
    const { backbone } = construct_data;
    const data = [
      { key: 'project', title: 'Project', content: (project && project.project_name) || '–' },
      {
        key: 'backbone',
        title: 'Backbone',
        content: `${backbone.vector}${backbone.type && (` (${backbone.type} / ${backbone.tag})`)}` || '–',
      },
      { key: 'insert', title: 'Insert', content: insert || '–' },
      { key: 'length', title: 'Length', content: `${length} bp` },
      { key: 'status', title: 'Status', content: (<StatusPill status={status} type={CLONING_BATCH} />) },
      { key: 'batches',
        title: 'Batches',
        content: (
          batches.length ? (
            <BatchPill group max={5} batches={batches} />
          ) : '–'
        ) },
      { key: 'created', title: 'Date Created', content: formatDate(created_at, FMT_DATE_READABLE) },
      { key: 'designed', title: 'Designed By', content: (author && author.name) || '–' },
    ];
    return (
      <Paper
        sx={{ flexGrow: 1, p: 3, color: 'text.secondary' }}
        variant='outlined'
      >
        <Typography variant='h3' sx={{ mb: 1.5, textAlign: 'center' }}>{construct_code}</Typography>
        <InfoTable
          tableProps={{ sx: { maxWidth: 0.6, m: 'auto' }}}
          data={data}
          borderTop
          borderBottom
        />
      </Paper>
    );
  }
}

export default ConstructInfo;
