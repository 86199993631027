import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

function PCRProtocol(props) {
  const { maxFragmentLength } = props;
  const timeAt72 = Math.ceil(maxFragmentLength / 1000 * 15) || '–';
  const steps = [
    { display: (<>10 sec @98ºC</>) },
    { display: (<>35 cycles:</>) },
    { display: (<>0 sec @98ºC</>), indented: true },
    { display: (<>5 sec @annealing temp</>), indented: true },
    {
      display: (<>72ºC for 15 sec per kb <Box component='span' sx={{ color: 'common.red' }}>({timeAt72} sec)</Box></>),
      indented: true,
    },
    { display: (<>1 min @72ºC</>) },
    { display: (<>4ºC infinite hold</>) },
  ];
  return (
    <Box>
      <Typography
        sx={{
          color: 'common.red',
          textDecoration: 'underline',
          mb: 0.5,
        }}
      >
        PCR Cycler Protocol
      </Typography>
      <Checklist
        dense
        items={steps.map((step) => ({
          content: step.display,
          sx: step.indented ? { ml: 3 } : null,
        }))}
      />
    </Box>
  );
}

PCRProtocol.propTypes = {
  maxFragmentLength: PropTypes.number.isRequired,
};

export default PCRProtocol;
