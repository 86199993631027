import Line from './Line';
import PropTypes from 'prop-types';
import React from 'react';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

class BatchSubstep extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    batchLineMeta: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isCurrentStep: PropTypes.bool.isRequired,
      isCompleted: PropTypes.bool.isRequired,
      isFutureStep: PropTypes.bool.isRequired,
    })).isRequired,
  };

  render() {
    const { step, substep, batchLineMeta } = this.props;
    return batchLineMeta.map((meta) => (
      <Line
        key={`${step}/${substep}/${meta.id}`}
        step={step}
        substep={substep}
        line={meta.id}
        isCompleted={meta.isCompleted}
        isCurrentStep={meta.isCurrentStep}
        isFutureStep={meta.isFutureStep}
      />
    ));
  }
}

const { selectBatctLineMeta } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    batchLineMeta: selectBatctLineMeta(state, ownProps.step, ownProps.substep),
  }),
)(BatchSubstep);
