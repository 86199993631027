import { GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import { batchActions, batchSelectors } from 'store/batch';

import { ACTION_TABLE_UPDATE } from 'constants/batchActions.constants';
import { Button } from '@acheloisbiosoftware/absui.core';
import DataTable from 'components/DataTable';
import OpacityIcon from '@mui/icons-material/Opacity';
import PropTypes from 'prop-types';
import React from 'react';
import { calcYield } from 'utils/biology.utils';
import { connect } from 'react-redux';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { round } from 'utils/formatting.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const DESIRED_CONCENTRATION = 500; // ng/µL
const TOLERANCE = 20; // ng/µL

function calcVolToAdd(volume, concentration) {
  if (!volume || !concentration) return '';
  const v = Number(volume);
  const c = Number(concentration);
  return c >= DESIRED_CONCENTRATION + TOLERANCE ? round((c * v / DESIRED_CONCENTRATION) - v) : '';
}

class YieldTable extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    batchType: PropTypes.oneOf([MAXI_BATCH, GIGA_BATCH]).isRequired,
    handleBatchAction: PropTypes.func.isRequired,
    editMiddleware: PropTypes.func.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        construct_code: PropTypes.string.isRequired,
        volume: PropTypes.string.isRequired,
        concentration: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    const { lineData } = props;
    const { data } = lineData;
    this.state = {
      toAdd: Object.fromEntries(
        data.map(({ construct_code, volume, concentration }) => [
          construct_code,
          calcVolToAdd(volume, concentration),
        ]),
      ),
      yieldVals: Object.fromEntries(
        data.map((yieldObj) => [
          yieldObj.construct_code,
          calcYield(yieldObj),
        ]),
      ),
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  handleInput(value, row, col) {
    const { handleBatchAction, step, substep, line, lineData, editMiddleware } = this.props;
    const { toAdd, yieldVals } = this.state;
    const { constructCode } = row;
    const { key } = col;
    const constructYield = lineData.data.find((e) => e.construct_code === constructCode);

    editMiddleware(() => {
      if (key === 'concentration') {
        const newToAdd = {
          ...toAdd,
          [constructCode]: calcVolToAdd(constructYield.volume, value),
        };
        const newYieldVals = {
          ...yieldVals,
          [constructCode]: calcYield({ concentration: value, volume: constructYield.volume }),
        };
        this.setState({ toAdd: newToAdd, yieldVals: newYieldVals });
        handleBatchAction({
          step_id: step,
          substep_id: substep,
          line_id: line,
          action: ACTION_TABLE_UPDATE,
          payload: {
            construct_code: constructCode,
            [key]: value,
          },
        });
      } else if (key === 'volume') {
        const newToAdd = {
          ...toAdd,
          [constructCode]: calcVolToAdd(value, constructYield.concentration),
        };
        const newYieldVals = {
          ...yieldVals,
          [constructCode]: calcYield({ concentration: constructYield.concentration, volume: value }),
        };
        this.setState({ toAdd: newToAdd, yieldVals: newYieldVals });
        handleBatchAction({
          step_id: step,
          substep_id: substep,
          line_id: line,
          action: ACTION_TABLE_UPDATE,
          payload: {
            construct_code: constructCode,
            [key]: value,
          },
        });
      } else if (key === 'toAdd') {
        const newToAdd = {
          ...toAdd,
          [constructCode]: value,
        };
        this.setState({ toAdd: newToAdd });
      }
    });
  }

  handleAdd(row) {
    const { handleBatchAction, step, substep, line, lineData } = this.props;
    const { toAdd } = this.state;
    const { constructCode } = row;
    const constructYield = lineData.data.find((e) => e.construct_code === constructCode);

    this.setState({ toAdd: { ...toAdd, [constructCode]: '' }});
    handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_TABLE_UPDATE,
      payload: {
        construct_code: constructCode,
        volume: `${Number(constructYield.volume) + Number(toAdd[constructCode])}`,
        concentration: '',
      },
    });
  }

  render() {
    const { sx, lineData, batchType, readOnly, appearDisabled, editMiddleware } = this.props;
    const { data } = lineData;
    const { toAdd, yieldVals } = this.state;
    const volumeUnit = batchType === MAXI_BATCH ? 'µL' : 'mL';
    const massUnit = batchType === MAXI_BATCH ? 'µg' : 'mg';
    const columns = [
      { key: 'constructCode', title: 'Construct', cellProps: { sx: { minWidth: 80 }}},
      { key: 'concentration', title: 'Concentration', editable: true, adornment: 'ng/µL', cellProps: { sx: { minWidth: 140 }}},
      { key: 'volume', title: 'Current Volume', editable: true, adornment: volumeUnit, cellProps: { sx: { minWidth: 120 }}},
      {
        key: 'toAdd',
        title: 'Volume to Add',
        editable: true,
        cellProps: { sx: { minWidth: 200 }},
        adornment: (row) => (!readOnly ? (
          <>
            {volumeUnit}
            <Button
              variant='text'
              onClick={() => editMiddleware(() => this.handleAdd(row))}
              sx={{ ...(appearDisabled ? { color: 'text.disabled' } : {}), ml: 1, minWidth: 82 }}
              startIcon={<OpacityIcon />}
              disabled={!row.toAdd}
            >
              Added
            </Button>
          </>
        ) : volumeUnit),
        inputProps: {
          sx: { maxWidth: 240 },
        },
      },
      { key: 'yield', title: 'Yield', cellProps: { sx: { minWidth: 90 }}, adornment: massUnit },
    ];

    const rows = data.map((constructYield) => ({
      key: `${constructYield.construct_code}_yieldRow`,
      constructCode: constructYield.construct_code,
      volume: constructYield.volume,
      concentration: constructYield.concentration,
      toAdd: toAdd[constructYield.construct_code] || (readOnly ? '–' : ''),
      yield: yieldVals[constructYield.construct_code],
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.handleInput}
        defaultInputProps={{ type: 'number', variant: 'outlined', onFocus: () => editMiddleware(null) }}
        defaultCellProps={{ sx: { height: 40 }}}
        containerProps={{ sx: mergeSx({ m: 3, maxWidth: 1200 }, sx) }}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
      />
    );
  }
}

const { selectBatchLineData, selectBatchType } = batchSelectors;
const { handleBatchAction } = batchActions;

export default connect(
  (state, ownProps) => ({
    batchType: selectBatchType(state),
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
  { handleBatchAction },
)(YieldTable);
