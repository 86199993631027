import Assembly from './Assembly';
import Box from '@mui/material/Box';
import CloningAccordion from './CloningAccordion';
import Digestion from './Digestion';
import Gel from './Gel';
import NotesRTE from '../NotesRTE';
import Pcr from './PCR';
import PropTypes from 'prop-types';
import React from 'react';
import StepperButtons from 'views/Batch/StepperButtons';
import TransformationProtocol from './TransformationProtocol';
import { backboneActions } from 'store/backbone';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';

const STEP_NAME = 'CLONING';

class CloningStep extends React.Component {
  static propTypes = {
    onNextStep: PropTypes.func.isRequired,
    fetchBackboneData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // BackboneData will be needed in multiple of this components children
    this.props.fetchBackboneData();
  }

  render() {
    const { onNextStep } = this.props;
    return (
      <Box sx={{ p: 3 }}>
        <CloningAccordion title='Digestion'>
          <Digestion />
        </CloningAccordion>

        <CloningAccordion title='PCR'>
          <Pcr />
        </CloningAccordion>

        <CloningAccordion title='Gel'>
          <Gel />
        </CloningAccordion>

        <CloningAccordion title='Assembly'>
          <Assembly />
        </CloningAccordion>

        <CloningAccordion title='Transformation' lastChild>
          <TransformationProtocol />
        </CloningAccordion>

        <NotesRTE
          step={STEP_NAME}
          sx={{ mt: 3 }}
        />
        <StepperButtons position='right' onNextStep={onNextStep} />
      </Box>
    );
  }
}

const { fetchBackboneData } = backboneActions;
const { onNextStep } = batchStepperActions;

export default connect(
  null, { fetchBackboneData, onNextStep },
)(CloningStep);
