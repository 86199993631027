import { CLONING_BATCH, GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import React, { useEffect } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { useDispatch, useSelector } from 'react-redux';

import CloningBatch from './Cloning';
import Error from 'components/Error';
import LoadingPage from 'components/LoadingPage';
import MaxiGigaBatch from './MaxiGiga';
import { useParams } from 'react-router-dom';

function Batch() {
  const { selectBatchId, selectBatchType, selectFetched } = batchSelectors;
  const dispatch = useDispatch();
  const params = useParams();
  const batchId = useSelector(selectBatchId);
  const batchType = useSelector(selectBatchType);
  const batchFetched = useSelector(selectFetched);

  useEffect(() => {
    dispatch(batchActions.fetchBatch(params.batchId));
    return () => dispatch(batchActions.resetBatch());
  }, [params, dispatch]);

  useEffect(() => {
    dispatch(batchActions.clearUpdates());
    return () => dispatch(batchActions.clearUpdates());
  }, [batchId, dispatch]);

  if (!batchFetched) {
    return <LoadingPage />;
  }
  if (!batchId) {
    return (
      <Error>Batch not found!</Error>
    );
  }
  const batchRouter = {
    [CLONING_BATCH]: (<CloningBatch />),
    [MAXI_BATCH]: (<MaxiGigaBatch />),
    [GIGA_BATCH]: (<MaxiGigaBatch />),
  };
  return batchRouter[batchType] || (<Error>Batch type not supported: {batchType}</Error>);
}

export default Batch;
