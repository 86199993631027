import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import React from 'react';

class TextEditor extends React.Component {
  static propTypes = {
    /** The content of the TextEditor. */
    content: PropTypes.any,

    /**
     * Callback fired when changes occur in the editor. The function will
     * receive the new content as the sole parameter.
     */
    handleChange: PropTypes.func,

    /** Height of the text editor. */
    height: PropTypes.number,

    /** Width of the text editor. */
    width: PropTypes.number,

    /**
     * Callback fired when CMD+S is pressed to save. Receives the editor object
     * as a parameter.
     * IMPORTANT NOTE: This function remains constant after the first rendering
     * of the editor. That means that if a hook function is passed to it, only
     * the first rendering of that function is used! If you need to extract the
     * content, you can find it at editor.targetElm.value, where editor is the
     * parameter consumed by the callback.
     */
    handleSave: PropTypes.func,
  };

  static defaultProps = {
    height: 240,
    width: 720,
  };

  render() {
    const { height, width, content, handleChange, handleSave } = this.props;
    return (
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        init={{
          height,
          width,
          menu: {
            file: { title: 'File', items: '' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
            view: { title: 'View', items: '' },
            insert: { title: 'Insert', items: 'inserttable | charmap hr insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | removeformat' },
            tools: { title: 'Tools', items: '' },
            table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
            help: { title: 'Help', items: 'help' },
          },
          insertdatetime_formats: ['%Y%m%d', '%d-%b-%Y', '%I:%M %p'],
          table_toolbar: 'tableprops tablecellprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells tablesplitcells',
          content_style: 'body {font-family: helvetica, arial, sans-serif; } p {margin: 0px}',
          save_onsavecallback: handleSave,
        }}
        toolbar='undo redo | fontsize bold italic backcolor | bullist numlist table align | fullscreen'
        plugins='lists advlist autolink charmap fullscreen help insertdatetime save searchreplace table wordcount visualblocks'
        onEditorChange={handleChange}
        value={content}
      />
    );
  }
}

export default TextEditor;
