import Cell from './Cell';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import { lighten } from '@mui/material/styles';

function BodyRow(props) {
  const { children, selected, columns, onClick, onHover, onSelect } = props;
  return (
    <TableRow
      hover
      selected={selected}
      onMouseOver={onHover ? () => onHover(children) : null}
      onClick={onClick}
      sx={{
        'cursor': 'pointer',
        '&.Mui-selected, &.Mui-selected:hover': {
          bgcolor: (theme) => lighten(theme.palette.primary.light, 0.3),
        },
      }}
    >
      {
        onSelect ? (
          <Cell padding='checkbox' onClick={onSelect}>
            <Checkbox checked={selected} sx={{ '&.Mui-checked': { color: 'action.active' }}} />
          </Cell>
        ) : null
      }
      {
        columns.map((col) => (
          <Cell key={col.id}>{col.getDisplay(children)}</Cell>
        ))
      }
    </TableRow>
  );
}

BodyRow.propTypes = {
  children: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    getDisplay: PropTypes.func.isRequired,
  })).isRequired,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onSelect: PropTypes.func,
};

BodyRow.defaultProps = {
  selected: false,
};

export function LoadingRow(props) {
  const { columns, selectableRows } = props;
  return (
    <TableRow>
      {
        selectableRows ? (
          <Cell padding='checkbox'>
            <Checkbox disabled />
          </Cell>
        ) : null
      }
      {
        columns.map((col) => (
          <Cell key={col.id}><Skeleton variant='rounded' height={32} /></Cell>
        ))
      }
    </TableRow>
  );
}

LoadingRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectableRows: PropTypes.bool,
};

export default BodyRow;
