import * as extraActions from './backbone.extraActions';
import * as selectors from './backbone.selectors';

import { extraReducers, reducers } from './backbone.reducers';

import { BACKBONE } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './backbone.initialState';

const slice = createSlice({
  name: BACKBONE,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const backboneSelectors = { ...selectors };
export const backboneActions = { ...slice.actions, ...extraActions };
export const { reducer: backboneReducer } = slice;
