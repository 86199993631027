import BatchPillIndividual from './BatchPillIndividual';
import Box from '@mui/material/Box';
import Pill from './Pill';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { batchTypeDisplay } from 'constants/batch.constants';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { pad } from 'utils/formatting.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function BatchPillGroup(props) {
  const { batches, max, noIcon, sx } = props;
  const displayed = batches.length > max ? batches.slice(0, max - 1) : batches.slice(0, max);
  const hidden = batches.length > max ? batches.slice(max - 1) : [];
  const tooltipTitle = (
    <Box sx={{ whiteSpace: 'pre-line' }}>
      {hidden.map((batch) => `${batchTypeDisplay[batch.type]} Batch ${pad(batch.batch_id)}`).join('\n')}
    </Box>
  );
  return (
    <Box sx={mergeSx(sx, { display: 'flex' })}>
      {
        displayed.map((batch) => (
          <BatchPillIndividual
            key={batch.batch_id}
            batch={batch}
            noIcon={noIcon}
            sx={{ mx: 0.25 }}
          />
        ))
      }
      {
        hidden.length ? (
          <Tooltip title={tooltipTitle} placement='top' arrow>
            <Box sx={{ position: 'relative' }}>
              <Pill sx={{ position: 'absolute', left: 6 }} />
              <Pill sx={{ mx: 0.25, position: 'absolute' }}>
                +{hidden.length}
              </Pill>
              <Pill sx={{ mx: 0.25, width: 24 + 8 }} />
            </Box>
          </Tooltip>
        ) : null
      }
    </Box>
  );
}

BatchPillGroup.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape({
    batch_id: PropTypes.number.isRequired,
  })).isRequired,
  max: PropTypes.number,
  noIcon: PropTypes.bool,
  sx: sxPropType,
};

BatchPillGroup.defaultProps = {
  max: 3,
  noIcon: false,
};

export default BatchPillGroup;
