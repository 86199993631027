import * as d3 from 'd3';

import Features, { getFeaturesHeight } from 'components/SeqView/Linear/Features';
import ReadsSequence, { getReadsSequenceHeight } from 'components/SeqView/Linear/ReadsSequence';
import Sequence, { getSequenceHeight } from 'components/SeqView/Linear/Sequence';

import { BLUE_SELECT } from 'constants/styles.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const FONT_SIZE = 16;
const ANNOT_BLOCK_HEIGHT = 30;
const ANNOT_BLOCK_SPACING = 12;
const CODON_FONT_SIZE = 13;
const BASE_WIDTH = 13;
const MARGIN = { top: 0, right: 20, bottom: 10, left: 20 };

class AlignmentScrolling extends React.Component {
  static propTypes = {
    alignment: PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      ref_sequence: PropTypes.string.isRequired,
      features: PropTypes.array.isRequired,
      alignment_data: PropTypes.shape({
        read_meta_data: PropTypes.arrayOf(PropTypes.shape({
          read_name: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        columns: PropTypes.arrayOf(PropTypes.shape({
          ref_idx: PropTypes.number.isRequired,
          ref: PropTypes.string,
          reads: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    setScrollingBox: PropTypes.func.isRequired,
    showChromatograms: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { alignment } = props;
    const { construct_code, clone, created_at } = alignment;
    const timeStamp = (new Date(created_at)).getTime();
    this.containerId = `alignment_scrolling-wrapper_${construct_code}-${clone}_${timeStamp}`;
    this.svgId = `alignment_scrolling-svg_${construct_code}-${clone}_${timeStamp}`;

    this.handleDrag = this.handleDrag.bind(this);
    this.setScroll = this.setScroll.bind(this);
  }

  handleDrag(svg) {
    let xStart = null;
    this.selection = this.svg.append('g');
    const that = this;

    function getX(event) {
      const { columns } = that.props.alignment.alignment_data;
      const xClick = event.x + that.container.node().scrollLeft;
      const col = Math.min(Math.max(Math.round(that.xToCol(xClick)), 0), columns.length);
      return that.x(col);
    }

    function getTextSeq(x1, x2) {
      const { alignment } = that.props;
      const { columns, read_meta_data } = alignment.alignment_data;
      const leftCol = Math.round(that.xToCol(Math.min(x1, x2)));
      const rightCol = Math.round(that.xToCol(Math.max(x1, x2)));
      const selectedCols = columns.slice(leftCol, rightCol);
      const refSeq = selectedCols.map((col) => col.ref || '-').join('');
      const readSeqs = read_meta_data.map((read) => ({
        readName: read.read_name,
        sequence: '',
      }));
      selectedCols.forEach((col) => {
        const { reads } = col;
        reads.forEach((read, idx) => {
          if (!read || !read.trim()) {
            readSeqs[idx].sequence += '-';
          } else {
            readSeqs[idx].sequence += read;
          }
        });
      });
      return [
        `>${alignment.construct_code}-${alignment.clone}\n${refSeq}\n`,
        ...readSeqs.map((r) => `>${r.readName}\n${r.sequence}\n`),
      ].join('\n');
    }

    function dragStart(event) {
      that.selection.selectAll('*').remove();
      xStart = getX(event);
      that.selection.append('line')
        .attr('x1', xStart)
        .attr('y1', 0)
        .attr('x2', xStart)
        .attr('y2', that.height)
        .attr('stroke', 'black')
        .attr('id', 'startLine');
    }

    function dragged(event) {
      const x = getX(event);
      that.selectionInput.text(getTextSeq(xStart, x));
      that.selectionInput.node().select();

      that.selection.select('#selectionBox').remove();
      that.selection.select('#endLine').remove();
      that.selection.append('rect')
        .attr('x', Math.min(xStart, x))
        .attr('y', 0)
        .attr('width', Math.abs(x - xStart))
        .attr('height', that.height)
        .attr('fill', BLUE_SELECT)
        .attr('fill-opacity', 0.3)
        .attr('id', 'selectionBox');
      that.selection.append('line')
        .attr('x1', x)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', that.height)
        .attr('stroke', 'black')
        .attr('id', 'endLine');
    }

    function dragEnd(event) {
      const x = getX(event);
      if (x === xStart) {
        that.selection.selectAll('*').remove();
        return;
      }
      that.selection.select('#endLine').remove();
      that.selection.append('line')
        .attr('x1', x)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', that.height)
        .attr('stroke', 'black')
        .attr('id', 'endLine');
    }

    return d3.drag()
      .on('start', dragStart)
      .on('drag', dragged)
      .on('end', dragEnd)(svg);
  }

  setScroll() {
    const { setScrollingBox, alignment } = this.props;
    const { columns } = alignment.alignment_data;
    const left = this.container.node().scrollLeft;
    const right = left + this.container.node().getBoundingClientRect().width;
    const colLeft = Math.min(Math.max(Math.floor(this.xToCol(left)), 0), columns.length - 1);
    const colRight = Math.min(Math.max(Math.floor(this.xToCol(right)), 0), columns.length - 1);
    const bpLeft = columns[colLeft].ref_idx;
    const bpRight = columns[colRight].ref_idx;
    setScrollingBox([bpLeft, bpRight]);
  }

  componentDidMount() {
    this.container = d3.select(`#${this.containerId}`);
    this.svg = d3.select(`#${this.svgId}`);
    this.handleDrag(this.svg);
    this.setScroll();
    this.container.node().addEventListener('scroll', this.setScroll);

    // This is placed behind the canvas, used to "overwrite" copy functionality.
    // Eslint thinks this is unused because it is accessed through "that." later.
    /* eslint-disable-next-line react/no-unused-class-component-methods */
    this.selectionInput = this.container.append('textarea')
      .style('position', 'absolute')
      .style('top', 0)
      .style('z-index', -1);
  }

  componentDidUpdate(prevProps) {
    // Remove selection if turning on/off chromatograms
    if (
      prevProps.showChromatograms !== this.props.showChromatograms &&
      this.selection
    ) {
      this.selection.selectAll('*').remove();
    }
  }

  render() {
    const { alignment, showChromatograms } = this.props;
    const { features, alignment_data, ref_sequence } = alignment;
    const { columns } = alignment_data;

    const bodyWidth = columns.length * BASE_WIDTH;
    const bpRange = [columns[0].ref_idx, columns[columns.length - 1].ref_idx + 1];
    const idxRange = [0, columns.length];
    const canvasRange = [MARGIN.left, bodyWidth + MARGIN.left];

    this.x = d3.scaleLinear().domain(idxRange).range(canvasRange);
    this.xToCol = d3.scaleLinear().domain(canvasRange).range(idxRange);

    const bpToCanvas = (bp) => {
      // Try to find bp in column ref_idxs exactly
      const colIdx = columns.findIndex((col) => col.ref_idx === bp);
      if (colIdx !== -1) return this.x(colIdx);

      // Find the first column with a ref_idx that's just a little bigger than bp
      const biggerColIdx = columns.findIndex((col) => col.ref_idx && col.ref_idx > bp);
      if (biggerColIdx === -1) return this.x(columns.length);

      // Find the first column with a ref_idx that's just a little smaller than bp
      const smallerColIdx = columns.length - 1 - columns.slice().reverse().findIndex((col) => col.ref_idx && col.ref_idx < bp);
      if (smallerColIdx === columns.length) return this.x(0);

      const refIdxStart = columns[smallerColIdx].ref_idx;
      const refIdxEnd = columns[biggerColIdx].ref_idx;
      const xBetween = d3.scaleLinear()
        .domain([refIdxStart, refIdxEnd])
        .range([this.x(smallerColIdx), this.x(biggerColIdx)]);
      return xBetween(bp);
    };

    const featuresProps = {
      features,
      bpRange,
      canvasRange,
      blockHeight: ANNOT_BLOCK_HEIGHT,
      blockSpacing: ANNOT_BLOCK_SPACING,
      fontSize: FONT_SIZE,
      stackUp: true,
      showCodons: true,
      sequence: ref_sequence,
      codonFontSize: CODON_FONT_SIZE,
      bpToCanvas,
    };
    const annotationHeight = getFeaturesHeight(featuresProps) + ANNOT_BLOCK_SPACING;

    const refSequenceProps = {
      canvasRange,
      y: MARGIN.top + annotationHeight,
      seq: columns.map((col) => col.ref || '\u00A0').join(''),
      fontSize: FONT_SIZE,
      upper: true,
    };
    const refHeight = getSequenceHeight(refSequenceProps);

    const readsSequenceProps = {
      alignmentData: alignment_data,
      containerId: this.containerId,
      y: MARGIN.top + annotationHeight + refHeight,
      canvasRange,
      fontSize: FONT_SIZE,
      chromatogramHeight: 100,
      showChromatograms,
    };
    const readHeight = getReadsSequenceHeight(readsSequenceProps);

    this.height = MARGIN.top + annotationHeight + refHeight + readHeight + MARGIN.bottom;

    return (
      <Box
        id={this.containerId}
        sx={{ overflow: 'auto', position: 'relative', height: this.height }}
      >
        <Box
          component='svg'
          id={this.svgId}
          width={MARGIN.left + bodyWidth + MARGIN.right}
          height={this.height}
          sx={{ position: 'absolute' }}
        >
          <Features {...featuresProps} />
          <Sequence {...refSequenceProps} />
          <ReadsSequence {...readsSequenceProps} />
        </Box>
      </Box>
    );
  }
}

export default AlignmentScrolling;
