import * as AutoclonerService from 'services/AutocloneService';
import * as ConstructService from 'services/ConstructService';

import { STATUS_ERROR, STATUS_IDLE } from 'constants/statuses.constants';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectConstructs, selectDesignStatus } from './design.selectors';

import { DESIGN } from 'constants/store.constants';
import { debounce } from 'lodash';
import { getAbbrev } from 'utils/biology.utils';
import { responseToPayload } from 'utils/store.utils';

export const autocloneDisplay = createAsyncThunk(
  `${DESIGN}/autoclone/display`,
  async (data, { rejectWithValue }) => {
    const res = await AutoclonerService.autocloneDisplay(data);
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ construct: response }),
    );
  },
);

export const autocloneMhc = createAsyncThunk(
  `${DESIGN}/autoclone/mhc`,
  async (data, { rejectWithValue }) => {
    const res = await AutoclonerService.autocloneMhc(data);
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ construct: response }),
    );
  },
);

export const uploadConstructs = createAsyncThunk(
  `${DESIGN}/upload_constructs`,
  async (data, { rejectWithValue }) => {
    const res = await ConstructService.uploadConstructs(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const validateSubmission = createAsyncThunk(
  `${DESIGN}/validate_submission`,
  async (constructCodes, { rejectWithValue }) => {
    const res = await AutoclonerService.validateSubmission(constructCodes);
    const reduceUnknownConstructs = (acc, curr, idx) => {
      if (curr.project) {
        return acc;
      }
      return acc.add(getAbbrev(constructCodes[idx]));
    };
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => {
        const unknownAbbrevs = Array.from(response.validations.reduce(reduceUnknownConstructs, new Set()));
        const existingConstructCodes = res.validations.map((v) => v.construct && v.construct.construct_code);
        return { unknownAbbrevs, existingConstructCodes };
      },
    );
  },
);

export const fetchInsertSuggestions = createAsyncThunk(
  `${DESIGN}/fetch_insert_suggestions`,
  async ({ insertName, species }, { rejectWithValue }) => {
    const res = await AutoclonerService.getInsertSuggestions(insertName, species);
    return responseToPayload(res, rejectWithValue);
  },
);

export const _clearInsertSuggestions = createAction(`${DESIGN}/clear_insert_suggestions`);
export const getInsertSuggestionsFactory = () => {
  let debouncer;
  const debounceTime = 500; // in ms
  return (insertName, species) => (dispatch) => {
    clearTimeout(debouncer);
    if (!insertName) {
      return dispatch(_clearInsertSuggestions());
    }
    debouncer = setTimeout(() => dispatch(fetchInsertSuggestions({ insertName, species })), debounceTime);
    return debouncer;
  };
};

export const updateConstructs = createAsyncThunk(
  `${DESIGN}/update`,
  async (constructs, { rejectWithValue }) => {
    const res = await ConstructService.updateConstructs(constructs);
    return responseToPayload(res, rejectWithValue);
  },
);

const save = (dispatch, getState) => {
  const state = getState();
  if (![STATUS_IDLE, STATUS_ERROR].includes(selectDesignStatus(state))) return;
  dispatch(updateConstructs(selectConstructs(state)));
};

const autosave = debounce(save, 10000);

export const handleSave = () => save;

export const _handleConstructInputAction = createAction(`${DESIGN}/handleConstructInput`);
export const handleConstructInput = (constructCode, keyList, value) => (dispatch, getState) => {
  dispatch(_handleConstructInputAction({ constructCode, keyList, value }));
  autosave(dispatch, getState);
};

export const _resetDesignAction = createAction(`${DESIGN}/resetDesign`);
export const resetDesign = () => (dispatch) => {
  autosave.cancel();
  dispatch(_resetDesignAction());
};
