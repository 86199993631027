import { batchActions, batchSelectors } from 'store/batch';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Signature from './Signature';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';

const NameText = (props) => (
  <Box component='span' sx={{ fontWeight: 'fontWeightBold', color: 'info.main' }} {...props} />
);

const DateText = (props) => (
  <Box component='span' sx={{ fontSize: 'fontSize', color: 'text.secondary' }} {...props} />
);

class SignatureSection extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    fetchBatchReportSigns: PropTypes.func.isRequired,
    reportData: PropTypes.shape({
      u_id: PropTypes.string,
      locked_status: PropTypes.bool.isRequired,
      locked: PropTypes.shape({
        by: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        at: PropTypes.string,
      }).isRequired,
    }).isRequired,
    reportSigns: PropTypes.arrayOf(PropTypes.shape({
      batch_sign_id: PropTypes.number.isRequired,
      sign_type: PropTypes.oneOf(['Sign', 'Comment']),
      u_id: PropTypes.string,
      sign: PropTypes.shape({
        by: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        at: PropTypes.string,
      }).isRequired,
    })).isRequired,
  };

  componentDidMount() {
    const { batchId, fetchBatchReportSigns } = this.props;
    fetchBatchReportSigns(batchId);
  }

  render() {
    const { reportData, reportSigns } = this.props;
    return (
      <Box>
        {
          reportData.locked_status ? (
            <>
              <Divider variant='middle' sx={{ m: 2 }} />
              <Typography>
                Report locked by <NameText>{reportData.locked.by.name}</NameText> <DateText>on {formatDate(reportData.locked.at, FMT_DATE_TIME)}</DateText>
              </Typography>
            </>
          ) : null
        }
        <List>
          {
            reportSigns.map((item) => (
              item.sign_type === 'Sign' ? (
                <Signature key={`signature_${item.batch_sign_id}`} signature={item} />
              ) : (
                <ListItem key={`comment_${item.batch_sign_id}`} alignItems='flex-start'>
                  <ListItemAvatar>
                    <Avatar>{item.sign.by.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Typography>
                        <NameText>{item.sign.by.name}</NameText> commented <DateText>on {formatDate(item.sign.at, FMT_DATE_TIME)}</DateText>
                      </Typography>
                    )}
                    secondary={item.sign_text}
                  />
                </ListItem>
              )
            ))
          }
        </List>
        {
          reportData.u_id ? (
            <Typography variant='h6'>Unique ID: {reportData.u_id}</Typography>
          ) : null
        }
      </Box>
    );
  }
}

const { selectBatchId, selectBatchReportData, selectBatchReportSigns } = batchSelectors;
const { fetchBatchReportSigns } = batchActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    reportData: selectBatchReportData(state),
    reportSigns: selectBatchReportSigns(state),
  }),
  { fetchBatchReportSigns },
)(SignatureSection);
