import PropTypes from 'prop-types';
import React from 'react';

class Bar extends React.Component {
  static propTypes = {
    canvasRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    seq: PropTypes.string.isRequired,
    barHeight: PropTypes.number.isRequired,
    barStroke: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  };

  render() {
    const { canvasRange, seq, barHeight, barStroke, y } = this.props;
    const width = canvasRange[1] - canvasRange[0];
    const maxTickHeight = barHeight / 3;
    const baseWidth = width / seq.length;
    const points = seq.split('').map((_, idx) => {
      const xStart = idx * baseWidth;
      const xEnd = (idx + 1) * baseWidth;
      const xMid = ((idx + 0.5) * baseWidth) - (barStroke / 2);
      if (idx % 10 === 0) {
        return [
          [xStart, 0],
          [xMid, 0],
          [xMid, maxTickHeight],
          [xMid, -maxTickHeight],
          [xMid, 0],
          [xEnd, 0],
        ];
      }
      return [
        [xStart, 0],
        [xMid, 0],
        [xMid, maxTickHeight / 2],
        [xMid, -maxTickHeight / 2],
        [xMid, 0],
        [xEnd, 0],
      ];
    }).reduce((acc, curr) => [...curr, ...acc], []);
    const starting = `M ${points[0][0]} ${points[0][1]}`;
    const path = starting + points.slice(1).map(([x_, y_]) => `L ${x_} ${y_}`).join(' ');
    return (
      <>
        <path
          d={path}
          stroke='black'
          strokeWidth={1}
          transform={`translate(${canvasRange[0]},${y})`}
        />
        <line
          x1={canvasRange[0]} y1={0}
          x2={canvasRange[1]} y2={0}
          stroke='black'
          strokeWidth={barStroke}
          transform={`translate(0,${y})`}
        />
      </>
    );
  }
}

export default Bar;
