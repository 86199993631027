import * as FiguresService from 'services/FiguresService';

import { Button, Image, UploadButton } from '@acheloisbiosoftware/absui.core';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import CellsOnlySelection from './CellsOnlySelection';
import ConditionRow from './ConditionRow';
import IconButton from '@mui/material/IconButton';
import Papa from 'papaparse';
import Paper from '@mui/material/Paper';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

const generateInputRow = () => ({
  dataSelection: {
    csvFile: 0,
    colRange: [],
    rowRange: [],
    data: [],
  },
  infectionParticle: '',
  neutralizingParticle: '',
  stockConcentration: '',
  highestVolume: 30,
  dilutionFactor: 2,
  wellVolume: 120,
});

class FigureAutomation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: [],
      conditionData: [
        generateInputRow(),
      ],
      cellsOnlyData: {
        csvFile: 0,
        colRange: [],
        rowRange: [],
        data: [],
      },
      figure: {},
      ic50s: [],
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDataSelection = this.handleDataSelection.bind(this);
    this.addInputRow = this.addInputRow.bind(this);
    this.deleteInputRow = this.deleteInputRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUpload(event) {
    const { files } = event.target;

    if (!files) {
      console.error('Error selecting files');
      return;
    }

    this.setState({ conditionData: [generateInputRow()]});

    for (let i = 0; i < files.length; i++) {
      Papa.parse(files[i], {
        dynamicTyping: true,
        complete: (results) => {
          const csv = results.data.filter((row) => row.join('').trim().length > 0);
          const { csvData } = this.state;
          csvData.push({ file: files[i].name, data: csv });
          this.setState({ csvData });
        },
      });
    }
  }

  handleInputChange(event, idx) {
    const { value, name } = event.target;
    const { conditionData } = this.state;
    conditionData[idx][name] = value;
    this.setState({ conditionData });
  }

  handleDataSelection(dataSelection, conditionIdx) {
    const { conditionData } = this.state;
    conditionData[conditionIdx].dataSelection = dataSelection;
    this.setState({ conditionData });
  }

  addInputRow() {
    const { conditionData } = this.state;
    conditionData.push(generateInputRow());
    this.setState({ conditionData });
  }

  deleteInputRow(rowIdx) {
    const { conditionData } = this.state;
    conditionData.splice(rowIdx, 1);
    if (conditionData.length === 0) {
      conditionData.push(generateInputRow());
    }
    this.setState({ conditionData });
  }

  async handleSubmit() {
    const { conditionData, cellsOnlyData } = this.state;
    const data = { conditionData, cellsOnlyData };
    const res = await FiguresService.generatePNAFigure(data);
    if (!res.error) {
      const { image, ic50s } = res;
      const figure = {
        source: `data:image/png;base64,${image}`,
        name: 'PNA figure',
      };
      this.setState({ figure, ic50s });
    }
  }

  render() {
    const { csvData, conditionData, cellsOnlyData, figure, ic50s } = this.state;
    const tableHeaders = [
      'Plate Position',
      'Infection Particle',
      'Neutralizing Particle',
      'Stock Concentration',
      'Highest Condition Volume (μL)',
      'Dilution Factor',
      'Well Volume (μL)',
      '',
    ];
    return (
      <Box sx={{ mt: '64px', py: 2, px: 4 }}>
        <Box sx={{ display: 'flex', m: 1.5 }}>
          <Box sx={{ m: 'auto', textAlign: 'center', color: 'warning.main' }}>
            <WarningIcon sx={{ fontSize: 64 }} />
            <Box sx={{ textAlign: 'center' }}>This page is still a work in progress. You may encounter bugs.</Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <UploadButton
            variant='contained'
            color={csvData.length ? 'inherit' : 'primary'}
            onChange={this.handleUpload}
            sx={{ m: 'auto', mb: 1.5 }}
            accept='.csv'
            multiple
          >
            Upload Data
          </UploadButton>
        </Box>
        {
          csvData.length ? (
            <Paper sx={{ overflow: 'auto', p: 3, textAlign: 'center' }}>
              <CellsOnlySelection
                csvData={csvData}
                cellsOnlyData={cellsOnlyData}
                handleCellsOnlySelection={(newCellsOnlyData) => this.setState({ cellsOnlyData: newCellsOnlyData })}
              />
              <Table size='small' sx={{ minWidth: 900 }}>
                <TableHead>
                  <TableRow>
                    {
                      tableHeaders.map((header) => (
                        <TableCell key={header} align='center' sx={{ fontWeight: 'fontWeightBold' }}>{header}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    conditionData.map((inputRow, idx) => (
                      <ConditionRow
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`condition_${idx}`}
                        csvData={csvData}
                        inputData={inputRow}
                        handleChange={(e) => this.handleInputChange(e, idx)}
                        handleDataSelection={(dataSelection) => this.handleDataSelection(dataSelection, idx)}
                        handleDelete={() => this.deleteInputRow(idx)}
                      />
                    ))
                  }
                  <TableRow>
                    {
                      tableHeaders.map((header, idx) => (
                        <TableCell key={header} align='right'>
                          {
                            idx + 1 === tableHeaders.length ? (
                              <IconButton size='small' onClick={this.addInputRow}><AddCircleIcon /></IconButton>
                            ) : null
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableBody>
              </Table>
              <Button
                sx={{ mt: 1.5 }}
                variant='contained'
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Paper>
          ) : null
        }
        {
          figure.source ? (
            <Paper sx={{ p: 3, textAlign: 'center', mt: 3 }}>
              <Image
                src={figure.source}
                alt={figure.name}
                sx={{ maxWidth: 1 }}
              />
            </Paper>
          ) : null
        }
        {
          ic50s.length ? (
            <Paper sx={{ p: 3, textAlign: 'center', mt: 3 }}>
              {
                ic50s.map((ic50, idx) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <Typography key={`ic50_${idx}`}>{ic50[0]}: {ic50[1]} M</Typography>
                ))
              }
            </Paper>
          ) : null
        }
      </Box>
    );
  }
}

export default FigureAutomation;
