import PropTypes from 'prop-types';
import React from 'react';
import { getAnnotationPointsLinear } from 'components/SeqView/SeqView.utils';

class AnnotationBlock extends React.Component {
  static propTypes = {
    readHeader: PropTypes.shape({
      read_idx: PropTypes.number.isRequired,
      canvasStart: PropTypes.number.isRequired,
      canvasEnd: PropTypes.number.isRequired,
      blockRow: PropTypes.number.isRequired,
      strand: PropTypes.oneOf([1, -1]).isRequired,
      mismatchPos: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
  };

  render() {
    const { readHeader, blockHeight, blockSpacing } = this.props;
    const { canvasStart, canvasEnd, blockRow, strand, mismatchPos } = readHeader;
    const y = (blockRow + 1) * (blockHeight + blockSpacing);
    const points = getAnnotationPointsLinear(canvasStart, y, canvasEnd - canvasStart, blockHeight, 'CDS', strand);
    return (
      <g>
        <polygon
          points={points.map((p) => p.join(',')).join(' ')}
          fill='green'
        />
        {
          mismatchPos.map((mismatch, idx) => (
            <line
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${readHeader.read_idx}_mismatch_${idx}`}
              x1={mismatch} y1={y}
              x2={mismatch} y2={y - blockHeight}
              stroke='white'
              strokeWidth={1}
            />
          ))
        }
        <polygon
          points={points.map((p) => p.join(',')).join(' ')}
          fillOpacity={0}
          stroke='black'
        />
      </g>
    );
  }
}

export default AnnotationBlock;
