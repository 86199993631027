import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Divider from '@mui/material/Divider';
import MapIcon from '@mui/icons-material/Map';
import MapView from './MapView';
import PropTypes from 'prop-types';
import React from 'react';
import ReorderIcon from '@mui/icons-material/Reorder';
import SequenceView from './SequenceView';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { featureTypes } from './SeqView.constants';

const VIEWS = [
  { id: 'map', name: 'Map', icon: <MapIcon /> },
  { id: 'sequence', name: 'Sequence', icon: <ReorderIcon /> },
  { id: 'alignments', name: 'Alignments', icon: <CompareArrowsIcon /> },
];

class SeqView extends React.Component {
  static propTypes = {
    /** Object containing all data necessary for sequence visualizations. */
    seqData: PropTypes.shape({

      /** Name of the construct/sequence. */
      name: PropTypes.string.isRequired,

      /** DNA sequence (must contain only valid DNA-characters). */
      sequence: PropTypes.string.isRequired,

      /** Array of objects representing enzymes to be displayed. */
      enzymes: PropTypes.arrayOf(PropTypes.shape({

        /** Name of enzyme. */
        enzyme_name: PropTypes.string.isRequired,

        /** Location of enzyme (bp) */
        location: PropTypes.number.isRequired,
      })).isRequired,

      /** Array of objects representing feature annotations to be displayed. */
      feature_locations: PropTypes.arrayOf(PropTypes.shape({

        /** ID of the feature. */
        feature_id: PropTypes.number.isRequired,

        /** ID of the feature location */
        location_id: PropTypes.number.isRequired,

        /** Object with data about the feature annotation. */
        feature: PropTypes.shape({

          /** Name of the feature annotation. */
          feature_name: PropTypes.string.isRequired,

          /** Length of the feature (in bp). */
          length: PropTypes.number.isRequired,

          /** Type of the feature annotation. */
          type: PropTypes.oneOf(featureTypes).isRequired,

          /** Object of other data pertaining to the feature annotation. */
          feature_data: PropTypes.shape({

            /** Object of annotation qualifiers. */
            qualifiers: PropTypes.objectOf(PropTypes.node),
          }).isRequired,
        }).isRequired,

        /** Object with data about the location of the feature annotation. */
        location_data: PropTypes.shape({

          /**
           * Type of location: 'singular' if a simple [start, end] location,
           * 'compound' if loops around origin (i.e. [start, total_length],
           * [0, end] where end < start).
           */
          type: PropTypes.oneOf(['singular', 'compound']),

          /**
           * Which strand the annotation is on (1 for forward strand, -1 for
           * reverse strand)
           */
          strand: PropTypes.oneOf([1, -1]).isRequired,

          /**
           * The start and end bp locations. If type is 'singular', this will be
           * an object { start, end }. If type is 'compound', this will be an
           * array of length 2 of such objects.
           */
          location: PropTypes.oneOfType([
            PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            }),
            PropTypes.arrayOf(PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            })),
          ]).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      view: VIEWS[0],
      selection: null,
    };

    this.setSelection = this.setSelection.bind(this);
  }

  setSelection(newSelection) {
    this.setState({ selection: newSelection });
  }

  render() {
    const { seqData } = this.props;
    const { name, sequence } = seqData;
    const { view, selection } = this.state;
    const height = 650;

    return (
      <Box sx={{ bgcolor: 'background.default' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='caption' sx={{ ml: 1.5, mr: 'auto' }}>
            {name} ({sequence.length} bp)
          </Typography>
          <Typography variant='caption' sx={{ ml: 'auto', mr: 1.5 }}>
            {
              selection ? (
                `Selected: ${selection[0]}...${selection[1]} = ${selection[1] - selection[0] + (selection[0] > selection[1] ? sequence.length : 0)} bp`
              ) : '\u00A0'
            }
          </Typography>
        </Box>
        {
          view.id === 'map' ? (
            <MapView
              seqData={seqData}
              height={height}
              selection={selection}
              setSelection={this.setSelection}
            />
          ) : view.id === 'sequence' ? (
            <SequenceView
              seqData={seqData}
              height={height}
              selection={selection}
              setSelection={this.setSelection}
            />
          ) : view.id === 'alignments' ? (
            <Box sx={{ width: 1, height, display: 'flex' }}>
              <Box sx={{ m: 'auto', color: 'warning.main', textAlign: 'center' }}>
                <WarningIcon sx={{ fontSize: 100, margin: 'auto' }} />
                <Box sx={{ typography: 'h6' }}>Alignment view coming soon!</Box>
              </Box>
            </Box>
          ) : null
        }
        <Divider />
        <BottomNavigation
          value={view.id}
          onChange={(_, newValue) => this.setState({ view: VIEWS.find((v) => v.id === newValue) })}
          showLabels
        >
          {
            VIEWS.map((v) => (
              <BottomNavigationAction
                key={v.id}
                label={v.name}
                value={v.id}
                icon={v.icon}
              />
            ))
          }
        </BottomNavigation>
      </Box>
    );
  }
}

export default SeqView;
