import { deepGet } from '@acheloisbiosoftware/absui.utils';

export async function responseToPayload(response, rejectWithValue, successPayload, errorPayload) {
  let errPayload = errorPayload;
  let succPayload = successPayload;
  if (typeof(errPayload) === 'undefined' || errPayload === null) {
    errPayload = () => ({});
  }
  if (typeof(succPayload) === 'undefined' || succPayload === null) {
    succPayload = (res) => ({ ...res });
  }

  if (response.error) {
    const errorData = deepGet(response, ['error', 'response', 'data']);
    if (errorData) {
      return rejectWithValue({
        error: errorData.message,
        ...await errPayload(response),
      });
    }
    return rejectWithValue({
      error: response.error.toString(),
      ...await errPayload(response),
    });
  } else if (!response.api_status) {
    return rejectWithValue({
      error: response.message,
      ...await errPayload(response),
    });
  }
  return await succPayload(response);
}
