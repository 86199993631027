import { Autocomplete, Button, Dialog } from '@acheloisbiosoftware/absui.core';
import React, { useEffect, useState } from 'react';
import { designActions, designSelectors } from 'store/design';
import { projectActions, projectSelectors } from 'store/project';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { unwrapResult } from '@reduxjs/toolkit';

function DesignSubmission(props) {
  const {
    constructCodes,
    updateConstructCode,
    validateInputs,
    handleSubmit: handleSubmitCallback,
  } = props;
  const [abbrevMapping, setAbbrevMapping] = useState({});
  const [overwriteWarnings, setOverwriteWarnings] = useState([]);
  const dispatch = useDispatch();
  const existingProjects = useSelector(projectSelectors.selectProjectNames);
  const loading = useSelector(designSelectors.selectDesignIsLoading);

  useEffect(() => {
    dispatch(projectActions.fetchProjects());
  }, [dispatch]);

  const handleSubmit = async () => {
    if (!validateInputs()) return;
    const res = await dispatch(designActions.validateSubmission(constructCodes));

    try {
      const { unknownAbbrevs, existingConstructCodes } = unwrapResult(res);
      if (unknownAbbrevs.length) {
        setAbbrevMapping(Object.fromEntries(unknownAbbrevs.map((abbrev) => [abbrev, null])));
        return;
      }
      const overwriteConstructs = existingConstructCodes.filter((c) => c);
      if (overwriteConstructs.length) {
        existingConstructCodes.forEach((constructCode, idx) => {
          if (constructCode) updateConstructCode(constructCode, idx);
        });
        setOverwriteWarnings(overwriteConstructs);
        return;
      }
    } catch (e) {
      return;
    }
    await handleSubmitCallback();
  };

  const handleProjectCreation = async () => {
    const abbreviations = Object.keys(abbrevMapping);
    const projectNames = abbreviations.map((abbrev) => abbrevMapping[abbrev].project);

    for (const projectName of projectNames) {
      if (!projectName) {
        return;
      }
    }

    const res = await dispatch(projectActions.createProjects({ projectNames, abbreviations }));

    try {
      unwrapResult(res);
      setAbbrevMapping({});
      await handleSubmit();
    } catch (e) {}
  };

  const handleOverwrite = async () => {
    await handleSubmitCallback();
    setOverwriteWarnings([]);
  };

  const columns = [
    { key: 'abbrev', title: 'Abbreviation' },
    { key: 'project', title: 'Project' },
  ];
  const rows = Object.entries(abbrevMapping).map(([abbrev, project]) => ({
    key: `projectRow_${abbrev}`,
    abbrev,
    project: (
      <Autocomplete
        value={project}
        onChange={(newProject) => setAbbrevMapping({ ...abbrevMapping, [abbrev]: newProject })}
        onCreate={(newProjectName) => ({ project: newProjectName })}
        options={existingProjects.map((p) => ({ project: p }))}
        getOptionLabel={(option) => option.project}
        createOptionText='Create New Project'
        label='Project'
        sx={{ width: 180 }}
        inputProps={(params) => ({
          ...params,
          error: !project,
        })}
      />
    ),
  }));

  const multipleNewProjects = Object.entries(abbrevMapping).length > 1;

  return (
    <Box>
      <Button
        onClick={handleSubmit}
        loading={loading}
        disabled={constructCodes.length === 0}
      >
        Submit
      </Button>

      <Dialog
        open={Object.entries(abbrevMapping).length > 0}
        onClose={() => setAbbrevMapping({})}
        onConfirm={handleProjectCreation}
        loading={loading}
        title='Create a new project?'
        disableCloseOnConfirm
      >
        <DialogContentText>
          No
          {multipleNewProjects ? ' projects ' : ' project '}
          with the
          {multipleNewProjects ? ' abbreviations ' : ' abbreviation '}
          {Object.keys(abbrevMapping).map((abbr) => `"${abbr}"`).join(', ')}
          {multipleNewProjects ? ' were ' : ' was '}
          found. Would you like to make
          {multipleNewProjects ? ' new projects ' : ' a new project '}
          or add
          {multipleNewProjects ? ' these abbreviations ' : ' this abbreviation '}
          to an existing project?
        </DialogContentText>
        <DataTable
          columns={columns}
          data={rows}
          noHeader
          containerProps={{ sx: { border: 'none' }}}
        />
      </Dialog>

      <Dialog
        open={overwriteWarnings.length > 0}
        onClose={() => setOverwriteWarnings([])}
        onConfirm={handleOverwrite}
        loading={loading}
        title='Overwrite existing construct?'
        confirmButtonText='Yes, overwrite'
        disableCloseOnConfirm
      >
        <DialogContentText>
          {overwriteWarnings.length > 1 ? 'Designs ' : 'A design '}
          with the construct
          {overwriteWarnings.length > 1 ? ' codes ' : ' code '}
          {overwriteWarnings.join(', ')} already
          {overwriteWarnings.length > 1 ? ' exist' : ' exists'}
          . Would you like to overwrite
          {overwriteWarnings.length > 1 ? ' these designs' : ' this design'}
          ?
        </DialogContentText>
      </Dialog>
    </Box>
  );
}

DesignSubmission.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  updateConstructCode: PropTypes.func.isRequired,
  validateInputs: PropTypes.func.isRequired,
  constructCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DesignSubmission;
