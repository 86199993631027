import {
  ASC,
  BATCH,
  BUFFER,
  CHEMICAL,
  COL_BATCH_TYPE,
  COL_DATE_RANGE,
  COL_NUMBER,
  COL_NUMBER_MEMBER,
  COL_NUMBER_RANGE,
  COL_SELECT,
  COL_STATUS,
  COL_TEXT,
  COL_TEXT_MEMBER,
  CONSTRUCT,
  DESC,
  EQUIPMENT,
  MATERIAL,
  REPORT,
} from 'constants/inventory.constants';
import EntityRow, { LoadingRow } from './BodyRow';
import React, { useEffect, useState } from 'react';

import ActionToolbar from './ActionToolbar';
import Box from '@mui/material/Box';
import Cell from './Cell';
import Checkbox from '@mui/material/Checkbox';
import HeaderCell from './HeaderCell';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { cloningStatuses } from 'constants/batch.constants';
import { columns } from './columns';
import { deepObjectModify } from 'utils/helpers';
import { range } from 'lodash';
import { useNavigate } from 'react-router-dom';

function descendingComparator(a, b, orderBy, tableType) {
  const col = columns[tableType].find((c) => c.id === orderBy[tableType]);
  let valA = col.getValue(a);
  let valB = col.getValue(b);

  if (tableType === CONSTRUCT && orderBy[tableType] === COL_STATUS) {
    valA = cloningStatuses.indexOf(a.status.toUpperCase());
    valB = cloningStatuses.indexOf(b.status.toUpperCase());
  }

  if (valA && !valB) return -1;
  if (valB && !valA) return 1;

  const defaultComparison = tableType === CONSTRUCT ? (
    b.construct_code.toLowerCase().localeCompare(a.construct_code.toLowerCase())
  ) : ([BATCH, REPORT].includes(tableType)) ? (
    b.batch_id - a.batch_id
  ) : ([BUFFER, CHEMICAL, EQUIPMENT, MATERIAL].includes(tableType)) ? (
    b.name.toLowerCase().localeCompare(a.name.toLowerCase())
  ) : 0;

  switch (typeof(valA)) {
    case 'string': return valB.toLowerCase().localeCompare(valA.toLowerCase()) || defaultComparison;
    case 'number': return valB - valA || defaultComparison;
    default: return defaultComparison;
  }
}

function getComparator(order, orderBy, tableType) {
  return order[tableType] === DESC ? (a, b) => descendingComparator(a, b, orderBy, tableType) : (a, b) => -descendingComparator(a, b, orderBy, tableType);
}

const defaultOrder = {
  [CONSTRUCT]: DESC,
  [BATCH]: DESC,
  [REPORT]: DESC,
  [EQUIPMENT]: DESC,
  [MATERIAL]: DESC,
  [CHEMICAL]: DESC,
  [BUFFER]: DESC,
};

const defaultOrderBy = {
  [CONSTRUCT]: 'date',
  [BATCH]: 'modified',
  [REPORT]: 'last_signed_at',
  [EQUIPMENT]: 'name',
  [MATERIAL]: 'name',
  [CHEMICAL]: 'name',
  [BUFFER]: 'name',
};

const defaultFilters = Object.fromEntries(Object.keys(columns).map(
  (type) => [
    type,
    Object.fromEntries(columns[type].map(
      (col) => [col.id, { active: false, value: col.filter.default }],
    )),
  ],
));

function InventoryTable(props) {
  const { entities: allEntities, onHover, dropCols, tableType } = props;
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState({ ...defaultOrder, [tableType]: props.order });
  const [orderBy, setOrderBy] = useState({ ...defaultOrderBy, [tableType]: props.orderBy });
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setSelected([]);
  }, [tableType]);


  const getEntityId = (entity) => {
    switch (tableType) {
      case CONSTRUCT: return entity.construct_code;
      case BATCH: return entity.batch_id;
      case REPORT: return entity.batch_id;
      case EQUIPMENT: return entity.equipment_id;
      case MATERIAL: return entity.material_id;
      case CHEMICAL: return entity.chemical_id;
      case BUFFER: return entity.buffer_id;
      default: return null;
    }
  };

  const cols = columns[tableType].filter((col) => !dropCols.includes(col.label));
  const numSelected = selected.length;
  let entities = [...allEntities];
  cols.forEach((col) => {
    const filterData = filters[tableType][col.id];
    if (filterData.active) {
      switch (col.filter.type) {
        case COL_TEXT: {
          entities = entities.filter((e) => col.getValue(e).toLowerCase().includes(filterData.value.toLowerCase()));
          break;
        }
        case COL_TEXT_MEMBER: {
          entities = entities.filter((e) => (
            col.getValue(e).filter((v) => (
              v.toLowerCase().includes(filterData.value.toLowerCase())
            )).length > 0
          ));
          break;
        }
        case COL_NUMBER: {
          entities = entities.filter((e) => col.getValue(e) === Number(filterData.value));
          break;
        }
        case COL_NUMBER_MEMBER: {
          entities = entities.filter((c) => col.getValue(c).includes(Number(filterData.value)));
          break;
        }
        case COL_NUMBER_RANGE: {
          entities = entities.filter((e) => (
            col.getValue(e) >= Number(filterData.value[0]) &&
              col.getValue(e) <= Number(filterData.value[1])
          ));
          break;
        }
        case COL_STATUS:
        case COL_BATCH_TYPE:
        case COL_SELECT: {
          entities = entities.filter((e) => col.getValue(e) === filterData.value);
          break;
        }
        case COL_DATE_RANGE: {
          entities = entities.filter((e) => {
            const val = col.getValue(e);
            const { startDate, endDate } = filterData.value;
            return (
              (!startDate || val > (new Date(startDate)).getTime()) &&
                (!endDate || val < (new Date(endDate)).getTime())
            );
          });
          break;
        }
        default: break;
      }
    }
  });
  entities.sort(getComparator(order, orderBy, tableType));
  const selectableRows = [CONSTRUCT, BATCH].includes(tableType);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(entities.map((e) => getEntityId(e)));
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (event, entity) => {
    event.stopPropagation();
    setSelected((oldSelected) => {
      const newSelected = [...oldSelected];
      const entityId = getEntityId(entity);
      if (newSelected.includes(entityId)) {
        newSelected.splice(newSelected.indexOf(entityId), 1);
      } else {
        newSelected.push(entityId);
      }
      return newSelected;
    });
  };

  const handleSort = (property) => {
    const isAsc = (orderBy[tableType] === property) && (order[tableType] === ASC);
    setOrder({ ...order, [tableType]: (isAsc ? DESC : ASC) });
    setOrderBy({ ...orderBy, [tableType]: property });
  };

  const handleFilter = (property, value) => {
    setFilters(deepObjectModify(filters, [tableType, property], value));
  };

  const handleRowClick = (entity) => {
    const entityId = getEntityId(entity);
    if (tableType === CONSTRUCT) {
      navigate(`/construct/${entityId}`);
    } else if ([BATCH, REPORT].includes(tableType)) {
      navigate(`/batch/${entityId}`);
    } else if (tableType === MATERIAL) {
      navigate(`/material/${entityId}`);
    } else if (tableType === EQUIPMENT) {
      navigate(`/equipment/${entityId}`);
    } else if (tableType === CHEMICAL) {
      navigate(`/chemical/${entityId}`);
    } else if (tableType === BUFFER) {
      navigate(`/buffer/${entityId}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', overflow: 'auto' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, borderRight: (theme) => `1px solid ${theme.palette.divider}` }}
          stickyHeader
          size='small'
        >
          <TableHead>
            <TableRow>
              {
                selectableRows ? (
                  <Cell padding='checkbox'>
                    <Checkbox
                      indeterminate={numSelected > 0 && numSelected < entities.length}
                      checked={entities.length > 0 && numSelected === entities.length}
                      onChange={handleSelectAll}
                      sx={{ '&.Mui-checked': { color: 'action.active' }}}
                    />
                  </Cell>
                ) : null
              }
              {
                cols.map((col) => (
                  <HeaderCell
                    key={col.id}
                    column={col}
                    order={order[tableType]}
                    orderBy={orderBy[tableType]}
                    onSort={handleSort}
                    filterData={filters[tableType][col.id]}
                    onFilter={(val) => handleFilter(col.id, val)}
                    tableType={tableType}
                  >
                    {allEntities}
                  </HeaderCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              allEntities.length ? entities.map((entity) => (
                <EntityRow
                  key={getEntityId(entity)}
                  selected={selected.includes(getEntityId(entity))}
                  onHover={onHover}
                  onClick={() => handleRowClick(entity)}
                  onSelect={selectableRows ? (event) => handleSelect(event, entity) : null}
                  columns={cols}
                >
                  {entity}
                </EntityRow>
              )) : range(25).map((i) => (
                <LoadingRow key={`loadingRow${i}`} columns={cols} selectableRows={selectableRows} />
              ))

            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        selectableRows && numSelected > 0 ? (
          <ActionToolbar
            tableType={tableType}
            selected={allEntities.filter((e) => selected.includes(getEntityId(e)))}
            clearSelected={() => setSelected([])}
          />
        ) : null
      }
    </Box>
  );
}

InventoryTable.propTypes = {
  /**
   * Type of table. Currently support 'Batch', 'Construct', 'Report',
   * 'Equipment', 'Material', 'Chemical', and 'Buffer'.
   */
  tableType: PropTypes.oneOf(Object.keys(columns)).isRequired,

  /**
   * Default order for sorting, either 'asc' for ascending order or 'desc' for
   * descending order.
   */
  order: PropTypes.oneOf([ASC, DESC]),

  /**
   * The column to sort on by default. The options depend on the table type.
   * For type 'Construct': [construct_code, backbone, type, tag, insert, length, batch, status, date, project, author]
   * For type 'Batch': [batch_id, type, status, constructs, created, modified, modifier]
   * For type 'Report': [batch_id, status, last_signed_by, last_signed_at, total_sign]
   */
  orderBy: (props, propName, componentName) => {
    const options = columns[props.tableType]?.map((col) => col.id);
    if (!options.includes(props[propName])) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected one of [${options.join(', ')}], given '${props[propName]}'.`);
    }
    return null;
  },

  /**
   * A list of entities to be put into the table. The type of the entity must
   * match the table type. E.g. if props.tableType === 'Construct', then
   * props.entities must be provided a list of constructs.
   */
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * A list of columns to hide from the table. The options of which columns to
   * drop are dependent on the table type.
   * For type 'Construct': [Construct Code, Backbone, Display, Tag, Insert, Length, Batch, Status, Date Created, Project, Designed By]
   * For type 'Batch': [Batch ID, Batch Type, Status, Constructs, Date Created, Last Modified, Last Modified By]
   * For type 'Report': [Batch ID, Status, Last Signed By, Signed Date/Time, Total Sign]
   */
  dropCols: (props, propName, componentName) => {
    const dropCols = props[propName];
    if (typeof(dropCols) === 'undefined' || dropCols === null) {
      return null;
    }
    if (!Array.isArray(dropCols)) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected an array, given '${dropCols}'.`);
    }
    const options = columns[props.tableType]?.map((col) => col.label);
    for (let i = 0; i < dropCols.length; i++) {
      if (!options.includes(dropCols[i])) {
        return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected all elements of ${propName} to be in [${options.join(', ')}], given '${dropCols[i]}' at position ${i}.`);
      }
    }
    return null;
  },

  /**
   * Callback function for hovering over table rows. If provided, when a row
   * is hovered over, the function will be called with the entity (construct,
   * batch, etc.) that was hovered over as the parameter.
   */
  onHover: PropTypes.func,
};

InventoryTable.defaultProps = {
  dropCols: [],
  order: DESC,
};

export default InventoryTable;
