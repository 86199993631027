import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AlignmentMap from './AlignmentMap';
import AlignmentScrolling from './AlignmentScrolling';
import BatchPill from 'components/BatchPill';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FMT_DATE_READABLE } from 'constants/dateFormats.constants';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoPopper from 'components/InfoPopper';
import InfoTable from 'components/InfoTable';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';
import { isEqual } from 'lodash';
import { sequencingActions } from 'store/sequencing';

class Alignment extends React.Component {
  static propTypes = {
    alignment: PropTypes.shape({
      alignment_id: PropTypes.number.isRequired,
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      alignment_data: PropTypes.shape({
        read_meta_data: PropTypes.arrayOf(PropTypes.shape({
          read_name: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      batch: PropTypes.object,
    }).isRequired,
    removeAlignment: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showChromatograms: false,
      viewBoxRange: null,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  handleDelete(event) {
    const { alignment, removeAlignment } = this.props;
    event.stopPropagation();
    removeAlignment(alignment.alignment_id);
  }

  render() {
    const { alignment } = this.props;
    const { construct_code, clone, created_at, alignment_data, author, batch } = alignment;
    const { read_meta_data } = alignment_data;
    const { expanded, showChromatograms, viewBoxRange } = this.state;
    const date = formatDate(created_at, FMT_DATE_READABLE);
    const timeStamp = (new Date(created_at)).getTime();
    return (
      <Accordion expanded={expanded} onChange={this.handleExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ my: 'auto', mx: 0 }}>{construct_code}-{clone}</Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography variant='caption' sx={{ m: 'auto', mr: 0, color: 'text.secondary' }}>{date}</Typography>
            <InfoPopper
              placement='left-start'
              buttonProps={{ size: 'small', sx: { ml: 1.5, mr: 0, color: 'text.secondary' }}}
              iconProps={{ fontSize: 'small' }}
            >
              <InfoTable
                tableProps={{ size: 'small' }}
                data={[
                  { key: 'construct', title: 'Construct', content: `${construct_code}-${clone}` },
                  { key: 'uploaded_at', title: 'Date Uploaded', content: date },
                  { key: 'uploaded_by', title: 'Uploaded By', content: author?.name || '–' },
                  { key: 'read_name',
                    title: 'Read Names',
                    content: read_meta_data.map((header) => (
                      <React.Fragment key={`${construct_code}-${clone}_${timeStamp}_${header.read_name}`}>
                        {header.read_name}
                        <br />
                      </React.Fragment>
                    )) },
                  { key: 'batch', title: 'Batch', content: (<BatchPill batches={batch} />) },
                  { key: 'delete_button',
                    title: '',
                    bodyCellProps: { sx: { textAlign: 'right' }},
                    content: (
                      <Button
                        size='small'
                        variant='outlined'
                        endIcon={<DeleteIcon />}
                        onClick={this.handleDelete}
                      >
                        Remove
                      </Button>
                    ) },
                ]}
              />
            </InfoPopper>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'block' }}>
          <Paper variant='outlined' sx={{ mb: 1.5 }}>
            <AlignmentMap
              alignment={alignment}
              viewBoxRange={viewBoxRange}
            />
          </Paper>
          <Paper variant='outlined' sx={{ pt: 2, pb: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <FormControl component='fieldset' sx={{ mr: 0, ml: 'auto' }}>
                <FormControlLabel
                  control={(
                    <Switch
                      color='primary'
                      value={showChromatograms}
                      onChange={() => this.setState({ showChromatograms: !showChromatograms })}
                    />
                  )}
                  label='Show Chromatogram'
                  labelPlacement='end'
                />
              </FormControl>
            </Box>
            <AlignmentScrolling
              alignment={alignment}
              showChromatograms={showChromatograms}
              setScrollingBox={(bpRange) => this.setState({ viewBoxRange: bpRange })}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const { removeAlignment } = sequencingActions;

export default connect(
  null,
  { removeAlignment },
)(Alignment);
