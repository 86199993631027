import * as extraActions from './user.extraActions';

import { STATUS_ERROR, STATUS_SUCCESS } from 'constants/statuses.constants';

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const fetchUsers = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { users } = action.payload;
    state.users = users;
    state.status = STATUS_SUCCESS;
    state.error = null;
  },
  [rejected]: (state, action) => {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  },
});

export const reducers = {};
export const extraReducers = {
  ...fetchUsers(extraActions.fetchUsers),
};
