import { batchActions, batchSelectors } from 'store/batch';

import PCRTable from 'components/PCRTable';
import PropTypes from 'prop-types';
import React from 'react';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';

class PCR extends React.Component {
  static propTypes = {
    handleSave: PropTypes.func.isRequired,
    handleConstructInput: PropTypes.func.isRequired,
    constructs: PropTypes.array.isRequired,
  };

  render() {
    const { constructs, handleConstructInput, handleSave } = this.props;
    return (
      <PCRTable
        showProtocol
        constructs={constructs}
        handleConstructInput={handleConstructInput}
        handleSave={handleSave}
      />
    );
  }
}

const { handleConstructInput } = batchActions;
const { selectConstructs } = batchSelectors;
const { handleSave } = batchStepperActions;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
  }),
  { handleConstructInput, handleSave },
)(PCR);
