import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { materialActions, materialSelectors } from 'store/material';

import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import InfoTable from 'components/InfoTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deepObjectModify } from 'utils/helpers';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

const initState = (initData) => ({
  data: {
    batch_no: '',
    date_prepared: moment(),
    storage_location: '',
    expiration_date: null,
    custom_props: [],
    ...initData,
  },
  error: '',
});

class BufferBatchDialog extends React.Component {
  static propTypes = {
    buffer: PropTypes.shape({
      buffer_id: PropTypes.number,
      name: PropTypes.string,
      preparation: PropTypes.string,
    }),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    createBufferBatch: PropTypes.func,
    patchBufferBatch: PropTypes.func,
    initData: PropTypes.shape({
      batch_no: PropTypes.string,
      date_prepared: PropTypes.object, // moment object
      storage_location: PropTypes.string,
      expiration_date: PropTypes.object, // moment object
      custom_props: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })),
    }),
    createNew: PropTypes.bool,
  };

  static defaultProps = {
    createNew: true,
  };

  constructor(props) {
    super(props);
    this.state = initState(props.initData);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCustomPropChange = this.handleCustomPropChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addProperty = this.addProperty.bind(this);
    this.removeProperty = this.removeProperty.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open, initData } = this.props;
    if (!prevProps.open && open) {
      this.setState({ ...initState(initData) });
    }
  }

  handleClose() {
    this.setState({ ...initState() });
    this.props.onClose();
  }

  handleInputChange(event) {
    const { data, error } = this.state;
    const { id, value } = event.target;
    const newData = { ...data, [id]: value };
    const newError = id === 'batch_no' ? '' : error;
    this.setState({ data: newData, error: newError });
  }

  handleCustomPropChange(event, idx, type) {
    const { data } = this.state;
    const { value } = event.target;
    const newData = deepObjectModify(data, ['custom_props', idx, type], value);
    this.setState({ data: newData });
  }

  addProperty() {
    const { data } = this.state;
    const { custom_props } = data;
    const newData = {
      ...data,
      custom_props: [...custom_props, { title: '', value: '' }],
    };
    this.setState({ data: newData });
  }

  removeProperty(idx) {
    const { data } = this.state;
    const { custom_props } = data;
    const newCustomProps = [...custom_props];
    newCustomProps.splice(idx, 1);
    const newData = {
      ...data,
      custom_props: newCustomProps,
    };
    this.setState({ data: newData });
  }

  async handleSubmit() {
    const { createNew, onClose, createBufferBatch, patchBufferBatch, buffer } = this.props;
    const { data } = this.state;
    const {
      buffer_batch_id,
      batch_no,
      date_prepared,
      storage_location,
      expiration_date,
      custom_props,
    } = data;
    if (!batch_no) {
      this.setState({ error: 'Batch # required' });
      return;
    }
    if (!date_prepared?.toISOString?.()) {
      return;
    }
    if (expiration_date && !expiration_date.toISOString?.()) {
      return;
    }
    for (const prop of custom_props) {
      if (!prop.title && prop.value) {
        return;
      }
    }

    const _data = {
      buffer_id: buffer.buffer_id,
      batch_no,
      date_prepared: date_prepared.toISOString(),
      storage_location,
      expiration_date: expiration_date?.toISOString(),
      custom_props: custom_props.filter((prop) => prop.title),
    };
    const res = await (createNew ? createBufferBatch(_data) : patchBufferBatch({
      ..._data,
      buffer_batch_id,
    }));

    try {
      const resUnwrapped = unwrapResult(res);
      this.setState({ ...initState() });
      onClose(resUnwrapped.buffer_batch);
    } catch (e) {}
  }

  render() {
    const { loading, open, buffer, createNew } = this.props;
    const { data, error } = this.state;
    const { batch_no, date_prepared, storage_location, expiration_date, custom_props } = data;

    const isInvalidDateReceieved = Boolean(date_prepared && !date_prepared?.toISOString?.());
    const isInvalidDateExpires = Boolean(expiration_date && !expiration_date?.toISOString?.());
    const inputSx = { minWidth: 250 };

    const rows = [
      {
        key: 'name',
        title: 'Name',
        content: buffer.name,
      },
      {
        key: 'preparation',
        title: 'Preparation',
        content: (
          <>
            {buffer.preparation.split('\n').map((step, idx) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Box key={`prep${idx}`}>{step}</Box>
            ))}
          </>
        ),
      },
      {
        key: 'batch_no',
        title: 'Batch #',
        content: (
          <TextField
            variant='outlined'
            id='batch_no'
            label='Batch #'
            value={batch_no}
            onChange={this.handleInputChange}
            sx={inputSx}
            error={Boolean(error)}
            helperText={error}
          />
        ),
      },
      {
        key: 'date_prepared',
        title: 'Date Prepared',
        content: (
          <DatePicker
            value={date_prepared}
            onChange={(value) => this.setState({ data: { ...data, date_prepared: value }})}
            disableFuture
            renderInput={(params) => (
              <TextField
                {...params}
                sx={mergeSx(params.sx, inputSx)}
                error={params.error || isInvalidDateReceieved}
                helperText={isInvalidDateReceieved ? 'Invalid date' : params.helperText}
              />
            )}
          />
        ),
      },
      {
        key: 'storage_location',
        title: 'Storage Location',
        content: (
          <TextField
            variant='outlined'
            id='storage_location'
            label='Storage Location'
            value={storage_location}
            onChange={this.handleInputChange}
            sx={inputSx}
          />
        ),
      },
      {
        key: 'expiration_date',
        title: 'Expiration Date',
        content: (
          <DatePicker
            value={expiration_date}
            onChange={(value) => this.setState({ data: { ...data, expiration_date: value }})}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={mergeSx(params.sx, inputSx)}
                error={params.error || isInvalidDateExpires}
                helperText={isInvalidDateExpires ? 'Invalid date' : params.helperText}
              />
            )}
          />
        ),
      },
      ...custom_props.map(({ title, value }, idx) => ({
        key: `custom_prop${idx}`,
        title: (
          <TextField
            variant='outlined'
            label='Property Name'
            value={title}
            onChange={(e) => this.handleCustomPropChange(e, idx, 'title')}
            sx={{ width: 150 }}
            error={Boolean(!title && value)}
            helperText={!title && value ? 'Required' : ''}
          />
        ),
        content: (
          <Box sx={{ display: 'flex' }}>
            <TextField
              variant='outlined'
              label={title}
              value={value}
              onChange={(e) => this.handleCustomPropChange(e, idx, 'value')}
              sx={inputSx}
            />
            <IconButton onClick={() => this.removeProperty(idx)} sx={{ m: 'auto', ml: 1 }}>
              <ClearIcon />
            </IconButton>
          </Box>
        ),
      })),
    ];

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        onConfirm={this.handleSubmit}
        disableCloseOnConfirm
        loading={loading}
        confirmButtonText='Submit'
        maxWidth={false}
        title={createNew ? 'New Buffer Batch' : 'Modify Buffer Batch'}
        extraDialogActions={(
          <Button onClick={this.addProperty} variant='text'>
            Add Property
          </Button>
        )}
      >
        <InfoTable data={rows} tableProps={{ sx: { minWidth: 470 }}} />
      </Dialog>
    );
  }
}

const { selectPageLoading } = materialSelectors;
const { createBufferBatch, patchBufferBatch } = materialActions;

export default connect(
  (state) => ({
    loading: selectPageLoading(state),
  }),
  { createBufferBatch, patchBufferBatch },
)(BufferBatchDialog);
