import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';
import { batchStepperActions, batchStepperSelectors } from 'store/batchStepper';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { connect } from 'react-redux';

class StepperButtons extends React.Component {
  static propTypes = {
    handleSave: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
    saveStatus: PropTypes.oneOf([STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS]).isRequired,
    position: PropTypes.oneOf(['left', 'right', 'center']),
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  };

  static defaultProps = {
    position: 'left',
  };

  constructor(props) {
    super(props);
    this.ctrlSHandler = this.ctrlSHandler.bind(this);
    this.ctrlEnterHandler = this.ctrlEnterHandler.bind(this);
  }

  ctrlSHandler(e) {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
      e.preventDefault();
      this.props.handleSave();
    }
  }

  ctrlEnterHandler(e) {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 13) {
      e.preventDefault();
      this.props.onNextStep();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.ctrlEnterHandler);
    document.addEventListener('keydown', this.ctrlSHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.ctrlEnterHandler);
    document.removeEventListener('keydown', this.ctrlSHandler);
  }

  render() {
    const { children, position, saveStatus, handleSave, onNextStep } = this.props;
    const displayLoading = saveStatus === STATUS_LOADING;
    const displaySuccess = saveStatus === STATUS_SUCCESS;
    const displayFailure = saveStatus === STATUS_ERROR;
    const positionStyle = (
      position === 'left' ? { ml: 0 } : (
        position === 'right' ? { mr: 0 } : (
          position === 'center' ? { m: 'auto' } : {}
        )
      )
    );

    return (
      <Box sx={{ display: 'flex', m: 3, mb: 0 }}>
        <Box sx={{ m: 'auto', display: 'flex', ...positionStyle }}>
          {children}
          <ButtonGroup size='small'>
            <Button
              startIcon={
                displaySuccess ? (
                  <CheckCircleOutlineIcon sx={{ color: 'success.main' }} />
                ) : displayFailure ? (
                  <ErrorOutlineIcon sx={{ color: 'error.main' }} />
                ) : (
                  <SaveIcon />
                )
              }
              loading={displayLoading}
              disabled={displaySuccess}
              onClick={handleSave}
              variant='outlined'
              size='small'
            >
              Save
            </Button>
            <Button
              size='small'
              endIcon={<DoubleArrowIcon />}
              onClick={onNextStep}
              variant='outlined'
            >
              Next Step
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
}

const { selectSaveStatus } = batchStepperSelectors;
const { handleSave } = batchStepperActions;

export default connect(
  (state) => ({
    saveStatus: selectSaveStatus(state),
  }),
  { handleSave },
)(StepperButtons);
