export const BACKBONE = 'backbone';
export const BATCH = 'batch';
export const BATCH_STEPPER = 'batchStepper';
export const CONSTRUCT = 'construct';
export const DESIGN = 'design';
export const INVENTORY = 'inventory';
export const MATERIAL = 'material';
export const NAVIGATION = 'navigation';
export const NOTIFICATION = 'notification';
export const PRIMER = 'primer';
export const PROJECT = 'project';
export const SEQUENCING = 'sequencing';
export const SESSION = 'session';
export const USER = 'user';
