import { GIGA_BATCH, batchTypeDisplay } from 'constants/batch.constants';
import React, { useState } from 'react';

import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { FMT_DATE_READABLE } from 'constants/dateFormats.constants';
import IconButton from '@mui/material/IconButton';
import InfoTable from 'components/InfoTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StatusPill from 'components/StatusPill';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { batchSelectors } from 'store/batch';
import { formatDate } from 'utils/date.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { pad } from 'utils/formatting.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { useSelector } from 'react-redux';

function BatchInfo(props) {
  const [expanded, setExpanded] = useState(false);
  const batch = useSelector(batchSelectors.selectBatch);
  const constructCodes = useSelector(batchSelectors.selectConstructCodes);
  const numConstructs = constructCodes.length;
  const projects = useSelector(batchSelectors.selectProjects);

  const { sx } = props;
  const {
    batch_id,
    type: batchType,
    created_at,
    modified_at,
    modifier,
    status,
  } = batch;

  const data_giga = [
    { key: 'construct', title: 'Construct Code', content: constructCodes[0] },
    { key: 'project', title: 'Project', content: projects.join(', ') },
    { key: 'created', title: 'Date Created', content: formatDate(created_at, FMT_DATE_READABLE) },
    { key: 'modified', title: 'Last Modified', content: `${formatDate(modified_at, FMT_DATE_READABLE)} ${modifier && modifier.name ? `by ${modifier.name}` : ''}` },
    { key: 'status', title: 'Status', content: (<StatusPill status={status} type={batchType} />) },
  ];
  const data_maxi_cloning = [
    { key: 'numConstructs', title: 'Number of Constructs', content: numConstructs },
    { key: 'project', title: 'Project', content: projects.join(', ') },
    { key: 'created', title: 'Date Created', content: formatDate(created_at, FMT_DATE_READABLE) },
    { key: 'modified', title: 'Last Modified', content: `${formatDate(modified_at, FMT_DATE_READABLE)} ${modifier && modifier.name ? `by ${modifier.name}` : ''}` },
    { key: 'status', title: 'Status', content: (<StatusPill status={status} type={batchType} />) },
  ];
  return (
    <>
      <Box sx={mergeSx({ m: 'auto', p: 3, display: 'flex' }, sx)}>
        <BatchType type={batchType} size={56} sx={{ color: 'text.secondary', m: 'auto', mr: 0 }}>
          {batchTypeDisplay[batchType]} Batch {pad(batch_id)}
        </BatchType>
        <IconButton
          sx={{ m: 'auto', ml: 0.5, color: 'text.secondary' }}
          onClick={() => setExpanded(!expanded)}
        >
          {!expanded ? <MoreVertIcon /> : <UnfoldLessIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <InfoTable
          data={batchType === GIGA_BATCH ? data_giga : data_maxi_cloning}
          tableProps={{ sx: { width: 0.6, m: 'auto', mb: 3 }}}
          borderTop
          borderBottom
        />
      </Collapse>
    </>
  );
}

BatchInfo.propTypes = {
  sx: sxPropType,
};

export default BatchInfo;
