import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

import AllInboxIcon from '@mui/icons-material/AllInbox';
import AppBar from '@mui/material/AppBar';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ICON_DESIGN } from 'constants/icon.constants';
import { Icon } from '@iconify/react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Login from './Login';
import Logout from './Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';
import { STATUS_INIT } from 'constants/statuses.constants';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { batchTypeDisplay } from 'constants/batch.constants';
import { sessionSelectors } from 'store/session';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';

const PATH_TITLES = {
  'design': 'Design',
  'sequencing': 'Sequencing',
  'inventory': {
    construct: 'Construct Inventory',
    batch: 'Batch Inventory',
    report: 'Report Inventory',
    equipment: 'Equipment Inventory',
    material: 'Material Inventory',
    chemical: 'Chemical Inventory',
    buffer: 'Buffer Inventory',
  },
  'construct': 'Construct',
  'batch': 'Batch',
  'equipment': 'Equipment',
  'material': 'Material',
  'chemical': 'Chemical',
  'buffer': 'Buffer',
  'figures': 'Figures',
  'privacy-policy': 'Privacy Policy',
};

const MENU_OPTIONS = [
  {
    title: 'Inventory',
    link: '/inventory',
    icon: <AllInboxIcon />,
    nestedOptions: [
      { title: 'Construct', link: '/inventory/construct', icon: <Icon icon='healthicons:medicines' height={24} width={24} /> },
      { title: 'Batch', link: '/inventory/batch', icon: <Icon icon='bi:card-checklist' height={24} width={24} /> },
      { title: 'Report', link: '/inventory/report', icon: <Icon icon='bxs:report' height={24} width={24} /> },
      { title: 'Equipment', link: '/inventory/equipment', icon: <Icon icon='bi:gear-fill' height={24} width={24} /> },
      { title: 'Material', link: '/inventory/material', icon: <Icon icon='academicons:open-materials' height={24} width={24} /> },
      { title: 'Chemical', link: '/inventory/chemical', icon: <Icon icon='clarity:atom-solid' height={24} width={24} /> },
      { title: 'Buffer', link: '/inventory/buffer', icon: <Icon icon='fontisto:test-bottle' height={24} width={24} /> },
    ],
  },
  { title: 'Design', link: '/design', icon: <Icon icon={ICON_DESIGN} height={24} width={24} /> },
  { title: 'Sequencing', link: '/sequencing', icon: <CompareArrowsIcon /> },
  { title: 'Figures', link: '/figures', icon: <AssessmentIcon /> },
];

function NavBar() {
  const batchType = useSelector(batchSelectors.selectBatchType);
  const session = useSelector(sessionSelectors.selectSession);
  const { authState } = useOktaAuth();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedOpen, setNestedOpen] = useState(Object.fromEntries(MENU_OPTIONS.map((menuOption) => [menuOption.link, false])));

  const getPathTitle = (path) => {
    const pathBases = path.split('/');
    const pathBase = pathBases[1];
    if (pathBase === 'batch') {
      return batchTypeDisplay[batchType] || 'Batch';
    }
    if (pathBase === 'inventory') {
      return PATH_TITLES[pathBase][pathBases[2]] || 'Construct Inventory';
    }
    return PATH_TITLES[pathBase] || '';
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleNestedOpen = (link) => {
    setNestedOpen((oldNestedOpen) => ({ ...oldNestedOpen, [link]: !oldNestedOpen[link] }));
  };

  const pathTitle = getPathTitle(location.pathname);
  return (
    <AppBar position='fixed' color='primary' sx={{ flexGrow: 1, zIndex: 'appBar' }}>
      <Toolbar>
        {
          session.isAuthenticated ? (
            <>
              <IconButton color='inherit' onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
              >
                <List>
                  {
                    MENU_OPTIONS.map((menuOption) => (
                      menuOption.nestedOptions ? (
                        <React.Fragment key={menuOption.link}>
                          <ListItem button onClick={() => toggleNestedOpen(menuOption.link)}>
                            <ListItemIcon sx={{ minWidth: 40 }}>{menuOption.icon}</ListItemIcon>
                            <ListItemText primary={menuOption.title} />
                            {nestedOpen[menuOption.link] ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse in={nestedOpen[menuOption.link]}>
                            {
                              menuOption.nestedOptions.map((nestedOption) => (
                                <Link to={nestedOption.link} key={nestedOption.link}>
                                  <ListItem button onClick={handleMenuClose} sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{ minWidth: 40 }}>{nestedOption.icon}</ListItemIcon>
                                    <ListItemText primary={nestedOption.title} />
                                  </ListItem>
                                </Link>
                              ))
                            }
                          </Collapse>
                        </React.Fragment>
                      ) : (
                        <Link to={menuOption.link} key={menuOption.link}>
                          <ListItem button onClick={handleMenuClose}>
                            <ListItemIcon sx={{ minWidth: 40 }}>{menuOption.icon}</ListItemIcon>
                            <ListItemText primary={menuOption.title} />
                          </ListItem>
                        </Link>
                      )
                    ))
                  }
                </List>
              </Popover>
            </>
          ) : (
            <Box sx={{ width: 48 }} />
          )
        }
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          {
            pathTitle ? (
              <Typography variant='h5' sx={{ my: 'auto', mr: 1.5, ml: 0 }}>
                {pathTitle}
              </Typography>
            ) : null
          }
        </Box>
        {
          session.status !== STATUS_INIT || !authState?.isAuthenticated ? (
            session.isAuthenticated ? <Logout variant='text' /> : <Login variant='text' />
          ) : null
        }
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
