import { Button, Dialog, UploadButton } from '@acheloisbiosoftware/absui.core';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DataTable from 'components/DataTable';
import DescriptionIcon from '@mui/icons-material/Description';
import DesignSubmission from './DesignSubmission';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { connect } from 'react-redux';
import { deepObjectModify } from 'utils/helpers';
import { designActions } from 'store/design';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { unwrapResult } from '@reduxjs/toolkit';

class ConstructUpload extends React.Component {
  static propTypes = {
    uploadConstructs: PropTypes.func.isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rowData: [],
      uploadErrors: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({ open: false, rowData: []});
  }

  handleUpload(event) {
    const files = Array.from(event.target.files);
    if (!files.length) {
      return;
    }
    const rowData = files.map((file) => {
      const constructName = file.name.slice(0, -4); // Getting rid of '.dna'
      const constructCode = constructName.split('_')[0];
      const insertName = constructName.split('_').slice(1).join('_');
      const errors = {
        constructCode: constructCode ? '' : 'Unable to extract construct code',
        insertName: insertName ? '' : 'Unable to extract insert name',
      };
      return { constructCode, insertName, errors, file };
    });
    this.setState({ rowData });
  }

  handleInputChange(value, row, col) {
    const { rowData } = this.state;
    const newRow = deepObjectModify(
      deepObjectModify(rowData[row.idx], [col.key], value),
      ['errors', col.key],
      '',
    );
    const newRowData = [...rowData];
    newRowData[row.idx] = newRow;
    this.setState({ rowData: newRowData });
  }

  handleRemoveRow(idx) {
    const { rowData } = this.state;
    const newRowData = [...rowData];
    newRowData.splice(idx, 1);
    this.setState({ rowData: newRowData });
  }

  validateInputs() {
    const { rowData } = this.state;
    let valid = true;
    const requiredFields = ['constructCode', 'insertName'];
    rowData.forEach((row) => {
      requiredFields.forEach((field) => {
        if (!row[field]) {
          row.errors[field] = 'Required';
          valid = false;
        }
      });
    });
    if (!valid) {
      this.setState({ rowData });
    }
    return valid;
  }

  async handleSubmit() {
    const { rowData } = this.state;
    const res = await this.props.uploadConstructs({
      files: rowData.map((row) => row.file),
      body: {
        construct_info: rowData.map((row) => ({ insert_name: row.insertName, construct_code: row.constructCode })),
      },
    });
    try {
      const { failed_uploads } = unwrapResult(res);
      this.setState({ uploadErrors: failed_uploads });
      this.handleClose();
    } catch (e) {}
  }

  render() {
    const { sx } = this.props;
    const { open, rowData, uploadErrors } = this.state;
    const columns = [
      {
        key: 'constructCode',
        title: 'Construct Code',
        editable: true,
        inputProps: (row) => ({
          error: Boolean(row.errors.constructCode),
          helperText: row.errors.constructCode || null,
        }),
      },
      {
        key: 'insertName',
        title: 'Insert',
        editable: true,
        inputProps: (row) => ({
          error: Boolean(row.errors.insertName),
          helperText: row.errors.insertName || null,
        }),
      },
      { key: 'file', title: 'File' },
    ];
    const rows = rowData.map((row, idx) => ({
      key: row.file.name,
      idx,
      constructCode: row.constructCode,
      insertName: row.insertName,
      errors: row.errors,
      file: (
        <Chip
          icon={<DescriptionIcon />}
          label={row.file.name}
          onDelete={() => this.handleRemoveRow(idx)}
        />
      ),
    }));
    return (
      <Box sx={sx}>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => this.setState({ open: true })}
          sx={{
            height: 40,
            color: 'common.white',
            borderColor: 'common.white',
          }}
          startIcon={<UploadIcon />}
        >
          Upload
        </Button>

        <Dialog
          open={open}
          onClose={this.handleClose}
          maxWidth='md'
          title='Upload Constructs'
          noDefaultDialogActions
          extraDialogActions={(
            <>
              <Button onClick={this.handleClose} variant='text' sx={{ mx: 1 }}>
                Cancel
              </Button>
              <DesignSubmission
                validateInputs={this.validateInputs}
                handleSubmit={this.handleSubmit}
                constructCodes={rowData.map((row) => row.constructCode)}
                updateConstructCode={(constructCode, idx) => this.setState({ rowData: rowData.map((row, rowIdx) => (rowIdx !== idx || !constructCode ? row : { ...row, constructCode })) })}
              />
            </>
          )}
        >
          <Box sx={{ textAlign: 'center', mb: 1, width: 600 }}>
            <DialogContentText>Select Snapgene files to upload.</DialogContentText>
            <UploadButton
              variant='contained'
              color='primary'
              onChange={this.handleUpload}
              accept='.dna'
              multiple
            >
              Upload Files
            </UploadButton>
          </Box>
          <DataTable
            columns={columns}
            data={rows}
            onChange={this.handleInputChange}
            noHeader
            defaultInputProps={{ variant: 'outlined', size: 'medium' }}
            containerProps={{ sx: { border: 'none' }}}
          />
        </Dialog>

        <Dialog
          open={uploadErrors.length > 0}
          onClose={() => this.setState({ uploadErrors: []})}
          onConfirm={() => this.setState({ uploadErrors: []})}
          confirmOnly
          title='Upload Error'
          disableCloseOnConfirm
        >
          <DialogContentText>
            The following
            {uploadErrors.length > 1 ? ' constructs were ' : ' construct was '}
            unable to be uploaded:
          </DialogContentText>
          <Paper
            variant='outlined'
            sx={{
              display: 'flex',
              maxHeight: 200,
              m: 'auto',
              mb: 1.5,
              overflow: 'scroll',
            }}
          >
            <List
              sx={[
                { m: 'auto', columnGap: (theme) => theme.spacing(4) },
                uploadErrors.length > 4 ? { columnCount: 2 } : {},
              ]}
            >
              {
                uploadErrors.map((constructCode) => (
                  <ListItem key={constructCode}>
                    <ListItemIcon><ErrorOutlineIcon sx={{ color: 'error.main' }} /></ListItemIcon>
                    <ListItemText primary={constructCode} />
                  </ListItem>
                ))
              }
            </List>
          </Paper>
          <DialogContentText>
            The
            {uploadErrors.length > 1 ? ' files ' : ' file '}
            uploaded likely
            {uploadErrors.length > 1 ? ' contain ' : ' contains '}
            a "source" feature in Snapgene. Try deleting this feature and reupload.
          </DialogContentText>
        </Dialog>
      </Box>
    );
  }
}

const { uploadConstructs } = designActions;

export default connect(
  null,
  { uploadConstructs },
)(ConstructUpload);
