import React, { useEffect, useState } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { materialActions, materialSelectors } from 'store/material';
import { mergeSx, usePromise } from '@acheloisbiosoftware/absui.utils';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_SELECT_MATERIAL } from 'constants/batchActions.constants';
import { AddMaterialDialog } from 'views/Material';
import { Autocomplete } from '@acheloisbiosoftware/absui.core';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function LotField(props) {
  const {
    material,
    value,
    onChange,
    editMiddleware,
    appearDisabled,
    readOnly,
  } = props;
  const { material_id, material_lots } = material;
  const [open, setOpen] = useState(false);
  const [lotNo, setLotNo] = useState('');
  const { promise, defer } = usePromise();

  const onCreate = (inputValue) => {
    setLotNo(inputValue);
    setOpen(true);
    return defer();
  };

  const onClose = (newMaterialLot) => {
    onChange(material_id, newMaterialLot);
    promise.resolve(newMaterialLot || null);
    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        onChange={(newValue) => onChange(material_id, newValue)}
        onCreate={onCreate}
        getOptionLabel={(option) => option.lot_no}
        createOptionText='New Material Lot'
        options={material_lots}
        label='Lot #'
        inputProps={(params) => ({
          ...params,
          variant: 'standard',
          onFocus: () => editMiddleware(null),
          InputProps: {
            ...params?.InputProps,
            sx: mergeSx(params?.InputProps?.sx, appearDisabled ? { color: 'text.disabled' } : {}),
          },
        })}
        value={value || null}
        disableClearable={appearDisabled}
        disabled={readOnly}
      />
      <AddMaterialDialog
        material={material}
        open={open}
        onClose={onClose}
        initData={{ lot_no: lotNo }}
      />
    </>
  );
}

LotField.propTypes = {
  material: PropTypes.shape({
    material_id: PropTypes.number.isRequired,
    material_lots: PropTypes.arrayOf(PropTypes.shape({
      lot_no: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  value: PropTypes.shape({
    lot_no: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  editMiddleware: PropTypes.func.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

function MaterialTable(props) {
  const { step, substep, line, readOnly, appearDisabled, editMiddleware } = props;
  const dispatch = useDispatch();
  const lineData = useSelector((state) => batchSelectors.selectBatchLineData(state, step, substep, line));
  const materials = useSelector(materialSelectors.selectMaterials);

  useEffect(() => {
    dispatch(materialActions.fetchMaterials(true));
  }, [dispatch]);

  const setLotNo = (materialId, value) => {
    dispatch(batchActions.handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_SELECT_MATERIAL,
      payload: {
        row_id_key: 'material_id',
        material_id: materialId,
        material_lot_id: value?.material_lot_id || null,
      },
    }));
  };

  const { title, data } = lineData;
  const columns = [
    { key: 'name', title: 'Name', cellProps: { sx: { width: 120 }}},
    { key: 'vendor', title: 'Vendor', cellProps: { sx: { width: 100 }}},
    { key: 'catalog_no', title: 'Catalog #', cellProps: { sx: { width: 130 }}},
    { key: 'lot_no', title: 'Lot #', cellProps: { sx: { width: 200 }}},
  ];

  const rows = data.map(({ material_id, material_lot_id }) => {
    const material = materials.find((m) => m.material_id === material_id);
    if (!material || !material.material_lots) {
      const skeletonProps = { height: 45 };
      return {
        key: `material${material_id}_row`,
        name: <Skeleton {...skeletonProps} />,
        vendor: <Skeleton {...skeletonProps} />,
        catalog_no: <Skeleton {...skeletonProps} />,
        lot_no: <Skeleton {...skeletonProps} />,
      };
    }
    const materialLot = material.material_lots.find((ml) => ml.material_lot_id === material_lot_id);
    return {
      key: `material${material_id}_row`,
      name: (
        <Link
          href={`/material/${material_id}`}
          target='_blank'
          sx={appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {}}
        >
          {material.name}
        </Link>
      ),
      vendor: material.vendor,
      catalog_no: material.catalog_no,
      lot_no: (
        <LotField
          material={material}
          value={materialLot}
          onChange={setLotNo}
          editMiddleware={editMiddleware}
          appearDisabled={appearDisabled}
          readOnly={readOnly}
        />
      ),
    };
  });
  return (
    <Box sx={{ textAlign: 'center', m: 3 }}>
      <Typography variant='h6'>{title}</Typography>
      <DataTable
        columns={columns}
        data={rows}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
        containerProps={{ sx: { m: 1 }}}
      />
    </Box>
  );
}

MaterialTable.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  editMiddleware: PropTypes.func.isRequired,
};

export default MaterialTable;
