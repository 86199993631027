import client from 'services';

export async function validateSequencing(fileNames) {
  const data = { fileNames };
  return await client({ method: 'post', url: '/sequencing/upload/validate', data });
}

export async function uploadSequencing(data) {
  // data = {
  //   files: array of files,
  //   body: {
  //     read_mapping: [{
  //       file_name: string,
  //       construct_code: string,
  //       clone: string number,
  //     }],
  //     batch_id: number,
  //   },
  // };
  const formData = new FormData();
  const { files, body } = data;
  files.forEach((file) => {
    formData.append(file.name, file);
  });
  if (body) {
    formData.append('body', JSON.stringify(body));
  }
  return await client({
    method: 'post',
    url: '/sequencing/upload',
    data: formData,
    timeout: 60000,
  });
}

export async function removeAlignment(alignmentId) {
  const data = { alignment_id: alignmentId };
  return await client({ method: 'post', url: '/sequencing/remove_alignment', data });
}

export async function getSequencing(data) {
  // data = { construct_codes: list of strings, batch_id: number };
  return await client({ method: 'get', url: '/sequencing', params: data });
}
