import client from 'services';

export async function uploadGelImages(data) {
  // data = {
  //   files: list of images,
  //   body: {
  //     batch_id: number,
  //     updates: [{step_id: string, substep_id: string, line_id: string, action: string, payload: object}],
  //     batch_data: batch_data object (only for cloning),
  //     construct_data: list of construct objects (only for cloning),
  //     step_id: ID of the step where the image is being uploaded (only for maxi/giga),
  //     substep_id: ID of the substep where the image is being uploaded (only for maxi/giga),
  //     line_id: ID of the line where the image is being uploaded (only for maxi/giga),
  //   },
  // };
  const formData = new FormData();
  const { files, body } = data;
  for (let i = 0; i < files.length; i++) {
    formData.append(files[i].name, files[i]);
  }
  formData.append('body', JSON.stringify(body));
  return await client({ method: 'post', url: '/batch/gel_images/upload', data: formData });
}

export async function editGelImage(data) {
  // data = {
  //   batch_id: number,
  //   image_name: string,
  //   image_data: base64 string,
  //   updates: [{step_id: string, substep_id: string, line_id: string, action: string, payload: object}],
  //   batch_data: batch_data object (only for cloning),
  //   construct_data: list of construct objects (only for cloning),
  //   step_id: ID of the step where the image is being uploaded (only for maxi/giga),
  //   substep_id: ID of the substep where the image is being uploaded (only for maxi/giga),
  //   line_id: ID of the line where the image is being uploaded (only for maxi/giga),
  // };
  return await client({ method: 'post', url: '/batch/gel_image/edit', data });
}

export async function removeGelImage(data) {
  // data = {
  //   batch_id: number,
  //   image_name: string,
  //   updates: [{step_id: string, substep_id: string, line_id: string, action: string, payload: object}],
  //   batch_data: batch_data object (only for cloning),
  //   construct_data: list of construct objects (only for cloning),
  //   step_id: ID of the step where the image is being uploaded (only for maxi/giga),
  //   substep_id: ID of the substep where the image is being uploaded (only for maxi/giga),
  //   line_id: ID of the line where the image is being uploaded (only for maxi/giga),
  // };
  return await client({ method: 'post', url: '/batch/gel_image/remove', data });
}

export async function updateBatch(data) {
  // data = {
  //   batch_id: number,
  //   updates: [{step_id: string, substep_id: string, line_id: string, action: string, payload: object}],
  //   batch_data: batch_data object (only for cloning),
  //   construct_data: list of construct objects (only for cloning),
  //   status: string (optional),
  // };
  return await client({ method: 'post', url: '/batch/update', data });
}

export async function createBatch(data) {
  // data = {
  //   constructs: list of strings (construct codes),
  //   type: string,
  //   status: string (optional, only for cloning),
  // };
  return await client({ method: 'post', url: '/batch-new', data });
}

export async function getBatchReport(batchId) {
  return await client({ method: 'get', url: `/batch/${batchId}/report` });
}

export async function getBatchReportPdf(batchId) {
  const res = await client({ method: 'get', url: `/batch/${batchId}/report_pdf` });
  return res.error ? res : {
    name: res.file_name,
    file: `data:${res.content_type};base64,${res.file}`,
  };
}

// TODO: not currently used
export async function getBatchReports() {
  return await client({ method: 'get', url: '/reports' });
}

export async function getReportSignatures(batchId) {
  return await client({ method: 'get', url: `/batch/${batchId}/report/signs` });
}

export async function getReportComments(batchId) {
  return await client({ method: 'get', url: `/batch/${batchId}/report/comments` });
}

export async function lockReport(batchId) {
  return await client({ method: 'post', url: `/batch/${batchId}/report/lock` });
}

export async function addSignees(batchId, signees) {
  // signees = [{ order: number, user_id: number }];
  const data = { signees };
  return await client({ method: 'post', url: `/batch/${batchId}/report/add_signee`, data });
}

export async function updateSignees(batchId, signees) {
  // signees = [{ order: number, user_id: number }];
  const data = { signees };
  return await client({ method: 'post', url: `/batch/${batchId}/report/update_signee`, data });
}

export async function signReport(batchId, comment) {
  const data = { sign_text: comment };
  return await client({ method: 'post', url: `/batch/${batchId}/report/sign`, data });
}

export async function verifySignature(batchSignatureId) {
  return await client({ method: 'get', url: `/batch/report/verify_signature/${batchSignatureId}` });
}

export async function commentReport(batchId, comment) {
  const data = { comment_text: comment };
  return await client({ method: 'post', url: `/batch/${batchId}/report/comment`, data });
}

export async function getReportSignees(batchId) {
  return await client({ method: 'get', url: `/batch/${batchId}/get_signees` });
}

export async function getBatch(batchId) {
  return await client({ method: 'get', url: `/batch-new/${batchId}` });
}

export async function getBatches() {
  return await client({ method: 'get', url: '/batches' });
}

export async function deleteBatches(batchIds) {
  const data = { batches: batchIds };
  return await client({ method: 'post', url: '/batch/delete', data });
}
