import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import SignatureValidIcon from 'assets/SignatureValidIcon';
import SignatureWarningIcon from 'assets/SignatureWarningIcon';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { verifySignature } from 'services/BatchService';

const NameText = (props) => (
  <Box component='span' sx={{ fontWeight: 'fontWeightBold', color: 'info.main' }} {...props} />
);

const DateText = (props) => (
  <Box component='span' sx={{ fontSize: 'fontSize', color: 'text.secondary' }} {...props} />
);

class Signature extends React.Component {
  static propTypes = {
    signature: PropTypes.shape({
      batch_sign_id: PropTypes.number.isRequired,
      u_id: PropTypes.string,
      sign_text: PropTypes.string,
      sign: PropTypes.shape({
        by: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        at: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      verified: false,
      message: '',
    };
  }

  async componentDidMount() {
    const { signature } = this.props;
    const res = await verifySignature(signature.batch_sign_id);
    if (!res.error) {
      const { verified, message } = res;
      this.setState({ loading: false, verified, message });
    } else {
      const { error } = res;
      const { message } = error.response.data;
      this.setState({ loading: false, verified: false, message });
    }

  }

  render() {
    const { signature } = this.props;
    const { loading, verified, message } = this.state;
    const { sign, sign_text, u_id } = signature;
    const { by, at } = sign;
    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{sign.by.name[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Typography>
                <NameText>{by.name}</NameText> signed <DateText>on {formatDate(at, FMT_DATE_TIME)}</DateText>
              </Typography>
            )}
            secondary={sign_text}
          />
        </ListItem>
        <Box component='li' sx={{ display: 'flex' }}>
          <Box sx={{ m: 'auto', display: 'flex' }}>
            {
              loading ? (
                <Tooltip title='Verifying signature...' arrow>
                  <Box component='span'>
                    <Skeleton variant='rounded' width={36} height={36} sx={{ m: 'auto' }} />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip title={message} arrow>
                  <Box>
                    {
                      verified ? (
                        <SignatureValidIcon sx={{ fontSize: 36, color: 'text.primary' }} />
                      ) : (
                        <SignatureWarningIcon sx={{ fontSize: 36, color: 'text.primary' }} />
                      )
                    }
                  </Box>
                </Tooltip>
              )
            }
            <Typography
              variant='body2'
              display='inline'
              sx={{ fontWeight: 'fontWeightBold', m: 'auto', ml: 1 }}
            >
              Signature ID: {u_id}
            </Typography>
          </Box>
        </Box>
        <Divider component='li' sx={{ mx: 2, my: 1 }} />
      </>
    );
  }
}

export default Signature;
