import * as ProjectService from 'services/ProjectService';

import { PROJECT } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const createProjects = createAsyncThunk(
  `${PROJECT}/create_projects`,
  async ({ projectNames, abbreviations }, { rejectWithValue }) => {
    const res = await ProjectService.createProjects(projectNames, abbreviations);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchProjects = createAsyncThunk(
  `${PROJECT}/fetch`,
  async (_, { rejectWithValue }) => {
    const res = await ProjectService.getProjects();
    return responseToPayload(res, rejectWithValue);
  },
);
