import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

function Error(props) {
  const { children } = props;
  return (
    <Box sx={{ mt: '64px', display: 'flex', height: 'calc(100vh - 64px)' }}>
      <Box sx={{ m: 'auto', mt: '20vh', textAlign: 'center', color: 'error.main' }}>
        <Typography variant='h1'>Oops!</Typography>
        <br />
        {children}
      </Box>
    </Box>
  );
}

Error.propTypes = {
  // The content of the error page (below the 'Oops!').
  children: PropTypes.node,
};

export default Error;
