import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { batchActions, batchSelectors } from 'store/batch';

import { ACTION_SHAKE_UPDATE } from 'constants/batchActions.constants';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import InfoTable from 'components/InfoTable';
import Paper from '@mui/material/Paper';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PropTypes from 'prop-types';
import React from 'react';
import TimerIcon from '@mui/icons-material/Timer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';
import moment from 'moment';
import { sessionSelectors } from 'store/session';

const MIN_SHAKE = 15; // Hours
const MAX_SHAKE = 20; // Hours
const BUTTON_WIDTH = 240;

class ShakeTimer extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    handleBatchAction: PropTypes.func.isRequired,
    editMiddleware: PropTypes.func.isRequired,
    lineData: PropTypes.shape({
      start: PropTypes.shape({
        at: PropTypes.string,
      }).isRequired,
      started: PropTypes.bool.isRequired,
      end: PropTypes.shape({
        at: PropTypes.string,
      }).isRequired,
      ended: PropTypes.bool.isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { lineData } = this.props;
    const { start, started, end, ended } = lineData;
    this.state = {
      warningOpen: false,
      startMoment: started ? moment(start.at) : null,
      endMoment: ended ? moment(end.at) : null,
    };
    this.editClock = this.editClock.bind(this);
  }

  editClock(type, dateTime) {
    const { handleBatchAction, step, substep, line } = this.props;
    if (type === 'start') {
      this.setState({ startMoment: dateTime });
    } else if (type === 'end') {
      this.setState({ endMoment: dateTime });
    }

    if (dateTime?.toISOString?.()) {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_SHAKE_UPDATE,
        payload: {
          type,
          at: dateTime ? dateTime.toISOString() : null,
        },
      });
    }
  }

  render() {
    const { startMoment, endMoment } = this.state;
    const { lineData, readOnly, appearDisabled, editMiddleware } = this.props;
    const { start, started, end, ended } = lineData;
    const { warningOpen } = this.state;
    const running = started && !ended;
    const runTimeHrs = started ? moment(new Date()).diff(start.at, 'hours', true) : 0;
    const completeRunTimeHrs = started && ended ? moment(end.at).diff(start.at, 'hours', true) : 0;

    return (
      <>
        {
          !readOnly ? (
            <Box sx={{ display: 'flex', mb: 2 }}>
              <ButtonGroup variant='contained' sx={{ m: 'auto' }}>
                {
                  running && runTimeHrs < MIN_SHAKE ? (
                    <Tooltip arrow placement='top' title='Stop Early'>
                      <Box component='span'>
                        <Button
                          sx={{
                            'color': 'warning.contrastText',
                            'bgcolor': 'warning.main',
                            'width': BUTTON_WIDTH,
                            '&:hover': {
                              color: 'warning.contrastText',
                              bgcolor: 'warning.main',
                            },
                          }}
                          variant='contained'
                          onClick={() => this.setState({ warningOpen: true })}
                        >
                          Shaking...
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : running ? (
                    <Button
                      sx={{
                        'color': 'error.contrastText',
                        'bgcolor': 'error.main',
                        'width': BUTTON_WIDTH,
                        '&:hover': {
                          color: 'error.contrastText',
                          bgcolor: 'error.main',
                        },
                      }}
                      variant='contained'
                      startIcon={<PauseCircleFilledIcon />}
                      onClick={() => this.editClock('end', new Date())}
                    >
                      Stop Shaking
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        'color': 'success.contrastText',
                        'bgcolor': 'success.main',
                        'width': BUTTON_WIDTH,
                        '&:hover': {
                          color: 'success.contrastText',
                          bgcolor: 'success.main',
                        },
                      }}
                      variant='contained'
                      startIcon={<PlayCircleFilledWhiteIcon />}
                      onClick={() => this.editClock('start', new Date())}
                      disabled={Boolean(started && ended)}
                    >
                      Start Shaking
                    </Button>
                  )
                }
                <Button disabled variant='contained'>
                  {
                    running ? (
                      <CircularProgress size={20} />
                    ) : ended ? (
                      <CheckCircleOutlineIcon sx={{ width: 20, height: 20, color: 'success.main' }} />
                    ) : (
                      <TimerIcon sx={{ width: 20, height: 20 }} />
                    )
                  }
                </Button>
              </ButtonGroup>
            </Box>
          ) : null
        }
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Paper variant='outlined' sx={{ m: 'auto' }}>
            <InfoTable
              defaultHeaderCellProps={{ sx: appearDisabled ? { color: 'text.disabled' } : null }}
              defaultBodyCellProps={{ sx: { ...(appearDisabled ? { color: 'text.disabled' } : {}), minWidth: 240, height: 32 }}}
              autoSpacing
              data={[
                {
                  key: 'start',
                  title: 'Shake Start:',
                  bodyCellProps: { sx: { height: 56 }},
                  content: (
                    !readOnly ? (
                      started ? (
                        <DateTimePicker
                          value={startMoment}
                          onChange={(value) => this.editClock('start', value)}
                          maxDateTime={ended ? moment(end.at) : null}
                          readOnly={Boolean(appearDisabled)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onFocus={() => editMiddleware(null)}
                              InputSx={appearDisabled ? { color: 'text.disabled' } : {}}
                            />
                          )}
                        />
                      ) : '–'
                    ) : formatDate(start.at, FMT_DATE_TIME)
                  ),
                },
                {
                  key: 'end',
                  title: 'Shake End:',
                  bodyCellProps: { sx: { height: 56 }},
                  content: (
                    !readOnly ? (
                      ended ? (
                        <DateTimePicker
                          value={endMoment}
                          onChange={(value) => this.editClock('end', value)}
                          minDateTime={started ? moment(start.at) : null}
                          readOnly={Boolean(appearDisabled)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onFocus={() => editMiddleware(null)}
                              InputSx={appearDisabled ? { color: 'text.disabled' } : {}}
                            />
                          )}
                        />
                      ) : '–'
                    ) : formatDate(end.at, FMT_DATE_TIME)
                  ),
                },
                {
                  key: 'duration',
                  title: 'Shaking Duration:',
                  content: (
                    started && ended ? (
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant='body2'>
                          {moment(start.at).from(end.at, true)}
                        </Typography>
                        {
                          completeRunTimeHrs >= MIN_SHAKE && completeRunTimeHrs <= MAX_SHAKE ? (
                            <CheckCircleOutlineIcon
                              sx={{ width: 20, height: 20, ml: 12, color: 'success.main' }}
                            />
                          ) : (
                            <Tooltip
                              arrow
                              placement='top'
                              title={`Ideally, culture is grown between ${MIN_SHAKE} and ${MAX_SHAKE} hours.`}
                            >
                              <ErrorOutlineIcon
                                sx={{ width: 20, height: 20, ml: 12, color: 'error.main' }}
                              />
                            </Tooltip>
                          )
                        }
                      </Box>
                    ) : started ? (
                      <Typography variant='body2'>
                        {moment(start.at).fromNow(true)}
                      </Typography>
                    ) : (<Typography variant='body2'>–</Typography>)
                  ),
                },
              ]}
            />
          </Paper>
        </Box>
        <Dialog
          title='Stop shaking early?'
          open={warningOpen}
          onClose={() => this.setState({ warningOpen: false })}
          onConfirm={() => this.editClock('end', new Date())}
        >
          Ideally, culture is grown between {MIN_SHAKE} and {MAX_SHAKE} hours. Are you sure you'd like to stop shaking? (If your start time is inaccurate, you can stop and edit the start time.)
        </Dialog>
      </>
    );
  }
}

const { selectBatchLineData } = batchSelectors;
const { handleBatchAction } = batchActions;
const { selectSessionUser } = sessionSelectors;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
    user: selectSessionUser(state),
  }),
  { handleBatchAction },
)(ShakeTimer);
