import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import React from 'react';

const RedText = (props) => (
  <Box component='span' sx={{ color: 'common.red' }} {...props} />
);

class TransformationProtocol extends React.Component {
  render() {
    const steps = [
      (<>Thaw E. coli DH5-α on ice</>),
      (<>Pipette <RedText>50μL</RedText> bacteria into tubes on ice</>),
      (<>Add <RedText>2μL</RedText> plasmid DNA to bacteria</>),
      (<>Flick tubes 5 times to mix</>),
      (<>Ice mixture for <RedText>30 min</RedText></>),
      (<>Heat shock mixture for <RedText>exactly 30 sec @42ºC</RedText></>),
      (<>Ice for <RedText>5 min</RedText></>),
      (<>Pipette <RedText>950μL</RedText> SOC outgrowth media onto mixture</>),
      (<>Shake and incubate for <RedText>1 hr @37ºC</RedText></>),
      (<>Label LB amp plates with constrcut name and date, then warm in incubator</>),
      (<>Add <RedText>100-150μL</RedText> bacteria onto plates and mix with a dozen or so beads</>),
      (<>Incubate overnight</>),
    ];
    return (
      <Checklist
        dense
        items={steps.map((step) => ({ content: step }))}
      />
    );
  }
}

export default TransformationProtocol;
