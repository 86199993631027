import client from 'services';

export async function getEquipments() {
  return await client({ method: 'get', url: '/equipments' });
}

export async function getEquipment(equipmentId) {
  return await client({ method: 'get', url: `/equipment/${equipmentId}` });
}

export async function getMaterials(withLots) {
  const params = withLots ? { with_lots: true } : {};
  return await client({ method: 'get', url: '/materials', params });
}

export async function getMaterial(materialId) {
  return await client({ method: 'get', url: `/material/${materialId}` });
}

export async function createMaterialLot(data) {
  // data = {
  //   material_id: int,
  //   lot_no: str,
  //   date_recieved: iso str,
  //   storage_location: str,
  //   custom_props: [
  //     {title: str, value: str},
  //     ...
  //   ]
  // };
  return await client({ method: 'post', url: '/material_lot/create', data });
}

export async function getChemicals(withLots) {
  const params = withLots ? { with_lots: true } : {};
  return await client({ method: 'get', url: '/chemicals', params });
}

export async function getChemical(chemicalId) {
  return await client({ method: 'get', url: `/chemical/${chemicalId}` });
}

export async function createChemicalLot(data) {
  // data = {
  //   chemical_id: int,
  //   lot_no: str,
  //   date_recieved: iso str,
  //   storage_location: str,
  //   expiration_date: iso str,
  //   custom_props: [
  //     {title: str, value: str},
  //     ...
  //   ]
  // };
  return await client({ method: 'post', url: '/chemical_lot/create', data });
}

export async function getBuffers(withBatches) {
  const params = withBatches ? { with_batches: true } : {};
  return await client({ method: 'get', url: '/buffers', params });
}

export async function getBuffer(bufferId) {
  return await client({ method: 'get', url: `/buffer/${bufferId}` });
}

export async function createBufferBatch(data) {
  // data = {
  //   buffer_id: int,
  //   batch_no: str,
  //   date_prepared: iso str,
  //   storage_location: str,
  //   expiration_date: iso str,
  //   custom_props: [
  //     {title: str, value: str},
  //     ...
  //   ]
  // };
  return await client({ method: 'post', url: '/buffer_batch/create', data });
}

export async function patchBufferBatch(data) {
  // data = {
  //   buffer_batch_id: int (REQUIRED),
  //   buffer_id: int,
  //   batch_no: str,
  //   date_prepared: iso str,
  //   storage_location: str,
  //   expiration_date: iso str,
  //   custom_props: [
  //     {title: str, value: str},
  //     ...
  //   ]
  // };
  const { buffer_batch_id, ...restData } = data;
  return await client({ method: 'patch', url: `/buffer_batch/${buffer_batch_id}`, data: restData });
}
