import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';

function ImageCaption(props) {
  const { img, onEdit, onDelete } = props;
  const { uploaded_at, author } = img;

  const getCaption = () => {
    if (img.caption) return img.caption;
    if (uploaded_at && author?.name) return `Uploaded on ${formatDate(uploaded_at, FMT_DATE_TIME)} by ${author.name}`;
    if (uploaded_at) return `Uploaded on ${formatDate(uploaded_at, FMT_DATE_TIME)}`;
    if (author?.name) return `Uploaded by ${author.name}`;
    return '';
  };

  return (
    <Typography variant='caption' sx={{ color: 'grey.100' }}>
      { getCaption() }
      {
        onEdit ? (
          <IconButton
            size='small'
            onClick={onEdit}
            sx={{ ml: 1 }}
          >
            <EditIcon sx={{ color: 'grey.100' }} />
          </IconButton>
        ) : null
      }
      {
        onDelete ? (
          <IconButton
            size='small'
            onClick={onDelete}
          >
            <DeleteIcon sx={{ color: 'grey.100' }} />
          </IconButton>
        ) : null
      }
    </Typography>
  );
}

ImageCaption.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  img: PropTypes.shape({
    caption: PropTypes.node,
    uploaded_at: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default ImageCaption;
