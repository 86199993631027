import * as extraActions from './user.extraActions';
import * as selectors from './user.selectors';

import { extraReducers, reducers } from './user.reducers';

import { USER } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './user.initialState';

const slice = createSlice({
  name: USER,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const userSelectors = { ...selectors };
export const userActions = { ...slice.actions, ...extraActions };
export const { reducer: userReducer } = slice;
