import * as d3 from 'd3';

import { BLUE_SELECT, FONT, disableTextSelect } from 'constants/styles.constants';

import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';

const BLOCK_WIDTH = 75;
const BLOCK_HEIGHT = 25;
const TEXT_PADDING = 4;
const FONT_SIZE = 12;

class TableSelection extends React.Component {
  static propTypes = {
    /* eslint-disable-next-line react/no-unused-prop-types */
    setSelection: PropTypes.func.isRequired, // eslint confused because use that.props
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    selection: PropTypes.shape({
      rowRange: PropTypes.arrayOf(PropTypes.number).isRequired,
      colRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDrag(svg) {
    let colStart = null;
    let rowStart = null;
    this.selection = this.svg.append('g');
    const that = this;

    function getCol(event) {
      const { data } = that.props;
      const xClick = event.x + that.container.node().scrollLeft;
      return Math.min(Math.max(Math.floor(that.xInv(xClick)), 0), d3.max(data, (d) => d.length));
    }

    function getRow(event) {
      const { data } = that.props;
      const yClick = event.y + that.container.node().scrollTop;
      return Math.min(Math.max(Math.floor(that.yInv(yClick)), 0), data.length);
    }

    function dragStart(event) {
      that.selection.selectAll('*').remove();
      colStart = getCol(event);
      rowStart = getRow(event);
      that.selection.append('rect')
        .attr('x', that.x(colStart))
        .attr('y', that.y(rowStart))
        .attr('width', BLOCK_WIDTH)
        .attr('height', BLOCK_HEIGHT)
        .attr('stroke', BLUE_SELECT)
        .attr('fill', BLUE_SELECT)
        .attr('fill-opacity', 0.3)
        .attr('id', 'selectionBox');
    }

    function dragged(event) {
      const col = getCol(event);
      const row = getRow(event);
      that.selection.selectAll('*').remove();
      that.selection.append('rect')
        .attr('x', that.x(Math.min(colStart, col)))
        .attr('y', that.y(Math.min(rowStart, row)))
        .attr('width', that.x(Math.abs(col - colStart) + 1))
        .attr('height', that.y(Math.abs(row - rowStart) + 1))
        .attr('stroke', BLUE_SELECT)
        .attr('fill', BLUE_SELECT)
        .attr('fill-opacity', 0.3)
        .attr('id', 'selectionBox');
    }

    function dragEnd(event) {
      const { setSelection } = that.props;
      const col = getCol(event);
      const row = getRow(event);
      setSelection({
        rowRange: [rowStart, row].sort((a, b) => a - b),
        colRange: [colStart, col].sort((a, b) => a - b),
      });
    }

    return d3.drag()
      .on('start', dragStart)
      .on('drag', dragged)
      .on('end', dragEnd)(svg);
  }

  componentDidMount() {
    const { selection } = this.props;
    /* eslint-disable-next-line react/no-unused-class-component-methods */
    this.container = d3.select('#csv_selction_container');
    this.svg = d3.select('#csv_selection');
    this.handleDrag(this.svg);
    if (selection.rowRange && selection.colRange) {
      const [rowStart, rowEnd] = selection.rowRange;
      const [colStart, colEnd] = selection.colRange;
      this.selection.append('rect')
        .attr('x', this.x(colStart))
        .attr('y', this.y(rowStart))
        .attr('width', this.x(Math.abs(colEnd - colStart) + 1))
        .attr('height', this.y(Math.abs(rowEnd - rowStart) + 1))
        .attr('stroke', BLUE_SELECT)
        .attr('fill', BLUE_SELECT)
        .attr('fill-opacity', 0.3)
        .attr('id', 'selectionBox');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.selection && prevProps.data !== this.props.data) {
      this.selection.selectAll('*').remove();
    }
  }

  render() {
    const { data } = this.props;
    const maxCols = d3.max(data, (row) => row.length);
    const height = data.length * BLOCK_HEIGHT;
    const width = maxCols * BLOCK_WIDTH;
    const x = d3.scaleLinear()
      .domain([0, maxCols])
      .range([0, width]);
    const y = d3.scaleLinear()
      .domain([0, data.length])
      .range([0, height]);
    const xInv = d3.scaleLinear()
      .domain([0, width])
      .range([0, maxCols]);
    const yInv = d3.scaleLinear()
      .domain([0, height])
      .range([0, data.length]);

    this.x = x;
    this.y = y;
    /* eslint-disable-next-line react/no-unused-class-component-methods */
    this.xInv = xInv;
    /* eslint-disable-next-line react/no-unused-class-component-methods */
    this.yInv = yInv;
    return (
      <svg
        width={width}
        height={height}
        id='csv_selection'
      >
        {
          data.map((row, rowIdx) => row.map((val, colIdx) => {
            const textWidth = TextMeasurer.getWidth(val, FONT_SIZE, FONT);
            const scaleFactor = (BLOCK_WIDTH - (2 * TEXT_PADDING)) / textWidth;
            return (
              /* eslint-disable-next-line react/no-array-index-key */
              <g key={`csv_${rowIdx},${colIdx}`}>
                <rect
                  x={x(colIdx)}
                  y={y(rowIdx)}
                  width={BLOCK_WIDTH}
                  height={BLOCK_HEIGHT}
                  fill='none'
                  stroke='grey'
                />
                <text
                  x={x(colIdx) + TEXT_PADDING}
                  y={y(rowIdx) + (BLOCK_HEIGHT / 2) + (FONT_SIZE / 2)}
                  fontSize={FONT_SIZE}
                  fill='black'
                  fontFamily={FONT}
                  style={{ ...disableTextSelect, transformOrigin: `${x(colIdx)}px ${y(rowIdx) + (BLOCK_HEIGHT / 2)}px` }}
                  transform={scaleFactor < 1 ? `scale(${scaleFactor},${scaleFactor})` : null}
                >
                  {val}
                </text>
              </g>
            );
          }))
        }
      </svg>
    );
  }
}

export default TableSelection;
