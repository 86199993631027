import { batchActions, batchSelectors } from 'store/batch';

import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class VectorConcentrationTable extends React.Component {
  static propTypes = {
    handleConstructInput: PropTypes.func.isRequired,
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      construct_data: PropTypes.shape({
        backbone: PropTypes.shape({
          vector: PropTypes.string.isRequired,
          concentration: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })).isRequired,
    newDigestConstructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(value, row) {
    const { handleConstructInput } = this.props;
    handleConstructInput(row.constructCode, ['construct_data', 'backbone', 'concentration'], value);
  }

  render() {
    const { constructs, newDigestConstructs } = this.props;
    const columns = [
      { key: 'vectorNumber', title: '' },
      { key: 'constructCode', title: 'Construct' },
      { key: 'vector', title: 'Vector' },
      {
        key: 'concentration',
        title: 'Concentration',
        editable: (row) => newDigestConstructs.map((c) => c.construct_code).includes(row.constructCode),
        adornment: 'ng/µL',
        inputProps: { type: 'number' },
      },
    ];
    const rows = constructs.map((construct, idx) => ({
      key: `${construct.construct_code}_vectorConcentrationRow`,
      vectorNumber: `V${idx + 1}`,
      constructCode: construct.construct_code,
      vector: construct.construct_data.backbone.vector || '–',
      concentration: construct.construct_data.backbone.concentration || '',
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.onInputChange}
        tableProps={{ size: 'small' }}
        containerProps={{ sx: { maxHeight: 600, overflow: 'auto', m: 'auto' }}}
      />
    );
  }
}

const { selectConstructs, selectNewDigestConstructs } = batchSelectors;
const { handleConstructInput } = batchActions;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
    newDigestConstructs: selectNewDigestConstructs(state),
  }),
  { handleConstructInput },
)(VectorConcentrationTable);
