import { Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';

import InfoTable from 'components/InfoTable';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { featureDetailTypes } from 'constants/seqFeatures.constants';
import { getLocationRange } from 'utils/sequence.utils';

function FeatureDialog(props) {
  const { feature, onClose } = props;
  const [detailSelected, setDetailSelected] = useState(Object.keys(featureDetailTypes)[0]);

  if (!feature) return null;
  const { feature_name, feature_data, length, type } = feature.feature;
  const { strand } = feature.location_data;
  const location = getLocationRange(feature.location_data);
  const details = [feature_data.qualifiers[detailSelected]].flat();
  const data = [
    { key: 'feature', title: 'Feature', content: feature_name },
    { key: 'location', title: 'Location', content: `${location[0]}...${location[1]}` },
    { key: 'length', title: 'Length', content: `${length} bp` },
    { key: 'strand', title: 'Strand', content: strand === 1 ? 'Top' : strand === -1 ? 'Bottom' : 'N/A' },
    { key: 'type', title: 'Type', content: type },
    {
      key: 'details',
      title: (
        <TextField
          select
          value={detailSelected}
          onChange={(event) => setDetailSelected(event.target.value)}
          InputSx={{ fontWeight: 'fontWeightBold' }}
        >
          {
            Object.entries(featureDetailTypes).map(([key, displayName]) => (
              <MenuItem value={key} key={key}>{displayName}</MenuItem>
            ))
          }
        </TextField>
      ),
      content: details.map((d, idx) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <React.Fragment key={`detail${idx}`}>{d}<br /></React.Fragment>
      )),
    },
  ];
  return (
    <Dialog
      open={Boolean(feature)}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { minWidth: 640 }}}
      title={feature_name}
      onConfirm={onClose}
      confirmButtonText='OK'
      disableCloseOnConfirm
    >
      <InfoTable
        data={data}
        defaultHeaderCellProps={{ sx: { width: 0.35 }}}
        defaultBodyCellProps={{ sx: { width: 0.65 }}}
        borderTop
        borderBottom
      />
    </Dialog>
  );
}

FeatureDialog.propTypes = {
  feature: PropTypes.shape({
    feature: PropTypes.shape({
      feature_name: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      feature_data: PropTypes.shape({
        qualifiers: PropTypes.objectOf(PropTypes.node),
      }).isRequired,
    }).isRequired,
    location_data: PropTypes.shape({
      strand: PropTypes.oneOf([1, -1]).isRequired,
      type: PropTypes.oneOf(['singular', 'compound']).isRequired,
      location: PropTypes.oneOfType([
        PropTypes.shape({
          start: PropTypes.number.isRequired,
          end: PropTypes.number.isRequired,
        }),
        PropTypes.arrayOf(PropTypes.shape({
          start: PropTypes.number.isRequired,
          end: PropTypes.number.isRequired,
        })),
      ]).isRequired,
    }).isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default FeatureDialog;
