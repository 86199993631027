import { designActions, designSelectors } from 'store/design';

import AutocloneForm from './AutocloneForm';
import AutocloneResults from './AutocloneResults';
import Box from '@mui/material/Box';
import ConstructUpload from './ConstructUpload';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class Design extends React.Component {
  static propTypes = {
    resetDesign: PropTypes.func.isRequired,
    handleConstructInput: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    constructs: PropTypes.array.isRequired,
  };

  componentWillUnmount() {
    this.props.resetDesign();
  }

  render() {
    const { constructs, handleConstructInput, handleSave } = this.props;
    return (
      <>
        <ConstructUpload sx={{ position: 'fixed', top: 12, left: 150, zIndex: 1101 }} />
        <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, width: 1050 }}>
          <AutocloneForm />
          {
            constructs.map((construct) => (
              <AutocloneResults
                key={construct.construct_code}
                construct={construct}
                handleConstructInput={handleConstructInput}
                handleSave={handleSave}
              />
            ))
          }
        </Box>
      </>
    );
  }
}

const { selectConstructs } = designSelectors;
const { handleConstructInput, handleSave, resetDesign } = designActions;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
  }),
  { resetDesign, handleConstructInput, handleSave },
)(Design);
