import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TextField } from '@acheloisbiosoftware/absui.core';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

export const STATIC = 'STATIC';
export const INPUT_TEXT = 'INPUT_TEXT';
export const INPUT_NUM = 'INPUT_NUM';
export const CALC = 'CALC';

function ChecklistItem(props) {
  const {
    sx,
    content,
    subcontent,
    checked,
    onClick,
    dense,
    disabled,
    appearDisabled,
    checkboxProps,
    onInput,
    inputProps,
  } = props;

  const sxDisabled = { color: 'text.disabled' };
  const applySxDisabled = appearDisabled || disabled;
  const emphasizedSx = { fontWeight: 'fontWeightBold', fontStyle: 'italic' };

  return (
    <Box sx={mergeSx({ display: 'flex', flexDirection: 'column' }, sx)}>
      <FormControlLabel
        label={content}
        sx={applySxDisabled ? sxDisabled : null}
        control={(
          <Checkbox
            color='default'
            checked={checked}
            onClick={onClick}
            size={dense ? 'small' : 'medium'}
            disabled={disabled}
            {...checkboxProps}
            sx={mergeSx(
              checkboxProps?.sx,
              applySxDisabled ? sxDisabled : {},
              { p: dense ? 0.5 : 1 },
            )}
          />
        )}
      />
      {
        subcontent ? (
          <Box
            sx={{
              ...(applySxDisabled ? sxDisabled : {}),
              ml: dense ? '28px' : '30px',
            }}
          >
            <Box component='ul' sx={{ listStyleType: 'none', my: 0 }}>
              {
                subcontent.map((sc) => (
                  <Box component='li' key={sc.id}>
                    {
                      [INPUT_NUM, INPUT_TEXT].includes(sc.type) ? (
                        <Box sx={{ mb: 1 }}>
                          {
                            sc.label.split('¿').map((segment, idx, arr) => (
                              /* eslint-disable-next-line react/no-array-index-key */
                              <React.Fragment key={`segment${idx}_${sc.id}`}>
                                <Typography
                                  display='inline'
                                  sx={[
                                    { mx: 0 },
                                    sc.emphasized ? emphasizedSx : {},
                                  ]}
                                >
                                  {segment}
                                </Typography>
                                {
                                  (idx + 1 < arr.length) || (arr.length === 1) ? (
                                    <TextField
                                      type={sc.type === INPUT_NUM ? 'number' : 'text'}
                                      value={sc.value}
                                      variant='outlined'
                                      size='small'
                                      onChange={(e) => onInput?.(e, sc.id)}
                                      disabled={disabled}
                                      InputSx={applySxDisabled ? sxDisabled : null}
                                      endAdornment={sc.unit}
                                      {...inputProps}
                                      sx={mergeSx({ verticalAlign: 'baseline', mx: 1.5 }, inputProps?.sx)}
                                    />
                                  ) : null
                                }
                              </React.Fragment>
                            ))
                          }
                        </Box>
                      ) : sc.type === STATIC ? (
                        <Typography>{sc.label}</Typography>
                      ) : sc.type === CALC ? (
                        <Typography sx={sc.emphasized ? emphasizedSx : null}>
                          {
                            sc.label.split('¿').map((segment, idx, arr) => (
                              /* eslint-disable-next-line react/no-array-index-key */
                              <React.Fragment key={`segment${idx}_${sc.id}`}>
                                {segment}
                                {
                                  (idx + 1 < arr.length) || (arr.length === 1) ? (
                                    sc.value ? (
                                      <Box
                                        component='span'
                                        sx={{
                                          color: sc.emphasized ? 'common.blue' : 'common.red',
                                          whiteSpace: 'nowrap',
                                          opacity: (theme) => (applySxDisabled ? theme.opacity.disabled : theme.opacity.primary),
                                        }}
                                      >
                                        [ {sc.unit ? `${sc.value} ${sc.unit}` : sc.value} ]
                                      </Box>
                                    ) : (
                                      <Tooltip
                                        title={<Typography variant='caption'>A previous input is required for this calculated value.</Typography>}
                                        arrow
                                        placement='top'
                                      >
                                        <Box component='span'>
                                          <Skeleton width={80} sx={{ display: 'inline-block' }} />
                                        </Box>
                                      </Tooltip>
                                    )
                                  ) : null
                                }
                              </React.Fragment>
                            ))
                          }
                        </Typography>
                      ) : sc?.label
                    }
                  </Box>
                ))
              }
            </Box>
          </Box>
        ) : null
      }
    </Box>
  );
}

ChecklistItem.propTypes = {
  /** The checklist item content to be rendered. */
  content: PropTypes.node.isRequired,

  /**
   * The subcontent to render as an array of objects with an ID, type, label
   * (the content to be displayed), value (if one of the input or calculation
   * types), and unit (if one of the input or calculation types, optional).
   */
  subcontent: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([STATIC, INPUT_TEXT, INPUT_NUM, CALC]),
    label: PropTypes.node.isRequired,
    value: PropTypes.node,
    unit: PropTypes.string,
  })),

  /** Whether or not the checkbox is checked. */
  checked: PropTypes.bool,

  /**
   * Click callback function for the *entire* element (including the checkbox
   * AND the content).
   */
  onClick: PropTypes.func,

  /**
   * Callback function for input changes in INPUT_NUM/INPUT_TEXT subcontent
   * lines. (event, subcontent.id) => void
   */
  onInput: PropTypes.func,

  /** If true, the items are rendered with dense padding. */
  dense: PropTypes.bool,

  /** If true, the checklist item will be uneditable. */
  disabled: PropTypes.bool,

  /**
   * If true, the checklsit item will appear disabled. NOTE: this is purely
   * visual, and the item IS still editable. Use the prop disabled to prevent
   * editing.
   */
  appearDisabled: PropTypes.bool,

  /** Props to be passed to the Checkbox component. */
  checkboxProps: PropTypes.object,

  /** Props to be passed to any input boxes in subcontent. */
  inputProps: PropTypes.object,

  /** sx to be applied to the FormGroup wrapper component. */
  sx: sxPropType,
};

export default ChecklistItem;
