import Grid from '@mui/material/Grid';
import NotesRTE from '../NotesRTE';
import PropTypes from 'prop-types';
import React from 'react';
import ReactionList from './ReactionList';
import ReactionRecipe from './ReactionRecipe';
import ReactionTable from './ReactionTable';
import StepperButtons from 'views/Batch/StepperButtons';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';

const STEP_NAME = 'SEQUENCING';

class SequencingStep extends React.Component {
  static propTypes = {
    onNextStep: PropTypes.func.isRequired,
  };

  render() {
    const { onNextStep } = this.props;
    return (
      <>
        <Grid container spacing={2} sx={{ px: 3, py: 1 }}>
          <Grid item xs={5}>
            <ReactionTable />
          </Grid>
          <Grid item xs={4}>
            <ReactionList />
          </Grid>
          <Grid item xs={3}>
            <ReactionRecipe />
          </Grid>
          <Grid item xs={12}>
            <NotesRTE step={STEP_NAME} />
          </Grid>
        </Grid>
        <StepperButtons position='right' onNextStep={onNextStep} />
      </>
    );
  }
}

const { onNextStep } = batchStepperActions;

export default connect(
  null, { onNextStep },
)(SequencingStep);
