import { designActions, designSelectors } from 'store/design';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InsertSuggestionItem from './InsertSuggestionItem';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class InsertSuggestions extends React.Component {
  static propTypes = {
    handleSuggestionSelection: PropTypes.func.isRequired,
    getSuggestions: PropTypes.func.isRequired,
    insertName: PropTypes.string.isRequired,
    species: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    insertSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    sx: sxPropType,
  };

  componentDidUpdate(prevProps) {
    const { insertName, species, getSuggestions } = this.props;
    if (prevProps.insertName !== insertName || prevProps.species !== species) {
      getSuggestions(insertName, species);
    }
  }

  render() {
    const { sx, handleSuggestionSelection, insertSuggestions, loading } = this.props;
    return (
      <Paper variant='outlined' sx={mergeSx(sx, { overflow: 'auto' })}>
        {loading ? (
          <Box sx={mergeSx(sx, { display: 'flex', width: 1 })}>
            <CircularProgress size={48} sx={{ m: 'auto' }} />
          </Box>
        ) : insertSuggestions.length > 0 ? (
          <List dense>
            {insertSuggestions.map((suggestion) => (
              <InsertSuggestionItem
                key={JSON.stringify(suggestion)}
                handleSuggestionSelection={() => handleSuggestionSelection(suggestion)}
                suggestion={suggestion}
              />
            ))}
          </List>
        ) : (
          <Typography variant='subtitle1' sx={{ m: 3, textAlign: 'center' }}>
            No insert suggestions to show...
          </Typography>
        )}
      </Paper>
    );
  }
}

const { selectInsertSuggestions, selectSuggestionIsLoading } = designSelectors;
const { getInsertSuggestionsFactory } = designActions;

export default connect(
  (state) => ({
    insertSuggestions: selectInsertSuggestions(state),
    loading: selectSuggestionIsLoading(state),
  }),
  { getSuggestions: getInsertSuggestionsFactory() },
)(InsertSuggestions);
