import PropTypes from 'prop-types';
import React from 'react';
import { featureTypes } from 'components/SeqView/SeqView.constants';
import { getAnnotationPathCircular } from 'components/SeqView/SeqView.utils';

class AnnotationBlock extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      thetaStart: PropTypes.number.isRequired,
      thetaEnd: PropTypes.number.isRequired,
      blockRow: PropTypes.number,
      r_block: PropTypes.number.isRequired,
      type: PropTypes.oneOf(featureTypes).isRequired,
      strand: PropTypes.oneOf([1, -1]).isRequired,
      color: PropTypes.object.isRequired,
      location_id: PropTypes.number.isRequired,
      annotationId: PropTypes.string.isRequired,
    }).isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    blockHeight: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const { feature, cx, cy, blockHeight, onClick, onDoubleClick, onHover } = this.props;
    const {
      thetaStart,
      thetaEnd,
      blockRow,
      r_block,
      type,
      strand,
      color,
      location_id,
      annotationId,
    } = feature;
    if (blockRow === null) return null;
    const path = getAnnotationPathCircular(cx, cy, r_block, [thetaStart, thetaEnd], blockHeight, type, strand);
    const clickable = onClick || onDoubleClick;
    return (
      <path
        d={path}
        fill={color}
        stroke='black'
        className={`${annotationId}_block`}
        onClick={onClick ? () => onClick(location_id) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(location_id) : null}
        style={clickable ? { cursor: 'pointer' } : {}}
        onMouseEnter={clickable ? () => onHover(feature, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(feature, 'leave') : null}
      />
    );
  }
}

export default AnnotationBlock;
