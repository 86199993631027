import * as SequencingService from 'services/SequencingService';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  isValidReadMapping,
  selectReadMapping,
  selectSequencingStatus,
  selectShowMappingDialog,
} from './sequencing.selectors';

import { SEQUENCING } from 'constants/store.constants';
import { STATUS_ERROR } from 'constants/statuses.constants';
import { responseToPayload } from 'utils/store.utils';

export const _validateSequencing = createAsyncThunk(
  `${SEQUENCING}/validate`,
  async (fileNames, { rejectWithValue }) => {
    const res = await SequencingService.validateSequencing(fileNames);
    return responseToPayload(res, rejectWithValue);
  },
);

export const _uploadSequencing = createAsyncThunk(
  `${SEQUENCING}/upload`,
  async (data, { rejectWithValue }) => {
    const res = await SequencingService.uploadSequencing(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const removeAlignment = createAsyncThunk(
  `${SEQUENCING}/remove_alignment`,
  async (alignmentId, { rejectWithValue }) => {
    const res = await SequencingService.removeAlignment(alignmentId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchAlignments = createAsyncThunk(
  `${SEQUENCING}/fetch`,
  async (data, { rejectWithValue }) => {
    const res = await SequencingService.getSequencing(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const hideMappingDialog = createAction(`${SEQUENCING}/hideMappingDialog`);

export const uploadSequencing = (files, batchId) => (dispatch, getState) => {
  const state = getState();
  const readMapping = selectReadMapping(state);
  if (!isValidReadMapping(readMapping)) return;
  if (selectShowMappingDialog(state)) dispatch(hideMappingDialog());
  if (!files) return;
  const fileList = Array.from(files);
  dispatch(_uploadSequencing({
    files: fileList,
    body: { read_mapping: readMapping, batch_id: batchId },
  }));
};

export const validateUploadSequencing = (files, batchId) => async (dispatch, getState) => {
  if (!files) return;
  const fileList = Array.from(files);
  const fileNames = fileList.map((file) => file.name);
  await dispatch(_validateSequencing(fileNames));
  const state = getState();
  if (
    selectSequencingStatus(state) !== STATUS_ERROR &&
      !selectShowMappingDialog(state)
  ) {
    await dispatch(uploadSequencing(fileList, batchId));
  }
};
