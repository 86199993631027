import { batchActions } from 'store/batch';
import { constructActions } from 'store/construct';
import { designActions } from 'store/design';

// #############################################################################
// ############################## Reused Reducers ##############################
// #############################################################################
const reduceLock = (state) => {
  state.navLocked = true;
};

const reduceUnlock = (state) => {
  state.navLocked = false;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const lockNav = reduceLock;
const unlockNav = reduceUnlock;
const setBlockMessage = (state, action) => {
  state.message = action.payload;
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const unlockFromRequest = ({ fulfilled }) => ({
  [fulfilled]: reduceUnlock,
});

const lockFromAction = (actionName) => ({
  [actionName]: reduceLock,
});

export const reducers = { lockNav, unlockNav, setBlockMessage };
export const extraReducers = {
  ...lockFromAction(batchActions._handleBatchInputAction),
  ...lockFromAction(batchActions._handleBatchActionAction),
  ...lockFromAction(batchActions._handleConstructInputAction),
  ...lockFromAction(constructActions._handleConstructInputAction),
  ...lockFromAction(designActions._handleConstructInputAction),
  ...unlockFromRequest(batchActions.editGelImage),
  ...unlockFromRequest(batchActions.fetchBatch),
  ...unlockFromRequest(batchActions.removeGelImage),
  ...unlockFromRequest(batchActions.updateBatch),
  ...unlockFromRequest(constructActions.updateConstruct),
  ...unlockFromRequest(designActions.updateConstructs),
  ...unlockFromRequest(batchActions.uploadGelImages),
};
