import { batchActions, batchSelectors } from 'store/batch';

import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import NotesRTE from './NotesRTE';
import PropTypes from 'prop-types';
import React from 'react';
import StepperButtons from 'views/Batch/StepperButtons';
import Typography from '@mui/material/Typography';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { timeNow } from 'utils/date.utils';

const STEP_NAME = 'MAXI_PREP';

class MaxiPrepStep extends React.Component {
  static propTypes = {
    handleBatchInput: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    maxiData: PropTypes.shape({
      plasmid_backups_made: PropTypes.shape({
        at: PropTypes.string,
      }).isRequired,
    }).isRequired,
    onNextStep: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(name) {
    const { maxiData, user, handleBatchInput } = this.props;
    const currChecked = Boolean(maxiData[name].at);
    handleBatchInput([STEP_NAME, name], !currChecked ? { at: timeNow(), by: user } : { at: null, by: null });
  }

  render() {
    const { maxiData, onNextStep } = this.props;
    const checklistItems = [
      { key: 'plasmid_backups_made', display: 'Two separate plasmid backups made?' },
      { key: 'bacteria_stock_banked', display: 'Bacteria working stock banked in -80ºC?' },
      { key: 'bacteria_backup_banked', display: 'Bacteria backup banked in -80ºC?' },
      { key: 'post_maxi_sequenced', display: 'Post maxi prep sequencing done?' },
    ];
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ m: 'auto' }}>
            <Typography
              variant='h5'
              sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5, textAlign: 'center' }}
            >
              Storage
            </Typography>
            <Checklist
              items={checklistItems.map(({ key, display }) => ({
                content: display,
                checked: Boolean(maxiData[key].at),
                onClick: () => this.handleCheck(key),
              }))}
            />

          </Box>
          <NotesRTE
            step={STEP_NAME}
            sx={{ m: 'auto' }}
          />
        </Box>
        <StepperButtons position='right' onNextStep={onNextStep} />
      </>
    );
  }
}

const { handleBatchInput } = batchActions;
const { selectBatchStepData } = batchSelectors;
const { onNextStep } = batchStepperActions;
const { selectSessionUser } = sessionSelectors;

export default connect(
  (state) => ({
    maxiData: selectBatchStepData(state, STEP_NAME),
    user: selectSessionUser(state),
  }),
  { handleBatchInput, onNextStep },
)(MaxiPrepStep);
