import { navigationSelectors } from 'store/navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function NavigationLock() {
  const { selectNavLocked } = navigationSelectors;
  const when = useSelector(selectNavLocked);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      // Prompts the user before closing the page, see:
      // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
      event.preventDefault();
      event.returnValue = '';
    };

    const startBlocking = () => {
      window.addEventListener('beforeunload', onBeforeUnload);
    };

    const stopBlocking = () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };

    if (when) startBlocking();
    if (!when) stopBlocking();
    return () => stopBlocking();
  }, [when]);

  return null;
}

export default NavigationLock;
