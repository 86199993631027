import * as extraActions from './project.extraActions';
import * as selectors from './project.selectors';

import { extraReducers, reducers } from './project.reducers';

import { PROJECT } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './project.initialState';

const slice = createSlice({
  name: PROJECT,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const projectSelectors = { ...selectors };
export const projectActions = { ...slice.actions, ...extraActions };
export const { reducer: projectReducer } = slice;
