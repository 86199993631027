import { Dialog } from '@acheloisbiosoftware/absui.core';
import InfoTable from 'components/InfoTable';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from 'utils/formatting.utils';

const createInfoRow = (entry) => {
  const [key, value] = entry;
  let content;
  if (key === 'full_record') {
    content = (<Link href={value} target='_blank' rel='noreferrer'>{value}</Link>);
  } else if (key === 'length') {
    content = `${value} bp`;
  } else if (Array.isArray(value)) {
    content = value.map((s, idx) => (
      <React.Fragment key={`contentLine_${s}`}>
        {s}
        {(idx + 1 !== value.length) ? <br /> : null}
      </React.Fragment>
    ));
  } else {
    content = value;
  }
  return { key, title: capitalize(key.replace('_', ' ')), content };
};

class InsertSuggestionModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    suggestion: PropTypes.shape({
      feature_name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      origin: PropTypes.string.isRequired,
      additional_info: PropTypes.object.isRequired,
    }).isRequired,
  };

  render() {
    const { suggestion, open, onClose, handleConfirm } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        title={suggestion.feature_name}
        subtitle={`${suggestion.type} from ${suggestion.origin}`}
        onConfirm={handleConfirm}
      >
        <InfoTable
          autoSpacing
          data={Object.entries(suggestion.additional_info).map(createInfoRow)}
          borderTop
          borderBottom
        />
      </Dialog>
    );
  }
}

export default InsertSuggestionModal;
