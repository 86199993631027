import { sequencingActions, sequencingSelectors } from 'store/sequencing';

import Alignment from './Alignment';
import Box from '@mui/material/Box';
import NoAlignmentError from './NoAlignmentError';
import NoConstructError from './NoConstructError';
import PropTypes from 'prop-types';
import React from 'react';
import ReadMappingDialog from './ReadMappingDialog';
import { UploadButton } from '@acheloisbiosoftware/absui.core';
import { connect } from 'react-redux';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class Sequencing extends React.Component {
  static propTypes = {
    // The batch ID of the batch to fetch sequencing for. NOTE: if this prop
    // is supplied, props.constructs will be ignored.
    batchId: PropTypes.number,

    // The constructs to fetch sequencing for. Each object representing a
    // construct must contain a construct code (construct.construct_code). NOTE:
    // this prop is ignored if props.batchId is supplied.
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
    })),

    // If true, sequence uploading will be disabled, so the alignments are only
    // viewable.
    readOnly: PropTypes.bool,

    // sx to be applied to the sequencing container.
    sx: sxPropType,

    // Internal props
    alignments: PropTypes.arrayOf(PropTypes.shape({
      alignment_id: PropTypes.number.isRequired,
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      alignment_data: PropTypes.shape({
        read_meta_data: PropTypes.array.isRequired,
      }).isRequired,
    })).isRequired,
    fetchAlignments: PropTypes.func.isRequired,
    validateUploadSequencing: PropTypes.func.isRequired,
    uploadSequencing: PropTypes.func.isRequired,
    resetSequencing: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  handleUpload(event) {
    const { batchId } = this.props;
    const { files } = event.target;
    if (!files) {
      return;
    }
    const fileList = Array.from(files);
    this.setState({ files: fileList });
    this.props.validateUploadSequencing(fileList, batchId);
  }

  removeFile(idx) {
    const { files } = this.state;
    files.splice(idx, 1);
    this.setState({ files });
  }

  componentDidMount() {
    const { fetchAlignments, constructs, batchId } = this.props;
    if (constructs) {
      const constructCodes = constructs.map((construct) => construct.construct_code);
      fetchAlignments({ construct_codes: constructCodes, batch_id: batchId });
    }
  }

  componentWillUnmount() {
    this.props.resetSequencing();
  }

  render() {
    const { sx, readOnly, loading, alignments, uploadSequencing, batchId } = this.props;
    const { files } = this.state;
    return (
      <Box sx={mergeSx({ py: 2, px: 4 }, sx)}>
        {
          readOnly ? null : (
            <Box sx={{ display: 'flex' }}>
              <UploadButton
                loading={loading}
                onChange={this.handleUpload}
                sx={{ m: 'auto', mb: 1.5 }}
                accept='.ab1'
                multiple
              >
                Upload Reads
              </UploadButton>
            </Box>
          )
        }
        {
          alignments.map((alignment) => {
            const { alignment_data, construct_code, clone, alignment_id } = alignment;
            if (alignment_data.read_meta_data.length) {
              return (
                <Alignment
                  key={`${construct_code}-${clone}_${alignment_id}`}
                  alignment={alignment}
                />
              );
            }
            return null;
          })
        }
        <ReadMappingDialog
          onConfirm={() => uploadSequencing(files, batchId)}
          loading={loading}
          removeFile={this.removeFile}
        />
        <NoAlignmentError />
        <NoConstructError />
      </Box>
    );
  }
}

const { fetchAlignments, resetSequencing, uploadSequencing, validateUploadSequencing } = sequencingActions;
const { selectAlignments, selectSequencingLoading } = sequencingSelectors;

export default connect(
  (state) => ({
    alignments: selectAlignments(state),
    loading: selectSequencingLoading(state),
  }),
  { fetchAlignments, resetSequencing, uploadSequencing, validateUploadSequencing },
)(Sequencing);
