import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import React from 'react';
import Typography from '@mui/material/Typography';

const RedText = (props) => (
  <Box component='span' sx={{ color: 'common.red' }} {...props} />
);

class DigestionRecipe extends React.Component {
  render() {
    const ingredients = [
      (<>2μg DNA <RedText>(4μL)</RedText></>),
      (<>10X digestion buffer <RedText>(5μL)</RedText></>),
      (<><RedText>1μL</RedText> each enzyme (add last)</>),
      (<>H20 to 50μL <RedText>(39-40μL)</RedText></>),
      (<>Pipette mix</>),
      (<><RedText>37ºC</RedText> for <RedText>30 minutes</RedText></>),
    ];
    return (
      <Box>
        <Typography sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}>Recipe</Typography>
        <Checklist
          dense
          items={ingredients.map((ingredient) => ({ content: ingredient }))}
        />
      </Box>
    );
  }
}

export default DigestionRecipe;
