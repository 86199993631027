import * as BatchService from 'services/BatchService';
import * as CommonService from 'services/CommonService';

import { ROW_TYPE_COMBINE_ELUTIONS, ROW_TYPE_LOAD } from 'constants/batchLineTypes.constants';
import { deepGet, mergeSx } from '@acheloisbiosoftware/absui.utils';

import { BATCH_REPORT } from 'constants/batch.constants';
import Box from '@mui/material/Box';
import CommentButton from './CommentButton';
import DataTable from 'components/DataTable';
import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import ImageGallery from 'components/ImageGallery';
import InfoTable from 'components/InfoTable';
import LockButton from './LockButton';
import PropTypes from 'prop-types';
import React from 'react';
import SendSignaturesButton from './SendSignaturesButton';
import SignButton from './SignButton';
import SignatureSection from './SignatureSection';
import StatusPill from 'components/StatusPill';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';
import { idFindFn } from 'utils/helpers';
import { notificationActions } from 'store/notification';
import { sessionSelectors } from 'store/session';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { userActions } from 'store/user';

const CenterText = (props) => (
  <Typography {...props} sx={mergeSx({ textAlign: 'center', m: 'auto' }, props?.sx)} />
);
CenterText.propTypes = {
  sx: sxPropType,
};

// Displays report step for giga prep
class GigaReport extends React.Component {
  static propTypes = {
    batchData: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
    constructCodes: PropTypes.arrayOf(PropTypes.string),
    batchId: PropTypes.number,
    batchReportData: PropTypes.shape({
      signed_status: PropTypes.string,
    }),
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  render() {
    const { batchData, token, constructCodes, batchId, batchReportData, enqueueSnackbar } = this.props;

    // Variation Table
    const columnsVariations = [
      { key: 'step', title: 'Step' },
      { key: 'note', title: 'Variation / Observation' },
      { key: 'reported', title: 'Reporter' },
    ];
    const rowsVariations = batchData.map((step) => step.substeps.map((substep) => substep.lines.map((line) => ({
      stepTitle: step.title,
      substepTitle: substep.title,
      lineTitle: line.title,
      notes: line.notes,
    })))).flat(3)
      .filter((line) => line.notes?.content)
      .map((row) => ({
        key: `${row.stepTitle}/${row.substepTitle}/${row.lineTitle}`,
        step: (
          <>
            {row.stepTitle}
            <br />
            {row.substepTitle}
            <br />
            {row.lineTitle}
          </>
        ),
        note: row.notes.content,
        reported: (
          <>
            {row.notes.by?.name}
            <br />
            {row.notes?.at ? formatDate(row.notes?.at, FMT_DATE_TIME) : ''}
          </>
        ),
      }));

    // Yield Table Info
    const yieldColumns = [
      { key: 'step', title: 'Step' },
      { key: 'mg', title: 'Nucleic Acid Amount', adornment: 'mg' },
      { key: 'percent', title: 'Step Yield' },
      { key: 'total', title: 'Total Yield' },
    ];
    const day_2 = deepGet(batchData, [idFindFn('Day 2'), 'substeps', idFindFn('D2_3'), 'lines', idFindFn('D2_S9'), 'subcontent', idFindFn('D2_S9_7'), 'value']);
    const day_3 = deepGet(batchData, [idFindFn('Day 3'), 'substeps', idFindFn('D3_2'), 'lines', idFindFn('D3_S2_pstable'), 'data', idFindFn(ROW_TYPE_LOAD, 'row_type'), 'amount']);
    const day_3_2 = deepGet(batchData, [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S8_pstable'), 'data', idFindFn(ROW_TYPE_COMBINE_ELUTIONS, 'row_type'), 'amount']);
    const day_4 = deepGet(batchData, [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S14_qtable'), 'data', idFindFn(ROW_TYPE_COMBINE_ELUTIONS, 'row_type'), 'amount']);
    const day_5 = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_5'), 'value']);
    const relative1 = day_2 ? Math.round(day_3 / day_2 * 100) : 0;
    const relative2 = day_3 ? Math.round(day_3_2 / day_3 * 100) : 0;
    const relative3 = day_3_2 ? Math.round(day_4 / day_3_2 * 100) : 0;
    const relative4 = day_4 ? Math.round(day_5 / day_4 * 100) : 0;
    const compared1 = day_3 ? Math.round(day_4 / day_3 * 100) : 0;
    const compared2 = day_4 ? Math.round(day_5 / day_3 * 100) : 0;
    const yields = [
      { key: '1', step: 'Lysis', mg: day_2, percent: '', total: '' },
      { key: '2', step: 'Ammonia Sulfate', mg: day_3, percent: `${relative1}%`, total: '' },
      { key: '3', step: 'Plasmid Select', mg: day_3_2, percent: `${relative2}%`, total: '' },
      { key: '4', step: 'Q-Sepharose', mg: day_4, percent: `${relative3}%`, total: `${compared1}%` },
      { key: '5', step: 'Final Precipitation', mg: day_5, percent: `${relative4}%`, total: `${compared2}%` },
    ];

    // Summary Info
    const fermentationBatch = deepGet(batchData, [idFindFn('Day 1'), 'substeps', idFindFn('D1_2'), 'lines', idFindFn('D1_S1'), 'subcontent', idFindFn('D1_S1_4'), 'value']);
    const concentration = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_2'), 'value']);
    const od = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_3'), 'value']);
    const volume = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_2'), 'lines', idFindFn('D5_S6'), 'subcontent', idFindFn('D5_S6_4'), 'value']);
    const sterility = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_3'), 'lines', idFindFn('D5_S7'), 'subcontent', idFindFn('D5_S7_3'), 'value']);
    const endotoxin = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_3'), 'lines', idFindFn('D5_S8'), 'subcontent', idFindFn('D5_S8_4'), 'value']);
    const storage = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_3'), 'lines', idFindFn('D5_S10'), 'subcontent', idFindFn('D5_S10_6'), 'value']);
    const summaryData = [
      { key: 'construct', title: 'Construct Code', content: constructCodes[0] },
      { key: 'batch', title: 'Fermentation Batch Number', content: fermentationBatch },
      { key: 'concentration', title: 'Final Concentration', content: concentration ? `${concentration} ng/µL` : '' },
      { key: 'od', title: 'Final OD260/280', content: od },
      { key: 'volume', title: 'Final Volume', content: volume ? `${volume} mL` : '' },
      { key: 'sterility', title: 'Sterility Info', content: sterility },
      { key: 'endotoxin', title: 'Endotoxin', content: endotoxin ? `${endotoxin} EU/mg` : '' },
      { key: 'storage', title: 'Storage Info', content: storage },
    ];

    // Images
    const gelImages = [
      ...deepGet(batchData, [idFindFn('Day 3'), 'substeps', idFindFn('D3_3'), 'lines', idFindFn('D3_S10_gel'), 'data']),
      ...deepGet(batchData, [idFindFn('Day 4'), 'substeps', idFindFn('D4_3'), 'lines', idFindFn('D4_S13_gel'), 'data']),
      ...deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_3'), 'lines', idFindFn('D5_S9_gel'), 'data']),
    ];
    const sterilityImages = deepGet(batchData, [idFindFn('Day 5'), 'substeps', idFindFn('D5_3'), 'lines', idFindFn('D5_S7_sterility'), 'data']);

    return (
      <Box>
        <CenterText variant='h3'>Report</CenterText>
        <InfoTable
          tableProps={{ sx: { maxWidth: 500, mx: 'auto', my: 1.5 }}}
          data={summaryData}
          borderTop
          borderBottom
        />

        <CenterText variant='h5' sx={{ mt: 4, mb: 1 }}>Yield Summary</CenterText>
        <DataTable
          columns={yieldColumns}
          data={yields}
          containerProps={{ sx: { width: 0.6, m: 'auto' }}}
        />

        <Box sx={{ display: 'flex', mt: 4 }}>
          <Box sx={{ width: 0.45, m: 'auto' }}>
            <CenterText variant='h5' sx={{ mb: 1 }}>Sterility Image(s)</CenterText>
            {
              sterilityImages.length ? (
                <ImageGallery images={sterilityImages} getImageUrl={CommonService.getImageUrlBuilder('gel', token)} />
              ) : (
                <CenterText>None</CenterText>
              )
            }
          </Box>

          <Box sx={{ width: 0.45, m: 'auto' }}>
            <CenterText variant='h5' sx={{ mb: 1 }}>Gel Images</CenterText>
            {
              gelImages.length ? (
                <ImageGallery images={gelImages} getImageUrl={CommonService.getImageUrlBuilder('gel', token)} />
              ) : (
                <CenterText>None</CenterText>
              )
            }
          </Box>
        </Box>

        <CenterText variant='h5' sx={{ mt: 4, mb: 1 }}>Variations / Observations</CenterText>
        <DataTable
          columns={columnsVariations}
          data={rowsVariations}
          containerProps={{ sx: { width: 0.8, m: 'auto' }}}
        />

        <Box sx={{ display: 'flex', mt: 4, mb: 1 }}>
          <Typography variant='h6' sx={{ m: 'auto', mr: 1 }}> Report Status: </Typography>
          <StatusPill status={batchReportData.signed_status} type={BATCH_REPORT} sx={{ mr: 2 }} />
        </Box>
        <Box sx={{ mr: 2, textAlign: 'right' }}>
          <LockButton sx={{ ml: 2 }} />
          <SendSignaturesButton sx={{ ml: 2 }} />
          <CommentButton sx={{ ml: 2 }} />
          <SignButton sx={{ ml: 2 }} />
          <DownloadButton
            variant='outlined'
            size='small'
            sx={{ ml: 2 }}
            download={() => BatchService.getBatchReportPdf(batchId)}
            onError={(err) => enqueueSnackbar({
              message: err?.error?.response?.data?.message ?? 'Error downloading report.',
              key: `reportError_${(new Date()).getTime()}`,
              options: { variant: 'error' },
            })}
          >
            Export
          </DownloadButton>
        </Box>
        <SignatureSection />
      </Box>
    );
  }
}

const { selectBatchData, selectBatchId, selectBatchReportData, selectConstructCodes } = batchSelectors;
const { fetchUsers } = userActions;
const { selectSessionToken } = sessionSelectors;
const { enqueueSnackbar } = notificationActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchData: selectBatchData(state),
    batchReportData: selectBatchReportData(state),
    constructCodes: selectConstructCodes(state),
    token: selectSessionToken(state),
  }),
  { fetchUsers, enqueueSnackbar },
)(GigaReport);
