import { SESSION } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { releaseQueue } from 'services';
import { selectSession } from './session.selectors';

export const fetchSession = createAsyncThunk(
  `${SESSION}/fetch`,
  async ({ authState, oktaAuth }, { rejectWithValue }) => {
    try {
      const userInfo = await oktaAuth.getUser();
      const session = {
        user: {
          name: userInfo.name,
          email: userInfo.email,
        },
        token: authState.accessToken,
        isAuthenticated: authState.isAuthenticated,
      };
      releaseQueue(authState.accessToken);
      return { session };
    } catch (e) {
      await oktaAuth.signOut();
      oktaAuth.tokenManager.clear();
      return rejectWithValue({ error: e.toString() });
    }
  },
);

export const refreshSession = createAsyncThunk(
  `${SESSION}/refresh`,
  async ({ authState, oktaAuth }, { getState, rejectWithValue }) => {
    try {
      oktaAuth.session.refresh();
      const oldSession = selectSession(getState());
      const userInfo = await oktaAuth.getUser();
      const token = await oktaAuth.token.renew(oldSession.token);
      const session = {
        user: {
          name: userInfo.name,
          email: userInfo.email,
        },
        token,
        isAuthenticated: authState.isAuthenticated,
      };
      releaseQueue(authState.accessToken);
      return { session };
    } catch (e) {
      await oktaAuth.signOut();
      oktaAuth.tokenManager.clear();
      return rejectWithValue({ error: e.toString() });
    }
  },
);
