import * as extraActions from './material.extraActions';

import { STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const handlePageError = (state, action) => {
  const { error } = action.payload;
  state.statusPage = STATUS_ERROR;
  state.errorPage = error;
};

const handlePageLoading = (state) => {
  state.statusPage = STATUS_LOADING;
  state.errorPage = null;
};

const handleInventoryError = (state, action) => {
  const { error } = action.payload;
  state.statusInventory = STATUS_ERROR;
  state.errorInventory = error;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const resetPage = (state) => {
  state.statusPage = STATUS_INIT;
  state.equipment = {};
  state.material = {};
  state.buffer = {};
  state.chemical = {};
  state.errorPage = null;
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################

const createMaterialLot = ({ pending, fulfilled, rejected }) => ({
  [pending]: handlePageLoading,
  [fulfilled]: (state, action) => {
    const { material_lot } = action.payload;
    if (state.material.material_lots) {
      state.material.material_lots = [...state.material.material_lots, material_lot];
    }
    const material = state.materials.find((m) => m.material_id === material_lot.material_id);
    if (material && material.material_lots) {
      material.material_lots = [...material.material_lots, material_lot];
    }
    state.statusPage = STATUS_SUCCESS;
  },
  [rejected]: handlePageError,
});

const createChemicalLot = ({ pending, fulfilled, rejected }) => ({
  [pending]: handlePageLoading,
  [fulfilled]: (state, action) => {
    const { chemical_lot } = action.payload;
    if (state.chemical.chemical_lots) {
      state.chemical.chemical_lots = [...state.chemical.chemical_lots, chemical_lot];
    }
    const chemical = state.chemicals.find((c) => c.chemical_id === chemical_lot.chemical_id);
    if (chemical && chemical.chemical_lots) {
      chemical.chemical_lots = [...chemical.chemical_lots, chemical_lot];
    }
    state.statusPage = STATUS_SUCCESS;
  },
  [rejected]: handlePageError,
});

const reduceBufferBatch = ({ pending, fulfilled, rejected }) => ({
  [pending]: handlePageLoading,
  [fulfilled]: (state, action) => {
    const { buffer_batch } = action.payload;
    if (state.buffer.buffer_batches) {
      const existingIdx = state.buffer.buffer_batches.findIndex((bb) => bb.buffer_batch_id === buffer_batch.buffer_batch_id);
      if (existingIdx !== -1) {
        state.buffer.buffer_batches[existingIdx] = buffer_batch;
      } else {
        state.buffer.buffer_batches = [...state.buffer.buffer_batches, buffer_batch];
      }
    }
    const buffer = state.buffers.find((b) => b.buffer_id === buffer_batch.buffer_id);
    if (buffer && buffer.buffer_batches) {
      const existinigIdx = buffer.buffer_batches.findIndex((bb) => bb.buffer_batch_id === buffer_batch.buffer_batch_id);
      if (existinigIdx !== -1) {
        buffer.buffer_batches[existinigIdx] = buffer_batch;
      } else {
        buffer.buffer_batches = [...buffer.buffer_batches, buffer_batch];
      }
    }
    state.statusPage = STATUS_SUCCESS;
  },
  [rejected]: handlePageError,
});

const fetchEquipments = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { equipments } = action.payload;
    state.equipments = equipments;
    state.statusInventory = STATUS_SUCCESS;
    state.errorInventory = null;
  },
  [rejected]: handleInventoryError,
});

const fetchEquipment = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { equipment } = action.payload;
    state.equipment = equipment;
    state.statusPage = STATUS_SUCCESS;
    state.errorPage = null;
  },
  [rejected]: handlePageError,
});

const fetchMaterials = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { materials } = action.payload;
    state.materials = materials;
    state.statusInventory = STATUS_SUCCESS;
    state.errorInventory = null;
  },
  [rejected]: handleInventoryError,
});

const fetchMaterial = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { material } = action.payload;
    state.material = material;
    state.statusPage = STATUS_SUCCESS;
    state.errorPage = null;
  },
  [rejected]: handlePageError,
});

const fetchChemicals = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { chemicals } = action.payload;
    state.chemicals = chemicals;
    state.statusInventory = STATUS_SUCCESS;
    state.errorInventory = null;
  },
  [rejected]: handleInventoryError,
});

const fetchChemical = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { chemical } = action.payload;
    state.chemical = chemical;
    state.statusPage = STATUS_SUCCESS;
    state.errorPage = null;
  },
  [rejected]: handlePageError,
});

const fetchBuffers = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { buffers } = action.payload;
    state.buffers = buffers;
    state.statusInventory = STATUS_SUCCESS;
    state.errorInventory = null;
  },
  [rejected]: handleInventoryError,
});

const fetchBuffer = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { buffer } = action.payload;
    state.buffer = buffer;
    state.statusPage = STATUS_SUCCESS;
    state.errorPage = null;
  },
  [rejected]: handlePageError,
});

export const reducers = { resetPage };
export const extraReducers = {
  ...createMaterialLot(extraActions.createMaterialLot),
  ...createChemicalLot(extraActions.createChemicalLot),
  ...reduceBufferBatch(extraActions.createBufferBatch),
  ...reduceBufferBatch(extraActions.patchBufferBatch),
  ...fetchEquipments(extraActions.fetchEquipments),
  ...fetchEquipment(extraActions.fetchEquipment),
  ...fetchMaterials(extraActions.fetchMaterials),
  ...fetchMaterial(extraActions.fetchMaterial),
  ...fetchChemicals(extraActions.fetchChemicals),
  ...fetchChemical(extraActions.fetchChemical),
  ...fetchBuffers(extraActions.fetchBuffers),
  ...fetchBuffer(extraActions.fetchBuffer),
};
