import { batchActions, batchSelectors } from 'store/batch';

import { ACTION_TABLE_UPDATE } from 'constants/batchActions.constants';
import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class FlaskTable extends React.Component {
  static propTypes = {
    handleBatchAction: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        construct_code: PropTypes.string.isRequired,
        count: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    appearDisabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    editMiddleware: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, row) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_TABLE_UPDATE,
        payload: {
          construct_code: row.constructCode,
          count: value,
        },
      });
    });
  }

  render() {
    const { lineData, readOnly, appearDisabled, editMiddleware } = this.props;
    const { data } = lineData;
    const columns = [
      { key: 'constructCode', title: 'Construct' },
      {
        key: 'numFlasks',
        title: 'Number of 200 mL Cultures Seeding',
        editable: true,
        adornment: 'Flasks',
        inputProps: { type: 'number', variant: 'outlined', sx: { maxWidth: 150, display: 'block', m: 'auto' }},
      },
    ];
    const rows = data.map(({ construct_code, count }) => ({
      key: `${construct_code}_numFlasks`,
      constructCode: construct_code,
      numFlasks: count,
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.handleChange}
        defaultInputProps={{ onFocus: () => editMiddleware(null) }}
        defaultCellProps={{ sx: { height: 40 }}}
        containerProps={{ sx: { maxWidth: 400, m: 2 }}}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
      />
    );
  }
}

const { handleBatchAction } = batchActions;
const { selectBatchLineData } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
  { handleBatchAction },
)(FlaskTable);
