import { deepGet } from '@acheloisbiosoftware/absui.utils';

export function idFindFn(id, key = 'id') {
  return (e) => e[key] === id;
}

export function isPositiveInt(value) {
  const checkPos = (x) => (x | 0) === x && x >= 0; // eslint-disable-line no-bitwise
  return !isNaN(value) && checkPos(parseFloat(value));
}

function deepObjectModifyCopy(obj, keyList, value) {
  const firstKey = keyList[0];
  const restKeys = keyList.slice(1);

  if (Array.isArray(obj)) {
    const newList = [...obj];
    if (Number.isInteger(firstKey)) {
      if (firstKey >= newList.length || firstKey < 0) throw new TypeError(`Index ${firstKey} out of bounds of ${newList}`);
      newList[firstKey] = !restKeys.length ? value : deepObjectModifyCopy(newList[firstKey], restKeys, value);
      return newList;
    } // Function to use with Array.find
    const idx = newList.findIndex(firstKey);
    if (idx === -1) throw new TypeError(`Could not find list element to set`);
    newList[idx] = !restKeys.length ? value : deepObjectModifyCopy(newList[idx], restKeys, value);
    return newList;
  } // Regular object
  if (!Object.hasOwn(obj, firstKey)) throw new TypeError(`Property "${firstKey}" does not exist in ${obj}`);
  return {
    ...obj,
    [firstKey]: !restKeys.length ? value : deepObjectModifyCopy(obj[firstKey], restKeys, value),
  };
}

function deepObjectModifyInplace(obj, keyList, value) {
  const lastKey = keyList.pop();
  const deepObj = deepGet(obj, keyList);
  if (Array.isArray(deepObj)) {
    if (Number.isInteger(lastKey)) {
      if (lastKey >= deepObj.length || lastKey < 0) throw new TypeError(`Index ${lastKey} out of bounds of ${deepObj}`);
      deepObj[lastKey] = value;
    } else { // Function to use with Array.find
      const idx = deepObj.findIndex(lastKey);
      if (idx === -1) throw new TypeError(`Could not find list element to set`);
      deepObj[idx] = value;
    }
  } else { // Regular object
    if (!Object.hasOwn(deepObj, lastKey)) throw new TypeError(`Property "${lastKey}" does not exist in ${obj}`);
    deepObj[lastKey] = value;
  }
}

export function deepObjectModify(obj, keyList, value, inplace = false) {
  return inplace ? deepObjectModifyInplace(obj, keyList, value) : deepObjectModifyCopy(obj, keyList, value);
}

export function reorderList(lst, srcIdx, destIdx, inplace = false) {
  const result = inplace ? lst : [...lst];
  const [removed] = result.splice(srcIdx, 1);
  result.splice(destIdx, 0, removed);
  return result;
}

export function reorderIndexList(lst, srcIdx, destIdx, inplace = false) {
  // Given a list of indices, adds or subtracts 1 from the indices affected by
  // the reordering of srcIdx --> destIdx.
  const result = lst.map((i) => {
    if (i === srcIdx) return destIdx;
    if (i < srcIdx && i >= destIdx) return i + 1;
    if (i > srcIdx && i <= destIdx) return i - 1;
    return i;
  });
  if (!inplace) return result;
  result.forEach((newI, idx) => {
    lst[idx] = newI;
  });
  return result;
}

export function mergeLists(oldList, newList, idKey) {
  const newEntries = newList.filter((eNew) => !oldList.map((eOld) => eOld[idKey]).includes(eNew[idKey]));
  const updatedEntries = oldList.map((eOld) => {
    const updatedEntry = newList.find((eNew) => eNew[idKey] === eOld[idKey]);
    return updatedEntry || eOld;
  });
  return [...updatedEntries, ...newEntries];
}


