import {
  BACKBONE,
  BATCH,
  BATCH_STEPPER,
  CONSTRUCT,
  DESIGN,
  INVENTORY,
  MATERIAL,
  NAVIGATION,
  NOTIFICATION,
  PRIMER,
  PROJECT,
  SEQUENCING,
  SESSION,
  USER,
} from 'constants/store.constants';

import { backboneReducer } from './backbone';
import { batchReducer } from './batch';
import { batchStepperReducer } from './batchStepper';
import { combineReducers } from 'redux';
import { constructReducer } from './construct';
import { designReducer } from './design';
import { inventoryReducer } from './inventory';
import { materialReducer } from './material';
import { navigationReducer } from './navigation';
import { notificationReducer } from './notification';
import { primerReducer } from './primer';
import { projectReducer } from './project';
import { sequencingReducer } from './sequencing';
import { sessionReducer } from './session';
import { userReducer } from './user';

export default combineReducers({
  [BACKBONE]: backboneReducer,
  [BATCH]: batchReducer,
  [BATCH_STEPPER]: batchStepperReducer,
  [CONSTRUCT]: constructReducer,
  [DESIGN]: designReducer,
  [INVENTORY]: inventoryReducer,
  [MATERIAL]: materialReducer,
  [NAVIGATION]: navigationReducer,
  [NOTIFICATION]: notificationReducer,
  [PRIMER]: primerReducer,
  [PROJECT]: projectReducer,
  [SEQUENCING]: sequencingReducer,
  [SESSION]: sessionReducer,
  [USER]: userReducer,
});
