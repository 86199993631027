import React, { useEffect, useState } from 'react';
import { materialActions, materialSelectors } from 'store/material';
import { useDispatch, useSelector } from 'react-redux';

import AddChemicalDialog from './AddChemicalDialog';
import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import DataTable from 'components/DataTable';
import Error from 'components/Error';
import InfoTable from 'components/InfoTable';
import Link from '@mui/material/Link';
import LoadingPage from 'components/LoadingPage';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { useParams } from 'react-router-dom';

function Chemical() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const chemical = useSelector(materialSelectors.selectChemical);
  const fetchedChemical = useSelector(materialSelectors.selectPageFetched);

  useEffect(() => {
    dispatch(materialActions.fetchChemical(params.chemicalId));
    return () => dispatch(materialActions.resetPage());
  }, [params, dispatch]);

  if (!fetchedChemical) {
    return <LoadingPage />;
  }
  if (!chemical || !chemical.chemical_id) {
    return (
      <Error>Chemical not found!</Error>
    );
  }
  const {
    batch_type,
    name,
    vendor,
    catalog_no,
    purchase_url,
    formula,
    molecular_weight,
    storage_cond,
    appearance,
    custom_props,
    chemical_lots,
  } = chemical;
  const infoData = [
    {
      key: 'vendor',
      title: 'Vendor',
      content: vendor,
    },
    {
      key: 'catalog_no',
      title: 'Catalog #',
      content: catalog_no,
    },
    {
      key: 'appearance',
      title: 'Appearance',
      content: appearance,
    },
    {
      key: 'formula',
      title: 'Formula',
      content: formula,
    },
    {
      key: 'molecular_weight',
      title: 'Molecular Weight',
      content: `${molecular_weight} Da`,
    },
    {
      key: 'storage_cond',
      title: 'Storage Conditions',
      content: storage_cond,
    },
    {
      key: 'url',
      title: 'Product Website',
      content: <Link href={purchase_url} target='_blank'>{vendor} {catalog_no}</Link>,
    },
    ...custom_props.map(({ title, value }, idx) => ({
      key: `custom_prop${idx}`,
      title,
      content: value,
    })),
    {
      key: 'experiment',
      title: 'Experiment',
      content: (<BatchType type={batch_type} />),
    },
  ];

  const columns = [
    { key: 'lot_no', title: 'Lot #' },
    { key: 'date_received', title: 'Date Received' },
    { key: 'received_by', title: 'Received By' },
    { key: 'storage_location', title: 'Storage Location' },
    { key: 'expiration_date', title: 'Expiration Date' },
    { key: 'custom_props', title: 'Additional Properties' },
  ];
  const lotData = chemical_lots.map((lot) => ({
    key: `chemical_lot_${lot.chemical_lot_id}`,
    lot_no: lot.lot_no,
    date_received: formatDate(lot.date_received),
    received_by: lot.received_by?.name,
    storage_location: lot.storage_location,
    expiration_date: formatDate(lot.expiration_date),
    custom_props: lot.custom_props.map((prop) => (
      <Typography key={prop.title} variant='body2'>
        {prop.title}: {prop.value}
      </Typography>
    )),
  }));

  const sxPaper = { p: 3, color: 'text.secondary', mb: 2 };
  const sxCentered = { textAlign: 'center', m: 'auto', justifyContent: 'center' };

  return (
    <Box sx={{ m: 'auto', mt: '64px', py: 2, px: 4, maxWidth: 1000 }}>
      <Paper sx={sxPaper} variant='outlined'>
        <Typography variant='h4' sx={sxCentered}>{name}</Typography>
        <InfoTable
          data={infoData}
          borderTop
          tableProps={{ sx: { ...sxCentered, maxWidth: 500, mt: 1.5 }}}
        />
      </Paper>
      <Paper sx={sxPaper} variant='outlined'>
        <Typography variant='h5' sx={sxCentered}>Chemical Lots</Typography>
        <DataTable
          columns={columns}
          data={lotData}
          containerProps={{ sx: { m: 1.5 }}}
        />
        <Box sx={{ width: 1, display: 'flex' }}>
          <Button
            variant='contained'
            sx={{ mr: 1.5, ml: 'auto' }}
            onClick={() => setDialogOpen(true)}
          >
            Create new
          </Button>
          <AddChemicalDialog
            chemical={chemical}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default Chemical;
