import {
  ASC,
  BATCH,
  BUFFER,
  CHEMICAL,
  COL_BATCH_TYPE,
  COL_DATE_RANGE,
  COL_NUMBER,
  COL_NUMBER_MEMBER,
  COL_NUMBER_RANGE,
  COL_SELECT,
  COL_STATUS,
  COL_TEXT,
  COL_TEXT_MEMBER,
  CONSTRUCT,
  DESC,
  EQUIPMENT,
  MATERIAL,
  REPORT,
} from 'constants/inventory.constants';
import { Autocomplete, Button, TextField } from '@acheloisbiosoftware/absui.core';
import {
  BATCH_REPORT,
  CLONING_BATCH,
  GIGA_BATCH,
  MAXI_BATCH,
  batchTypeDisplay,
  cloningStatuses,
  gigaStatuses,
  maxiStatuses,
  reportStatuses,
} from 'constants/batch.constants';

import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Cell from './Cell';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FMT_DATE_SHORT } from 'constants/dateFormats.constants';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import InputPopper from 'components/InputPopper';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import StatusPill from 'components/StatusPill';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { capitalize } from 'utils/formatting.utils';
import { formatDate } from 'utils/date.utils';

function HeaderCellContent(props) {
  const { column, children, filterData, onFilter, tableType } = props;

  const sxActionButtons = { display: 'flex', justifyContent: 'flex-end', mt: 0.5 };
  const sxInputRoot = { color: 'text.primary' };

  if ([COL_TEXT, COL_TEXT_MEMBER, COL_NUMBER, COL_NUMBER_MEMBER].includes(column.filter.type)) {
    const isNumeric = [COL_NUMBER, COL_NUMBER_MEMBER].includes(column.filter.type);
    return (
      <Tooltip
        placement='top'
        arrow
        title={filterData.value || ''}
      >
        <TextField
          label={column.label}
          variant='standard'
          type={isNumeric ? 'number' : null}
          value={filterData.value}
          onChange={(e) => onFilter({ active: Boolean(e.target.value), value: e.target.value })}
          sx={{ minWidth: column.width }}
          InputLabelProps={{ sx: sxInputRoot }}
          InputSx={sxInputRoot}
          endAdornment={
            filterData.active ? (
              <IconButton size='small' onClick={() => onFilter({ active: false, value: column.filter.default })}>
                <HighlightOffIcon fontSize='small' color='disabled' />
              </IconButton>
            ) : (
              <SearchIcon fontSize='small' color='disabled' />
            )
          }
        />
      </Tooltip>
    );
  }

  if (column.filter.type === COL_NUMBER_RANGE) {
    const sxInput = { width: 120, mx: 0.5, my: 1 };
    return (
      <InputPopper
        id={column.id}
        label={column.label}
        sx={{ minWidth: column.width }}
        value={filterData.active ? `${filterData.value[0]}-${filterData.value[1]} ${column.filter.units || ''}` : ''}
        onClear={() => onFilter({ active: false, value: column.filter.default })}
      >
        {
          (onClose) => (
            <Paper sx={{ px: 0.5, pb: 1 }}>
              <Box>
                <TextField
                  sx={sxInput}
                  variant='outlined'
                  size='small'
                  label='From'
                  type='number'
                  value={filterData.value[0]}
                  onChange={(e) => onFilter({ ...filterData, value: [e.target.value, filterData.value[1]]})}
                  endAdornment={column.filter.units}
                />
                <TextField
                  sx={sxInput}
                  variant='outlined'
                  size='small'
                  label='To'
                  type='number'
                  value={filterData.value[1]}
                  onChange={(e) => onFilter({ ...filterData, value: [filterData.value[0], e.target.value]})}
                  endAdornment={column.filter.units}
                />
              </Box>
              <ButtonGroup sx={sxActionButtons}>
                <Button
                  variant='outlined'
                  size='small'
                  startIcon={<ClearIcon />}
                  disabled={!filterData.value[0] && !filterData.value[1]}
                  onClick={() => onFilter({ active: false, value: column.filter.default })}
                >
                  Clear
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  startIcon={<CheckIcon />}
                  disabled={!filterData.value[0] && !filterData.value[1]}
                  onClick={() => {
                    const v0 = parseFloat(filterData.value[0]) || 0;
                    const v1 = parseFloat(filterData.value[1]) || Number.MAX_SAFE_INTEGER;
                    onFilter({ active: true, value: [v0, v1]});
                    onClose();
                  }}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Paper>
          )
        }
      </InputPopper>
    );
  }

  if (column.filter.type === COL_STATUS) {
    const justCloningStatuses = tableType === CONSTRUCT;
    if (tableType === REPORT) {
      return (
        <Autocomplete
          label={column.label}
          disableCreateOption
          inputProps={(params) => ({ ...params, InputLabelProps: { sx: sxInputRoot }, variant: 'standard' })}
          sx={{ minWidth: column.width }}
          value={filterData.active ? { status: filterData.value } : null}
          onChange={(newValue) => onFilter({ active: Boolean(newValue), value: newValue?.status || column.filter.default })}
          options={reportStatuses.map((status) => ({ status }))}
          getOptionLabel={(option) => option?.status}
          renderOption={(props_, option) => (
            <MenuItem {...props_}>
              <StatusPill status={option.status} type={BATCH_REPORT} />
            </MenuItem>
          )}
        />
      );
    }
    const options = [
      ...cloningStatuses.map((status) => ({ status, type: CLONING_BATCH, group: 'Cloning Statuses' })),
      ...(!justCloningStatuses ? maxiStatuses.map((status) => ({ status, type: MAXI_BATCH, group: 'Maxi Prep Statuses' })) : []),
      ...(!justCloningStatuses ? gigaStatuses.map((status) => ({ status, type: GIGA_BATCH, group: 'Giga Prep Statuses' })) : []),
    ];

    return (
      <Autocomplete
        label={column.label}
        disableCreateOption
        inputProps={(params) => ({ ...params, InputLabelProps: { sx: sxInputRoot }, variant: 'standard' })}
        sx={{ minWidth: column.width }}
        value={filterData.active ? options.find((o) => o.status === filterData.value) : null}
        onChange={(newValue) => onFilter({ active: Boolean(newValue), value: newValue?.status || column.filter.default })}
        options={options}
        groupBy={!justCloningStatuses ? (option) => option.group : null}
        getOptionLabel={(option) => capitalize(option.status.replace('_', ' '))}
        isOptionEqualToValue={(option, value) => option.status === value.status}
        renderOption={(props_, option) => (
          <MenuItem {...props_}>
            <StatusPill status={option.status} type={option.type} />
          </MenuItem>
        )}
      />
    );
  }

  if (column.filter.type === COL_BATCH_TYPE) {
    const options = [CLONING_BATCH, MAXI_BATCH, GIGA_BATCH].map((type) => ({ type }));
    return (
      <Autocomplete
        label={column.label}
        disableCreateOption
        inputProps={(params) => ({ ...params, InputLabelProps: { sx: sxInputRoot }, variant: 'standard' })}
        sx={{ minWidth: column.width }}
        value={filterData.active ? { type: batchTypeDisplay[filterData.value] } : null}
        onChange={(newValue) => onFilter({ active: Boolean(newValue), value: newValue?.type || column.filter.default })}
        options={options}
        getOptionLabel={(option) => option.type}
        isOptionEqualToValue={(option, value) => batchTypeDisplay[option.type] === value.type}
        renderOption={(props_, option) => (
          <MenuItem {...props_}>
            <BatchType type={option.type} />
          </MenuItem>
        )}
      />
    );
  }

  if (column.filter.type === COL_SELECT) {
    const selectOptions = Array.from(new Set(children.map(column.getValue)))
      .filter((s) => s)
      .sort()
      .map((value) => ({ value }));
    return (
      <Autocomplete
        label={column.label}
        disableCreateOption
        inputProps={(params) => ({ ...params, InputLabelProps: { sx: sxInputRoot }, variant: 'standard' })}
        sx={{ minWidth: column.width }}
        options={selectOptions}
        getOptionLabel={(option) => option.value}
        value={filterData.active ? { value: filterData.value } : null}
        onChange={(newValue) => onFilter({ active: Boolean(newValue), value: newValue?.value || column.filter.default })}
      />
    );
  }

  if (column.filter.type === COL_DATE_RANGE) {
    const startDate = filterData.value.startDate ? formatDate(filterData.value.startDate, FMT_DATE_SHORT) : '';
    const endDate = filterData.value.endDate ? formatDate(filterData.value.endDate, FMT_DATE_SHORT) : '';
    return (
      <InputPopper
        id={column.id}
        label={column.label}
        sx={{ minWidth: column.width }}
        value={filterData.active ? `${startDate}-${endDate}` : ''}
        onClear={() => onFilter({ active: false, value: column.filter.default })}
      >
        {
          (onClose) => (
            <Paper sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ m: 'auto', mr: 1 }}>Start</Typography>
                <DatePicker
                  value={filterData.value.startDate}
                  onChange={(date) => onFilter({ ...filterData, value: { ...filterData.value, startDate: date }})}
                  maxDate={filterData.value.endDate}
                  sx={{ m: 'auto' }}
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ m: 'auto', mr: 1 }}>End</Typography>
                <DatePicker
                  value={filterData.value.endDate}
                  onChange={(date) => onFilter({ ...filterData, value: { ...filterData.value, endDate: date }})}
                  minDate={filterData.value.startDate}
                  sx={{ m: 'auto' }}
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                />
              </Box>
              <ButtonGroup sx={sxActionButtons}>
                <Button
                  variant='outlined'
                  size='small'
                  startIcon={<ClearIcon />}
                  disabled={!filterData.value.startDate && !filterData.value.endDate}
                  onClick={() => onFilter({ active: false, value: column.filter.default })}
                >
                  Clear
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  startIcon={<CheckIcon />}
                  disabled={!filterData.value.startDate && !filterData.value.endDate}
                  onClick={() => {
                    onFilter({ ...filterData, active: true });
                    onClose();
                  }}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Paper>
          )
        }
      </InputPopper>
    );
  }

  return null;
}

function HeaderCell(props) {
  const { column, orderBy, order, onSort } = props;
  return (
    <Cell>
      <Box sx={{ display: 'flex' }}>
        <HeaderCellContent {...props} />
        {
          column.sortable ? (
            <Tooltip placement='top' arrow title={`Sort by ${column.label}`}>
              <IconButton
                size='small'
                onClick={() => onSort(column.id)}
                sx={{ mx: 1, mt: 'auto', mb: 0.5 }}
              >
                <FilterListIcon
                  color={orderBy === column.id ? 'inherit' : 'disabled'}
                  fontSize='small'
                  sx={orderBy === column.id && order === ASC ? { transform: 'rotate(180deg)' } : null}
                />
              </IconButton>
            </Tooltip>
          ) : null
        }
      </Box>
    </Cell>
  );
}

const headerCellPropTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    getValue: PropTypes.func.isRequired,
    sortable: PropTypes.bool.isRequired,
    filter: PropTypes.shape({
      type: PropTypes.string.isRequired,
      default: PropTypes.any.isRequired,
      units: PropTypes.node,
    }).isRequired,
  }).isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterData: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  tableType: PropTypes.oneOf([CONSTRUCT, BATCH, REPORT, EQUIPMENT, MATERIAL, CHEMICAL, BUFFER]),
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  onSort: PropTypes.func.isRequired,
};

HeaderCellContent.propTypes = headerCellPropTypes;
HeaderCell.propTypes = headerCellPropTypes;

export default HeaderCell;
