import * as extraActions from './inventory.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_INIT } from 'constants/statuses.constants';

import { batchActions } from 'store/batch';
import { constructActions } from 'store/construct';
import { designActions } from 'store/design';
import { mergeLists } from 'utils/helpers';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const reduceError = (state, action) => {
  const { error } = action.payload;
  state.status = STATUS_ERROR;
  state.error = error;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const resetInventory = (state) => {
  state.status = STATUS_INIT;
  state.error = null;
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const deleteBatches = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { batch_ids } = action.payload;
    const batches = state.batches.filter((batch) => !batch_ids.includes(batch.batch_id));
    state.batches = batches;
    state.error = null;
  },
});

const deleteConstructs = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { construct_codes } = action.payload;
    const constructs = state.constructs.filter((construct) => !construct_codes.includes(construct.construct_code));
    state.constructs = constructs;
    state.error = null;
  },
});

const fetchBatches = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { batches } = action.payload;
    state.batches = batches;
    state.status = STATUS_IDLE;
    state.error = null;
  },
  [rejected]: reduceError,
});

const fetchConstructs = ({ fulfilled, rejected }) => ({
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    state.constructs = constructs;
    state.status = STATUS_IDLE;
    state.error = null;
  },
  [rejected]: reduceError,
});

const reduceNewConstructs = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    state.constructs = mergeLists(state.constructs, constructs, 'construct_code');
    state.error = null;
  },
});

export const reducers = { resetInventory };
export const extraReducers = {
  ...reduceNewConstructs(batchActions.createBatch),
  ...deleteBatches(batchActions.deleteBatches),
  ...deleteConstructs(constructActions.deleteConstructs),
  ...fetchBatches(extraActions.fetchBatches),
  ...fetchConstructs(extraActions.fetchConstructs),
  ...reduceNewConstructs(batchActions.updateBatch),
  ...reduceNewConstructs(designActions.uploadConstructs),
};
