import Color from 'color';
import { FONT } from 'constants/styles.constants';
import { TextMeasurer } from 'utils/visualization.utils';
import { range as _range } from 'lodash';
import { annotationArrowTypes } from './SeqView.constants';
import { isOverlapping } from 'utils/sequence.utils';

export function getFeatureColor(featureId, featureType) {
  const colors = [
    '#FAA4D3',
    '#F726FF',
    '#F80000', // Pinks/reds
    '#FDD19F',
    '#FDD000',
    '#FBA200', // Oranges
    '#D6FED0',
    '#4AFD00',
    '#47A26F', // Greens
    '#D5FFFF',
    '#44FFFF',
    '#4FD2D3', // Cyans
    '#A6D3FF',
    '#34D3FF',
    '#3A76FF', // Blues
    '#CFA6FF',
    '#9F3C72',
    '#870F8E', // Purples
  ];
  if (featureType === 'rep_origin') {
    return Color('#FFFD00'); // Yellow
  }
  if (featureType === 'promoter') {
    return Color('#FFFFFF'); // White
  }
  if (featureType === 'enhancer' || featureType === 'polyA_signal' || featureType === 'intron') {
    return Color('#A3A3A3'); // Grey
  }
  return Color(colors[featureId % colors.length]);
}

export function assignSpaceLinear(annotations, {
  blockStartKey,
  blockEndKey,
  textKey,
  textMiddleKey,
  getNumRows = () => 1,
  blockRowKey = 'blockRow',
  textRowKey = 'textRow',
  fontSize = 16,
  fontFamily = FONT,
  textPadding = 4,
}) {
  const placeBlocks = blockStartKey && blockEndKey;
  let annots;
  if (placeBlocks) {
    annots = [...annotations].sort((a1, a2) => Math.abs(a2[blockEndKey] - a2[blockStartKey]) - Math.abs(a1[blockEndKey] - a1[blockStartKey]));
    annots.forEach((annot, idx) => {
      const numRows = getNumRows(annot);
      annot[blockRowKey] = numRows - 1;
      for (let i = 0; i < idx; i++) {
        const other = annots[i];
        const overlapsPrev = _range(numRows).filter((r) => (
          _range(getNumRows(other)).filter((rOther) => (
            (annot[blockRowKey] - r === other[blockRowKey] - rOther) &&
            isOverlapping([annot[blockStartKey], annot[blockEndKey]], [other[blockStartKey], other[blockEndKey]])
          )).length > 0
        )).length > 0;
        if (overlapsPrev) {
          // Increment row and try again
          annot[blockRowKey] += 1;
          i = -1;
        }
      }
    });
  } else {
    annots = [...annotations].sort((a1, a2) => TextMeasurer.getWidth(a2[textKey], fontSize, fontFamily) - TextMeasurer.getWidth(a1[textKey], fontSize, fontFamily));
  }

  if (textKey) {
    annots.forEach((annot, idx) => {
      annot[textRowKey] = placeBlocks ? annot[blockRowKey] : 0;
      const textWidth = TextMeasurer.getWidth(annot[textKey], fontSize, fontFamily);
      const textStart = placeBlocks ? (annot[blockEndKey] + annot[blockStartKey] - textWidth) / 2 : annot[textMiddleKey] - (textWidth / 2);
      if (!placeBlocks || textWidth + (2 * textPadding) >= annot[blockEndKey] - annot[blockStartKey]) {
        for (let i = 0; i < annots.length; i++) {
          const other = annots[i];
          const overlapsBox = placeBlocks && _range(getNumRows(other)).filter((rOther) => (
            (annot[textRowKey] === other[blockRowKey] - rOther) &&
            isOverlapping([textStart - textPadding, textStart + textWidth + textPadding], [other[blockStartKey], other[blockEndKey]])
          )).length > 0;
          const otherTextWidth = TextMeasurer.getWidth(other[textKey], fontSize, fontFamily);
          const otherTextStart = placeBlocks ? (other[blockEndKey] + other[blockStartKey] - otherTextWidth) / 2 : other[textMiddleKey] - (otherTextWidth / 2);
          const overlapsText = (
            (i < idx) && (annot[textRowKey] === other[textRowKey]) &&
            isOverlapping([textStart - textPadding, textStart + textWidth + textPadding], [otherTextStart - textPadding, otherTextStart + otherTextWidth + textPadding])
          );
          if (overlapsBox || overlapsText) {
            annot[textRowKey] += 1;
            i = -1; // Needs to be -1 so that after i++, it restarts loop at i=0
          }
        }
      }
    });
  }

  return annots;
}

export function getAnnotationPointsLinear(x, y, width, height, featureType, strand) {
  if (!annotationArrowTypes.includes(featureType) || !strand) {
    // Rectangular
    return [
      [x, y],
      [x + width, y],
      [x + width, y - height],
      [x, y - height],
    ];
  }
  const arrowLength = Math.min(height, width);
  if (strand === -1) {
    // Reverse arrow
    return [
      [x, y - (height / 2)],
      [x + arrowLength, y],
      [x + width, y],
      [x + width, y - height],
      [x + arrowLength, y - height],
    ];
  }
  // Forward arrow
  return [
    [x, y],
    [x + width - arrowLength, y],
    [x + width, y - (height / 2)],
    [x + width - arrowLength, y - height],
    [x, y - height],
  ];
}

export function assignSpaceCircular(annotations, deltaR, {
  blockStartKey, // Start and end key values have radian values
  blockEndKey,
  textKey,
  textMiddleKey,
  r_blockKey,
  r_textKey,
  blockRowKey = 'blockRow',
  textRowKey = 'textRow',
  fontSize = 12,
  fontFamily = FONT,
  textPadding = 4,
}) {
  const placeBlocks = blockStartKey && blockEndKey && r_blockKey;
  const placeText = textKey && r_textKey;
  let annots;
  if (placeBlocks) {
    annots = [...annotations].sort((a1, a2) => Math.abs(a2[blockEndKey] - a2[blockStartKey]) - Math.abs(a1[blockEndKey] - a1[blockStartKey]));
    annots.forEach((annot, idx) => {
      annot[blockRowKey] = 0;
      for (let i = 0; i < idx; i++) {
        const other = annots[i];
        if (
          (annot[blockRowKey] === other[blockRowKey]) &&
          isOverlapping([annot[blockStartKey], annot[blockEndKey]], [other[blockStartKey], other[blockEndKey]], Math.PI * 2)
        ) {
          // Overlaps with previous feature, increment row and try again
          annot[blockRowKey] += 1;
          annot[r_blockKey] -= deltaR;
          if (placeText) annot[r_textKey] -= deltaR;
          if (annot[r_blockKey] < deltaR) {
            annot[blockRowKey] = null;
            break;
          }
          i = -1;
        }
      }
      if (placeText) annot[textRowKey] = annot[blockRowKey];
    });
  } else {
    annots = [...annotations].sort((a1, a2) => TextMeasurer.getWidth(a2[textKey], fontSize, fontFamily) - TextMeasurer.getWidth(a1[textKey], fontSize, fontFamily));
  }

  if (placeText) {
    const getTextWidth = (annot) => TextMeasurer.getWidth(annot[textKey], fontSize, fontFamily) / annot[r_textKey];
    const getTextPadding = (annot) => Math.min(textPadding / annot[r_textKey], Math.PI);
    const getTextRange = (annot) => {
      const mid = placeBlocks ? (annot[blockEndKey] + annot[blockStartKey]) / 2 : annot[textMiddleKey];
      const width = getTextWidth(annot);
      const pad = getTextPadding(annot);
      return [mid - (width / 2) - pad, mid + (width / 2) + pad];
    };
    annots.forEach((annot, idx) => {
      if ((placeBlocks && annot[blockRowKey] === null) || annot[r_textKey] < deltaR) {
        annot[textRowKey] = null;
        return;
      }
      if (!placeBlocks) annot[textRowKey] = 0;

      if (!placeBlocks || getTextWidth(annot) + (2 * getTextPadding(annot)) >= annot[blockEndKey] - annot[blockStartKey]) {
        for (let i = 0; i < annots.length; i++) {
          const other = annots[i];
          const overlapsBox = (
            placeBlocks && (annot[textRowKey] === other[blockRowKey]) &&
            isOverlapping(getTextRange(annot), [other[blockStartKey], other[blockEndKey]], 2 * Math.PI)
          );
          const overlapsText = (
            (i < idx) && (annot[textRowKey] === other[textRowKey]) &&
            isOverlapping(getTextRange(annot), getTextRange(other), 2 * Math.PI)
          );
          if (overlapsBox || overlapsText) {
            annot[textRowKey] += 1;
            annot[r_textKey] -= deltaR;
            if (annot[r_textKey] < deltaR || getTextWidth(annot) > Math.PI) {
              annot[textRowKey] = null;
              break;
            }
            i = -1; // Needs to be -1 so that after i++, it restarts loop at i=0
          }
        }
      }
    });
  }

  return annots;
}

export function getAnnotationPathCircular(cx, cy, r, thetaRange, height, featureType, strand) {
  // NOTE: thetaRange[0] must be < thetaRange[1]
  const ARROW_THETA = Math.PI / 80;
  const r_out = r + (height / 2);
  const r_in = r - (height / 2);

  const thetaStart = thetaRange[0] + (Math.PI / 2);
  const thetaEnd = thetaRange[1] + (Math.PI / 2);
  const cosThetaStart = Math.cos(thetaStart);
  const sinThetaStart = Math.sin(thetaStart);
  const cosThetaEnd = Math.cos(thetaEnd);
  const sinThetaEnd = Math.sin(thetaEnd);

  const arcFlag1 = thetaEnd - thetaStart < Math.PI ? '0,1' : '1,1';
  const arcFlag2 = thetaEnd - thetaStart < Math.PI ? '0,0' : '1,0';

  if (!annotationArrowTypes.includes(featureType) || !strand) {
    // Rectangular
    return `
      M ${cx - (r_out * cosThetaStart)},${cy - (r_out * sinThetaStart)}
      A ${r_out},${r_out} 0 ${arcFlag1} ${cx - (r_out * cosThetaEnd)},${cy - (r_out * sinThetaEnd)}
      L ${cx - (r_in * cosThetaEnd)},${cy - (r_in * sinThetaEnd)}
      A ${r_in},${r_in} 0, ${arcFlag2} ${cx - (r_in * cosThetaStart)},${cy - (r_in * sinThetaStart)}
      L ${cx - (r_out * cosThetaStart)},${cy - (r_out * sinThetaStart)}
    `;
  }
  if (strand === -1) {
    // Reverse arrow
    const arrowBaseTheta = Math.min(thetaStart + ARROW_THETA, thetaEnd);
    const cosThetaArrowBase = Math.cos(arrowBaseTheta);
    const sinThetaArrowBase = Math.sin(arrowBaseTheta);
    return `
      M ${cx - (r * cosThetaStart)},${cy - (r * sinThetaStart)}
      L ${cx - (r_out * cosThetaArrowBase)},${cy - (r_out * sinThetaArrowBase)}
      A ${r_out},${r_out} 0 ${arcFlag1} ${cx - (r_out * cosThetaEnd)},${cy - (r_out * sinThetaEnd)}
      L ${cx - (r_in * cosThetaEnd)},${cy - (r_in * sinThetaEnd)}
      A ${r_in},${r_in} 0, ${arcFlag2} ${cx - (r_in * cosThetaArrowBase)},${cy - (r_in * sinThetaArrowBase)}
      L ${cx - (r * cosThetaStart)},${cy - (r * sinThetaStart)}
    `;
  }
  // Forward arrow
  const arrowBaseTheta = thetaEnd > thetaStart ? Math.max(thetaEnd - ARROW_THETA, thetaStart) : Math.min(thetaEnd - ARROW_THETA, thetaStart);
  const cosThetaArrowBase = Math.cos(arrowBaseTheta);
  const sinThetaArrowBase = Math.sin(arrowBaseTheta);
  return `
    M ${cx - (r_out * cosThetaStart)},${cy - (r_out * sinThetaStart)}
    A ${r_out},${r_out} 0 ${arcFlag1} ${cx - (r_out * cosThetaArrowBase)},${cy - (r_out * sinThetaArrowBase)}
    L ${cx - (r * cosThetaEnd)},${cy - (r * sinThetaEnd)}
    L ${cx - (r_in * cosThetaArrowBase)},${cy - (r_in * sinThetaArrowBase)}
    A ${r_in},${r_in} 0, ${arcFlag2} ${cx - (r_in * cosThetaStart)},${cy - (r_in * sinThetaStart)}
    L ${cx - (r_out * cosThetaStart)},${cy - (r_out * sinThetaStart)}
  `;
}
