import * as extraActions from './construct.extraActions';
import * as selectors from './construct.selectors';

import { extraReducers, reducers } from './construct.reducers';

import { CONSTRUCT } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './construct.initialState';

const slice = createSlice({
  name: CONSTRUCT,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const constructSelectors = { ...selectors };
export const constructActions = { ...slice.actions, ...extraActions };
export const { reducer: constructReducer } = slice;
