import * as extraActions from './sequencing.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_INIT, STATUS_LOADING } from 'constants/statuses.constants';

import { getInitialState } from './sequencing.initialState';
import { isValidReadMapping } from './sequencing.selectors';
import { mergeLists } from 'utils/helpers';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const reduceLoading = (state) => {
  state.status = STATUS_LOADING;
  state.error = null;
};

const reduceError = (state, action) => {
  if (state.status !== STATUS_INIT) {
    const { error } = action.payload;
    state.status = STATUS_ERROR;
    state.error = error;
  }
};

const parseAlignments = (alignments, oldAlignments = []) => {
  const newAlignments = mergeLists(oldAlignments, alignments, 'alignment_id');
  newAlignments.sort((a1, a2) => {
    if (a1.construct_code < a2.construct_code) {
      return -1;
    } else if (a1.construct_code > a2.construct_code) {
      return 1;
    }
    if (a1.clone !== a2.clone) {
      return a1.clone - a2.clone;
    }
    return a1.date - a2.date;
  });
  return newAlignments;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const clearWarnings = (state, action) => {
  const warningType = action.payload;
  state.uploadWarnings[warningType] = [];
};

const handleMappingInput = {
  reducer: (state, action) => {
    const { name, value, idx } = action.payload;
    state.readMapping[idx][name] = value;
  },
  prepare: (value, row, col) => ({ payload: { name: col.key, value, idx: row.idx }}),
};

const removeMappingRow = (state, action) => {
  const idx = action.payload;
  state.readMapping.splice(idx, 1);
};

const resetSequencing = (state) => ({ ...state, ...getInitialState() });

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const fetchAlignments = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { alignments } = action.payload;
    state.alignments = parseAlignments(alignments, state.alignments);
    state.status = STATUS_IDLE;
  },
  [rejected]: reduceError,
});

const hideMappingDialog = (actionName) => ({
  [actionName]: (state) => {
    state.showMappingDialog = false;
  },
});

const removeAlignment = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    if (state.status !== STATUS_INIT) { // Status init would signify user changed pages and sequencing is no longer mounted
      const { alignment_id } = action.payload;
      state.alignments = state.alignments.filter((alignment) => alignment.alignment_id !== alignment_id);
      state.status = STATUS_IDLE;
    }
  },
  [rejected]: reduceError,
});

const uploadSequencing = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    if (state.status !== STATUS_INIT) { // Status init would signify user changed pages and sequencing is no longer mounted
      const { alignments, no_construct_found, no_alignments_found } = action.payload;
      state.alignments = parseAlignments(alignments, state.alignments);
      state.uploadWarnings = {
        ...state.uploadWarnings,
        noAlignments: no_alignments_found,
        noConstructs: no_construct_found,
      };
      state.status = STATUS_IDLE;
    }
  },
  [rejected]: reduceError,
});

const validateSequencing = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    if (state.status !== STATUS_INIT) { // Status init would signify user changed pages and sequencing is no longer mounted
      const { read_mapping } = action.payload;
      state.readMapping = read_mapping;
      if (!isValidReadMapping(read_mapping)) {
        state.showMappingDialog = true;
      }
      state.status = STATUS_IDLE;
    }
  },
  [rejected]: reduceError,
});

export const reducers = {
  clearWarnings,
  handleMappingInput,
  removeMappingRow,
  resetSequencing,
};
export const extraReducers = {
  ...fetchAlignments(extraActions.fetchAlignments),
  ...hideMappingDialog(extraActions.hideMappingDialog),
  ...removeAlignment(extraActions.removeAlignment),
  ...uploadSequencing(extraActions._uploadSequencing),
  ...validateSequencing(extraActions._validateSequencing),
};
