import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { styled } from '@mui/system';

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderLeft: '1px solid',
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    p: '6px 12px',
    left: 'auto', // This prevents sticky horizontal scroll bug
  },
  [`&.${tableCellClasses.body}`]: {
    borderLeft: '1px solid',
    borderColor: theme.palette.divider,
    p: '6px 16px',
  },
}));

export default Cell;
