import DigestionRecipe from './DigestionRecipe';
import DigestionTable from './DigestionTable';
import Grid from '@mui/material/Grid';
import InfoPopper from 'components/InfoPopper';
import PropTypes from 'prop-types';
import React from 'react';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';

class Digestion extends React.Component {
  static propTypes = {
    newDigestions: PropTypes.bool.isRequired,
  };

  render() {
    const { newDigestions } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <DigestionTable />
        </Grid>
        {
          newDigestions ? (
            <Grid item xs={1}>
              <InfoPopper
                tooltipProps={{ title: 'Show Recipe', placement: 'top', arrow: true }}
                paperProps={{ sx: { p: 2 }}}
                placement='bottom-end'
              >
                <DigestionRecipe />
              </InfoPopper>
            </Grid>
          ) : null
        }
      </Grid>
    );
  }
}

const { selectNewDigestConstructs } = batchSelectors;

export default connect(
  (state) => ({
    newDigestions: selectNewDigestConstructs(state).length > 0,
  }),
)(Digestion);
