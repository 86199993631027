export const ACTION_CHECKED = 'Checked';
export const ACTION_NOTES = 'Notes';
export const ACTION_TABLE_UPDATE = 'TableUpdate';
export const ACTION_SEQUENCE_CONFIRMATION = 'SequenceConfirmation';
export const ACTION_SHAKE_UPDATE = 'ShakeUpdate';
export const ACTION_INPUT = 'Input';
export const ACTION_PS = 'ActionPS';
export const ACTION_CALC = 'ActionCalc';
export const ACTION_Q = 'ActionQ';
export const ACTION_READ = 'Read';
export const ACTION_SELECT_MATERIAL = 'SelectMaterial';
export const ACTION_ALTER_PS_TABLE = 'AlterPSTable';
export const ACTION_ALTER_Q_TABLE = 'AlterQTable';

/* PS & Q Table row action types */
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
