import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

class CloningAccordion extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    lastChild: PropTypes.bool,
  };

  render() {
    const { title, children, lastChild } = this.props;

    return (
      <Accordion
        disableGutters
        elevation={0}
        square
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderBottom: lastChild ? null : 0,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h5' sx={{ color: 'common.red', textDecoration: 'underline' }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default CloningAccordion;
