import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';
import { useTheme } from '@mui/material';

function Bases(props) {
  const { canvasRange, seq, fontSize, y } = props;
  const theme = useTheme();
  const dnaTextStyle = theme.typography.dna;
  const totalWidth = canvasRange[1] - canvasRange[0];
  const baseWidth = totalWidth / seq.length;
  const avgLetterWidth = TextMeasurer.getWidth(seq, fontSize, dnaTextStyle.fontFamily) / seq.length;
  return (
    <text
      x={canvasRange[0] + ((baseWidth - avgLetterWidth) / 2)}
      y={y}
      style={{ ...dnaTextStyle, ...disableTextSelect, whiteSpace: 'pre' }}
      fontSize={fontSize}
      textLength={totalWidth - (baseWidth - avgLetterWidth)}
      lengthAdjust='spacing'
      alignmentBaseline='text-before-edge'
    >
      {seq}
    </text>
  );
}

Bases.propTypes = {
  canvasRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  seq: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  y: PropTypes.number,
};

export default Bases;
