import * as ConstructService from 'services/ConstructService';

import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class ExportButton extends React.Component {
  static propTypes = {
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
    })).isRequired,
    sx: sxPropType,
  };

  render() {
    const { sx, constructs } = this.props;
    const constructDownload = async () => await ConstructService.getConstructsDownload(constructs.map((c) => c.construct_code));
    return (
      <DownloadButton
        sx={sx}
        color='inherit'
        download={constructDownload}
      >
        Export
      </DownloadButton>
    );
  }
}

export default ExportButton;
