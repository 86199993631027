import React, { useEffect, useState } from 'react';
import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import ImageAnnotator from './ImageAnnotator';
import ImageCaption from './ImageCaption';
import ImageCarousel from './ImageCarousel';
import ImageTile from './ImageTile';
import MuiDialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { UploadButton } from '@acheloisbiosoftware/absui.core';
import { deepObjectModify } from 'utils/helpers';
import green from '@mui/material/colors/green';

function ImageGallery(props) {
  const {
    onUpload,
    onDelete,
    onEdit,
    images,
    getImageUrl,
    onEditorOpen,
    height,
    maxWidth,
  } = props;
  const [loadedImages, setLoadedImages] = useState({});
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [galleryIdx, setGalleryIdx] = useState(0);
  const [saveStatus, setSaveStatus] = useState(STATUS_IDLE);
  const [editImg, setEditImg] = useState(null);

  useEffect(() => {
    images.forEach((img) => {
      if (!loadedImages[img.file_name]) {
        setLoadedImages({
          ...loadedImages,
          [img.file_name]: {
            source: getImageUrl(img),
            name: img.file_name,
          },
        });
      }
    });
  }, [images, getImageUrl, loadedImages]);

  useEffect(() => {
    let timer;
    if (saveStatus === STATUS_SUCCESS) {
      timer = setTimeout(() => setSaveStatus(STATUS_IDLE), 1000);
    } else if (saveStatus === STATUS_ERROR) {
      timer = setTimeout(() => setSaveStatus(STATUS_IDLE), 3000);
    }
    return () => clearTimeout(timer);
  }, [saveStatus]);

  const uploadImage = (event) => {
    const { files } = event.target;
    if (!files) {
      return;
    }
    onUpload(files);
  };

  const removeImage = (imageName) => {
    setGalleryOpen(false);
    setGalleryIdx(0);
    onDelete(imageName);
  };

  const onSave = async (imgName, imgData) => {
    setSaveStatus(STATUS_LOADING);
    const res = await onEdit(imgName, imgData);
    if (!res?.error) {
      const newLoadedImages = deepObjectModify(loadedImages, [imgName, 'source'], imgData);
      setLoadedImages(newLoadedImages);
      setSaveStatus(STATUS_SUCCESS);
    } else {
      setSaveStatus(STATUS_ERROR);
    }
  };

  const handleEditorOpen = (img) => () => {
    const openEditor = () => {
      setEditImg(img.name);
      setGalleryOpen(false);
    };
    if (onEditorOpen) {
      onEditorOpen(img, openEditor);
    } else {
      openEditor();
    }
  };

  const views = images.map((img) => ({
    ...loadedImages[img.file_name],
    refNum: img.refNum,
    caption: (
      <ImageCaption
        img={img}
        onEdit={onEdit ? handleEditorOpen(loadedImages[img.file_name]) : null}
        onDelete={onDelete ? () => removeImage(img.file_name) : null}
      />
    ),
  }));
  return (
    <Box sx={{ display: 'flex' }}>
      <Paper variant='outlined' sx={{ m: 'auto', overflowX: 'auto', maxWidth }}>
        <Box sx={{ display: 'inline-block', p: 2 }}>
          <Box sx={{ flexWrap: 'nowrap', display: 'flex', maxWidth: 1, height: height + 4 }}>
            {
              views.map((img, idx) => (
                <ImageTile
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`img_${img.name}_${idx}`}
                  height={height}
                  image={img}
                  onClick={() => {
                    setGalleryOpen(true);
                    setGalleryIdx(idx);
                  }}
                  onEdit={onEdit ? handleEditorOpen(img) : null}
                  onDelete={onDelete ? () => removeImage(img.name) : null}
                />
              ))
            }
            {
              onUpload ? (
                <Box sx={{ mx: 0.5, position: 'relative' }}>
                  <Tooltip title='Upload image'>
                    <Box component='span'>
                      <UploadButton
                        variant='text'
                        sx={{
                          height,
                          'width': height,
                          'display': 'flex',
                          'bgcolor': green[100],
                          'borderRadius': 2,
                          '&:hover': {
                            bgcolor: green[200],
                          },
                        }}
                        startIcon={null}
                        onChange={uploadImage}
                        accept={['.gif', '.jpg', '.jpeg', '.png']}
                        multiple
                      >
                        <AddIcon sx={{ m: 'auto', color: green[800], height: 30, width: 30 }} />
                      </UploadButton>
                    </Box>
                  </Tooltip>
                </Box>
              ) : null
            }
          </Box>
        </Box>
      </Paper>
      <MuiDialog
        open={Boolean(editImg)}
        onClose={() => setEditImg(null)}
        maxWidth={false}
        disableEnforceFocus
      >
        {
          editImg ? (
            <ImageAnnotator
              img={loadedImages[editImg]}
              saveStatus={saveStatus}
              onSave={onSave}
            />
          ) : null
        }
      </MuiDialog>
      <ImageCarousel
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        views={views.filter((img) => img?.source)}
        galleryIdx={galleryIdx}
      />
    </Box>
  );
}

ImageGallery.propTypes = {
  /**
   * Callback function when uploading a new image. Upon upload, the function
   * will be passed an array of files (images) to upload. If not provided,
   * the component will hide the option to upload any images.
   */
  onUpload: PropTypes.func,

  /**
   * Callback function when deleting an existing image. Upon deletion, the
   * function will be passed the string name of the image to delete. If not
   * provided, the component will hide the option to delete any images.
   */
  onDelete: PropTypes.func,

  /**
   * Callback function when editing an existing image. Upon editing, the
   * function will be passed the string name of the image and the edited image
   * data (as a base64 encoded string).
   */
  onEdit: PropTypes.func,

  /**
   * Function to get the image URL for a given image. The function is passed
   * in one of the image objects from props.images and must return a URL in
   * which the image source data can be retrieved.
   */
  getImageUrl: PropTypes.func.isRequired,

  /**
   * An array of objects representing images to display. Each image must
   * include the unique name of the image (img.file_name). Additionally, each
   * image may contain a caption (img.caption) to be displayed when maximized,
   * an upload date (img.uploaded_at) to be displayed when no caption is
   * given, an author's name (img.author.name) to be displayed when no caption
   * is given, and a badge number (img.refNum) which will be displayed on the
   * image tile (NOTE: must be between 1 and 99).
   */
  images: PropTypes.arrayOf(PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    caption: PropTypes.node,
    uploaded_at: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
    refNum: PropTypes.number,
  })).isRequired,

  /** Height of the images. Defaults to 180px. */
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  /** Maximum width of the image gallery. Defaults to 80% of parent width. */
  maxWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  /**
   * Callback function for when editor is first opened. Takes in the image
   * opened and the opening callback. Call the open callback in order to proceed
   * with opening the editor.
   */
  onEditorOpen: PropTypes.func,
};

ImageGallery.defaultProps = {
  height: 180,
  maxWidth: 0.8,
};

export default ImageGallery;
