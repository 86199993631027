import * as ConstructService from 'services/ConstructService';

import { PRIMER } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const uploadPrimers = createAsyncThunk(
  `${PRIMER}/upload`,
  async (primerData, { rejectWithValue }) => {
    const res = await ConstructService.uploadPrimers(primerData);
    return responseToPayload(res, rejectWithValue);
  },
);
