import { DownloadButton } from '@acheloisbiosoftware/absui.core';
import NotesRTE from './NotesRTE';
import PrimerTable from 'components/PrimerTable';
import PrimerUpload from 'components/PrimerUpload';
import PropTypes from 'prop-types';
import React from 'react';
import StepperButtons from 'views/Batch/StepperButtons';
import { batchSelectors } from 'store/batch';
import { batchStepperActions } from 'store/batchStepper';
import { connect } from 'react-redux';
import { createPrimerTsv } from 'utils/biology.utils';

const STEP_NAME = 'PRIMER_ORDER';

class PrimerOrderStep extends React.Component {
  static propTypes = {
    onNextStep: PropTypes.func.isRequired,
    batchId: PropTypes.number.isRequired,
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_data: PropTypes.shape({
        primers: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          sequence: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    })).isRequired,
  };

  render() {
    const { batchId, constructs, onNextStep } = this.props;
    const primers = Array.prototype.concat.apply([], constructs.filter((c) => c.construct_data.primers).map((c) => c.construct_data.primers));
    const primerTsv = {
      name: `batch${batchId}_primers.tsv`,
      file: createPrimerTsv(primers),
    };
    return (
      <>
        <PrimerTable constructs={constructs} />
        <NotesRTE
          step={STEP_NAME}
          sx={{ mt: 3 }}
        />
        <StepperButtons position='right' onNextStep={onNextStep}>
          <PrimerUpload
            constructs={constructs}
            sx={{ mr: 1 }}
          />
          <DownloadButton
            download={primerTsv}
            sx={{ mr: 1 }}
            size='small'
            color='primary'
          >
            Download Primers
          </DownloadButton>
        </StepperButtons>
      </>
    );
  }
}

const { selectBatchId, selectConstructs } = batchSelectors;
const { onNextStep } = batchStepperActions;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    constructs: selectConstructs(state),
  }),
  { onNextStep },
)(PrimerOrderStep);
