import { Button, Dialog } from '@acheloisbiosoftware/absui.core';

import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import React from 'react';
import { batchActions } from 'store/batch';
import { connect } from 'react-redux';
import { constructActions } from 'store/construct';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

class DeleteButton extends React.Component {
  static propTypes = {
    entities: PropTypes.arrayOf(PropTypes.object).isRequired,
    entityType: PropTypes.oneOf(['construct', 'batch']).isRequired,
    clearSelected: PropTypes.func.isRequired,
    deleteBatches: PropTypes.func.isRequired,
    deleteConstructs: PropTypes.func.isRequired,
    sx: sxPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      warningOpen: false,
    };
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { entities, entityType, clearSelected, deleteBatches, deleteConstructs } = this.props;
    if (entityType === 'construct') {
      deleteConstructs(entities.map((c) => c.construct_code));
    } else if (entityType === 'batch') {
      deleteBatches(entities.map((c) => c.batch_id));
    }
    clearSelected();
  }

  render() {
    const { sx, entities, entityType } = this.props;
    const { warningOpen } = this.state;
    return (
      <>
        <Button
          startIcon={<DeleteIcon />}
          variant='outlined'
          color='inherit'
          onClick={() => this.setState({ warningOpen: true })}
          sx={sx}
        >
          Delete
        </Button>
        <Dialog
          open={warningOpen}
          onClose={() => this.setState({ warningOpen: false })}
          onConfirm={this.onDelete}
          title={`Delete ${entityType}${entityType === 'batch' ? 'e' : ''}s?`}
          confirmButtonText='Yes, delete'
        >
          <DialogContentText>Are you sure you would like to delete the {entities.length} selected {entityType}{entityType === 'batch' ? 'e' : ''}s? These changes will be permanent.</DialogContentText>
        </Dialog>
      </>
    );
  }
}

const { deleteBatches } = batchActions;
const { deleteConstructs } = constructActions;

export default connect(
  null,
  { deleteBatches, deleteConstructs },
)(DeleteButton);
