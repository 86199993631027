import PropTypes from 'prop-types';
import React from 'react';

// WARNING: This class only re-renders when the window is changed. Any desired
// changes made to the SVG should be handled by d3 rather than React re-renders.
class ResizableSvg extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired, // (width: PropTypes.number) => { height: PropTypes.number, style: PropTypes.object, render: PropTypes.node }
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.width !== nextState.width) return true;
    return false;
  }

  handleResize() {
    this.setState({ width: this.divElement.clientWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  render() {
    const { children, id } = this.props;
    const { width } = this.state;
    if (!width) {
      return (
        <svg
          id={id}
          style={{ width: '100%' }}
          ref={(divElement) => { this.divElement = divElement; }}
        />
      );
    }
    const child = children(width);
    return (
      <svg
        id={id}
        width='100%'
        height={child.height}
        style={child.style}
        ref={(divElement) => { this.divElement = divElement; }}
      >
        { child.render }
      </svg>
    );
  }
}

export default ResizableSvg;
