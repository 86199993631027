import Box from '@mui/material/Box';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

class InfoDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
  };

  render() {
    const { open, onClose, onDone, children, readOnly, appearDisabled } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        onConfirm={onDone}
        scroll='paper'
        fullWidth
        maxWidth='md'
        title='Additional Step Details'
        dividers
        confirmButtonText='Done'
        confirmDisabled={readOnly}
        confirmButtonProps={{
          sx: appearDisabled ? {
            'color': 'action.disabled',
            'bgcolor': 'action.disabledBackground',
            '&:hover': {
              color: 'primary.contrastText',
            },
          } : null,
        }}
      >
        {
          typeof(children) === 'string' ? (
            <Typography>{children}</Typography>
          ) : (
            <Box>{children}</Box>
          )
        }
      </Dialog>
    );
  }
}

export default InfoDialog;
