import * as extraActions from './batchStepper.extraActions';

import { GIGA_BATCH, MAXI_BATCH, batchStatuses } from 'constants/batch.constants';
import { LEFT, RIGHT } from 'constants/enums.constants';
import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';

import { batchActions } from 'store/batch';
import { getInitialState } from './batchStepper.initialState';
import { idFindFn } from 'utils/helpers';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const reduceLoading = (state) => {
  state.saveStatus = STATUS_LOADING;
};
const reduceError = (state) => {
  state.saveStatus = STATUS_ERROR;
};
const reduceSuccess = (state) => {
  state.saveStatus = STATUS_SUCCESS;
};
const reduceIdle = (state) => {
  state.saveStatus = STATUS_IDLE;
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const updateSubstep = (state, action) => {
  const stepIdx = action.payload;
  state.activeSubstep = stepIdx;
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const reduceUpdate = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: reduceSuccess,
  [rejected]: reduceError,
});

const reduceStagedUpdate = (actionName) => ({
  [actionName]: reduceIdle,
});

const fetchBatch = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { batch } = action.payload;
    if (batch) {
      const { type, status, batch_data } = batch;
      state.activeStep = batchStatuses[type].indexOf(status);
      if ([MAXI_BATCH, GIGA_BATCH].includes(type)) {
        // TODO: temporary fix
        const batchStep = batch_data.find(idFindFn(status));
        if (batchStep) {
          const batchSubstep = batchStep.substeps.find(idFindFn(batchStep.substatus));
          state.activeSubstep = batchStep.substeps.indexOf(batchSubstep);
        }
      }
      state.saveStatus = STATUS_SUCCESS;
    }
  },
});

const resetBatch = (actionName) => ({
  [actionName]: (state) => ({
    ...state,
    ...getInitialState(),
  }),
});

const updateStep = (actionName) => ({
  [actionName]: (state, action) => {
    const { stepIdx, substepIdx } = action.payload;
    state.slideDirection = state.activeStep > stepIdx ? RIGHT : LEFT;
    state.activeStep = stepIdx;
    state.activeSubstep = substepIdx;
  },
});

export const reducers = { updateSubstep };
export const extraReducers = {
  ...reduceUpdate(batchActions.editGelImage),
  ...fetchBatch(batchActions.fetchBatch),
  ...reduceStagedUpdate(batchActions._handleBatchInputAction),
  ...reduceStagedUpdate(batchActions._handleBatchActionAction),
  ...reduceStagedUpdate(batchActions._handleConstructInputAction),
  ...reduceUpdate(batchActions.removeGelImage),
  ...resetBatch(batchActions._resetBatchAction),
  ...reduceUpdate(batchActions.updateBatch),
  ...reduceUpdate(batchActions.uploadGelImages),
  ...updateStep(extraActions._updateStepAction),
};
