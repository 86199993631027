import { CLONING_BATCH, GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import { ICON_CLONING, ICON_GIGA, ICON_MAXI } from 'constants/icon.constants';

import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const icons = {
  [CLONING_BATCH]: (<Icon style={{ margin: 'auto' }} icon={ICON_CLONING} height={20} width={20} />),
  [MAXI_BATCH]: (<Icon style={{ margin: 'auto' }} icon={ICON_MAXI} height={20} width={20} />),
  [GIGA_BATCH]: (<Icon style={{ margin: 'auto' }} icon={ICON_GIGA} height={25} width={25} />),
};

function Pill(props) {
  const { children, type, onClick, sx } = props;
  return (
    <Box
      sx={
        mergeSx({
          height: 24,
          px: 1,
          py: 0.5,
          display: 'flex',
          borderRadius: 5,
          bgcolor: 'background.paper',
          color: 'text.primary',
          border: 1,
          cursor: 'pointer',
          width: type ? 48 : 24,
        },
        sx)
      }
      onClick={onClick}
    >
      {icons[type]}
      <Typography
        sx={{ m: 'auto', fontWeight: 'fontWeightBold', fontSize: 'fontSizeSmall' }}
        variant='button'
      >
        {children}
      </Typography>
    </Box>
  );
}

Pill.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  type: PropTypes.oneOf([CLONING_BATCH, MAXI_BATCH, GIGA_BATCH]),
  onClick: PropTypes.func,
  sx: sxPropType,
};

export default Pill;
