import PropTypes from 'prop-types';
import React from 'react';

class AnnotationStem extends React.Component {
  static propTypes = {
    enzyme: PropTypes.shape({
      annotationId: PropTypes.string.isRequired,
      r_text: PropTypes.number.isRequired,
      thetaLoc: PropTypes.number.isRequired,
      textRow: PropTypes.number,
    }).isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r_in: PropTypes.number.isRequired,
  };

  render() {
    const { enzyme, cx, cy, r_in } = this.props;
    const { annotationId, r_text, thetaLoc, textRow } = enzyme;
    if (textRow === null) return null;
    return (
      <line
        x1={cx} y1={cy - r_in}
        x2={cx} y2={cy - r_text}
        stroke='grey'
        /* eslint-disable-next-line react/no-unknown-property */
        transform-origin={`${cx}px ${cy}px`}
        transform={`rotate(${thetaLoc / (2 * Math.PI) * 360})`}
        className={`${annotationId}_stem`}
      />
    );
  }
}

export default AnnotationStem;
