import React, { useState } from 'react';
import { deepObjectModify, reorderIndexList, reorderList } from 'utils/helpers';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { ChecklistItem } from 'components/Checklist';
import DataTable from 'components/DataTable';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoPopper from 'components/InfoPopper';
import MenuItem from '@mui/material/MenuItem';
import PCRProtocol from './PCRProtocol';
import PCRRecipe from './PCRRecipe';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import { batch as reduxBatch } from 'react-redux';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const selectPcrFragments = (constructs) => constructs.map((c) => c.construct_data.pcr_fragments.map((f, idx) => ({
  ...f,
  construct: c.construct_code,
  constructFragmentIdx: idx,
}))).flat();

function PCRTable(props) {
  const { constructs, handleConstructInput, handleSave, sx, showProtocol } = props;
  const [selected, setSelected] = useState([]);
  const [editing, setEditing] = useState(true);

  const singleConstruct = constructs.length === 1;

  const onSelect = (idx) => {
    const search = selected.indexOf(idx);
    const newSelected = [...selected];
    if (search !== -1) {
      newSelected.splice(search, 1);
    } else {
      newSelected.push(idx);
    }
    setSelected(newSelected);
  };

  const onAddRow = () => {
    const construct = constructs[constructs.length - 1];
    const newFragments = [...construct.construct_data.pcr_fragments, {
      template: '',
      fragment: '',
      primer1: '',
      primer2: '',
      length: '',
      concentration: '',
    }];
    handleConstructInput(construct.construct_code, ['construct_data', 'pcr_fragments'], newFragments);
  };

  const onRemoveRows = () => {
    const pcrFragments = selectPcrFragments(constructs);
    const toRemove = pcrFragments.filter((_, idx) => selected.includes(idx));
    reduxBatch(() => {
      constructs.forEach((construct) => {
        const idxsToRemove = toRemove.filter((f) => f.construct === construct.construct_code).map((f) => f.constructFragmentIdx);
        if (idxsToRemove.length) {
          const newFragments = construct.construct_data.pcr_fragments.filter((_, idx) => !idxsToRemove.includes(idx));
          handleConstructInput(construct.construct_code, ['construct_data', 'pcr_fragments'], newFragments);
        }
      });
    });
    setSelected([]);
  };

  const onInputChange = (value, row, col) => {
    if (col.key === 'construct') {
      const oldConstruct = constructs.find((c) => c.construct_code === row.construct);
      const newConstruct = constructs.find((c) => c.construct_code === value);

      const oldConstructFragments = [...oldConstruct.construct_data.pcr_fragments];
      const fragmentData = oldConstructFragments.splice(row.constructFragmentIdx, 1)[0];
      const newConstructFragments = [...newConstruct.construct_data.pcr_fragments];
      newConstructFragments.push(fragmentData);

      reduxBatch(() => {
        handleConstructInput(row.construct, ['construct_data', 'pcr_fragments'], oldConstructFragments);
        handleConstructInput(value, ['construct_data', 'pcr_fragments'], newConstructFragments);
      });
    } else {
      const construct = constructs.find((c) => c.construct_code === row.construct);
      const newPcrFragments = deepObjectModify(construct.construct_data.pcr_fragments, [row.constructFragmentIdx, col.key], value);
      handleConstructInput(row.construct, ['construct_data', 'pcr_fragments'], newPcrFragments);
    }
  };

  const onReorder = (row, srcIdx, destIdx) => {
    // Only when singleConstruct
    const newPcrFragments = reorderList(constructs[0].construct_data.pcr_fragments, srcIdx, destIdx);
    const newSelected = reorderIndexList(selected, srcIdx, destIdx);
    setSelected(newSelected);
    handleConstructInput(row.construct, ['construct_data', 'pcr_fragments'], newPcrFragments);
  };

  const onSave = () => {
    handleSave?.();
    setEditing(false);
  };

  const pcrFragments = selectPcrFragments(constructs);
  const maxFragmentLength = Math.max(...pcrFragments.map((f) => f.length));
  const constructCodes = constructs.map((c) => c.construct_code);

  const columns = [
    { key: 'fragmentNumber', title: '' },
    (!singleConstruct ? ({
      key: 'construct',
      title: 'Construct',
      editable: true,
      inputProps: {
        select: true,
        children: constructCodes.map((constructCode) => (
          <MenuItem key={constructCode} value={constructCode}>
            {constructCode}
          </MenuItem>
        )),
      },
    }) : null),
    { key: 'fragment', title: 'PCR Fragment', editable: true },
    { key: 'template', title: 'PCR Template', editable: true },
    { key: 'primer1', title: 'Primer 1', editable: true },
    { key: 'primer2', title: 'Primer 2', editable: true },
    {
      key: 'length',
      title: 'Fragment Length',
      editable: true,
      adornment: 'bp',
      inputProps: { type: 'number' },
    },
  ].filter((col) => col);

  const rows = pcrFragments.map((fragment, idx) => ({
    key: `${fragment.construct}_PCRRow${idx}`,
    idx,
    ...fragment,
    fragmentNumber: (
      <ChecklistItem
        content={idx + 1}
        onClick={() => onSelect(idx)}
        checked={selected.includes(idx)}
        sx={{ m: 'auto' }}
      />
    ),
  }));

  const sxActionButton = { mb: 1, display: 'block' };

  return (
    <Grid container spacing={2} sx={sx}>
      <Grid item xs={11}>
        <DataTable
          columns={columns}
          data={rows}
          onChange={onInputChange}
          onReorder={singleConstruct && editing ? onReorder : null}
          tableProps={{ size: 'small' }}
          containerProps={{ sx: { maxHeight: 600, overflow: 'auto', m: 'auto' }}}
          readOnly={!editing}
        />
      </Grid>
      <Grid item xs={1}>
        {
          showProtocol ? (
            <InfoPopper
              tooltipProps={{ title: 'Show Recipe', placement: 'right', arrow: true }}
              paperProps={{ sx: { p: 2 }}}
              placement='bottom-end'
              buttonProps={{ sx: sxActionButton, size: 'small' }}
            >
              <Box>
                <PCRRecipe />
                <Divider sx={{ my: 1 }} />
                <PCRProtocol maxFragmentLength={maxFragmentLength} />
              </Box>
            </InfoPopper>
          ) : null
        }
        {
          editing ? (
            <>
              <IconButton
                onClick={onSave}
                size='small'
                sx={sxActionButton}
              >
                <Tooltip title='Save' placement='right' arrow>
                  <SaveIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={onAddRow}
                size='small'
                sx={sxActionButton}
              >
                <Tooltip title='Add Fragment' placement='right' arrow>
                  <AddCircleIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={onRemoveRows}
                size='small'
                sx={sxActionButton}
              >
                <Tooltip title='Remove Fragment' placement='right' arrow>
                  <RemoveCircleIcon />
                </Tooltip>
              </IconButton>
            </>
          ) : (
            <IconButton
              onClick={() => setEditing(true)}
              size='small'
              sx={sxActionButton}
            >
              <Tooltip title='Edit' placement='right' arrow>
                <EditIcon />
              </Tooltip>
            </IconButton>
          )
        }
      </Grid>
    </Grid>
  );
}

PCRTable.propTypes = {
  /**
   * Callback fired when any data is changed in the table. Consumes the
   * construct code of the construct changed, a list of keys to the data that
   * was changed, and the new value at that key.
   */
  handleConstructInput: PropTypes.func.isRequired,

  /** Callback fired when the data should be saved. Takes in no arguments. */
  handleSave: PropTypes.func,

  /**
   * The list of constructs. The table will show all of the PCR fragments from
   * all of the constructs, and allow editing/adding new fragments for each of
   * those constructs (in edit mode).
   */
  constructs: PropTypes.arrayOf(PropTypes.shape({
    construct_code: PropTypes.string.isRequired,
    construct_data: PropTypes.shape({
      pcr_fragments: PropTypes.arrayOf(PropTypes.shape({
        template: PropTypes.string.isRequired,
        fragment: PropTypes.string.isRequired,
        primer1: PropTypes.string.isRequired,
        primer2: PropTypes.string.isRequired,
        length: PropTypes.string.isRequired,
        concentration: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
  })).isRequired,

  /** If true, will show an InfoPopper with the PCR protocol. */
  showProtocol: PropTypes.bool,

  /** sx to be applied to the Grid container. */
  sx: sxPropType,
};

export default PCRTable;
