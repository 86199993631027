import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';
import { disableTextSelect } from 'constants/styles.constants';

class Title extends React.Component {
  static propTypes = {
    seqData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      sequence: PropTypes.string.isRequired,
    }).isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    fontSize: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
  };

  render() {
    const { cx, cy, seqData, maxLength, fontSize, fontFamily } = this.props;
    let fontSizeTitle = fontSize;
    let fontSizeLength = fontSizeTitle * 2 / 3;

    const title = seqData.name;
    let titleLength = TextMeasurer.getWidth(title, fontSizeTitle, fontFamily);
    if (titleLength > maxLength) {
      fontSizeTitle *= maxLength / titleLength;
      titleLength = TextMeasurer.getWidth(title, fontSizeTitle, fontFamily);
    }

    fontSizeLength = Math.min(fontSizeLength, fontSizeTitle);
    const bp = `${seqData.sequence.length} bp`;
    let bpLength = TextMeasurer.getWidth(bp, fontSizeLength, fontFamily);
    if (bpLength > maxLength) {
      fontSizeLength *= maxLength / bpLength;
      bpLength = TextMeasurer.getWidth(bp, fontSizeLength, fontFamily);
    }

    if (fontSizeTitle < 10) return null;
    return (
      <>
        <text
          x={cx - (titleLength / 2)}
          y={cy}
          fontSize={fontSizeTitle}
          fontFamily={fontFamily}
          fontWeight='bold'
          style={disableTextSelect}
        >
          {title}
        </text>
        <text
          x={cx - (bpLength / 2)}
          y={cy + (fontSizeTitle * 1.2)}
          fontSize={fontSizeLength}
          fontFamily={fontFamily}
          style={disableTextSelect}
        >
          {bp}
        </text>
      </>
    );
  }
}

export default Title;
