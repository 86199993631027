import { STATUS_INIT, STATUS_LOADING } from 'constants/statuses.constants';

import { MATERIAL } from 'constants/store.constants';

const selectMaterialSlice = (state) => state[MATERIAL];
export const selectEquipments = (state) => selectMaterialSlice(state).equipments;
export const selectMaterials = (state) => selectMaterialSlice(state).materials;
export const selectChemicals = (state) => selectMaterialSlice(state).chemicals;
export const selectBuffers = (state) => selectMaterialSlice(state).buffers;

export const selectEquipment = (state) => selectMaterialSlice(state).equipment;
export const selectMaterial = (state) => selectMaterialSlice(state).material;
export const selectChemical = (state) => selectMaterialSlice(state).chemical;
export const selectBuffer = (state) => selectMaterialSlice(state).buffer;
export const selectPageFetched = (state) => selectMaterialSlice(state).statusPage !== STATUS_INIT;
export const selectPageLoading = (state) => selectMaterialSlice(state).statusPage === STATUS_LOADING;
