import React, { useEffect } from 'react';
import { batchActions, batchSelectors } from 'store/batch';
import { materialActions, materialSelectors } from 'store/material';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_SELECT_MATERIAL } from 'constants/batchActions.constants';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import DataTable from 'components/DataTable';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function EquipmentTable(props) {
  const { step, substep, line, readOnly, appearDisabled, editMiddleware } = props;
  const dispatch = useDispatch();
  const lineData = useSelector((state) => batchSelectors.selectBatchLineData(state, step, substep, line));
  const equipments = useSelector(materialSelectors.selectEquipments);

  useEffect(() => {
    dispatch(materialActions.fetchEquipments(true));
  }, [dispatch]);

  const handleAcknowledge = (equipmentId, checked) => {
    dispatch(batchActions.handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_SELECT_MATERIAL,
      payload: {
        row_id_key: 'equipment_id',
        equipment_id: equipmentId,
        acknowledged: checked,
      },
    }));
  };

  const { title, data } = lineData;
  const columns = [
    { key: 'name', title: 'Name', cellProps: { sx: { width: 120 }}},
    { key: 'vendor', title: 'Vendor', cellProps: { sx: { width: 100 }}},
    { key: 'model', title: 'Model', cellProps: { sx: { width: 130 }}},
    { key: 'serial_no', title: 'Serial #', cellProps: { sx: { width: 200 }}},
    { key: 'location', title: 'Location', cellProps: { sx: { width: 150 }}},
    { key: 'acknowledged', title: 'Acknowledged', cellProps: { sx: { width: 100 }}},
  ];

  const rows = data.map(({ equipment_id, acknowledged }) => {
    const equipment = equipments.find((e) => e.equipment_id === equipment_id);
    if (!equipment) {
      const skeletonProps = { height: 45 };
      return {
        key: `equipment${equipment_id}_row`,
        name: <Skeleton {...skeletonProps} />,
        vendor: <Skeleton {...skeletonProps} />,
        model: <Skeleton {...skeletonProps} />,
        serial_no: <Skeleton {...skeletonProps} />,
        location: <Skeleton {...skeletonProps} />,
        acknowledged: <Skeleton {...skeletonProps} />,
      };
    }
    return {
      key: `equipment${equipment_id}_row`,
      name: (
        <Link
          href={`/equipment/${equipment_id}`}
          target='_blank'
          sx={appearDisabled ? { opacity: (theme) => theme.opacity.disabled } : {}}
        >
          {equipment.name}
        </Link>
      ),
      vendor: equipment.vendor,
      model: equipment.model,
      serial_no: equipment.serial_no,
      location: equipment.location,
      acknowledged: (
        <Checkbox
          checked={acknowledged}
          color='default'
          disabled={readOnly}
          sx={appearDisabled ? { color: 'text.disabled' } : null}
          onChange={(e) => {
            const { checked } = e.target;
            editMiddleware(() => handleAcknowledge(equipment_id, checked));
          }}
        />
      ),
    };
  });
  return (
    <Box sx={{ textAlign: 'center', m: 3 }}>
      <Typography variant='h6'>{title}</Typography>
      <DataTable
        columns={columns}
        data={rows}
        readOnly={readOnly}
        appearDisabled={appearDisabled}
        containerProps={{ sx: { m: 1 }}}
      />
    </Box>
  );
}

EquipmentTable.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired,
  appearDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  editMiddleware: PropTypes.func.isRequired,
};

export default EquipmentTable;
