import PropTypes from 'prop-types';
import React from 'react';

class AnnotationStem extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      annotationId: PropTypes.string.isRequired,
      canvasStart: PropTypes.number.isRequired,
      canvasEnd: PropTypes.number.isRequired,
      blockRow: PropTypes.number.isRequired,
      textRow: PropTypes.number.isRequired,
      color: PropTypes.object.isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
  };

  render() {
    const { feature, blockHeight, blockSpacing, stackUpHeight } = this.props;
    const { annotationId, canvasStart, canvasEnd, blockRow, textRow, color } = feature;
    if (blockRow === textRow) {
      return null;
    }
    const x = (canvasStart + canvasEnd) / 2;
    const y1 = (blockRow * (blockHeight + blockSpacing)) + (blockHeight / 2);
    const y2 = (textRow * (blockHeight + blockSpacing)) + (blockHeight / 2);
    return (
      <line
        x1={x} y1={!stackUpHeight ? y1 : stackUpHeight - y1}
        x2={x} y2={!stackUpHeight ? y2 : stackUpHeight - y2}
        stroke={color.hex() === '#FFFFFF' ? '#A3A3A3' : color}
        className={`${annotationId}_stem`}
      />
    );
  }
}

export default AnnotationStem;
