import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import React from 'react';
import Typography from '@mui/material/Typography';

const RedText = (props) => (
  <Box component='span' sx={{ color: 'common.red' }} {...props} />
);

function PCRRecipe() {
  const ingredients = [
    (<><RedText>1μL</RedText> Primer 1 (10μM)</>),
    (<><RedText>1μL</RedText> Primer 2 (10μM)</>),
    (<><RedText>1μL</RedText> Template DNA (1ng/μL)</>),
    (<><RedText>10μL</RedText> 2X Phusion Flash MM</>),
    (<><RedText>7μL</RedText> H20</>),
  ];
  return (
    <Box>
      <Typography sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}>Recipe</Typography>
      <Checklist
        dense
        items={ingredients.map((ingredient) => ({ content: ingredient }))}
      />
    </Box>
  );
}

export default PCRRecipe;
