import * as extraActions from './inventory.extraActions';
import * as selectors from './inventory.selectors';

import { extraReducers, reducers } from './inventory.reducers';

import { INVENTORY } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './inventory.initialState';

const slice = createSlice({
  name: INVENTORY,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const inventorySelectors = { ...selectors };
export const inventoryActions = { ...slice.actions, ...extraActions };
export const { reducer: inventoryReducer } = slice;
