import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { alpha } from '@mui/material/styles';

function LoadingPage(props) {
  const { children } = props;
  return (
    <Backdrop
      open
      sx={{
        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.opacity.light),
        color: 'primary.dark',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 'backdrop',
      }}
    >
      <CircularProgress size={100} sx={{ m: 'auto', mb: 2 }} color='inherit' />
      <Box component='span' sx={{ m: 'auto', mt: 2 }}>
        {children}
      </Box>
    </Backdrop>
  );
}

LoadingPage.propTypes = {
  // The content of the loading page (below the progress circle).
  children: PropTypes.node,
};

export default LoadingPage;
