import { GIGA_BATCH, MAXI_BATCH, batchStatuses } from 'constants/batch.constants';
import { STATUS_INIT, STATUS_LOADING } from 'constants/statuses.constants';
import { isLineComplete, isLineCurrent, isLineFuture } from 'utils/batch.utils';

import { BATCH } from 'constants/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { deepGet } from '@acheloisbiosoftware/absui.utils';
import { idFindFn } from 'utils/helpers';

const selectBatchSlice = (state) => state[BATCH];
export const selectBatch = (state) => selectBatchSlice(state).batch;
export const selectBatchId = (state) => selectBatch(state).batch_id;
export const selectBatchType = (state) => selectBatch(state).type;
export const selectBatchData = (state) => selectBatch(state).batch_data;
export const selectBatchStatus = (state) => selectBatch(state).status;
export const selectBatchLoading = (state) => selectBatchSlice(state).status === STATUS_LOADING;
export const selectBatchReportData = (state) => selectBatch(state).report_data;
export const selectBatchReportStatus = (state) => selectBatchReportData(state).signed_status;
export const selectBatchLockedStatus = (state) => selectBatchReportData(state).locked_status;
export const selectBatchReportSignees = (state) => selectBatchSlice(state).reportSignees;
export const selectBatchReportSigns = (state) => selectBatchSlice(state).reportSigns;
// TODO: store report
// export const selectBatchReport = (state) => (selectBatchSlice(state).report));
export const selectBatchStepIdx = (state, step) => {
  // TODO: temporary fix
  if (selectBatchType(state) !== MAXI_BATCH) return batchStatuses[selectBatchType(state)].indexOf(selectBatchStatus(state));
  return selectBatchData(state).findIndex(idFindFn(step || selectBatchStatus(state)));
};
export const selectBatchSubstatus = (state, step) => {
  // TODO: temporary fix
  if (![MAXI_BATCH, GIGA_BATCH].includes(selectBatchType(state))) return null;
  const status = step || selectBatchStatus(state);
  const stepData = selectBatchData(state).find(idFindFn(status));
  return stepData && stepData.substatus;
};
export const selectBatchSubstepIdx = (state, step) => {
  const status = step || selectBatchStatus(state);
  const substatus = selectBatchSubstatus(state, status);
  if (substatus) {
    const stepData = selectBatchData(state).find(idFindFn(status));
    const substepData = stepData.substeps.find(idFindFn(substatus));
    return stepData.substeps.indexOf(substepData);
  }
  return null;
};
export const selectBatchStepData = (state, step) => selectBatchData(state)[step] || selectBatchData(state).find(idFindFn(step));
export const selectBatchSubstepData = (state, step, substep) => selectBatchStepData(state, step).substeps.find(idFindFn(substep));
export const selectBatchLineData = (state, step, substep, lineId) => selectBatchSubstepData(state, step, substep).lines.find(idFindFn(lineId));
export const selectConstructs = (state) => selectBatchSlice(state).constructs;
export const selectFetched = (state) => selectBatchSlice(state).status !== STATUS_INIT;
export const selectBatchUpdates = (state) => selectBatchSlice(state).updates;

export const selectConstructCodes = createSelector(
  selectConstructs,
  (constructs) => constructs.map((c) => c.construct_code),
);
export const selectNewDigestConstructs = createSelector(
  selectConstructs,
  (constructs) => constructs.filter((c) => !c.construct_data.backbone.predigested),
);
export const selectProjects = createSelector(
  selectConstructs,
  (constructs) => Array.from(new Set(constructs.map((c) => c.project && c.project.project_name).filter((p) => p))),
);
export const selectBatchStepMeta = createSelector(
  selectBatchData,
  selectBatchStatus,
  (batch_data, status) => batch_data.map((step) => ({
    id: step.id,
    title: step.title,
    is_completed: step.is_completed,
  })).concat({
    id: 'DONE',
    title: 'Report',
    is_completed: status === 'DONE',
  }),
);
export const selectBatchSubstepMeta = createSelector(
  selectBatchData,
  (state, status) => status,
  (batch_data, status) => (status !== 'DONE' ? (batch_data.find(idFindFn(status)).substeps.map((substep) => ({
    id: substep.id,
    title: substep.title,
    is_completed: substep.is_completed,
  }))) : []),
);

export const selectBatctLineMeta = createSelector(
  selectBatchData,
  (state, stepId) => stepId,
  (state, stepId, substepId) => substepId,
  (batchData, stepId, substepId) => deepGet(batchData, [idFindFn(stepId), 'substeps', idFindFn(substepId), 'lines']).map((line) => ({
    id: line.id,
    isCompleted: isLineComplete(stepId, substepId, line, batchData),
    isCurrentStep: isLineCurrent(stepId, substepId, line, batchData),
    isFutureStep: isLineFuture(stepId, substepId, line, batchData),
  })),
);
