import { Button, TextField } from '@acheloisbiosoftware/absui.core';

import CropFreeIcon from '@mui/icons-material/CropFree';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSelectionDialog from './TableSelectionDialog';

class FigureAutomation extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleDataSelection: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    inputData: PropTypes.shape({
      dataSelection: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.array).isRequired,
      }).isRequired,
      infectionParticle: PropTypes.string.isRequired,
      neutralizingParticle: PropTypes.string.isRequired,
      stockConcentration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      highestVolume: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      dilutionFactor: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      wellVolume: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
    csvData: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectionModal: false,
    };
  }

  render() {
    const { csvData, inputData, handleChange, handleDataSelection, handleDelete } = this.props;
    const { selectionModal } = this.state;
    return (
      <TableRow>
        <TableCell>
          {
            inputData.dataSelection.data.length ? (
              <Button
                color='inherit'
                variant='contained'
                onClick={() => this.setState({ selectionModal: true })}
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={() => this.setState({ selectionModal: true })}
                endIcon={<CropFreeIcon />}
              >
                Select
              </Button>
            )
          }
          <TableSelectionDialog
            open={selectionModal}
            onClose={() => this.setState({ selectionModal: false })}
            csvData={csvData}
            handleDataSelection={handleDataSelection}
            selection={inputData.dataSelection}
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.infectionParticle}
            onChange={handleChange}
            name='infectionParticle'
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.neutralizingParticle}
            onChange={handleChange}
            name='neutralizingParticle'
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.stockConcentration}
            type='number'
            onChange={handleChange}
            name='stockConcentration'
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.highestVolume}
            type='number'
            onChange={handleChange}
            name='highestVolume'
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.dilutionFactor}
            type='number'
            onChange={handleChange}
            name='dilutionFactor'
          />
        </TableCell>
        <TableCell>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            value={inputData.wellVolume}
            type='number'
            onChange={handleChange}
            name='wellVolume'
          />
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={handleDelete}><DeleteIcon /></IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default FigureAutomation;
