import { GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import { GigaReport, MaxiReport } from './ReportStep';
import { batchStepperActions, batchStepperSelectors } from 'store/batchStepper';
import { connect, batch as reduxBatch } from 'react-redux';

import BatchSubstep from './BatchSubstep';
import PropTypes from 'prop-types';
import React from 'react';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import StepperButtons from 'views/Batch/StepperButtons';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { idFindFn } from 'utils/helpers';

class BatchStep extends React.Component {
  static propTypes = {
    updateStep: PropTypes.func.isRequired,
    updateSubstep: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    activeSubstep: PropTypes.number,
    batchStepMeta: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
    batchSubstepMeta: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      is_completed: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
    batchType: PropTypes.oneOf([MAXI_BATCH, GIGA_BATCH]).isRequired,
  };

  constructor(props) {
    super(props);
    this.onNextStep = this.onNextStep.bind(this);
  }

  onNextStep() {
    const {
      updateStep,
      updateSubstep,
      batchStepMeta,
      step,
      batchSubstepMeta,
      activeSubstep,
    } = this.props;

    const nextSubstep = batchSubstepMeta[activeSubstep + 1];

    reduxBatch(() => {
      if (nextSubstep) {
        updateSubstep(activeSubstep + 1);
      } else {
        updateStep(batchStepMeta.findIndex(idFindFn(step)) + 1);
      }
    });
  }

  render() {
    const { step, batchSubstepMeta, batchType, activeSubstep, updateSubstep } = this.props;
    if (step === 'DONE') {
      if (batchType === MAXI_BATCH) {
        return (<MaxiReport />);
      }
      return (<GigaReport />);
    }
    return (
      <Stepper nonLinear activeStep={activeSubstep} orientation='vertical'>
        {
          batchSubstepMeta.map((meta, idx) => (
            <Step key={meta.id} completed={meta.is_completed}>
              <StepButton onClick={() => updateSubstep(idx)}>
                <Typography sx={idx === activeSubstep ? { fontWeight: 'fontWeightBold' } : null}>
                  {meta.title}
                </Typography>
              </StepButton>
              <StepContent>
                <BatchSubstep step={step} substep={meta.id} />
                <StepperButtons onNextStep={this.onNextStep} />
              </StepContent>
            </Step>
          ))
        }
      </Stepper>
    );
  }
}

const { selectActiveSubstep } = batchStepperSelectors;
const { updateStep, updateSubstep } = batchStepperActions;
const { selectBatchStepMeta, selectBatchSubstepMeta, selectBatchType } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    batchStepMeta: selectBatchStepMeta(state),
    batchSubstepMeta: selectBatchSubstepMeta(state, ownProps.step),
    activeSubstep: selectActiveSubstep(state),
    batchType: selectBatchType(state),
  }),
  { updateStep, updateSubstep },
)(BatchStep);
