import CircleText from 'components/SeqView/Circular/CircleText';
import PropTypes from 'prop-types';
import React from 'react';
import { TextMeasurer } from 'utils/visualization.utils';

class TickLabel extends React.Component {
  static propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    bp: PropTypes.number.isRequired,
    nextBp: PropTypes.number.isRequired,
    plasmidLength: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    thickness: PropTypes.number.isRequired,
  };

  render() {
    const {
      cx,
      cy,
      r,
      bp,
      nextBp,
      plasmidLength,
      fontFamily,
      fontSize,
      thickness,
    } = this.props;
    const theta = (bp / plasmidLength) * 2 * Math.PI;
    const thetaNext = (nextBp / plasmidLength) * 2 * Math.PI;
    const textStart = theta + (2 * thickness / r);
    const textWidth = TextMeasurer.getWidth(`${bp}`, fontSize, fontFamily) / r;
    if (textStart + (textWidth * 3 / 2) >= thetaNext) return null;
    return (
      <CircleText
        id={`bpLabel${bp}`}
        cx={cx}
        cy={cy}
        r={r}
        thetaRange={[textStart]}
        fontSize={fontSize}
        fontFamily={fontFamily}
      >
        {bp}
      </CircleText>
    );
  }
}

export default TickLabel;
