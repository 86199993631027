import { sequencingActions, sequencingSelectors } from 'store/sequencing';

import Chip from '@mui/material/Chip';
import DataTable from 'components/DataTable';
import DescriptionIcon from '@mui/icons-material/Description';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isPositiveInt } from 'utils/helpers';

class ReadMappingDialog extends React.Component {
  static propTypes = {
    removeFile: PropTypes.func.isRequired,
    removeMappingRow: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    hideMappingDialog: PropTypes.func.isRequired,
    handleMappingInput: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    showMappingDialog: PropTypes.bool,
    readMapping: PropTypes.arrayOf(PropTypes.shape({
      file_name: PropTypes.string.isRequired,
      construct_code: PropTypes.string,
      clone: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
  }

  handleRemoveRow(idx) {
    const { removeFile, removeMappingRow } = this.props;
    removeMappingRow(idx);
    removeFile(idx);
  }

  render() {
    const {
      showMappingDialog,
      onConfirm,
      loading,
      readMapping,
      hideMappingDialog,
      handleMappingInput,
    } = this.props;
    const columns = [
      { key: 'file', title: 'File' },
      {
        key: 'construct_code',
        title: 'Construct Code',
        editable: true,
        inputProps: (row) => ({ error: !row.construct_code }),
      },
      {
        key: 'clone',
        title: 'Clone',
        editable: true,
        inputProps: (row) => ({
          type: 'number',
          error: !row.clone || !isPositiveInt(row.clone),
          InputProps: { inputProps: { min: 1, step: 1 }},
        }),
      },
    ];
    const rows = readMapping.map((file, idx) => ({
      key: `${file.file_name}_${idx}`,
      idx,
      file: (
        <Chip
          icon={<DescriptionIcon />}
          label={file.file_name}
          onDelete={() => this.handleRemoveRow(idx)}
        />
      ),
      construct_code: file.construct_code || '',
      clone: file.clone || '',
    }));
    return (
      <Dialog
        open={showMappingDialog}
        onClose={() => hideMappingDialog()}
        onConfirm={onConfirm}
        loading={loading}
        title='Match reads to constructs/clones'
        confirmButtonText='Submit'
        confirmDisabled={!sequencingSelectors.isValidReadMapping(readMapping)}
        maxWidth='md'
      >
        <DataTable
          columns={columns}
          data={rows}
          onChange={handleMappingInput}
          noHeader
          defaultInputProps={{ variant: 'outlined', size: 'medium' }}
          containerProps={{ sx: { border: 'none' }}}
        />
      </Dialog>
    );
  }
}

const { selectReadMapping, selectShowMappingDialog } = sequencingSelectors;
const { handleMappingInput, hideMappingDialog, removeMappingRow } = sequencingActions;

export default connect(
  (state) => ({
    showMappingDialog: selectShowMappingDialog(state),
    readMapping: selectReadMapping(state),
  }),
  { handleMappingInput, hideMappingDialog, removeMappingRow },
)(ReadMappingDialog);
