import 'components/SeqView/SeqView.styles.css';

import * as d3 from 'd3';

import { assignSpaceLinear, getFeatureColor } from 'components/SeqView/SeqView.utils';
import { getLocationRange, isOverlapping, seqSlice, seqTranslate } from 'utils/sequence.utils';

import AnnotationBlock from './AnnotationBlock';
import AnnotationStem from './AnnotationStem';
import AnnotationText from './AnnotationText';
import { FONT } from 'constants/styles.constants';
import PropTypes from 'prop-types';
import React from 'react';
import Translation from './Translation';
import { featureTypes } from 'components/SeqView/SeqView.constants';

const DEFAULT_PROPS = {
  features: [],
  bpRange: [0, 0],
  canvasRange: [0, 0],
  y: 0,
  blockHeight: 24,
  blockSpacing: 8,
  fontFamily: FONT,
  fontSize: 16,
  textPadding: 4,
  stackUp: false,
  showCodons: false,
  sequence: '',
  codonFontSize: 10,
  bpToCanvas: null,
  onClick: null,
  onDoubleClick: null,
  onClickCodon: null,
};

function parseFeatures(props) {
  const {
    sequence,
    features,
    bpRange,
    canvasRange,
    fontSize,
    fontFamily,
    textPadding,
    showCodons,
    bpToCanvas,
  } = { ...DEFAULT_PROPS, ...props };
  const x = bpToCanvas || d3.scaleLinear().domain(bpRange).range(canvasRange);

  // Filter for features in bpRange
  let parsedFeatures = features.filter((featLoc) => (
    featLoc.location_data.type === 'singular' ? (
      isOverlapping(bpRange, [featLoc.location_data.location.start, featLoc.location_data.location.end])
    ) : featLoc.location_data.type === 'compound' ? (
      featLoc.location_data.location.map((loc) => isOverlapping(bpRange, [loc.start, loc.end])).find((e) => e)
    ) : false
  ));

  // Parse feature locations for relevant data
  parsedFeatures = parsedFeatures.map((featLoc) => {
    const { feature, location_data, location_id } = featLoc;
    const { strand } = location_data;
    const [featStart, featEnd] = getLocationRange(location_data);
    const overlappingLoc = (
      location_data.type === 'singular' ? (
        location_data.location
      ) : location_data.type === 'compound' ? (
        location_data.location.find((loc) => isOverlapping(bpRange, [loc.start, loc.end]))
      ) : null
    );
    const featDisplayRange = [Math.max(overlappingLoc.start, bpRange[0]), Math.min(overlappingLoc.end, bpRange[1])];

    let codons;
    if (showCodons && feature.type === 'CDS') {
      const featSeq = seqSlice(sequence, [featStart, featEnd]);
      const featCodons = seqTranslate(featSeq, strand, 'short');
      const codonRange = strand === -1 ? (
        featDisplayRange.map((bp) => Math.round((featEnd - bp + (bp > featEnd ? sequence.length : 0)) / 3)).reverse()
      ) : (
        featDisplayRange.map((bp) => Math.round((bp - featStart + (featStart > bp ? sequence.length : 0)) / 3))
      );
      const codonLabels = featCodons.slice(...codonRange);
      const bases = sequence.slice(...featDisplayRange);
      const readingFrame = strand === -1 ? (
        featStart > featEnd && featDisplayRange[0] >= featStart ? (
          ((featEnd % 3) + (sequence.length % 3)) % 3
        ) : featEnd % 3
      ) : (
        featStart > featEnd && featDisplayRange[0] < featStart ? (
          ((featStart % 3) - (sequence.length % 3) + 3) % 3
        ) : featStart % 3
      );
      const codonMiddle = bases.split('').map((_, idx) => idx + featDisplayRange[0])
        .filter((bp) => (bp - readingFrame + 3) % 3 === 1);
      if (strand === -1) codonMiddle.reverse();
      codons = codonLabels.map((label, idx) => {
        const bpMiddle = codonMiddle[idx];
        const bpStart = bpMiddle - 1 + (bpMiddle - 1 < 0 ? sequence.length : 0);
        const bpEnd = bpMiddle + 2 - (bpMiddle + 2 >= sequence.length ? sequence.length : 0);
        return {
          label,
          codonNum: idx + codonRange[0],
          bpRange: [bpStart, bpEnd],
          canvasMiddle: x(bpMiddle + 0.5),
        };
      });
    }

    return {
      location_id,
      annotationId: `featureAnnotation_${location_id}`,
      feature_name: feature.feature_name,
      feature_data: feature.feature_data,
      type: feature.type,
      strand,
      color: getFeatureColor(featLoc.feature_id, feature.type),
      canvasStart: x(featDisplayRange[0]),
      canvasEnd: x(featDisplayRange[1]),
      blockRow: null,
      textRow: null,
      codons,
    };
  });

  // Assign block rows and text rows
  parsedFeatures = assignSpaceLinear(parsedFeatures, {
    blockStartKey: 'canvasStart',
    blockEndKey: 'canvasEnd',
    textKey: 'feature_name',
    blockRowKey: 'blockRow',
    textRowKey: 'textRow',
    getNumRows: showCodons ? (feat) => (feat.type === 'CDS' ? 2 : 1) : () => 1,
    fontSize,
    fontFamily,
    textPadding,
  });

  return parsedFeatures;
}

function _getFeaturesHeight(parsedFeatures, blockHeight, blockSpacing) {
  if (!parsedFeatures.length) return 0;
  const maxRow = Math.max(...parsedFeatures.map((feat) => Math.max(feat.blockRow, feat.textRow))) + 1;
  return maxRow * (blockHeight + blockSpacing);
}

function getFeaturesHeight(props) {
  const completeProps = { ...DEFAULT_PROPS, ...props };
  const parsedFeatures = parseFeatures(completeProps);
  const { blockHeight, blockSpacing } = completeProps;
  return _getFeaturesHeight(parsedFeatures, blockHeight, blockSpacing);
}

class Features extends React.Component {
  static propTypes = {
    // Because of how props are passed around and parsed, eslint cannot detect some are in use
    /* eslint-disable-next-line react/no-unused-prop-types */
    features: PropTypes.arrayOf(PropTypes.shape({
      feature_id: PropTypes.number.isRequired,
      location_id: PropTypes.number.isRequired,
      feature: PropTypes.shape({
        feature_name: PropTypes.string.isRequired,
        feature_data: PropTypes.object.isRequired,
        type: PropTypes.oneOf(featureTypes),
      }).isRequired,
      location_data: PropTypes.shape({
        type: PropTypes.oneOf(['singular', 'compound']),
        strand: PropTypes.oneOf([1, -1]).isRequired,
        location: PropTypes.oneOfType([
          PropTypes.shape({
            start: PropTypes.number.isRequired,
            end: PropTypes.number.isRequired,
          }),
          PropTypes.arrayOf(PropTypes.shape({
            start: PropTypes.number.isRequired,
            end: PropTypes.number.isRequired,
          })),
        ]).isRequired,
      }).isRequired,
    })).isRequired,
    /* eslint-disable-next-line react/no-unused-prop-types */
    bpRange: PropTypes.arrayOf(PropTypes.number),
    /* eslint-disable-next-line react/no-unused-prop-types */
    canvasRange: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.number,
    blockHeight: PropTypes.number,
    blockSpacing: PropTypes.number,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    textPadding: PropTypes.number,
    stackUp: PropTypes.bool,
    showCodons: PropTypes.bool,
    /* eslint-disable-next-line react/no-unused-prop-types */
    sequence: PropTypes.string, // Only necessary if showCodons is true
    codonFontSize: PropTypes.number,
    /* eslint-disable-next-line react/no-unused-prop-types */
    bpToCanvas: PropTypes.func, // Maps bp location to canvas x
    onClick: PropTypes.func, // Takes in location_id of feature clicked
    onDoubleClick: PropTypes.func, // Takes in location_id of feature clicked
    onClickCodon: PropTypes.func, // Takes in codon label, codon base range, and CDS's location_id for codon clicked
  };

  static defaultProps = DEFAULT_PROPS;

  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(feature, trigger) {
    const { annotationId } = feature;
    const isHovering = trigger === 'enter';
    d3.selectAll(`.${annotationId}_stem`).classed('hoverStem', isHovering);
    d3.selectAll(`.${annotationId}_block`).classed('hoverBlock', isHovering);
    d3.selectAll(`.${annotationId}_text`).classed('hoverText', isHovering);
    d3.selectAll(`.${annotationId}_textContainer`).classed('hoverTextContainer', isHovering);
  }

  render() {
    const {
      y,
      blockHeight,
      blockSpacing,
      fontFamily,
      fontSize,
      textPadding,
      stackUp,
      showCodons,
      codonFontSize,
      onClick,
      onDoubleClick,
      onClickCodon,
    } = this.props;
    const parsedFeatures = parseFeatures(this.props);
    const height = _getFeaturesHeight(parsedFeatures, blockHeight, blockSpacing);

    return (
      <g transform={`translate(0,${y})`}>
        {
          parsedFeatures.map((feature) => (
            <AnnotationStem
              key={`${feature.annotationId}_stemKey`}
              feature={feature}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              stackUpHeight={stackUp ? height : null}
            />
          ))
        }
        {
          parsedFeatures.map((feature) => (
            <AnnotationBlock
              key={`${feature.annotationId}_blockKey`}
              feature={feature}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              stackUpHeight={stackUp ? height : null}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
        {
          parsedFeatures.map((feature) => (
            <AnnotationText
              key={`${feature.annotationId}_textKey`}
              feature={feature}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              fontFamily={fontFamily}
              fontSize={fontSize}
              textPadding={textPadding}
              stackUpHeight={stackUp ? height : null}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onHover={this.handleHover}
            />
          ))
        }
        {
          showCodons ? parsedFeatures.map((feature) => (
            <Translation
              key={`${feature.annotationId}_translationKey`}
              feature={feature}
              blockHeight={blockHeight}
              blockSpacing={blockSpacing}
              fontFamily={fontFamily}
              fontSize={codonFontSize}
              stackUpHeight={stackUp ? height : null}
              onClick={onClickCodon}
            />
          )) : null
        }
      </g>
    );
  }
}

export { getFeaturesHeight };
export default Features;
