import * as extraActions from './session.extraActions';
import * as selectors from './session.selectors';

import { extraReducers, reducers } from './session.reducers';

import { SESSION } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './session.initialState';

const slice = createSlice({
  name: SESSION,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const sessionSelectors = { ...selectors };
export const sessionActions = { ...slice.actions, ...extraActions };
export const { reducer: sessionReducer } = slice;
