import * as d3 from 'd3';

import { BLUE_SELECT, FONT } from 'constants/styles.constants';
import Enzymes, { getEnzymesWidth } from 'components/SeqView/Circular/Enzymes';
import Features, { getFeaturesWidth } from 'components/SeqView/Circular/Features';
import { getLocationRange, seqSlice } from 'utils/sequence.utils';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FeatureDialog from 'components/SeqView/FeatureDialog';
import PropTypes from 'prop-types';
import React from 'react';
import ResizableSvg from 'components/SeqView/ResizableSvg';
import Ring from 'components/SeqView/Circular/Ring';
import Title from './Title';
import { featureTypes } from '../SeqView.constants';
import { getAnnotationPathCircular } from 'components/SeqView/SeqView.utils';

const MARGIN = 8;
const ENZYME_FONT_SIZE = 10;
const ENZYME_SPACING = 4;
const ANNOT_HEIGHT = 20;
const ANNOT_SPACING = 10;
const ANNOT_FONT_SIZE = 12;
const RING_WIDTH = 15;
const TITLE_FONT_SIZE = 18;


class MapView extends React.Component {
  static propTypes = {
    seqData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      sequence: PropTypes.string.isRequired,
      enzymes: PropTypes.arrayOf(PropTypes.shape({
        enzyme_name: PropTypes.string.isRequired,
        location: PropTypes.number.isRequired,
      })).isRequired,
      feature_locations: PropTypes.arrayOf(PropTypes.shape({
        location_id: PropTypes.number.isRequired,
        feature_id: PropTypes.number.isRequired,
        feature: PropTypes.shape({
          feature_name: PropTypes.string.isRequired,
          feature_data: PropTypes.object.isRequired,
          type: PropTypes.oneOf(featureTypes),
        }).isRequired,
        location_data: PropTypes.shape({
          type: PropTypes.oneOf(['singular', 'compound']),
          strand: PropTypes.oneOf([1, -1]).isRequired,
          location: PropTypes.oneOfType([
            PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            }),
            PropTypes.arrayOf(PropTypes.shape({
              start: PropTypes.number.isRequired,
              end: PropTypes.number.isRequired,
            })),
          ]).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    setSelection: PropTypes.func.isRequired,
    selection: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const name = props.seqData.name.replace(/[^a-zA-Z0-9\-_]/g, '');
    this.containerId = `container_${name}`;
    this.svgId = `svg_${name}`;

    this.state = {
      featureOpened: null,
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.drawSelectionLine = this.drawSelectionLine.bind(this);
    this.drawSelection = this.drawSelection.bind(this);
    this.handleFeatureClick = this.handleFeatureClick.bind(this);
    this.handleFeatureDoubleClick = this.handleFeatureDoubleClick.bind(this);
    this.handleEnzymeClick = this.handleEnzymeClick.bind(this);
    this.shiftListener = this.shiftListener.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  handleDrag() {
    const { seqData, setSelection } = this.props;
    const { sequence } = seqData;
    this.selectionG = this.svg.append('g').attr('id', 'selection');
    const that = this;

    function getBpPos(event, strict = false) {
      const y = event.y - that.cy;
      const x = event.x - that.cx;
      const r = Math.sqrt((x * x) + (y * y));
      let theta = Math.atan2(y, x);
      theta = theta + (Math.PI / 2); // Rotate so 0 is at the vertical
      theta = theta >= 0 ? theta : theta + (2 * Math.PI); // Standardize so theta in [0,2*PI]
      const bpPos = Math.round(theta / (2 * Math.PI) * sequence.length);
      if (strict) {
        return r < that.r_ring + ENZYME_SPACING && r > that.r_ring - RING_WIDTH ? bpPos : null;
      }
      return bpPos;
    }

    function dragged(event) {
      const { selection } = that.props;
      if (!selection) return; // No starting point yet
      const bpEnd = getBpPos(event);
      const selectionRange = [selection[0], bpEnd];
      that.drawSelection(selectionRange);
      setSelection(selectionRange);
    }

    function dragStart(event) {
      if (that.shift) {
        dragged(event);
      } else {
        that.clearSelection();
        const bpPos = getBpPos(event, true);
        if (bpPos !== null) { // BpPos === null if click wasn't on sequence
          const selectionRange = [bpPos, bpPos];
          that.drawSelection(selectionRange);
          setSelection(selectionRange);
        }
      }
    }

    function dragEnd(event) {
      const { selection } = that.props;
      that.selectionInput.node().select();
      const bpEnd = getBpPos(event);
      if (!selection) return; // No starting point yet
      setSelection([selection[0], bpEnd]);
    }

    d3.drag()
      .on('start', dragStart)
      .on('drag', dragged)
      .on('end', dragEnd)(this.svg);
  }

  clearSelection() {
    this.props.setSelection(null);
    this.selectionInput.text('');
    this.selectionG.selectAll('*').remove();
  }

  drawSelectionLine(bpPos, id) {
    const { sequence } = this.props.seqData;
    const thetaUnbound = ((-bpPos / sequence.length) * 2 * Math.PI) + (Math.PI / 2);
    const theta = thetaUnbound >= 0 ? thetaUnbound : thetaUnbound + (2 * Math.PI);
    const cosTheta = Math.cos(theta);
    const sinTheta = Math.sin(theta);
    const r_out = this.r_ring + ENZYME_SPACING;
    const r_in = (this.r_ring - RING_WIDTH) / 2;
    this.selectionG.select(`#${id}`).remove();
    this.selectionG.append('line')
      .attr('x1', this.cx + (r_out * cosTheta))
      .attr('y1', this.cy - (r_out * sinTheta))
      .attr('x2', this.cx + (r_in * cosTheta))
      .attr('y2', this.cy - (r_in * sinTheta))
      .attr('stroke', BLUE_SELECT)
      .attr('stroke-width', 2)
      .attr('id', id);
  }

  drawSelection(selectionRange) {
    const { sequence } = this.props.seqData;
    this.selectionInput.text(seqSlice(sequence, selectionRange));
    this.selectionInput.node().select();

    const r_out = this.r_ring + ENZYME_SPACING;
    const r_in = this.r_ring - RING_WIDTH;
    const r = (r_out + r_in) / 2;
    const height = r_out - r_in;
    const thetaRange = selectionRange.map((bpPos) => (bpPos / sequence.length) * 2 * Math.PI);
    if (thetaRange[0] > thetaRange[1]) thetaRange[1] += 2 * Math.PI;
    const path = getAnnotationPathCircular(this.cx, this.cy, r, thetaRange, height);
    this.selectionG.select('#selectionBox').remove();
    this.selectionG.append('path')
      .attr('id', 'selectionBox')
      .attr('d', path)
      .attr('fill', BLUE_SELECT)
      .attr('fill-opacity', 0.8);
    this.drawSelectionLine(selectionRange[0], 'selectionStart');
    this.drawSelectionLine(selectionRange[1], 'selectionEnd');
  }

  handleFeatureClick(location_id) {
    const { seqData, selection, setSelection } = this.props;
    const featureClicked = seqData.feature_locations.find((f) => f.location_id === location_id);
    if (!featureClicked) return;
    const [start, end] = getLocationRange(featureClicked.location_data);
    let selectionRange;
    if (this.shift && selection) {
      selectionRange = [selection[0], end];
    } else {
      this.clearSelection();
      selectionRange = [start, end];
    }
    this.drawSelection(selectionRange);
    this.selectionInput.node().select();
    setSelection(selectionRange);
  }

  handleFeatureDoubleClick(location_id) {
    const { seqData } = this.props;
    const featureClicked = seqData.feature_locations.find((f) => f.location_id === location_id);
    if (!featureClicked) return;
    this.setState({ featureOpened: featureClicked });
  }

  handleEnzymeClick(enzymeName, location) {
    const { selection, setSelection } = this.props;
    if (this.shift && selection) {
      const selectionRange = [selection[0], location];
      this.drawSelection(selectionRange);
    } else {
      this.clearSelection();
      const selectionRange = [location, location];
      this.drawSelection(selectionRange);
      setSelection(selectionRange);
    }
  }

  shiftListener(event) {
    if (event.keyCode === 16) { // Shift keyCode = 16
      this.shift = event.type === 'keydown';
    }
  }

  componentDidMount() {
    // FYI: componentDidMount will run BEFORE renderContent, but after render
    this.container = d3.select(`#${this.containerId}`);
    this.svg = d3.select(`#${this.svgId}`);
    this.handleDrag();
    document.addEventListener('keydown', this.shiftListener);
    document.addEventListener('keyup', this.shiftListener);

    // Textarea placed behind the canvas, used to "overwrite" copy functionality
    this.selectionInput = this.container.append('textarea')
      .style('position', 'absolute')
      .style('top', 0)
      .style('left', 0)
      .style('z-index', -1);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.shiftListener);
    document.removeEventListener('keyup', this.shiftListener);
  }

  renderContent(width) {
    const { seqData, height, selection } = this.props;
    const cx = width / 2;
    const cy = height / 2;
    this.cx = cx;
    this.cy = cy;
    const r_max = (Math.min(width, height) / 2) - MARGIN;
    const plasmidLength = seqData.sequence.length;

    const enzymesProps = {
      enzymes: seqData.enzymes,
      plasmidLength,
      cx,
      cy,
      r_out: r_max,
      blockHeight: ENZYME_FONT_SIZE,
      blockSpacing: ENZYME_SPACING,
      fontSize: ENZYME_FONT_SIZE,
      onClick: this.handleEnzymeClick,
    };
    const enzymesWidth = getEnzymesWidth(enzymesProps);

    this.r_ring = r_max - enzymesWidth;

    const featuresProps = {
      features: seqData.feature_locations,
      plasmidLength,
      cx,
      cy,
      r_out: r_max - enzymesWidth - RING_WIDTH,
      blockHeight: ANNOT_HEIGHT,
      blockSpacing: ANNOT_SPACING,
      fontFamily: FONT,
      fontSize: ANNOT_FONT_SIZE,
      onClick: this.handleFeatureClick,
      onDoubleClick: this.handleFeatureDoubleClick,
    };
    const featuresWidth = getFeaturesWidth(featuresProps);

    if (selection) {
      this.drawSelection(selection);
    }

    return {
      height,
      style: { minWidth: height },
      render: (
        <>
          <Enzymes {...enzymesProps} />
          <Ring
            cx={cx} cy={cy}
            r={this.r_ring}
            width={RING_WIDTH}
            plasmidLength={plasmidLength}
          />
          <Features {...featuresProps} />
          <Title
            cx={cx} cy={cy}
            seqData={seqData}
            fontSize={TITLE_FONT_SIZE}
            fontFamily={FONT}
            maxLength={2 * (r_max - enzymesWidth - RING_WIDTH - featuresWidth - ANNOT_SPACING)}
          />
        </>
      ),
    };
  }

  render() {
    const { height } = this.props;
    const { featureOpened } = this.state;
    return (
      <Box
        id={this.containerId}
        sx={{ overflow: 'auto', position: 'relative', height: height + 4 }}
      >
        <ClickAwayListener onClickAway={this.clearSelection}>
          <Box>
            <ResizableSvg id={this.svgId}>
              { this.renderContent }
            </ResizableSvg>
            <FeatureDialog feature={featureOpened} onClose={() => this.setState({ featureOpened: null })} />
          </Box>
        </ClickAwayListener>
      </Box>
    );
  }
}

export default MapView;
