import * as extraActions from './material.extraActions';
import * as selectors from './material.selectors';

import { extraReducers, reducers } from './material.reducers';

import { MATERIAL } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './material.initialState';

const slice = createSlice({
  name: MATERIAL,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const materialSelectors = { ...selectors };
export const materialActions = { ...slice.actions, ...extraActions };
export const { reducer: materialReducer } = slice;
