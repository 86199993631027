import * as extraActions from './design.extraActions';

import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statuses.constants';
import { deepObjectModify, mergeLists } from 'utils/helpers';

import { getInitialState } from './design.initialState';
import { projectActions } from 'store/project';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const reduceLoading = (state) => {
  state.status = STATUS_LOADING;
  state.error = null;
};

const reduceError = (state, action) => {
  const { error } = action.payload;
  state.status = STATUS_ERROR;
  state.error = error;
};

const reduceIdle = (state) => {
  state.status = STATUS_IDLE;
};

const reduceSuccess = (state) => {
  state.status = STATUS_SUCCESS;
  state.error = null;
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const autoclone = ({ pending, fulfilled, rejected }) => ({
  [pending]: (state) => {
    reduceLoading(state);
    state.constructs = [];
  },
  [fulfilled]: (state, action) => {
    const { construct } = action.payload;
    reduceSuccess(state);
    state.constructs = [construct];
  },
  [rejected]: reduceError,
});

const clearInsertSuggestions = (actionName) => ({
  [actionName]: (state) => {
    state.suggestionsStatus = STATUS_IDLE;
    state.insertSuggestions = [];
  },
});

const createProjects = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: reduceSuccess,
  [rejected]: reduceError,
});

const fetchInsertSuggestions = ({ pending, fulfilled, rejected }) => ({
  [pending]: (state) => {
    state.suggestionsStatus = STATUS_LOADING;
  },
  [fulfilled]: (state, action) => {
    const { suggestions } = action.payload;
    state.suggestionsStatus = STATUS_IDLE;
    state.insertSuggestions = suggestions;
  },
  [rejected]: (state) => {
    state.suggestionsStatus = STATUS_ERROR;
  },
});

const handleConstructInput = (actionName) => ({
  [actionName]: (state, action) => {
    const { constructCode, keyList, value } = action.payload;
    const construct = state.constructs.find((c) => c.construct_code === constructCode);
    deepObjectModify(construct, keyList, value, true);
    reduceIdle(state, action);
  },
});

const resetDesign = (actionName) => ({
  [actionName]: (state) => ({
    ...state,
    ...getInitialState(),
  }),
});

const updateConstructs = ({ fulfilled }) => ({
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    const newConstructs = mergeLists(state.constructs, constructs, 'construct_code');
    newConstructs.sort((c1, c2) => c1.construct_code.localeCompare(c2.construct_code));
    state.constructs = newConstructs;
    reduceSuccess(state, action);
  },
});

const uploadConstructs = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: (state, action) => {
    const { constructs } = action.payload;
    reduceSuccess(state, action);
    state.constructs = constructs;
  },
  [rejected]: reduceError,
});

const validateSubmission = ({ pending, fulfilled, rejected }) => ({
  [pending]: reduceLoading,
  [fulfilled]: reduceSuccess,
  [rejected]: reduceError,
});

export const reducers = {};
export const extraReducers = {
  ...autoclone(extraActions.autocloneDisplay),
  ...autoclone(extraActions.autocloneMhc),
  ...clearInsertSuggestions(extraActions._clearInsertSuggestions),
  ...createProjects(projectActions.createProjects),
  ...fetchInsertSuggestions(extraActions.fetchInsertSuggestions),
  ...handleConstructInput(extraActions._handleConstructInputAction),
  ...resetDesign(extraActions._resetDesignAction),
  ...updateConstructs(extraActions.updateConstructs),
  ...uploadConstructs(extraActions.uploadConstructs),
  ...validateSubmission(extraActions.validateSubmission),
};
