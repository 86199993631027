import { ACTION_CHECKED, ACTION_INPUT, ACTION_NOTES } from 'constants/batchActions.constants';
import { batchActions, batchSelectors } from 'store/batch';

import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import { ChecklistItem } from 'components/Checklist';
import CommentDialog from './CommentDialog';
import DetailedContent from './DetailedContent';
import IconButton from '@mui/material/IconButton';
import InfoDialog from './InfoDialog';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { timeNow } from 'utils/date.utils';

class SOPLine extends React.Component {
  static propTypes = {
    handleBatchAction: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    lineData: PropTypes.shape({
      content: PropTypes.string.isRequired,
      subcontent: PropTypes.array.isRequired,
      detailed_content: PropTypes.array.isRequired,
      checked: PropTypes.bool.isRequired,
      notes: PropTypes.shape({
        content: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    editMiddleware: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      commentOpen: false,
      infoOpen: false,
    };
    this.handleChecked = this.handleChecked.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChecked(checked) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    editMiddleware(() => handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_CHECKED,
      payload: {
        checked: checked,
        at: timeNow(),
      },
    }));
  }

  handleNotes(content) {
    const { handleBatchAction, step, substep, line } = this.props;
    handleBatchAction({
      step_id: step,
      substep_id: substep,
      line_id: line,
      action: ACTION_NOTES,
      payload: {
        content,
        at: timeNow(),
      },
    });
  }

  handleChange(event, subcontentId) {
    const { handleBatchAction, step, substep, line, editMiddleware } = this.props;
    const { value } = event.currentTarget;
    editMiddleware(() => {
      handleBatchAction({
        step_id: step,
        substep_id: substep,
        line_id: line,
        action: ACTION_INPUT,
        payload: {
          subcontent_id: subcontentId,
          value,
        },
      });
    });
  }

  render() {
    const { lineData, readOnly, appearDisabled, editMiddleware } = this.props;
    const { content, subcontent, detailed_content, checked, notes } = lineData;
    const { commentOpen, infoOpen } = this.state;

    return (
      <ChecklistItem
        checked={checked}
        onClick={(event) => this.handleChecked(event.target.checked)}
        subcontent={subcontent}
        onInput={(event, idx) => this.handleChange(event, idx)}
        disabled={readOnly}
        appearDisabled={appearDisabled}
        inputProps={{ InputProps: { onFocus: () => editMiddleware(null) }}}
        content={(
          <Box sx={{ display: 'flex', width: 1, my: 0.5 }}>
            <Typography
              sx={[{ my: 'auto', mx: 0 }, !readOnly ? { cursor: 'pointer' } : {}]}
            >
              {content}
            </Typography>
            <Box sx={{ m: 'auto', mr: 0, display: 'flex' }}>
              <IconButton
                disabled={readOnly}
                sx={[{ my: 'auto', mx: 0.25 }, appearDisabled ? { color: 'text.disabled' } : {}]}
                size='small'
                onClick={() => this.setState({ commentOpen: true })}
              >
                <AssignmentIcon
                  fontSize='small'
                  sx={notes.content ? {
                    color: appearDisabled ? 'warning.light' : 'warning.main',
                  } : null}
                />
              </IconButton>
              {
                lineData?.detailed_content?.length ? (
                  <IconButton
                    sx={[{ my: 'auto', mx: 0.25 }, appearDisabled ? { color: 'text.disabled' } : {}]}
                    size='small'
                    onClick={() => this.setState({ infoOpen: true })}
                  >
                    <InfoIcon fontSize='small' />
                  </IconButton>
                ) : null
              }
            </Box>
            <CommentDialog
              open={commentOpen}
              notes={notes.content}
              onClose={() => this.setState({ commentOpen: false })}
              handleSave={this.handleNotes}
              readOnly={readOnly}
              appearDisabled={appearDisabled}
              editMiddleware={editMiddleware}
            >
              {content}
            </CommentDialog>
            <InfoDialog
              open={infoOpen}
              onClose={() => this.setState({ infoOpen: false })}
              onDone={() => {
                if (!checked) {
                  this.handleChecked(true);
                }
                this.setState({ infoOpen: false });
              }}
              readOnly={readOnly}
              appearDisabled={appearDisabled}
            >
              <DetailedContent lines={detailed_content} />
            </InfoDialog>
          </Box>
        )}
      />
    );
  }
}

const { handleBatchAction } = batchActions;
const { selectBatchLineData } = batchSelectors;

export default connect(
  (state, ownProps) => ({
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
  }),
  { handleBatchAction },
)(SOPLine);
