import { batchActions, batchSelectors } from 'store/batch';

import DataTable from 'components/DataTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deepObjectModify } from 'utils/helpers';

const columns = [
  { key: 'fragmentNumber', title: '' },
  { key: 'constructCode', title: 'Construct' },
  { key: 'fragment', title: 'PCR Fragment' },
  {
    key: 'concentration',
    title: 'Concentration',
    editable: true,
    adornment: 'ng/µL',
    inputProps: { type: 'number' },
  },
];

class FragmentConcentrationTable extends React.Component {
  static propTypes = {
    handleConstructInput: PropTypes.func.isRequired,
    constructs: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      construct_data: PropTypes.shape({
        pcr_fragments: PropTypes.arrayOf(PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          concentration: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(value, row) {
    const { constructs, handleConstructInput } = this.props;
    const construct = constructs.find((c) => c.construct_code === row.constructCode);
    const newFragments = deepObjectModify(construct.construct_data.pcr_fragments, [row.constructFragmentIdx, 'concentration'], value);
    handleConstructInput(row.constructCode, ['construct_data', 'pcr_fragments'], newFragments);
  }

  render() {
    const { constructs } = this.props;
    const rows = constructs.map((c) => c.construct_data.pcr_fragments.map((f, f_idx) => ({
      ...f,
      construct: c.construct_code,
      constructFragmentIdx: f_idx,
    }))).flat().map((fragment, idx) => ({
      key: `fragment_${idx}`,
      idx,
      fragmentNumber: idx + 1,
      constructCode: fragment.construct,
      fragment: fragment.fragment,
      concentration: fragment.concentration,
      constructFragmentIdx: fragment.constructFragmentIdx,
    }));
    return (
      <DataTable
        columns={columns}
        data={rows}
        onChange={this.onInputChange}
        tableProps={{ size: 'small' }}
        containerProps={{ sx: { maxHeight: 600, overflow: 'auto', m: 'auto' }}}
      />
    );
  }
}

const { handleConstructInput } = batchActions;
const { selectConstructs } = batchSelectors;

export default connect(
  (state) => ({
    constructs: selectConstructs(state),
  }),
  { handleConstructInput },
)(FragmentConcentrationTable);
