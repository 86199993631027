export function pad(n, width = 3, padding = '0') {
  const nStr = String(n);
  return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(padding) + nStr;
}

export function capitalize(str) {
  return str.toLowerCase()
    .split(/\s+/)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
}

export function round(value, step = 1.0, roundType = 'round') {
  const inv = 1.0 / step;
  return Math[roundType](value * inv) / inv;
}
