import * as BackboneService from 'services/BackboneService';

import { BACKBONE } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const fetchBackboneData = createAsyncThunk(
  `${BACKBONE}/fetch`,
  async (_, { rejectWithValue }) => {
    const res = await BackboneService.getBackboneData();
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ backboneData: response.backbone_data }),
    );
  },
);

export const fetchMHCAlleles = createAsyncThunk(
  `${BACKBONE}/fetch/mhc_alleles`,
  async (_, { rejectWithValue }) => {
    const res = await BackboneService.getMHCAlleles();
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ mhcAlleles: response.mhc_alleles }),
    );
  },
);
