import * as BatchService from 'services/BatchService';

import { GIGA_BATCH, MAXI_BATCH } from 'constants/batch.constants';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectBatchData,
  selectBatchId,
  selectBatchType,
  selectBatchUpdates,
  selectConstructs,
} from './batch.selectors';

import { BATCH } from 'constants/store.constants';
import { debounce } from 'lodash';
import { responseToPayload } from 'utils/store.utils';

const autosave = debounce((dispatch, getState) => {
  const state = getState();
  // TODO: temporary fix
  if (selectBatchType(state) === MAXI_BATCH || selectBatchType(state) === GIGA_BATCH) {
    dispatch(updateBatch({ // eslint-disable-line no-use-before-define
      batch_id: selectBatchId(state),
      updates: selectBatchUpdates(state),
    }));
  } else {
    dispatch(updateBatch({ // eslint-disable-line no-use-before-define
      batch_id: selectBatchId(state),
      batch_data: selectBatchData(state),
      construct_data: selectConstructs(state),
    }));
  }
}, 10000);

export const createBatch = createAsyncThunk(
  `${BATCH}/create`,
  async (data, { rejectWithValue }) => {
    const res = await BatchService.createBatch(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const deleteBatches = createAsyncThunk(
  `${BATCH}/delete`,
  async (batchIds, { rejectWithValue }) => {
    const res = await BatchService.deleteBatches(batchIds);
    return responseToPayload(
      res,
      rejectWithValue,
      (response) => ({ batch_ids: response.batches }),
    );
  },
);

export const editGelImage = createAsyncThunk(
  `${BATCH}/edit_gel_image`,
  async (data, { getState, rejectWithValue }) => {
    autosave.cancel();
    const state = getState();
    // TODO: temporary fix
    let reqData = { ...data };
    if (selectBatchType(state) !== MAXI_BATCH) {
      const newBatchData = selectBatchData(state);
      const newConstructData = selectConstructs(state);
      reqData = {
        ...data,
        batch_data: newBatchData,
        construct_data: newConstructData,
      };
    }
    const res = await BatchService.editGelImage(reqData);
    return responseToPayload(
      res,
      rejectWithValue,
      null,
      () => ({ updates: data.updates || []}),
    );
  },
);

export const fetchBatch = createAsyncThunk(
  `${BATCH}/fetch`,
  async (batchId, { rejectWithValue }) => {
    const res = await BatchService.getBatch(batchId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const lockReport = createAsyncThunk(
  `${BATCH}/report/lock`,
  async (batchId, { rejectWithValue }) => {
    const res = await BatchService.lockReport(batchId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const addSignees = createAsyncThunk(
  `${BATCH}/report/add_signee`,
  async ({ batchId, signees }, { rejectWithValue }) => {
    const res = await BatchService.addSignees(batchId, signees);
    return responseToPayload(res, rejectWithValue);
  },
);

export const updateSignees = createAsyncThunk(
  `${BATCH}/report/update_signee`,
  async ({ batchId, signees }, { rejectWithValue }) => {
    const res = await BatchService.updateSignees(batchId, signees);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchSignees = createAsyncThunk(
  `${BATCH}/report/fetch_signees`,
  async (batchId, { rejectWithValue }) => {
    const res = await BatchService.getReportSignees(batchId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const commentReport = createAsyncThunk(
  `${BATCH}/report/comment`,
  async ({ batchId, comment }, { rejectWithValue }) => {
    const res = await BatchService.commentReport(batchId, comment);
    return responseToPayload(res, rejectWithValue);
  },
);

export const signReport = createAsyncThunk(
  `${BATCH}/report/sign`,
  async ({ batchId, comment }, { rejectWithValue }) => {
    const res = await BatchService.signReport(batchId, comment);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchBatchReportSigns = createAsyncThunk(
  `${BATCH}/report/fetch_signs`,
  async (batchId, { rejectWithValue }) => {
    const commentsRes = await BatchService.getReportComments(batchId);
    return responseToPayload(
      commentsRes,
      rejectWithValue,
      async (commentsResponse) => {
        const signsRes = await BatchService.getReportSignatures(batchId);
        return responseToPayload(
          signsRes,
          rejectWithValue,
          (signsResponse) => ({ ...commentsResponse, ...signsResponse }),
        );
      },
    );
  },
);

// TODO: get batch report
// export const fetchBatchReport = createAsyncThunk(
//   `${BATCH}/fetch_report`,
//   async (batchId, { rejectWithValue }) => {
//     const res = await BatchService.getBatchReport(batchId);
//     return responseToPayload(res, rejectWithValue);
//   }
// );

export const removeGelImage = createAsyncThunk(
  `${BATCH}/remove_gel_image`,
  async (data, { getState, rejectWithValue }) => {
    autosave.cancel();
    const state = getState();
    // TODO: temporary fix
    let reqData = { ...data };
    if (selectBatchType(state) !== MAXI_BATCH) {
      const newBatchData = selectBatchData(state);
      const newConstructData = selectConstructs(state);
      reqData = {
        ...data,
        batch_data: newBatchData,
        construct_data: newConstructData,
      };
    }
    const res = await BatchService.removeGelImage(reqData);
    return responseToPayload(
      res,
      rejectWithValue,
      null,
      () => ({ updates: data.updates || []}),
    );
  },
);

export const updateBatch = createAsyncThunk(
  `${BATCH}/update`,
  async (data, { rejectWithValue }) => {
    autosave.cancel();
    const res = await BatchService.updateBatch(data);
    return responseToPayload(
      res,
      rejectWithValue,
      null,
      () => ({ updates: data.updates || []}),
    );
  },
);

export const uploadGelImages = createAsyncThunk(
  `${BATCH}/upload_gel_images`,
  async (data, { getState, rejectWithValue }) => {
    autosave.cancel();
    const state = getState();
    // TODO: temporary fix
    if (selectBatchType(state) !== MAXI_BATCH) {
      const newBatchData = selectBatchData(state);
      const newConstructData = selectConstructs(state);
      data.body = {
        ...data.body,
        batch_data: newBatchData,
        construct_data: newConstructData,
      };
    }
    const res = await BatchService.uploadGelImages(data);
    return responseToPayload(
      res,
      rejectWithValue,
      null,
      () => ({ updates: data.updates || []}),
    );
  },
);

export const _handleBatchInputAction = createAction(`${BATCH}/handleBatchInput`);
export const handleBatchInput = (keyList, value) => (dispatch, getState) => {
  dispatch(_handleBatchInputAction({ keyList, value }));
  autosave(dispatch, getState);
};

export const _handleBatchActionAction = createAction(`${BATCH}/handleBatchAction`);
export const handleBatchAction = (actionData) => (dispatch, getState) => {
  dispatch(_handleBatchActionAction(actionData));
  autosave(dispatch, getState);
};

export const _handleConstructInputAction = createAction(`${BATCH}/handleConstructInput`);
export const handleConstructInput = (constructCode, keyList, value) => (dispatch, getState) => {
  dispatch(_handleConstructInputAction({ constructCode, keyList, value }));
  autosave(dispatch, getState);
};

export const _resetBatchAction = createAction(`${BATCH}/resetBatch`);
export const resetBatch = () => (dispatch) => {
  autosave.cancel();
  dispatch(_resetBatchAction());
};
