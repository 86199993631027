import * as selectors from './navigation.selectors';

import { extraReducers, reducers } from './navigation.reducers';

import { NAVIGATION } from 'constants/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './navigation.initialState';

const slice = createSlice({
  name: NAVIGATION,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export const navigationSelectors = { ...selectors };
export const navigationActions = { ...slice.actions };
export const { reducer: navigationReducer } = slice;
