import React from 'react';
import { ReactComponent as SignatureWarning } from './SignatureWarning.svg';
import SvgIcon from '@mui/material/SvgIcon';

class SignatureWarningIcon extends React.Component {
  render() {
    return (
      <SvgIcon {...this.props} inheritViewBox component={SignatureWarning} />
    );
  }
}

export default SignatureWarningIcon;
