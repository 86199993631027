import client from 'services';

export async function generatePNAFigure(data) {
  // data = {
  //   conditionData: [{
  //     dataSelection: {
  //       data: 2D array of numbers,
  //     },
  //     infectionParticle: string,
  //     neutralizingParticle: string,
  //     stockConcentration: number string,
  //     highestVolume: number string,
  //     dilutionFactor: number string,
  //     wellVolume: number string,
  //   }],
  //   cellsOnlyData: {
  //     data: 2D array of numbers,
  //   },
  // };
  return await client({ method: 'post', url: '/figures/generate_pna', data });
}
