import client from 'services';

export async function uploadConstructs(data) {
  // data = {
  //   files: array of files,
  //   body: {
  //     construct_info: [{ insert_name: string, construct_code: string }],
  //   },
  // };
  const formData = new FormData();
  const { files, body } = data;
  for (let i = 0; i < files.length; i++) {
    formData.append(files[i].name, files[i]);
  }
  formData.append('body', JSON.stringify(body));
  return await client({ method: 'post', url: '/construct/upload', data: formData });
}

export async function deleteConstructs(constructCodes) {
  const data = { constructs: constructCodes };
  return await client({ method: 'post', url: '/construct/delete', data });
}

export async function updateConstructs(constructs) {
  const data = { construct_data: constructs };
  return await client({ method: 'post', url: '/constructs/update', data });
}

export async function uploadPrimers(primerData) {
  // primerData = [{
  //   construct_code: string,
  //   primer_name: string,
  //   primer_seq: string of ACGT,
  // }];
  const data = { primer_data: primerData };
  return await client({ method: 'post', url: '/construct/upload_primers', data });
}

export async function getConstructsDownload(constructCodes) {
  const res = await client({
    method: 'get',
    url: '/constructs/download',
    params: { construct_codes: constructCodes },
    timeout: 20000,
  });
  return res.error ? res : {
    name: res.file_name,
    file: `data:${res.content_type};base64,${res.file}`,
  };
}

export async function getConstruct(constructCode) {
  return await client({ method: 'get', url: `/construct/${constructCode}` });
}

export async function getConstructs() {
  return await client({ method: 'get', url: '/constructs' });
}
