import { batchActions, batchSelectors } from 'store/batch';
import { reorderIndexList, reorderList } from 'utils/helpers';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Box from '@mui/material/Box';
import { ChecklistItem } from 'components/Checklist';
import DataTable from 'components/DataTable';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { connect } from 'react-redux';

class ReactionList extends React.Component {
  static propTypes = {
    handleBatchInput: PropTypes.func.isRequired,
    reactionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      autofocusLast: false,
      copySuccess: false,
    };

    this.onReorder = this.onReorder.bind(this);
    this.reactionChange = this.reactionChange.bind(this);
    this.addReaction = this.addReaction.bind(this);
    this.removeReaction = this.removeReaction.bind(this);
    this.checkReaction = this.checkReaction.bind(this);
    this.copyReactions = this.copyReactions.bind(this);
  }

  onReorder(row, srcIdx, destIdx) {
    const { reactionNames, handleBatchInput } = this.props;
    const { checked } = this.state;
    const newReactionNames = reorderList(reactionNames, srcIdx, destIdx);
    const newChecked = reorderIndexList(checked, srcIdx, destIdx);
    this.setState({ checked: newChecked });
    handleBatchInput(['SEQUENCING', 'reaction_names'], newReactionNames);
  }

  reactionChange(value, row) {
    const { reactionNames, handleBatchInput } = this.props;
    const newReactionNames = [...reactionNames];
    newReactionNames[row.idx] = value;
    handleBatchInput(['SEQUENCING', 'reaction_names'], newReactionNames);
  }

  addReaction() {
    const { reactionNames, handleBatchInput } = this.props;
    const newReactionNames = [...reactionNames, ''];
    this.setState({ autofocusLast: true });
    handleBatchInput(['SEQUENCING', 'reaction_names'], newReactionNames);
  }

  removeReaction() {
    const { reactionNames, handleBatchInput } = this.props;
    const { checked } = this.state;
    const newReactionNames = reactionNames.filter((e, idx) => !checked.includes(idx));

    if (newReactionNames.length === reactionNames.length) {
      // Means none were checked, so just remove one from the end
      newReactionNames.pop();
    }

    if (!newReactionNames.length) {
      newReactionNames.push('');
    }

    this.setState({
      checked: [],
      autofocusLast: false,
    });
    handleBatchInput(['SEQUENCING', 'reaction_names'], newReactionNames);
  }

  checkReaction(idx) {
    const { checked } = this.state;
    const search = checked.indexOf(idx);
    const newChecked = [...checked];
    if (search !== -1) {
      newChecked.splice(search, 1);
    } else {
      newChecked.push(idx);
    }
    this.setState({ checked: newChecked });
  }

  copyReactions() {
    const { reactionNames } = this.props;
    const underscoreLed = reactionNames.map((reactionName) => `_${reactionName}`);
    navigator.clipboard.writeText(underscoreLed.join('\n'));
    this.setState({ copySuccess: true });
    this.successTimer = setTimeout(() => this.setState({ copySuccess: false }), 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.successTimer);
  }

  render() {
    const { reactionNames } = this.props;
    const { checked, autofocusLast, copySuccess } = this.state;

    const columns = [
      { key: 'reactionNumber', title: '' },
      {
        key: 'reactionName',
        title: '',
        editable: true,
        inputProps: (row) => ({
          autoFocus: autofocusLast && row.idx + 1 === reactionNames.length,
        }),
      },
    ];

    const rows = reactionNames.map((reactionName, idx) => ({
      key: `reaction${idx}`,
      idx,
      reactionName,
      reactionNumber: (
        <ChecklistItem
          checked={checked.includes(idx)}
          onClick={() => this.checkReaction(idx)}
          dense
          content={idx + 1}
        />
      ),
    }));

    const sxButton = { p: 1 };

    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ m: 'auto' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant='h5'
              sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}
            >
              Reaction List
            </Typography>
            <Box sx={{ ml: 'auto', mr: -1 }}>
              <Zoom in={copySuccess}>
                {
                  copySuccess ? (
                    <IconButton sx={sxButton} onClick={this.copyReactions}>
                      <Tooltip title='Copy for Elim' arrow>
                        <AssignmentTurnedInIcon sx={{ color: 'success.main' }} />
                      </Tooltip>
                    </IconButton>
                  ) : <Box sx={{ position: 'absolute' }} />
                }
              </Zoom>
              <Zoom in={!copySuccess}>
                {
                  !copySuccess ? (
                    <IconButton sx={sxButton} onClick={this.copyReactions}>
                      <Tooltip title='Copy for Elim' arrow>
                        <FileCopyIcon />
                      </Tooltip>
                    </IconButton>
                  ) : <Box sx={{ position: 'absolute' }} />
                }
              </Zoom>
              <IconButton sx={sxButton} onClick={this.addReaction}>
                <Tooltip title='Add reaction' arrow>
                  <AddCircleIcon />
                </Tooltip>
              </IconButton>
              <IconButton sx={sxButton} onClick={this.removeReaction}>
                <Tooltip title='Remove reaction(s)' arrow>
                  <RemoveCircleIcon />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
          <DataTable
            columns={columns}
            data={rows}
            onChange={this.reactionChange}
            onReorder={this.onReorder}
            tableProps={{ size: 'small' }}
            containerProps={{ sx: { maxHeight: 500, overflow: 'auto' }}}
            noHeader
          />
        </Box>
      </Box>
    );
  }
}

const { handleBatchInput } = batchActions;
const { selectBatchStepData } = batchSelectors;

export default connect(
  (state) => ({
    reactionNames: selectBatchStepData(state, 'SEQUENCING').reaction_names,
  }),
  { handleBatchInput },
)(ReactionList);
