import { batchActions } from 'store/batch';
import { constructActions } from 'store/construct';
import { designActions } from 'store/design';
import { materialActions } from 'store/material';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const enqueueFromRequest = (message, options) => (state, action) => {
  const { requestId } = action.meta;
  return [...state, {
    key: requestId,
    message,
    options,
  }];
};

const enqueueFromRequestError = ({ rejected }) => ({
  [rejected]: (state, action) => {
    const { payload, meta } = action;
    const { requestId } = meta;
    const { error } = payload;
    return [...state, {
      key: requestId,
      message: error,
      options: { variant: 'error' },
    }];
  },
});

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const enqueueSnackbar = (state, action) => {
  const { message, key, options } = action.payload;
  return [...state, { key, message, options }];
};

const removeSnackbar = (state, action) => {
  const key = action.payload;
  return state.filter((notification) => notification.key !== key);
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const addSignees = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Signees added!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to add signees.', { variant: 'error' }),
});

const commentReport = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Comment added!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to add comment.', { variant: 'error' }),
});

const deleteBatches = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Batches deleted!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to delete batches.', { variant: 'error' }),
});

const deleteConstructs = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Constructs deleted!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to delete constructs.', { variant: 'error' }),
});

const editGelImage = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Gel image saved!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to save gel image.', { variant: 'error' }),
});

const lockReport = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Report locked!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to lock report.', { variant: 'error' }),
});

const removeGelImage = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Gel image removed!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to remove gel image.', { variant: 'error' }),
});

const savedChanges = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Saved changes!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to save changes.', { variant: 'error' }),
});

const signReport = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Report signed!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to sign report.', { variant: 'error' }),
});

const updateSignees = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Signees updated!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to update signees.', { variant: 'error' }),
});

const uploadGelImages = ({ fulfilled, rejected }) => ({
  [fulfilled]: enqueueFromRequest('Gel image uploaded!', { variant: 'success' }),
  [rejected]: enqueueFromRequest('Failed to upload gel image(s).', { variant: 'error' }),
});

export const reducers = { enqueueSnackbar, removeSnackbar };
export const extraReducers = {
  ...addSignees(batchActions.addSignees),
  ...commentReport(batchActions.commentReport),
  ...deleteBatches(batchActions.deleteBatches),
  ...deleteConstructs(constructActions.deleteConstructs),
  ...editGelImage(batchActions.editGelImage),
  ...enqueueFromRequestError(designActions.autocloneDisplay),
  ...enqueueFromRequestError(designActions.autocloneMhc),
  ...enqueueFromRequestError(batchActions.createBatch),
  ...enqueueFromRequestError(materialActions.createBufferBatch),
  ...enqueueFromRequestError(materialActions.createChemicalLot),
  ...enqueueFromRequestError(materialActions.createMaterialLot),
  ...lockReport(batchActions.lockReport),
  ...removeGelImage(batchActions.removeGelImage),
  ...signReport(batchActions.signReport),
  ...savedChanges(batchActions.updateBatch),
  ...savedChanges(constructActions.updateConstruct),
  ...savedChanges(designActions.updateConstructs),
  ...updateSignees(batchActions.updateSignees),
  ...uploadGelImages(batchActions.uploadGelImages),
};
