import { Button, Dialog } from '@acheloisbiosoftware/absui.core';
import { CLONING_BATCH, GIGA_BATCH, MAXI_BATCH, cloningStatuses } from 'constants/batch.constants';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';

import BatchType from 'components/BatchType';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PropTypes from 'prop-types';
import StatusPill from 'components/StatusPill';
import { batchActions } from 'store/batch';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function BatchButton(props) {
  const { constructs, sx } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(cloningStatuses[0]);

  const createBatch = async (type, status) => {
    if (type === CLONING_BATCH && !status && !dialogOpen) {
      let promptUser = false;
      constructs.forEach((construct) => {
        if (construct.status !== cloningStatuses[0]) promptUser = true;
      });
      if (promptUser) {
        setDialogOpen(true);
        setSelectedStatus(constructs[0].status);
        return;
      }
    }

    if (dialogOpen) {
      setDialogOpen(false);
    }

    const res = await dispatch(batchActions.createBatch({
      constructs: constructs.map((c) => c.construct_code),
      type,
      status,
    }));

    try {
      const resData = unwrapResult(res);
      navigate(`/batch/${resData.batch.batch_id}`);
    } catch (e) {}
  };

  return (
    <>
      <PopupState variant='popover'>
        {(popupState) => (
          <>
            <Button
              startIcon={<MergeTypeIcon />}
              variant='contained'
              color='inherit'
              {...bindTrigger(popupState)}
              sx={sx}
            >
              New Batch
            </Button>
            <Menu
              {...bindMenu(popupState)}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
            >
              {
                [CLONING_BATCH, MAXI_BATCH, GIGA_BATCH].map((type) => (
                  <MenuItem
                    key={type}
                    onClick={(e) => {
                      popupState.close(e);
                      createBatch(type);
                    }}
                  >
                    <BatchType type={type} />
                  </MenuItem>
                ))
              }
            </Menu>
          </>
        )}
      </PopupState>

      {/* This warning dialog is only opened for cloning batches */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => createBatch(CLONING_BATCH, selectedStatus)}
        title='Status confirmation'
        disableCloseOnConfirm
      >
        <DialogContentText>Which status should be used for the new batch?</DialogContentText>
        <Box sx={{ width: 1, display: 'flex', mt: 1 }}>
          <StatusPill
            status={selectedStatus}
            onSelect={(status) => setSelectedStatus(status)}
            sx={{ m: 'auto' }}
            type={CLONING_BATCH}
          />
        </Box>
      </Dialog>
    </>
  );
}

BatchButton.propTypes = {
  constructs: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string.isRequired,
    construct_code: PropTypes.string.isRequired,
  })).isRequired,
  sx: sxPropType,
};

export default BatchButton;
