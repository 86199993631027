import { SEQUENCING } from 'constants/store.constants';
import { STATUS_LOADING } from 'constants/statuses.constants';
import { isPositiveInt } from 'utils/helpers';

const selectSequencingSlice = (state) => state[SEQUENCING];
export const selectSequencingStatus = (state) => selectSequencingSlice(state).status;
export const selectAlignments = (state) => selectSequencingSlice(state).alignments;
export const selectReadMapping = (state) => selectSequencingSlice(state).readMapping;
export const selectShowMappingDialog = (state) => selectSequencingSlice(state).showMappingDialog;
export const selectUploadWarnings = (state) => selectSequencingSlice(state).uploadWarnings;
export const selectSequencingLoading = (state) => selectSequencingStatus(state) === STATUS_LOADING;

export const isValidReadMapping = (readMapping) => readMapping.every((file) => file.construct_code && file.clone && isPositiveInt(file.clone));
