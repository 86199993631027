import * as CommonService from 'services/CommonService';

import { batchActions, batchSelectors } from 'store/batch';

import Box from '@mui/material/Box';
import ImageGallery from 'components/ImageGallery';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { sessionSelectors } from 'store/session';
import { unwrapResult } from '@reduxjs/toolkit';

class GelImages extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    updates: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    uploadGelImages: PropTypes.func.isRequired,
    removeGelImage: PropTypes.func.isRequired,
    editGelImage: PropTypes.func.isRequired,
    lineData: PropTypes.shape({
      data: PropTypes.array.isRequired,
      title: PropTypes.string,
    }).isRequired,
    readOnly: PropTypes.bool,
    appearDisabled: PropTypes.bool,
    editMiddleware: PropTypes.func.isRequired,
    step: PropTypes.string.isRequired,
    substep: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.editImage = this.editImage.bind(this);
  }

  uploadImage(files) {
    const { editMiddleware, step, substep, line, batchId, uploadGelImages, updates } = this.props;
    editMiddleware(() => {
      uploadGelImages({
        files,
        body: {
          batch_id: batchId,
          updates,
          step_id: step,
          substep_id: substep,
          line_id: line,
        },
      });
    });
  }

  removeImage(imageName) {
    const { editMiddleware, step, substep, line, batchId, removeGelImage, updates } = this.props;
    editMiddleware(() => {
      removeGelImage({
        batch_id: batchId,
        image_name: imageName,
        updates,
        step_id: step,
        substep_id: substep,
        line_id: line,
      });
    });
  }

  async editImage(imageName, imageData) {
    const { step, substep, line, batchId, editGelImage, updates } = this.props;
    const res = await editGelImage({
      batch_id: batchId,
      image_name: imageName,
      image_data: imageData,
      updates,
      step_id: step,
      substep_id: substep,
      line_id: line,
    });
    try {
      return unwrapResult(res);
    } catch (e) {
      return e;
    }
  }

  render() {
    const { lineData, token, appearDisabled, readOnly, editMiddleware } = this.props;
    return (
      <Box sx={[appearDisabled ? { color: 'text.disabled' } : {}, { m: 3 }]}>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>{lineData.title}</Typography>
        {
          !readOnly || lineData.data.length ? (
            <ImageGallery
              images={lineData.data}
              getImageUrl={CommonService.getImageUrlBuilder('gel', token)}
              onDelete={!readOnly ? this.removeImage : null}
              onUpload={!readOnly ? this.uploadImage : null}
              onEdit={!readOnly ? this.editImage : null}
              onEditorOpen={(img, openEditor) => editMiddleware(openEditor)}
            />
          ) : (
            <Typography sx={{ textAlign: 'center' }}>None</Typography>
          )
        }
      </Box>
    );
  }
}

const { editGelImage, removeGelImage, uploadGelImages } = batchActions;
const { selectBatchId, selectBatchLineData, selectBatchUpdates } = batchSelectors;
const { selectSessionToken } = sessionSelectors;

export default connect(
  (state, ownProps) => ({
    batchId: selectBatchId(state),
    lineData: selectBatchLineData(state, ownProps.step, ownProps.substep, ownProps.line),
    updates: selectBatchUpdates(state),
    token: selectSessionToken(state),
  }),
  { uploadGelImages, removeGelImage, editGelImage },
)(GelImages);
