import PropTypes from 'prop-types';
import React from 'react';
import { featureTypes } from 'components/SeqView/SeqView.constants';
import { getAnnotationPointsLinear } from 'components/SeqView/SeqView.utils';

class AnnotationBlock extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      canvasStart: PropTypes.number.isRequired,
      canvasEnd: PropTypes.number.isRequired,
      blockRow: PropTypes.number.isRequired,
      type: PropTypes.oneOf(featureTypes).isRequired,
      strand: PropTypes.oneOf([1, -1]).isRequired,
      color: PropTypes.object.isRequired,
      location_id: PropTypes.number.isRequired,
      annotationId: PropTypes.string.isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
  };

  render() {
    const {
      feature,
      blockHeight,
      blockSpacing,
      stackUpHeight,
      onClick,
      onDoubleClick,
      onHover,
    } = this.props;
    const {
      canvasStart,
      canvasEnd,
      blockRow,
      type,
      strand,
      color,
      location_id,
      annotationId,
    } = feature;
    const width = canvasEnd - canvasStart;
    const yRow = blockRow * (blockHeight + blockSpacing);
    const y = !stackUpHeight ? yRow + blockHeight : stackUpHeight - yRow;
    const points = getAnnotationPointsLinear(canvasStart, y, width, blockHeight, type, strand);

    const clickable = onClick || onDoubleClick;
    return (
      <polygon
        points={points.map((p) => p.join(',')).join(' ')}
        fill={color}
        stroke='black'
        className={`${annotationId}_block`}
        onClick={onClick ? () => onClick(location_id) : null}
        onDoubleClick={onDoubleClick ? () => onDoubleClick(location_id) : null}
        style={clickable ? { cursor: 'pointer' } : {}}
        onMouseEnter={clickable ? () => onHover(feature, 'enter') : null}
        onMouseLeave={clickable ? () => onHover(feature, 'leave') : null}
      />
    );
  }
}

export default AnnotationBlock;
