import PropTypes from 'prop-types';
import React from 'react';

class AnnotationStem extends React.Component {
  static propTypes = {
    enzyme: PropTypes.shape({
      annotationId: PropTypes.string.isRequired,
      location: PropTypes.number.isRequired,
      textRow: PropTypes.number.isRequired,
    }).isRequired,
    blockHeight: PropTypes.number.isRequired,
    blockSpacing: PropTypes.number.isRequired,
    stackUpHeight: PropTypes.number,
  };

  render() {
    const { enzyme, blockHeight, blockSpacing, stackUpHeight } = this.props;
    const { annotationId, location, textRow } = enzyme;

    const yRow = (textRow * (blockHeight + blockSpacing)) + (blockHeight / 2);
    const y1 = stackUpHeight ? stackUpHeight - yRow : yRow;
    const y2 = stackUpHeight || 0;
    return (
      <line
        x1={location} y1={y1}
        x2={location} y2={y2}
        stroke='grey'
        className={`${annotationId}_stem`}
      />
    );
  }
}

export default AnnotationStem;
