import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig, { ORIGIN } from 'constants/oktaConfig';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Main from './Main';
import { Provider } from 'react-redux';
import React from 'react';
import { Security } from '@okta/okta-react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import store from 'store';
import { theme } from 'constants/theme';
import { useNavigate } from 'react-router-dom';

const oktaAuth = new OktaAuth(oktaConfig);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', ORIGIN));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
              <SnackbarProvider maxSnack={3}>
                <Main />
              </SnackbarProvider>
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Security>
  );
}

export default App;
