import { CLONING_BATCH, GIGA_BATCH, MAXI_BATCH, batchTypeDisplay } from 'constants/batch.constants';
import { ICON_CLONING, ICON_GIGA, ICON_MAXI } from 'constants/icon.constants';

import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function BatchType(props) {
  const { type, sx, children, size } = props;
  return (
    <Box
      sx={mergeSx({
        py: 0.5,
        px: 1,
        display: 'flex',
        borderRadius: 6,
        color: 'text.primary',
        height: size,
      }, sx)}
    >
      {
        type === CLONING_BATCH ? (
          <Icon icon={ICON_CLONING} height={size} width={size} />
        ) : type === MAXI_BATCH ? (
          <Icon icon={ICON_MAXI} height={size} width={size} />
        ) : type === GIGA_BATCH ? (
          <Icon icon={ICON_GIGA} width={size} height={size} />
        ) : null
      }
      <Typography
        sx={{ m: 'auto', ml: 1, fontSize: size / 2 }}
        variant={size > 36 ? 'h4' : 'button'}
        noWrap
      >
        {children || batchTypeDisplay[type]}
      </Typography>
    </Box>
  );
}

BatchType.propTypes = {
  /**
   * The type of batch being displayed. Currently supports 'MAXI' and 'CLONING'
   * types.
   */
  type: PropTypes.oneOf(Object.keys(batchTypeDisplay)).isRequired,

  /**
   * The content to be displayed. Typically this is the batch type and number.
   * If not provided, will default to either 'Cloning', 'Maxi Prep', or 'Giga
   * Prep' based on the type supplied by props.type.
   */
  children: PropTypes.node,

  /**
   * The size of the icon displayed (which also scales the font of the batch
   * textual content).
   */
  size: PropTypes.number,

  /** sx supplied to the wrapper container. */
  sx: sxPropType,
};

BatchType.defaultProps = {
  size: 24,
};

export default BatchType;
