import React, { useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

function InfoPopper(props) {
  const { children, placement, iconProps, buttonProps, tooltipProps, paperProps } = props;
  const popperAnchor = useRef(null);
  const [open, setOpen] = useState(false);

  const togglePopper = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        {...buttonProps}
        onClick={togglePopper}
        ref={popperAnchor}
      >
        {
          tooltipProps ? (
            <Tooltip {...tooltipProps}>
              <InfoIcon {...iconProps} />
            </Tooltip>
          ) : (
            <InfoIcon {...iconProps} />
          )
        }
      </IconButton>
      <Popper
        open={open}
        anchorEl={popperAnchor.current}
        placement={placement}
        sx={{ zIndex: 'modal' }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade {...TransitionProps}>
              <Paper elevation={5} {...paperProps}>
                {children}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

InfoPopper.propTypes = {
  /** The content to be displayed within the info popper. */
  children: PropTypes.node.isRequired,

  /**
   * Which direction the popper should be displayed in relationship to the
   * info icon.
   */
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),

  /** Props for the info icon. */
  iconProps: PropTypes.object,

  /** Props for the icon button. */
  buttonProps: PropTypes.object,

  /**
   * Props for the tooltip over the info icon. If none given, no tooltip will
   * render.
   */
  tooltipProps: PropTypes.object,

  /** Props for the paper container of the popper. */
  paperProps: PropTypes.object,
};

export default InfoPopper;
