import * as CommonService from 'services/CommonService';

import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Divider from '@mui/material/Divider';
import { FMT_DATE_TIME } from 'constants/dateFormats.constants';
import ImageGallery from 'components/ImageGallery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PrimerTable from 'components/PrimerTable';
import PropTypes from 'prop-types';
import React from 'react';
import Sequencing from 'views/Sequencing';
import Typography from '@mui/material/Typography';
import { batchSelectors } from 'store/batch';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date.utils';
import parse from 'html-react-parser';
import { sessionSelectors } from 'store/session';

class ReportStep extends React.Component {
  static propTypes = {
    batchId: PropTypes.number.isRequired,
    batchData: PropTypes.shape({
      DESIGN: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
      }).isRequired,
      PRIMER_ORDER: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
      }).isRequired,
      CLONING: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
        gel_images: PropTypes.array.isRequired,
      }).isRequired,
      SEQUENCING: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
        reaction_names: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
      SEQUENCE_ANALYSIS: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
      }).isRequired,
      MAXI_PREP: PropTypes.shape({
        completed: PropTypes.shape({
          at: PropTypes.string,
          by: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
        notes: PropTypes.string.isRequired,
        plasmid_backups_made: PropTypes.shape({ at: PropTypes.string }).isRequired,
        bacteria_stock_banked: PropTypes.shape({ at: PropTypes.string }).isRequired,
        bacteria_backup_banked: PropTypes.shape({ at: PropTypes.string }).isRequired,
        post_maxi_sequenced: PropTypes.shape({ at: PropTypes.string }).isRequired,
      }).isRequired,
    }).isRequired,
    constructs: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.create_report = this.create_report.bind(this);
  }

  create_report() {
    const { batchId, batchData, constructs, token } = this.props;
    const steps = [];
    const pushDivider = (step) => {
      steps.push(<Divider key={`${step} divider`} sx={{ my: 2 }} />);
    };

    const sxSubtitle = { textDecoration: 'underline' };

    // DESIGN
    const design_data = batchData.DESIGN;
    steps.push((
      <React.Fragment key='DESIGN'>
        <Typography variant='h4' sx={sxSubtitle}>Design</Typography>
        { design_data.completed && design_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(design_data.completed.at, FMT_DATE_TIME)} by {design_data.completed.by.name}
          </Typography>
        ) : null}
        {parse(design_data.notes)}
      </React.Fragment>
    ));
    pushDivider('DESIGN');

    // PRIMER_ORDER
    const primer_data = batchData.PRIMER_ORDER;
    steps.push((
      <React.Fragment key='PRIMER_ORDER'>
        <Typography variant='h4' sx={sxSubtitle}>Primer Order</Typography>
        { primer_data.completed && primer_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(primer_data.completed.at, FMT_DATE_TIME)} by {primer_data.completed.by.name}
          </Typography>
        ) : null}
        <PrimerTable constructs={constructs} />
        {parse(primer_data.notes)}
      </React.Fragment>
    ));
    pushDivider('PRIMER_ORDER');

    // CLONING
    const cloning_data = batchData.CLONING;
    const gelImages = cloning_data.gel_images;
    steps.push((
      <React.Fragment key='CLONING'>
        <Typography variant='h4' sx={sxSubtitle}>Cloning</Typography>
        { cloning_data.completed && cloning_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(cloning_data.completed.at, FMT_DATE_TIME)} by {cloning_data.completed.by.name}
          </Typography>
        ) : null}
        { gelImages.length ? (
          <ImageGallery
            images={gelImages}
            getImageUrl={CommonService.getImageUrlBuilder('gel', token)}
          />
        ) : null}
        {parse(cloning_data.notes)}
      </React.Fragment>
    ));
    pushDivider('CLONING');

    // SEQUENCING
    const sequencing_data = batchData.SEQUENCING;
    const reactionNames = sequencing_data.reaction_names;
    const numCols = Math.min(Math.ceil(reactionNames.length / 8), 4);
    steps.push((
      <React.Fragment key='SEQUENCING'>
        <Typography variant='h4' sx={sxSubtitle}>Sequencing</Typography>
        { sequencing_data.completed && sequencing_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(sequencing_data.completed.at, FMT_DATE_TIME)} by {sequencing_data.completed.by.name}
          </Typography>
        ) : null}
        <List dense component='ol' sx={{ columns: numCols, listStyle: 'none' }}>
          {reactionNames.map((reaction, idx) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <ListItem key={idx} sx={{ display: 'inline-block' }}>
              <Box sx={{ display: 'flex' }}>
                <ListItemIcon sx={{ my: 'auto' }}>{idx + 1}</ListItemIcon>
                <ListItemText sx={{ my: 'auto' }}>{reaction}</ListItemText>
              </Box>
            </ListItem>
          ))}
        </List>
        {parse(sequencing_data.notes)}
      </React.Fragment>
    ));
    pushDivider('SEQUENCING');

    // SEQUENCE_ANALYSIS
    const seq_analysis_data = batchData.SEQUENCE_ANALYSIS;
    steps.push((
      <React.Fragment key='SEQUENCE_ANALYSIS'>
        <Typography variant='h4' sx={sxSubtitle}>Sequence Analysis</Typography>
        { seq_analysis_data.completed && seq_analysis_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(seq_analysis_data.completed.at, FMT_DATE_TIME)} by {seq_analysis_data.completed.by.name}
          </Typography>
        ) : null}
        <Sequencing constructs={constructs} batchId={batchId} readOnly />
        {parse(seq_analysis_data.notes)}
      </React.Fragment>
    ));
    pushDivider('SEQUENCE_ANALYSIS');

    // MAXI_PREP
    const maxi_data = batchData.MAXI_PREP;
    const {
      plasmid_backups_made,
      bacteria_stock_banked,
      bacteria_backup_banked,
      post_maxi_sequenced,
    } = maxi_data;
    steps.push((
      <React.Fragment key='MAXI_PREP'>
        <Typography variant='h4' sx={sxSubtitle}>Maxi Prep</Typography>
        { maxi_data.completed && maxi_data.completed.at ? (
          <Typography variant='caption'>
            Completed on {formatDate(maxi_data.completed.at, FMT_DATE_TIME)} by {maxi_data.completed.by.name}
          </Typography>
        ) : null}
        <List dense>
          <ListItem>
            <ListItemIcon>
              {plasmid_backups_made.at ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText
              primary='Two separate plasmid backups made'
              secondary={plasmid_backups_made.at ? formatDate(plasmid_backups_made.at, FMT_DATE_TIME) : null}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {bacteria_stock_banked.at ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText
              primary='Bacteria working stock banked in -80ºC'
              secondary={bacteria_stock_banked.at ? formatDate(bacteria_stock_banked.at, FMT_DATE_TIME) : null}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {bacteria_backup_banked.at ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText
              primary='Bacteria backup banked in -80ºC'
              secondary={bacteria_backup_banked.at ? formatDate(bacteria_backup_banked.at, FMT_DATE_TIME) : null}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {post_maxi_sequenced.at ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText
              primary='Post maxi prep sequencing done'
              secondary={post_maxi_sequenced.at ? formatDate(post_maxi_sequenced.at, FMT_DATE_TIME) : null}
            />
          </ListItem>
        </List>
        {parse(maxi_data.notes)}
      </React.Fragment>
    ));

    return steps;
  }

  render() {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ m: 'auto', p: 3, width: 0.8 }}>
          <Paper variant='outlined' sx={{ p: 3 }}>
            { this.create_report() }
          </Paper>
        </Box>
      </Box>
    );
  }
}

const { selectBatchData, selectBatchId, selectConstructs } = batchSelectors;
const { selectSessionToken } = sessionSelectors;

export default connect(
  (state) => ({
    batchId: selectBatchId(state),
    batchData: selectBatchData(state),
    constructs: selectConstructs(state),
    token: selectSessionToken(state),
  }),
)(ReportStep);
