import PropTypes from 'prop-types';
import React from 'react';

class AnnotationStem extends React.Component {
  static propTypes = {
    feature: PropTypes.shape({
      blockRow: PropTypes.number,
      textRow: PropTypes.number,
      r_block: PropTypes.number.isRequired,
      r_text: PropTypes.number.isRequired,
      thetaStart: PropTypes.number.isRequired,
      thetaEnd: PropTypes.number.isRequired,
      color: PropTypes.shape({
        hex: PropTypes.func.isRequired,
      }).isRequired,
      annotationId: PropTypes.string.isRequired,
    }).isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
  };

  render() {
    const { feature, cx, cy } = this.props;
    const {
      blockRow,
      textRow,
      r_block,
      r_text,
      thetaStart,
      thetaEnd,
      color,
      annotationId,
    } = feature;
    if (blockRow === null || textRow === null) return null;

    const thetaCenter = ((thetaStart + thetaEnd) / 2) + (Math.PI / 2);

    return (
      <line
        x1={cx - (r_block * Math.cos(thetaCenter))}
        y1={cy - (r_block * Math.sin(thetaCenter))}
        x2={cx - (r_text * Math.cos(thetaCenter))}
        y2={cy - (r_text * Math.sin(thetaCenter))}
        stroke={color.hex() === '#FFFFFF' ? '#A3A3A3' : color}
        className={`${annotationId}_stem`}
      />
    );
  }
}

export default AnnotationStem;
