import { sequencingActions, sequencingSelectors } from 'store/sequencing';

import { Dialog } from '@acheloisbiosoftware/absui.core';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class NoAlignmentError extends React.Component {
  static propTypes = {
    noAlignments: PropTypes.arrayOf(PropTypes.shape({
      construct_code: PropTypes.string.isRequired,
      clone: PropTypes.number.isRequired,
      file_name: PropTypes.string.isRequired,
    })).isRequired,
    clearWarnings: PropTypes.func.isRequired,
  };

  render() {
    const { noAlignments, clearWarnings } = this.props;
    const onClose = () => clearWarnings('noAlignments');
    return (
      <Dialog
        open={noAlignments.length > 0}
        onClose={onClose}
        onConfirm={onClose}
        confirmOnly
        title='Failed to align'
        disableCloseOnConfirm
      >
        <DialogContentText>
          The following
          {noAlignments.length > 1 ? ' reads were ' : ' read was '}
          unable to be aligned:
        </DialogContentText>
        <Paper variant='outlined'>
          <List>
            {
              noAlignments.map((noAlignment) => (
                <ListItem key={`${noAlignment.construct_code}-${noAlignment.clone}_${noAlignment.file_name}`}>
                  <ListItemIcon><ErrorOutlineIcon sx={{ color: 'error.main' }} /></ListItemIcon>
                  <ListItemText primary={noAlignment.file_name} secondary={`${noAlignment.construct_code}-${noAlignment.clone}`} />
                </ListItem>
              ))
            }
          </List>
        </Paper>
      </Dialog>
    );
  }
}

const { selectUploadWarnings } = sequencingSelectors;
const { clearWarnings } = sequencingActions;

export default connect(
  (state) => ({
    noAlignments: selectUploadWarnings(state).noAlignments,
  }),
  { clearWarnings },
)(NoAlignmentError);
