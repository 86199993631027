import { BATCH, CONSTRUCT } from 'constants/inventory.constants';

import BatchButton from './BatchButton';
import Box from '@mui/material/Box';
import DeleteButton from './DeleteButton';
import ExportButton from './ExportButton';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';

function ActionToolbar(props) {
  const { clearSelected, selected, tableType } = props;
  const sxToolbar = { height: 72, bgcolor: 'primary.light' };
  const sxCenterButton = { mx: 1 };
  const sxDeleteButton = { position: 'absolute', right: 24 };

  if (tableType === CONSTRUCT) {
    return (
      <Toolbar sx={sxToolbar}>
        <Box sx={{ mx: 'auto' }}>
          <BatchButton
            sx={sxCenterButton}
            constructs={selected}
          />
          <ExportButton
            sx={sxCenterButton}
            constructs={selected}
          />
        </Box>
        <DeleteButton
          sx={sxDeleteButton}
          clearSelected={clearSelected}
          entityType='construct'
          entities={selected}
        />
      </Toolbar>
    );
  } else if (tableType === BATCH) {
    return (
      <Toolbar sx={sxToolbar}>
        <DeleteButton
          sx={sxDeleteButton}
          clearSelected={clearSelected}
          entityType='batch'
          entities={selected}
        />
      </Toolbar>
    );
  }
  return null;
}

ActionToolbar.propTypes = {
  clearSelected: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.object),
  tableType: PropTypes.oneOf([CONSTRUCT, BATCH]).isRequired,
};

export default ActionToolbar;
