import { batchStatuses, cloningStatuses } from 'constants/batch.constants';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import StatusPill from 'components/StatusPill';
import { bindMenu } from 'material-ui-popup-state';

class StatusMenu extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(Object.keys(batchStatuses)),
    onSelect: PropTypes.func.isRequired,
    popupState: PropTypes.shape({
      close: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    const { popupState, onSelect, type } = this.props;
    const options = batchStatuses[type] || cloningStatuses;
    return (
      <Menu
        {...bindMenu(popupState)}
        keepMounted
      >
        {
          options.map((s) => (
            <MenuItem
              key={s}
              onClick={(e) => {
                popupState.close(e);
                onSelect(s);
              }}
            >
              <StatusPill status={s} type={type} />
            </MenuItem>
          ))
        }
      </Menu>
    );
  }
}

export default StatusMenu;
