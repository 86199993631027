import Box from '@mui/material/Box';
import Checklist from 'components/Checklist';
import React from 'react';
import Typography from '@mui/material/Typography';

class ReactionRecipe extends React.Component {
  render() {
    const ingredients = [
      '300-500ng DNA',
      '1μL Primer (10μM)',
      'H20 to 15μL',
    ];
    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'block', m: 'auto', color: 'text.secondary' }}>
          <Typography variant='h5' sx={{ color: 'common.red', textDecoration: 'underline', mb: 0.5 }}>Reaction Recipe</Typography>
          <Checklist
            dense
            items={ingredients.map((ingredient) => ({ content: ingredient }))}
          />
        </Box>
      </Box>
    );
  }
}

export default ReactionRecipe;
