import * as MaterialsService from 'services/MaterialsService';

import { MATERIAL } from 'constants/store.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseToPayload } from 'utils/store.utils';

export const fetchEquipments = createAsyncThunk(
  `${MATERIAL}/fetch_equipments`,
  async (_, { rejectWithValue }) => {
    const res = await MaterialsService.getEquipments();
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchEquipment = createAsyncThunk(
  `${MATERIAL}/fetch_equipment`,
  async (equipmentId, { rejectWithValue }) => {
    const res = await MaterialsService.getEquipment(equipmentId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchMaterials = createAsyncThunk(
  `${MATERIAL}/fetch_materials`,
  async (withLots, { rejectWithValue }) => {
    const res = await MaterialsService.getMaterials(withLots);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchMaterial = createAsyncThunk(
  `${MATERIAL}/fetch_material`,
  async (materialId, { rejectWithValue }) => {
    const res = await MaterialsService.getMaterial(materialId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const createMaterialLot = createAsyncThunk(
  `${MATERIAL}/create_material_lot`,
  async (data, { rejectWithValue }) => {
    const res = await MaterialsService.createMaterialLot(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchChemicals = createAsyncThunk(
  `${MATERIAL}/fetch_chemicals`,
  async (withLots, { rejectWithValue }) => {
    const res = await MaterialsService.getChemicals(withLots);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchChemical = createAsyncThunk(
  `${MATERIAL}/fetch_chemical`,
  async (chemicalId, { rejectWithValue }) => {
    const res = await MaterialsService.getChemical(chemicalId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const createChemicalLot = createAsyncThunk(
  `${MATERIAL}/create_chemical_lot`,
  async (data, { rejectWithValue }) => {
    const res = await MaterialsService.createChemicalLot(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchBuffers = createAsyncThunk(
  `${MATERIAL}/fetch_buffers`,
  async (withBatches, { rejectWithValue }) => {
    const res = await MaterialsService.getBuffers(withBatches);
    return responseToPayload(res, rejectWithValue);
  },
);

export const fetchBuffer = createAsyncThunk(
  `${MATERIAL}/fetch_buffer`,
  async (bufferId, { rejectWithValue }) => {
    const res = await MaterialsService.getBuffer(bufferId);
    return responseToPayload(res, rejectWithValue);
  },
);

export const createBufferBatch = createAsyncThunk(
  `${MATERIAL}/create_buffer_batch`,
  async (data, { rejectWithValue }) => {
    const res = await MaterialsService.createBufferBatch(data);
    return responseToPayload(res, rejectWithValue);
  },
);

export const patchBufferBatch = createAsyncThunk(
  `${MATERIAL}/patch_buffer_batch`,
  async (data, { rejectWithValue }) => {
    const res = await MaterialsService.patchBufferBatch(data);
    return responseToPayload(res, rejectWithValue);
  },
);
